import React, { FC } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import RegisterAuth from "./RegisterAuth";

export const Register: FC = () => {
  let { path } = useRouteMatch();

  return (
    <div className="o-register__wrapper">
      <Switch>
        <Route exact path={`${path}/auth`}>
          <RegisterAuth />
        </Route>
      </Switch>
    </div>
  );
};
