import {
  FormControl,
  FormErrorMessage,
  InputGroup,
  InputLeftAddon,
  Select,
  Stack,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { useRouteMatch } from "react-router-dom";
import { LeadSource } from "types";
import { HomeownerHireContext } from "../../../context/homeowners/homeowner-hire.context";
import { logEvent } from "../../../helpers/analytics";
import { usePageView } from "../../../hooks/usePageView";
import {
  HireLayout,
  HireLayoutContent,
  HireLayoutFooter,
  HireLayoutHeader,
} from "./layout";
import { ThemedFormLabel } from "./themed-form-label";
import { ThemedInput } from "./themed-input";

const cleanPhoneNumber = (phoneNumberString: string) => {
  let cleaned = phoneNumberString.trim();
  // remove braces, dashes and spaces
  cleaned = cleaned.replace(/-|\s|\(|\)/g, "");

  if (cleaned.startsWith("0")) {
    cleaned = cleaned.replace(/^0/, "");
  }

  return cleaned;
};

export const isValidPhoneNumber = (phoneNumberString: string) => {
  const cleaned = cleanPhoneNumber(phoneNumberString);

  // Check if the cleaned number is 10 digits long
  if (cleaned.length === 10) {
    return true;
  }

  // If it's not 10 digits long, it's not a number.
  return false;
};

export const Profile = () => {
  usePageView();

  const match = useRouteMatch();

  const { formData, setFormData, isLoading } = useContext(HomeownerHireContext);

  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState(
    formData.phoneNumber || ""
  );
  const [leadSource, setLeadSource] = React.useState<LeadSource>();
  const [otherLeadSourceText, setOtherLeadSource] = React.useState<string>();

  const [showError, setShowError] = React.useState(false);

  const firstNameAlreadySet = !!formData.firstName;
  const lastNameAlreadySet = !!formData.lastName;

  let canProceed =
    isValidPhoneNumber(phoneNumber) &&
    firstName &&
    lastName &&
    leadSource &&
    (leadSource === LeadSource.Other ? !!otherLeadSourceText : true);

  if (firstNameAlreadySet && lastNameAlreadySet) {
    canProceed =
      isValidPhoneNumber(phoneNumber) &&
      leadSource &&
      (leadSource === LeadSource.Other ? !!otherLeadSourceText : true);
  }

  const handleProceed = () => {
    if (!canProceed) {
      setShowError(true);
      return;
    }

    logEvent("homeowner/hire/profile_set");

    setFormData(
      {
        ...(!firstNameAlreadySet && { firstName }),
        ...(!lastNameAlreadySet && { lastName }),
        phoneNumber: "+234" + cleanPhoneNumber(phoneNumber),
        leadSource,
        otherLeadSourceText,
      },
      "confirmation"
    );
  };

  const backToPath = match.url.replace(/\/[^/]+$/, "/start-date");

  const shouldCollectNames = !formData.firstName || !formData.lastName;

  return (
    <>
      <Helmet>
        <title>
          Profile Information | LaborHack Homeowners - Hire certified artisans
          for your home needs
        </title>
      </Helmet>
      <HireLayout>
        <HireLayoutHeader backTo={backToPath} isLoading={isLoading}>
          Complete your profile
        </HireLayoutHeader>
        <HireLayoutContent>
          <Stack spacing={4}>
            {shouldCollectNames && (
              <>
                <FormControl isInvalid={showError && !firstName}>
                  <ThemedFormLabel>First Name</ThemedFormLabel>
                  <ThemedInput
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                  <FormErrorMessage>
                    Please enter your first name
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={showError && !lastName}>
                  <ThemedFormLabel>Last Name</ThemedFormLabel>
                  <ThemedInput
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                  <FormErrorMessage>
                    Please enter your last name
                  </FormErrorMessage>
                </FormControl>
              </>
            )}

            <FormControl
              isInvalid={showError && !isValidPhoneNumber(phoneNumber)}
            >
              <ThemedFormLabel>Phone Number</ThemedFormLabel>
              <InputGroup size="lg">
                <InputLeftAddon
                  rounded="none"
                  border="1px"
                  borderColor="gray.300"
                  zIndex={99}
                >
                  🇳🇬 +234
                </InputLeftAddon>
                <ThemedInput
                  placeholder="8123456789"
                  value={phoneNumber}
                  type="tel"
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </InputGroup>

              <FormErrorMessage>
                Please enter a valid nigerian phone number
              </FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={
                showError &&
                (!leadSource ||
                  (leadSource === LeadSource.Other && !otherLeadSourceText))
              }
            >
              <ThemedFormLabel>How Did You Hear About Us</ThemedFormLabel>
              <Select
                value={leadSource}
                onChange={(e) => {
                  setLeadSource(e.target.value as LeadSource);
                }}
              >
                <option value="">Select an option</option>
                <option value={LeadSource.Friend_or_Referral}>
                  Friend or Referral
                </option>
                <option value={LeadSource.Marketing_Flyer}>
                  Marketing Flyer
                </option>
                <option value={LeadSource.Online_Search}>Online Search</option>
                <option value={LeadSource.Facebook_Post}>Facebook Post</option>
                <option value={LeadSource.Instagram_Post}>
                  Instagram Post
                </option>
                <option value={LeadSource.Twitter_Post}>Twitter Post</option>
                <option value={LeadSource.Facebook_Ad}>Facebook Ad</option>
                <option value={LeadSource.Instagram_Ad}>Instagram Ad</option>
                <option value={LeadSource.Youtube}>Youtube</option>
                <option value={LeadSource.LinkedIn}>LinkedIn</option>
                <option value={LeadSource.Radio}>Radio</option>
                <option value={LeadSource.TV}>TV</option>
                <option value={LeadSource.Voucher}>Voucher</option>
                <option value={LeadSource.Big_5_Construct_Exhibition}>
                  Big 5 Construct Exhibition
                </option>
                <option value={LeadSource.Newspaper}>Newspaper</option>
                <option value={LeadSource.Sales_Team}>Sales Team</option>
                <option value={LeadSource.Influencers}>Influencers</option>
                <option value={LeadSource.Other}>Other</option>
              </Select>
              {leadSource === LeadSource.Other && (
                <ThemedInput
                  mt={2}
                  placeholder="Please specify how you heard about us"
                  value={otherLeadSourceText}
                  onChange={(e) => setOtherLeadSource(e.target.value)}
                />
              )}
              <FormErrorMessage>
                Please indicate how you heard about us
              </FormErrorMessage>
            </FormControl>
          </Stack>
        </HireLayoutContent>
        <HireLayoutFooter isLoading={isLoading} onClick={handleProceed}>
          Proceed
        </HireLayoutFooter>
      </HireLayout>
    </>
  );
};
