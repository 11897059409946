import { useAuth0 } from "@auth0/auth0-react";
import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import {
  Link,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { Divider } from "_components/Divider";
import { useApplicationConfig } from "../../../context/ApplicationConfig";
import { logEvent } from "../../../helpers/analytics";
import { useQuery } from "../../../hooks";
import EmailLinkSignInForm from "../components/EmailLinkSignIn";
import FederatedIdentitySignInForm from "../components/FederatedIDPSignIn";

import businessCardImage from "assets/business-user-card.svg";
import artisanCardImage from "assets/artisan-user-card.svg";
import homeOwnerCardImage from "assets/homeowner-user-card.svg";

import styles from "./styles.module.scss";
import {
  Button,
  Heading,
  Radio,
  RadioGroup,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";

export interface LoginProps {}

export const Login: FC<LoginProps> = ({}) => {
  const { loginWithRedirect, isLoading } = useAuth0();

  const { toggles } = useApplicationConfig();

  const { pathname } = useLocation();

  const { path } = useRouteMatch();

  /**
   * Get the current user type route from url
   * /auth/register/<THIS_ITEM>
   */
  const selectedUserType = pathname.split("/")[3];

  const history = useHistory();
  const query = useQuery();
  const hideSelection = Boolean(query.get("hide_selection"));

  const continueUrl = query.get("continue");

  continueUrl && localStorage.setItem("continue_url_after_signin", continueUrl);

  const [userType, setUserType] = useState<string>(selectedUserType);

  const userTypeCardProps = [
    {
      text: "Company",
      image: businessCardImage,
      inactiveBgColor: "#A4A4A4",
      value: "business",
    },
    {
      text: "Artisan",
      image: artisanCardImage,
      inactiveBgColor: "#A4A4A4",
      value: "pro",
    },
    {
      text: "Home Owner",
      image: homeOwnerCardImage,
      inactiveBgColor: "#A4A4A4",
      value: "homeowner",
    },
  ];

  const handleUserTypeChange = (value: string) => {
    setUserType(value);
    history.push(`${path.replace("/:id", "")}/${value}`);
  };

  continueUrl && localStorage.setItem("continue_url_after_signin", continueUrl);

  useEffect(() => {
    logEvent("page_view");
  }, []);

  const handleLogin = () => {
    switch (userType) {
      case "business":
        loginWithRedirect({
          redirectUri: process.env.REACT_APP_MARKETPLACE_URL,
        });
        break;
      case "homeowner":
        window.open(process.env.REACT_APP_HOMEOWNER_DASHBOARD_URL, "_self");
        break;
      case "pro":
        loginWithRedirect({
          redirectUri: process.env.REACT_APP_PRO_APPLICATION_URL + "/trades",
        });
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Helmet>
        <title>Login | LaborHack</title>
      </Helmet>
      <VStack alignItems={"start"} width={"100%"} marginTop={"15%"}>
        <Heading
          color="#153243"
          fontWeight={700}
          fontSize={{
            base: "30px",
            md: "40px",
          }}
          marginBottom={4}
        >
          Welcome back!
        </Heading>
        <Text
          fontSize={{
            base: "16px",
            md: "18px",
          }}
          color="#A9A9A9"
        >
          Don&apos;t have an account yet?{" "}
          <Link
            className="underline text-[#ED6E5C]"
            to="/auth/register/homeowner"
          >
            {" "}
            Sign up
          </Link>
        </Text>

        <div className="my-8">
          {!hideSelection && (
            <>
              <Text
                fontSize={{
                  base: "18px",
                  md: "20px",
                }}
                fontWeight={500}
                color="#A9A9A9"
                marginBottom={4}
              >
                {`Log in as ${userType === "pro" ? "an" : "a"}:`}
              </Text>
              <div className="flex flex-col md:flex-row">
                <RadioGroup onChange={handleUserTypeChange} value={userType}>
                  <Stack
                    direction={{
                      base: "column",
                      md: "row",
                    }}
                    spacing={{
                      base: "10px",
                      md: "35px",
                    }}
                  >
                    {userTypeCardProps.map((props) => {
                      const isActive = props.value === userType;
                      return (
                        <Radio
                          key={props.text}
                          value={props.value}
                          size={"lg"}
                          colorScheme="flatteredFlamingo.500"
                        >
                          <Text
                            fontSize={{
                              base: "18px",
                              md: "20px",
                            }}
                            className={styles.radioText}
                            color={isActive ? "#5E5E5E" : props.inactiveBgColor}
                          >
                            {props.text}
                          </Text>
                        </Radio>
                      );
                    })}
                  </Stack>
                </RadioGroup>
              </div>
            </>
          )}
        </div>
        <Button
          color={"white"}
          bgColor="#ED6E5C"
          variant={"solid"}
          isLoading={isLoading}
          width={{
            base: "100%",
            md: "70%",
            lg: "100%",
          }}
          height={{
            base: "40px",
            md: "50px",
          }}
          onClick={handleLogin}
        >
          Proceed
        </Button>
      </VStack>
    </>
  );
};
