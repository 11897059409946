import { laborhackJurisdictions } from "./location-constants";

export const getNewSessionToken = () => {
  return new window.google.maps.places.AutocompleteSessionToken();
};

export const getTextSearchPredictions = async (
  query: string,
  token: google.maps.places.AutocompleteSessionToken
) => {
  const service = new google.maps.places.AutocompleteService();

  const results = await service.getPlacePredictions({
    input: query,
    types: ["geocode", "establishment"],
    sessionToken: token,
    componentRestrictions: {
      country: "NG",
    },
  });

  return results.predictions;
};

export const getLagosTextSearchPredictions = async (
  query: string,
  token: google.maps.places.AutocompleteSessionToken
) => {
  const predictions = await getTextSearchPredictions(query, token);

  // Filter predictions to include only those in places laborHack operates
  const lagosPredictions = predictions.filter((prediction) => {
    const predictionArray = prediction.description.split(",");

    const stateOrCity = predictionArray[predictionArray.length - 2].trim();

    return laborhackJurisdictions.includes(stateOrCity);
  });

  return lagosPredictions;
};


export const getPlaceDetails = async (
  placeId: string,
  token: google.maps.places.AutocompleteSessionToken
) => {
  const service = new google.maps.places.PlacesService(
    document.createElement("div")
  );

  const results = await new Promise<google.maps.places.PlaceResult>(
    (resolve, reject) => {
      service.getDetails(
        {
          placeId,
          sessionToken: token,
        },
        (result, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            resolve(result!);
          } else {
            reject(status);
          }
        }
      );
    }
  );

  return results;
};
