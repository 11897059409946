import { Modal, ModalContent, ModalOverlay } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { SelectCategory } from "./1-select-category";
import { DescribeTask } from "./2-describe-task";
import { ContactInformation } from "./3-contact-information";
import { HomeownerHireProvider } from "../../../context/homeowners/homeowner-hire.context";
import { AddressSelectorRoot } from "./4-address-selector";
import { StartDate } from "./5-start-date";
import { Confirmation } from "./7-confirmation";
import { Profile } from "./6-profile";
import {IsSubscriptionRequest} from "./is-subscription-request";
import {SubscriptionDetails} from "./subscription-details";
import { CustomSubscriptionDetails } from "./custom-subscription-details";
import { CantFindWhatYouWant } from "./cant-find-what-you-want";
import { SubscriptionPayment } from "./subscription-payment";

export const Root = () => {
  const match = useRouteMatch();

  return (
    <Modal isOpen={true} onClose={() => {}} size="2xl" isCentered>
      <ModalOverlay />
      <ModalContent borderRadius={0}>
        <HomeownerHireProvider>
          <Switch>
            <Route
              path={`${match.path}/confirmation`}
              component={Confirmation}
            />
            <Route path={`${match.path}/profile`} component={Profile} />
            <Route path={`${match.path}/start-date`} component={StartDate} />
            <Route
              path={`${match.path}/address-selector`}
              component={AddressSelectorRoot}
            />
            <Route
              path={`${match.path}/contact-information`}
              component={ContactInformation}
            />
            <Route
              path={`${match.path}/describe-task`}
              component={DescribeTask}
            />
            <Route
              path={`${match.path}/is-subscription-request`}
              component={IsSubscriptionRequest}
            />
            <Route
              path={`${match.path}/subscription-details`}
              component={SubscriptionDetails}
            />
             <Route
              path={`${match.path}/custom-subscription-details`}
              component={CustomSubscriptionDetails}
            />
            <Route
              path={`${match.path}/cant-find-what-you-want`}
              component={CantFindWhatYouWant}
            />
            <Route
              path={`${match.path}/subscription-payment`}
              component={SubscriptionPayment}
            />
            <Route path={`${match.path}`} component={SelectCategory} />
          </Switch>
        </HomeownerHireProvider>
      </ModalContent>
    </Modal>
  );
};
