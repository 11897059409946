import {
  Box,
  Button,
  Center,
  Grid,
  GridItem,
  Heading,
  Highlight,
  HStack,
  Icon,
  Image,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { BsAirplaneFill, BsArrowRight } from "react-icons/bs";
import { WorkAbroadRegistrationForm } from "../../features/work-abroad/registration-form";
import { logEvent } from "../../helpers/analytics";
import Footer from "../../_components/Footer";
import { Section } from "../landing/components/Section";
import Hero from "assets/work-abroad-hero.jpg";
import FAQs from "../../_components/FAQs";

import VerifiedSectionicon from "../../assets/verified-section-icon.svg";
import InsuranceSectionIcon from "../../assets/hospital-section-icon.svg";
import EarnSectionIcon from "../../assets/earn-section-icon.svg";
import YouTube from "react-youtube";
import {
  workAbroadFAQs,
  getRegistrationFormUrl,
  TELEGRAM_GROUP_LINK,
} from "../../features/work-abroad/constants";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { useQuery } from "../../hooks";

const BLOG_POST_URL =
  "https://blog.laborhack.com/israel-and-laborhack-collaborate-to-employ-2000-skilled-construction-workers/";

export const WorkAbroadPage = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const [showWebinarRegistration, setShowWebinarRegistration] =
    React.useState(false);

  /**
   * Get the referrer from the url using hooks
   */
  const query = useQuery();
  const referrer = query.get("referrer") || undefined;

  useEffect(() => {
    logEvent("page_view");
  }, []);

  const handleClose = () => {
    setIsModalOpen(false);
    setShowWebinarRegistration(false);
  };

  return (
    <>
      <Helmet>
        <title>Work Abroad | LaborHack</title>
      </Helmet>
      <Modal size="2xl" isOpen={isModalOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent top={12}>
          <ModalCloseButton />
          <VStack
            padding={{
              base: 8,
              lg: 12,
            }}
            alignItems="start"
            gap={8}
          >
            <Stack alignItems="start" gap={8}>
              <Stack alignItems="start">
                <Heading as="h3" size="lg">
                  Access International Opportunities
                </Heading>
                <Stack>
                  <Stack>
                    <Heading as="h4" size="md">
                      Opportunity Overview
                    </Heading>
                    <p>
                      Join a unique opportunity to work and live safely in
                      Central Israel, the commercial hub of Israel.
                      <br /> We are currently seeking skilled workers in tiling,
                      plastering, iron bending, form work, welding, and
                      aluminium work
                    </p>
                  </Stack>
                  <Stack>
                    <Heading as="h4" size="md">
                      Contract Duration
                    </Heading>
                    <p>
                      Successful candidates will be placed on a contract for a
                      maximum of 63 months.
                    </p>
                  </Stack>
                  <Stack>
                    <Heading as="h4" size="md">
                      Compensation
                    </Heading>
                    <p>
                      Earn a competitive rate of 10 euros per hour for a minimum
                      of 236 hours per month, working 6 days a week.
                      <br /> Additional benefits include free accommodation,
                      free local transportation from home to site daily, medical
                      insurance, annual vacation and pension.
                    </p>
                  </Stack>
                </Stack>
              </Stack>
              {!showWebinarRegistration && (
                <Stack w="full" gap={4}>
                  <Button
                    as="a"
                    href={getRegistrationFormUrl(referrer)}
                    colorScheme="flatteredFlamingo"
                    size="lg"
                  >
                    Register Now
                  </Button>
                  <Button
                    colorScheme="flatteredFlamingo"
                    size="lg"
                    variant="outline"
                    onClick={() => setShowWebinarRegistration(true)}
                  >
                    Join our Info Session
                  </Button>
                </Stack>
              )}
            </Stack>

            {showWebinarRegistration && <WorkAbroadRegistrationForm />}
          </VStack>
        </ModalContent>
      </Modal>
      <VStack w="full" spacing={0}>
        {/* hero */}
        <Section>
          <VStack w="full" spacing={12}>
            <VStack
              w={{
                base: "full",
                lg: "60%",
              }}
              alignItems="center"
              spacing={{
                base: 6,
                lg: 8,
              }}
            >
              <VStack
                w="full"
                alignItems="center"
                spacing={{
                  base: 3,
                  lg: 4,
                }}
              >
                <Heading
                  as="h1"
                  fontSize={{
                    base: 26,
                    md: 52,
                  }}
                  fontWeight="bold"
                  color="prussianBlue.500"
                  lineHeight={{
                    base: "base",
                    md: "shorter",
                  }}
                  textAlign="center"
                >
                  Get{" "}
                  <Highlight
                    query={["hired"]}
                    styles={{
                      padding: 0,
                      color: "flatteredFlamingo.500",
                    }}
                  >
                    hired
                  </Highlight>{" "}
                  globally
                  <br /> 10x faster and earn more
                </Heading>
                <Text
                  fontSize={{
                    base: 15,
                    lg: 24,
                  }}
                  lineHeight="base"
                  fontWeight="medium"
                  color="prussianBlue.500"
                  textAlign="center"
                >
                  Stand out from the crowd using your expertise and get paid in
                  Euros. Seize the opportunity to work abroad and achieve your
                  career goals.
                </Text>
              </VStack>

              <HStack w="full" spacing={4} justifyContent="center">
                <Button
                  colorScheme="flatteredFlamingo"
                  paddingY={{
                    base: 6,
                    lg: 10,
                  }}
                  paddingX={{
                    base: 8,
                    lg: 14,
                  }}
                  fontSize={{
                    base: 15,
                    lg: 22,
                  }}
                  rightIcon={<Icon as={BsArrowRight} w={6} h={6} />}
                  onClick={() => setIsModalOpen(true)}
                >
                  Register Now
                </Button>
              </HStack>
            </VStack>
          </VStack>
        </Section>
        <img
          src={Hero}
          alt="Work Abroad Hero"
          className="w-full h-[200px] sm:h-[400px] object-cover"
        />
        <Section>
          <Stack
            w="full"
            direction={{
              base: "column",
              lg: "row",
            }}
            py={{
              base: 6,
              lg: 0,
            }}
            spacing={{
              base: 4,
              lg: 16,
            }}
          >
            <HStack
              w="full"
              flex={1}
              justifyContent={{
                base: "center",
                lg: "start",
              }}
            >
              <Heading
                as="h2"
                fontSize={{
                  base: 22,
                  md: 44,
                }}
                fontWeight="bold"
                color="prussianBlue.500"
                lineHeight={{
                  base: "base",
                  md: "shorter",
                }}
                textAlign={{
                  base: "center",
                  lg: "start",
                }}
              >
                Enjoy the
                <br />{" "}
                <Highlight
                  query={["LaborHack"]}
                  styles={{
                    padding: 0,
                    color: "flatteredFlamingo.500",
                  }}
                >
                  LaborHack
                </Highlight>{" "}
                experience
              </Heading>
            </HStack>
            <HStack
              w="full"
              flex={1}
              justifyContent={{
                base: "center",
                lg: "start",
              }}
            >
              <Text
                fontSize={{
                  base: 15,
                  lg: 24,
                }}
                lineHeight="base"
                fontWeight={{
                  base: "normal",
                  lg: "medium",
                }}
                color="gray.500"
                textAlign={{
                  base: "center",
                  lg: "start",
                }}
              >
                We help you find international opportunities, get hired faster,
                get paid more and offer on-the-job support. That&apos;s the
                LaborHack Guarantee.
              </Text>
            </HStack>
          </Stack>
        </Section>
        <Section bgColor="gray.50">
          <VStack
            w="full"
            spacing={{
              base: 12,
              lg: 20,
            }}
          >
            <Stack
              w="full"
              direction={{
                base: "column",
                lg: "row",
              }}
              spacing={{
                base: 12,
                lg: 16,
              }}
            >
              <Box flex={1}>
                <VStack
                  w={{
                    base: "full",
                  }}
                  alignItems="start"
                  spacing={{
                    base: 2,
                    lg: 4,
                  }}
                >
                  <Stack
                    direction={{
                      base: "row",
                      lg: "column",
                    }}
                    spacing={{
                      base: 4,
                      lg: 8,
                    }}
                    alignItems={{
                      base: "center",
                      lg: "start",
                    }}
                  >
                    <Image
                      src={VerifiedSectionicon}
                      w={{
                        base: 10,
                        lg: 20,
                      }}
                    />
                    <Heading
                      as="h4"
                      fontSize={{
                        base: 18,
                        lg: 32,
                      }}
                      fontWeight="medium"
                    >
                      Access to International Jobs
                    </Heading>
                  </Stack>

                  <Text
                    fontSize={{
                      base: 15,
                      lg: 24,
                    }}
                    lineHeight="base"
                    fontWeight={{
                      base: "normal",
                      lg: "medium",
                    }}
                    color="gray.500"
                  >
                    We work with certified partners to bring you vetted work
                    opportunities around the world in your trade.
                  </Text>
                </VStack>
              </Box>
              <Box flex={1}>
                <VStack
                  w={{
                    base: "full",
                  }}
                  alignItems="start"
                  spacing={{
                    base: 2,
                    lg: 4,
                  }}
                >
                  <Stack
                    direction={{
                      base: "row",
                      lg: "column",
                    }}
                    spacing={{
                      base: 4,
                      lg: 8,
                    }}
                    alignItems={{
                      base: "center",
                      lg: "start",
                    }}
                  >
                    <Image
                      src={EarnSectionIcon}
                      w={{
                        base: 10,
                        lg: 20,
                      }}
                    />
                    <Heading
                      as="h4"
                      fontSize={{
                        base: 18,
                        lg: 32,
                      }}
                      fontWeight="medium"
                    >
                      Earn more money
                    </Heading>
                  </Stack>

                  <Text
                    fontSize={{
                      base: 15,
                      lg: 24,
                    }}
                    lineHeight="base"
                    fontWeight={{
                      base: "normal",
                      lg: "medium",
                    }}
                    color="gray.500"
                  >
                    Earn more than your counterparts by working with LaborHack
                    and it&apos;s partners to access high-paying jobs.
                  </Text>
                </VStack>
              </Box>
            </Stack>
            <Stack
              w="full"
              direction={{
                base: "column",
                lg: "row",
              }}
              spacing={{
                base: 12,
                lg: 16,
              }}
            >
              <Box flex={1}>
                <VStack
                  w={{
                    base: "full",
                  }}
                  alignItems="start"
                  spacing={{
                    base: 2,
                    lg: 4,
                  }}
                >
                  <Stack
                    direction={{
                      base: "row",
                      lg: "column",
                    }}
                    spacing={{
                      base: 4,
                      lg: 8,
                    }}
                    alignItems={{
                      base: "center",
                      lg: "start",
                    }}
                  >
                    <Center
                      rounded="full"
                      bg="white"
                      w={{
                        base: 10,
                        lg: 20,
                      }}
                      h={{
                        base: 10,
                        lg: 20,
                      }}
                    >
                      <Icon
                        as={BsAirplaneFill}
                        color="flatteredFlamingo.500"
                        w={{
                          base: 5,
                          lg: 10,
                        }}
                        h={{
                          base: 5,
                          lg: 10,
                        }}
                      />
                    </Center>
                    <Heading
                      as="h4"
                      fontSize={{
                        base: 18,
                        lg: 32,
                      }}
                      fontWeight="medium"
                    >
                      Explore a new country
                    </Heading>
                  </Stack>

                  <Text
                    fontSize={{
                      base: 15,
                      lg: 24,
                    }}
                    lineHeight="base"
                    fontWeight={{
                      base: "normal",
                      lg: "medium",
                    }}
                    color="gray.500"
                  >
                    While abroad, you get the opportunity to explore a new
                    Country and it&apos;s surrounding environment; expanding
                    your learned experiences
                  </Text>
                </VStack>
              </Box>

              <Box flex={1}>
                <VStack
                  w={{
                    base: "full",
                  }}
                  alignItems="start"
                  spacing={{
                    base: 2,
                    lg: 4,
                  }}
                >
                  <Stack
                    direction={{
                      base: "row",
                      lg: "column",
                    }}
                    spacing={{
                      base: 4,
                      lg: 8,
                    }}
                    alignItems={{
                      base: "center",
                      lg: "start",
                    }}
                  >
                    <Image
                      src={InsuranceSectionIcon}
                      w={{
                        base: 10,
                        lg: 20,
                      }}
                    />
                    <Heading
                      as="h4"
                      fontSize={{
                        base: 18,
                        lg: 32,
                      }}
                      fontWeight="medium"
                    >
                      On-the-job support
                    </Heading>
                  </Stack>

                  <Text
                    fontSize={{
                      base: 15,
                      lg: 24,
                    }}
                    lineHeight="base"
                    fontWeight={{
                      base: "normal",
                      lg: "medium",
                    }}
                    color="gray.500"
                  >
                    We work with our partners to offer on-the-job support
                    including training, local transportation, insurance, and
                    other perks
                  </Text>
                </VStack>
              </Box>
            </Stack>
          </VStack>
        </Section>
        <Section bgColor="gray.50">
          <VStack
            w="full"
            spacing={{
              base: 8,
              lg: 10,
            }}
          >
            <VStack
              w={{
                base: "80%",
                lg: "80%",
              }}
              spacing={{
                base: 3,
                lg: 6,
              }}
            >
              <Heading
                as="h2"
                fontSize={{
                  base: 22,
                  md: 44,
                }}
                fontWeight="bold"
                color="prussianBlue.500"
                lineHeight={{
                  base: "base",
                  md: "shorter",
                }}
                textAlign={{
                  base: "center",
                }}
              >
                Work abroad and
                <br className="hidden lg:block" /> earn more
              </Heading>
            </VStack>
            <Grid
              w="full"
              templateRows="repeat(4, 1fr)"
              templateColumns={{
                base: "repeat(1, 1fr)",
                lg: "repeat(4, 1fr)",
              }}
              gap={4}
            >
              <GridItem rowSpan={1} colSpan={1}>
                <HStack gap={4}>
                  <Heading
                    as="h3"
                    fontSize={{
                      base: "8xl",
                      lg: "9xl",
                    }}
                    color="prussianBlue.500"
                  >
                    01
                  </Heading>
                  <Text
                    as="a"
                    href={getRegistrationFormUrl(referrer)}
                    textDecoration="underline"
                    fontFamily="AeonikPro"
                    fontSize="2xl"
                  >
                    Complete Registration Form
                    <ExternalLinkIcon mx="2" />
                  </Text>
                </HStack>
              </GridItem>
              <GridItem
                colStart={2}
                rowSpan={1}
                colSpan={1}
                className="relative"
                display={{
                  base: "none",
                  lg: "block",
                }}
              >
                <div className="absolute border-r border-t border-red-600 w-20 h-20 bottom-0"></div>
              </GridItem>
              <GridItem
                colStart={{
                  base: 1,
                  lg: 2,
                }}
                rowStart={2}
                rowSpan={1}
                colSpan={1}
              >
                <HStack gap={4}>
                  <Heading
                    as="h3"
                    fontSize={{
                      base: "8xl",
                      lg: "9xl",
                    }}
                    color="prussianBlue.500"
                  >
                    02
                  </Heading>
                  <Text
                    fontFamily="AeonikPro"
                    fontSize="2xl"
                    as="a"
                    href={TELEGRAM_GROUP_LINK}
                    textDecoration="underline"
                  >
                    Join Community <ExternalLinkIcon mx="2" />
                  </Text>
                </HStack>
              </GridItem>
              <GridItem
                colStart={{
                  base: 1,
                  lg: 3,
                }}
                rowStart={2}
                rowSpan={1}
                colSpan={1}
                className="relative"
                display={{
                  base: "none",
                  lg: "block",
                }}
              >
                <div className="absolute border-r border-t border-red-600 w-20 h-20 bottom-0"></div>
              </GridItem>
              <GridItem
                colStart={{
                  base: 1,
                  lg: 3,
                }}
                rowStart={3}
                rowSpan={1}
                colSpan={1}
              >
                <HStack gap={4}>
                  <Heading
                    as="h3"
                    fontSize={{
                      base: "8xl",
                      lg: "9xl",
                    }}
                    color="prussianBlue.500"
                  >
                    03
                  </Heading>
                  <Text fontFamily="AeonikPro" fontSize="2xl">
                    Attend In-Person Skills Assesement
                  </Text>
                </HStack>
              </GridItem>
              <GridItem
                colStart={4}
                rowStart={3}
                rowSpan={1}
                colSpan={1}
                className="relative"
                display={{
                  base: "none",
                  lg: "block",
                }}
              >
                <div className="absolute border-r border-t border-red-600 w-20 h-20 bottom-0"></div>
              </GridItem>
              <GridItem
                colStart={{
                  base: 1,
                  lg: 4,
                }}
                rowStart={4}
                rowSpan={1}
                colSpan={1}
              >
                <HStack gap={4}>
                  <Heading
                    as="h3"
                    fontSize={{
                      base: "8xl",
                      lg: "9xl",
                    }}
                    color="prussianBlue.500"
                  >
                    04
                  </Heading>
                  <Text fontFamily="AeonikPro" fontSize="2xl">
                    Resume Work Abroad
                  </Text>
                </HStack>
              </GridItem>
            </Grid>
          </VStack>
        </Section>
        <Section hidden>
          <VStack
            w="full"
            spacing={{
              base: 8,
              lg: 10,
            }}
          >
            <VStack
              w={{
                base: "80%",
                lg: "80%",
              }}
              spacing={{
                base: 3,
                lg: 6,
              }}
            >
              <Heading
                as="h2"
                fontSize={{
                  base: 22,
                  md: 44,
                }}
                fontWeight="bold"
                color="prussianBlue.500"
                lineHeight={{
                  base: "base",
                  md: "shorter",
                }}
                textAlign={{
                  base: "center",
                }}
              >
                Hear what our Artisans have
                <br className="hidden lg:block" /> to say about LaborHack
              </Heading>
            </VStack>
            <Stack
              direction={{
                base: "column",
                lg: "row",
              }}
              w="full"
              spacing={6}
            >
              <VStack flex={1} spacing={0}>
                <YouTube
                  className="w-full"
                  videoId="qKxcQyiEMrc"
                  opts={{
                    width: "100%",
                    playerVars: {
                      mute: 1,
                      controls: 0,
                      loop: 1,
                      modestbranding: 1,
                    },
                  }}
                />
                <HStack
                  paddingX={5}
                  paddingY={6}
                  backgroundColor="lawnGreen.50"
                  w="full"
                >
                  <Text
                    fontSize={{
                      base: 16,
                      lg: 24,
                    }}
                    fontWeight="semibold"
                  >
                    Clement Osayande
                  </Text>
                </HStack>
              </VStack>
              <VStack flex={1} spacing={0}>
                <YouTube
                  className="w-full"
                  videoId="wdroFH9NsxQ"
                  opts={{
                    width: "100%",
                    playerVars: {
                      mute: 1,
                      controls: 0,
                      loop: 1,
                      modestbranding: 1,
                    },
                  }}
                />
                <HStack
                  paddingX={5}
                  paddingY={6}
                  backgroundColor="galleonBlue.50"
                  w="full"
                >
                  <Text
                    fontSize={{
                      base: 16,
                      lg: 24,
                    }}
                    fontWeight="semibold"
                  >
                    Kayode Anthony
                  </Text>
                </HStack>
              </VStack>
              <VStack flex={1} spacing={0}>
                <YouTube
                  className="w-full"
                  videoId="HbHXLNIljbk"
                  opts={{
                    width: "100%",
                    playerVars: {
                      mute: 1,
                      controls: 0,
                      loop: 1,
                      modestbranding: 1,
                    },
                  }}
                />
                <HStack
                  paddingX={5}
                  paddingY={6}
                  backgroundColor="flatteredFlamingo.50"
                  w="full"
                >
                  <Text
                    fontSize={{
                      base: 16,
                      lg: 24,
                    }}
                    fontWeight="semibold"
                  >
                    Michael Ogunnaike
                  </Text>
                </HStack>
              </VStack>
            </Stack>
          </VStack>
        </Section>
        <HStack
          w="full"
          bgColor="prussianBlue.500"
          justifyContent="center"
          position="relative"
        >
          <Box
            w="full"
            position="absolute"
            bgColor="white"
            minH={{
              base: 150,
              lg: 300,
            }}
            top={0}
          />
          <VStack
            zIndex={99}
            px={5}
            py={{
              base: 8,
              lg: 20,
            }}
            spacing={{
              base: 6,
              lg: 8,
            }}
            w="full"
            maxW="container.xl"
            alignItems="center"
          >
            <Heading
              as="h2"
              fontSize={{
                base: 22,
                md: 44,
              }}
              fontWeight="bold"
              color="prussianBlue.500"
              lineHeight={{
                base: "base",
                md: "shorter",
              }}
              textAlign={{
                base: "center",
              }}
            >
              Frequently Asked Questions
            </Heading>

            <Box
              w="full"
              paddingX={{
                base: 4,
                lg: 16,
              }}
              paddingY={{
                base: 4,
                lg: 8,
              }}
              bgColor="flatteredFlamingo.50"
            >
              <FAQs items={workAbroadFAQs} />
            </Box>
          </VStack>
        </HStack>
      </VStack>
      <Footer />
    </>
  );
};
