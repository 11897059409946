import React, { PropsWithChildren } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
} from "@chakra-ui/react";

export interface FAQProps {
  items: {
    title: string;
    body: React.JSX.Element | string;
  }[];
}

export const FAQs = ({ items }: PropsWithChildren<FAQProps>) => {
  const filtered = items.filter((item) => item.body !== "");

  return (
    <Accordion allowMultiple defaultIndex={[0]}>
      {filtered.map(({ title, body }, index) => {
        return (
          <AccordionItem
            key={title}
            borderTopWidth={index === 0 ? 0 : 1}
            borderBottomWidth={0}
            borderColor="gray.700"
          >
            <h2>
              <AccordionButton>
                <Box
                  fontFamily="inherit"
                  as="span"
                  fontSize={{
                    base: 16,
                    lg: 32,
                  }}
                  fontWeight="medium"
                  color="prussianBlue.500"
                  flex="1"
                  textAlign="left"
                  lineHeight="shorter"
                  py={{
                    base: 2,
                    lg: 8,
                  }}
                >
                  {title}
                </Box>
                <AccordionIcon
                  fontSize={{
                    base: 24,
                    lg: 36,
                  }}
                />
              </AccordionButton>
            </h2>

            <AccordionPanel
              pb={8}
              fontSize={{
                base: 14,
                lg: 24,
              }}
              fontWeight={{
                base: 400,
                lg: 500,
              }}
              color="gray.600"
              textAlign="left"
            >
              {body}
            </AccordionPanel>
          </AccordionItem>
        );
      })}
    </Accordion>
  );
};
