export const artisans = [
  "plumber",
  "electrician",
  "mason",
  "cleaner",
  "carpenter",
  "painter",
  "ac-technician",
  "solar-technician",
  "tiler",
  "dstv-installer",
  "fumigator",
  "pop-worker",
  "welder",
  "aluminum-worker",
  "artisan",
];

type ArtisanKey = "artisan";

interface AllowedArtisansValue {
  formattedName: string;
  categoryName: string;
  prompt?: string;
  services: Record<string, string>;
}

type SpecificArtisans = {
  [K in ArtisanKey]: AllowedArtisansValue;
};

type GeneralArtisans = {
  [K: string]: AllowedArtisansValue | undefined;
};

type AllowedArtisans = SpecificArtisans & GeneralArtisans;

export const allowedArtisans: AllowedArtisans = {
  plumber: {
    formattedName: "Plumber",
    categoryName: "Plumbing",
    services: {
      "plumbing-repair-services": "Plumbing Repair Services",
      "leak-detection-and-repair": "Leak Detection and Repair",
      "drain-cleaning-services": "Drain Cleaning Services",
      "plumbing-installation": "Plumbing Installation",
      "toilet-installation": "Toilet Installation",
    },
  },
  electrician: {
    formattedName: "Electrician",
    categoryName: "Electrical",
    services: {
      "electrical-repair-services": "Electrical Repair Services",
      "electrical-installation": "Electrical Installation",
      "electrical-maintenance": "Electrical Maintenance",
      "wiring-and-rewiring": "Wiring and Rewiring",
      "generator-installation": "Generator Installation",
      "lighting-installation": "Lighting Installation",
    },
  },
  mason: {
    formattedName: "Mason",
    categoryName: "Masonry",
    services: {
      "brick-and-stone-masonry": "Brick and Stone Masonry",
      "concrete-construction": "Concrete Construction",
    },
  },
  cleaner: {
    formattedName: "Cleaner",
    categoryName: "Cleaning",
    services: {
      "residential-cleaning-services": "Residential Cleaning Services",
      "commercial-cleaning": "Commercial Cleaning",
      "deep-cleaning-services": "Deep Cleaning Services",
      "carpet-and-upholstery-cleaning": "Carpet and Upholstery Cleaning",
      "house-cleaning-services": "House Cleaning Services",
      "office-cleaning": "Office Cleaning",
      "deep-cleaning": "Deep Cleaning",
      "carpet-cleaning": "Carpet Cleaning",
      "window-cleaning": "Window Cleaning",
      "post-construction-cleaning": "Post Construction Cleaning",
      "move-in-move-out-cleaning": "Move In/Move Out Cleaning",
      "janitorial-services": "Janitorial Services",
    },
  },
  carpenter: {
    formattedName: "Carpenter",
    categoryName: "Carpentry",
    services: {
      "custom-woodworking": "Custom Woodworking",
      "furniture-repair": "Furniture Repair",
      "cabinet-installation": "Cabinet Installation",
    },
  },
  painter: {
    formattedName: "Painter",
    categoryName: "Painting",
    services: {
      "interior-painting-services": "Interior Painting Services",
      "exterior-painting-services": "Exterior Painting Services",
      "commercial-painting": "Commercial Painting",
      "wallpaper-installation": "Wallpaper Installation",
    },
  },
  "ac-technician": {
    formattedName: "AC Technician",
    categoryName: "Airconditioning",
    prompt: "AC",
    services: {
      "air-conditioning-repair": "Air Conditioning Repair",
      "hvac-installation": "HVAC Installation",
      "ac-maintenance-services": "AC Maintenance Services",
      "thermostat-replacement": "Thermostat Replacement",
      "ac compressor repair": "AC Compressor Repair",
      "split-ac-installation": "Split AC Installation",
    },
  },
  "solar-technician": {
    formattedName: "Solar Technician",
    categoryName: "Solar Power",
    prompt: "solar installation",
    services: {
      "solar-panel-installation": "Solar Panel Installation",
      "solar-energy-system-design": "Solar Energy System Design",
      "solar-panel-maintenance": "Solar Panel Maintenance",
      "off-grid-solar-solutions": "Off Grid Solar Solutions",
    },
  },
  tiler: {
    formattedName: "Tiler",
    categoryName: "Tiling",
    services: {
      "tile-installation-services": "Tile Installation Services",
      "bathroom-tiling": "Bathroom Tiling",
      "kitchen-tiling": "Kitchen Tiling",
      "tile-repair-and-replacement": "Tile Repair and Replacement",
      "tile-removal": "Tile Removal",
    },
  },
  "dstv-installer": {
    formattedName: "DSTV Installer",
    categoryName: "DSTV",
    prompt: "DSTV installation",
    services: {
      "satellite-dish-installation": "Satellite Dish Installation",
      "dstv-setup-and-configuration": "DSTV Setup and Configuration",
      "signal-troubleshooting": "Signal Troubleshooting",
      "multi-room-dstv-installation": "Multi-Room DSTV Installation",
      "dstv-maintenance": "DSTV Maintenance",
      "dstv-intallation-services": "DSTV Installation Services",
    },
  },
  fumigator: {
    formattedName: "Fumigator",
    categoryName: "Fumigation",
    services: {
      "pest-control-services": "Pest Control Services",
      "termite-treatment": "Termite Treatment",
      "bed-bug-treatment": "Bed Bug Treatment",
    },
  },
  "pop-worker": {
    formattedName: "POP Worker",
    categoryName: "POP",
    prompt: "POP",
    services: {
      "pop-ceiling-installation": "POP Ceiling Installation",
      "pop-wall-design": "POP Wall Design",
      "decorative-pop-molding": "Decorative POP Molding",
      "pop-repair-and-renovation": "POP Repair and Renovation",
    },
  },
  welder: {
    formattedName: "Welder",
    categoryName: "Welding",
    services: {
      "metal-fabrication-services": "Metal Fabrication Services",
      "welding-repair": "Welding Repair",
      "custom-metal-work": "Custom Metal Work",
      "structural-welding": "Structural Welding",
    },
  },
  "aluminum-worker": {
    formattedName: "Aluminum Worker",
    categoryName: "Aluminum works",
    prompt: "aluminum works",
    services: {
      "aluminum-door-fabrication": "Aluminum Door Fabrication",
      "aluminum-window-installation": "Aluminum Window Installation",
    },
  },
  artisan: {
    formattedName: "Artisan",
    categoryName: "Other",
    prompt: "installation, repair or maintenance",
    services: {
      "maintenance-services": "Maintenance Services",
      "general-maintenance": "General Maintenance",
      "installation-services": "Installation Services",
      "repair-services": "Repair Services",
    },
  },
};

export const locations = [
  "lagos",
  "lekki",
  "ikate",
  "ikota",
  "vgc",
  "jakande",
  "agungi",
  "victoria-island",
  "ajah",
  "ikotun",
  "surulere",
  "magodo",
  "ikeja",
  "ikoyi",
  "yaba",
  "ilupeju",
  "shomolu",
  "agor-palace",
  "festac",
  "oniru",
  "chevron",
  "gbagada",
  "nigeria",
  "near-me",
];
