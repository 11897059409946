import Joi from "@hapi/joi";

export const validator = Joi.object({
  password: Joi.string().pattern(
    new RegExp(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
    )
  ),
  email: Joi.string().email({ minDomainSegments: 2, tlds: { allow: false } }),
});

export const resetPasswordValidator = validator.and("password");
