import { selectAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react";

var multiStyleConfig = createMultiStyleConfigHelpers(selectAnatomy.keys);

var definePartsStyle = multiStyleConfig.definePartsStyle;
var defineMultiStyleConfig = multiStyleConfig.defineMultiStyleConfig;

var xl = defineStyle({
  fontSize: "lg",
  px: "8",
  h: "24",
  borderRadius: 0,
});

var sizes = {
  xl: definePartsStyle({
    field: xl,
  }),
  md: definePartsStyle({
    field: {
      h: "46",
      borderRadius: 0,
      px: 4,
    },
  }),
};

export var selectTheme = defineMultiStyleConfig({ sizes });
