import React, { FC } from "react";
import { Redirect, RouteProps, Route } from "react-router-dom";
import { useAuth } from "hooks";
import { isLoggedIn } from "helpers/utils/funtions";
import Loading from "../Loading";
import { useAuth0 } from "@auth0/auth0-react";
import { useApplicationConfig } from "../../context/ApplicationConfig";

export const PrivateRoute: FC<RouteProps> = (props) => {
  const { user: firebaseuser, initializing } = useAuth();

  const { isLoading, user: auth0User } = useAuth0();

  const { toggles, loading } = useApplicationConfig();

  if (loading) {
    return <Loading />;
  }

  if (toggles?.use_auth0_explicitly) {
    return isLoading ? (
      <Loading />
    ) : auth0User ? (
      <Route {...props} />
    ) : (
      <Redirect to={`/auth/login?continue=${props.path}`} />
    );
  }

  return initializing ? (
    <Loading />
  ) : isLoggedIn(firebaseuser) ? (
    <Route {...props} />
  ) : (
    <Redirect to={`/auth/login?continue=${props.path}`} />
  );
};
