import React, { FC } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import CompleteProfile from "./completeProfile";
import CheckEmail from "./checkEmail";
import VerifyEmail from "./verifyEmail";
import Login from "./login";
import Register from "./register";
import { Link as RouterLink } from "react-router-dom";

import styles from "./styles.module.scss";
import clsx from "clsx";
import PrivateRoute from "../../_components/PrivateRoute";
import { Logo } from "../../_components/Logo/Logo";
import VerifyWaitlistedBusinessEmail from "./verifyWaitlistedBusinessEmail";
import { Img, Link } from "@chakra-ui/react";
import { useApplicationConfig } from "../../context/ApplicationConfig";
import { Auth0CompleteProfile } from "./completeProfile/Auth0CompleteProfile";
import ArtisanSideView from "../../assets/artisan-side-view.png"

export interface AuthProps {}

export const Auth: FC<AuthProps> = ({}) => {
  const { path } = useRouteMatch();
  const { toggles, loading } = useApplicationConfig();

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className={styles.wrapper}>
      <div
        className={clsx(
          styles.column,
          styles.authenticationPage,
          "px-8 lg:px-28"
        )}
      >
        <div className="w-full flex flex-col justify-center h-28">
          <Link as={RouterLink} to="/">
            <Logo height={24} width={174} />
          </Link>
        </div>
        <Switch>
          <Route path={`${path}/register`} component={Register} />
          <Route
            path={`${path}/verifyWaitlistedBusinessEmail`}
            component={VerifyWaitlistedBusinessEmail}
          />
          <Route path={`${path}/login`} component={Login} />
          <Route path={`${path}/verifyEmail`} component={VerifyEmail} />
          <Route path={`${path}/checkEmail`} component={CheckEmail} />
          <PrivateRoute
            path={`${path}/finishSignIn`}
            component={
              toggles?.use_auth0_explicitly
                ? Auth0CompleteProfile
                : CompleteProfile
            }
          />
        </Switch>
      </div>
      <div className={clsx(styles.column, "hidden lg:block", styles.rightSideImage)}>
    
      </div>
    </div>
  );
};
