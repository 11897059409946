import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { SavedAddressSelector } from "./4b-saved-address-selector";
import { AddressSelector } from "./4a-address-selector";

export const AddressSelectorRoot = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route
        path={`${match.path}/saved-addresses`}
        component={SavedAddressSelector}
      />
      <Route path={match.path} component={AddressSelector} />
    </Switch>
  );
};
