import Container from "@laborhack/container";
import React, { FC } from "react";
import MaintainSafeDistance from "assets/maintain-safe-distance.svg";
import { Icon } from "semantic-ui-react";

import styles from "./styles.module.scss";
import { PageSkeletion } from "../../_components/PageSkeleton/PageSkeletion";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import { logEvent } from "../../helpers/analytics";

export interface CovidGuidelinesProps {}

export const CovidGuidelines: FC<CovidGuidelinesProps> = ({}) => {
  useEffect(() => {
    logEvent("page_view");
  }, []);

  return (
    <PageSkeletion>
      <Helmet>
        <title>COVID-19 Guidelines</title>
        <meta
          name="description"
          content="LaborHack's COVID-19 guidelines for client and pro interaction"
        />
      </Helmet>
      <Container>
        <h2 className="text-2xl text-primary-500 font-bold w-full mb-4">
          Maintain a safe distance
        </h2>
        <div className={styles.imageWrapper}>
          <img src={MaintainSafeDistance} alt="" />
        </div>
        <ul className={styles.list}>
          <li>
            <Icon name="check"></Icon>
            <p>
              You and your family should maintain a safe distance when the
              professional is around
            </p>
          </li>
          <li>
            <Icon name="check"></Icon>
            <p>
              Please ensure you are in a different room if possible and follow
              social distancing norms to make this a low-contact service
            </p>
          </li>
          <li>
            <Icon name="check"></Icon>
            <p>
              Professionals are trained to clean and disinfect their work area
              after service delivery
            </p>
          </li>
        </ul>
      </Container>
    </PageSkeletion>
  );
};
