import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react";
import { inputAnatomy } from "@chakra-ui/anatomy";

var multiStyleConfig = createMultiStyleConfigHelpers(inputAnatomy.keys);

var definePartsStyle = multiStyleConfig.definePartsStyle;
var defineMultiStyleConfig = multiStyleConfig.defineMultiStyleConfig;

var xl = defineStyle({
  fontSize: 28,
  h: "84px",
  borderRadius: 0,
});

var sizes = {
  xl: definePartsStyle({ field: xl }),
};

export var inputTheme = defineMultiStyleConfig({
  sizes,
  defaultProps: {
    variant: "filled",
  },
});
