import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Grid,
  GridItem,
  Heading,
  Hide,
  Highlight,
  HStack,
  IconButton,
  Image,
  Link,
  Show,
  SlideFade,
  Stack,
  Text,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { Helmet } from "react-helmet";
import ProBadge from "../../assets/pro-badge.svg";

import Footer from "../../_components/Footer";
import { Section } from "../landing/components/Section";
import { TeamMember } from "./components/TeamMember";

// Hero
import HeroImageOne from "../../assets/about-us-hero-1.jpg";
import HeroImageTwo from "../../assets/about-us-hero-2.jpg";
import HeroImageThree from "../../assets/about-us-hero-3.jpg";
import HeroImageFour from "../../assets/about-us-hero-4.jpg";
import HeroImageFive from "../../assets/about-us-hero-5.jpg";

// Team Members
import Oare from "../../assets/about-us/oare.png";
import Patrick from "../../assets/about-us/patrick.png";
import Gary from "../../assets/about-us/gary.png";
import Henry from "../../assets/about-us/henry.png";

// Partners
import FutureAfrica from "../../assets/partners/future-africa.png";
import FoundersInstitute from "../../assets/partners/founders-institute.png";
import Techstars from "../../assets/partners/techstars.png";
import OPL from "../../assets/partners/opl-academy.png";
import Loyal from "../../assets/partners/loyal.png";
import ECITB from "../../assets/partners/ecitb.png";
import FUNEMA from "../../assets/partners/funema.png";
import TASC from "../../assets/partners/tasc.png";
import Kaleo from "../../assets/partners/kaleo.png";
import Samos from "../../assets/partners/samos.png";
import AlumniAngel from "../../assets/partners/alumni-angel.png";

// Perks
import Medical from "../../assets/medical-bw.svg";
import Diverse from "../../assets/diverse-bw.svg";
import Flexible from "../../assets/flexible-bw.svg";
import Paid from "../../assets/paid-bw.svg";
import Book from "../../assets/book-bw.svg";
import Pension from "../../assets/pension-bw.svg";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
} from "@chakra-ui/icons";
import { LABORHACK_CAREERS_URL } from "../../constants";

const values = [
  {
    title: "Conscientiousness",
    body: "We take our obligations to our clients seriously and put a lot of effort into our work. We are diligent, fair and considerate.",
  },
  {
    title: "Purpose",
    body: `We believe in meaningful satisfying work. Everything we do is
    done with the utmost sense of resolve and determination.`,
  },
  {
    title: "Knowledge",
    body: `We believe in acquiring skills through experience, research
    and instruction`,
  },
  {
    title: "Growth",
    body: `We believe in continuously improving our intelligence, ability and
    performance.`,
  },
];

const VISION = `To revolutionise the blue-collar skills industry by providing workers with predictable access to work at competitive pay leading to more secure financial futures while simplifying the process of sourcing, onboarding and managing workers for end users.`;

const MISSION = `To build Africa's largest pipeline of trained and 
certified workers and make them available on 
demand to companies that value them.`;

export const AboutUs = () => {
  const hiddenOnMobile = useBreakpointValue({
    base: true,
    lg: false,
  });

  const [isMissionVisible, setIsMissionVisible] = React.useState(false);

  return (
    <>
      <Helmet>
        <title>About</title>
        <meta
          name="description"
          content="We are on a mission to build Africa's largest pipeline of trained and certified workers and make them available on demand to companies that value them."
        />
      </Helmet>
      <VStack w="full" spacing={0}>
        {/* hero */}
        <Section>
          <VStack w="full" spacing={12}>
            <VStack
              w={{
                base: "full",
              }}
              alignItems="center"
              spacing={{
                base: 6,
                lg: 8,
              }}
            >
              <VStack
                w="full"
                alignItems="center"
                spacing={{
                  base: 3,
                  lg: 4,
                }}
              >
                <Text
                  textTransform="uppercase"
                  color="flatteredFlamingo.500"
                  fontSize={{
                    base: 14,
                    lg: 24,
                  }}
                  fontWeight="semibold"
                >
                  About Us
                </Text>
                <Heading
                  as="h1"
                  fontSize={{
                    base: 26,
                    md: 52,
                  }}
                  fontWeight="bold"
                  color="prussianBlue.500"
                  lineHeight={{
                    base: "base",
                    md: "shorter",
                  }}
                  textAlign="center"
                >
                  Empowering artisans,
                  <br /> powering projects
                </Heading>
              </VStack>
              <Grid
                h={{
                  base: "638px",
                  lg: "432px",
                }}
                w="full"
                templateAreas={{
                  base: `
                    "first first"
                    "first first"
                    "first first"
                    "first first"
                    "first first"
                    "third second"
                    "third second"
                    "third fifth"
                  `,
                  lg: `"first second third"
                  "first second third"
                  "first fourth third"
                  "first fourth fifth"
                  "first fourth fifth"
                    "first fourth fifth"
                    `,
                }}
                templateRows={{
                  base: "repeat(8, 1fr)",
                  lg: "repeat(6, 1fr)",
                }}
                templateColumns={{
                  base: "repeat(2, 1fr)",
                  lg: "2fr 1fr 1fr",
                }}
                gap={6}
              >
                <GridItem area={"first"}>
                  <Image
                    w="full"
                    src={HeroImageOne}
                    h="full"
                    objectFit="cover"
                  />
                </GridItem>
                <GridItem area={"second"}>
                  <Image
                    w="full"
                    src={HeroImageTwo}
                    h="full"
                    objectFit="cover"
                  />
                </GridItem>
                <GridItem area={"third"}>
                  <Image
                    w="full"
                    src={HeroImageThree}
                    h="full"
                    objectFit="cover"
                  />
                </GridItem>
                <GridItem area={"fourth"} hidden={hiddenOnMobile}>
                  <Image
                    w="full"
                    src={HeroImageFour}
                    h="full"
                    objectFit="cover"
                  />
                </GridItem>
                <GridItem area={"fifth"}>
                  <Image
                    w="full"
                    src={HeroImageFive}
                    h="full"
                    objectFit="cover"
                  />
                </GridItem>
              </Grid>

              {/* <HStack
                w="full"
                h="432px"
                spacing={6}
                justifyContent="space-between"
              >
                <Image
                  src={
                    "https://picsum.photos/seed/picsum/600/400?grayscale&blur=2"
                  }
                  h="full"
                />
                <HStack h="full">
                  <VStack>
                    <Image
                      src="https://picsum.photos/seed/picsum/600/400?grayscale&blur=2"
                      className="h-[calc(100%-100px)]"
                    />
                    <Image
                      src="https://picsum.photos/seed/picsum/600/400?grayscale&blur=2"
                      h={{
                        base: "70%",
                        lg: "30%",
                      }}
                    />
                  </VStack>
                </HStack>
              </HStack> */}
            </VStack>
          </VStack>
        </Section>
        <Section>
          <HStack
            w="full"
            spacing={{
              base: 12,
              lg: 36,
            }}
          >
            <Hide below="md">
              <Box flex={1}>
                <HStack
                  spacing={3}
                  w="full"
                  alignItems="center"
                  justifyContent="end"
                >
                  <Heading
                    as="h2"
                    fontSize={{
                      base: 22,
                      md: 44,
                    }}
                    fontWeight="bold"
                    color="prussianBlue.200"
                    lineHeight={{
                      base: "base",
                      md: "shorter",
                    }}
                    textAlign="center"
                  >
                    Our
                  </Heading>
                  <Box position="relative" w="180px" h="56px">
                    <SlideFade in={isMissionVisible} offsetY={"-50px"}>
                      <Heading
                        position="absolute"
                        as="h2"
                        fontSize={{
                          base: 22,
                          md: 44,
                        }}
                        fontWeight="bold"
                        color="prussianBlue.500"
                        lineHeight={{
                          base: "base",
                          md: "shorter",
                        }}
                        textAlign="center"
                      >
                        mission
                      </Heading>
                    </SlideFade>

                    <SlideFade in={!isMissionVisible} offsetY="50px">
                      <Heading
                        position={"absolute"}
                        as="h2"
                        fontSize={{
                          base: 22,
                          md: 44,
                        }}
                        fontWeight="bold"
                        color="prussianBlue.500"
                        lineHeight={{
                          base: "base",
                          md: "shorter",
                        }}
                        textAlign="center"
                      >
                        vision
                      </Heading>
                    </SlideFade>
                  </Box>
                </HStack>
              </Box>
            </Hide>

            <HStack spacing={6}>
              <Hide below="md">
                <VStack spacing={6}>
                  <IconButton
                    aria-label="View our vision"
                    icon={<ChevronUpIcon />}
                    fontSize={24}
                    isRound
                    colorScheme="prussianBlue"
                    isDisabled={!isMissionVisible}
                    size="sm"
                    onClick={() => setIsMissionVisible(false)}
                  />
                  <IconButton
                    aria-label="View our mission"
                    icon={<ChevronDownIcon />}
                    fontSize={24}
                    isRound
                    colorScheme="prussianBlue"
                    isDisabled={isMissionVisible}
                    onClick={() => setIsMissionVisible(true)}
                    size="sm"
                  />
                </VStack>
              </Hide>

              <Box bgColor="prussianBlue.100" position="relative" w={1} h={56}>
                <Box
                  position="absolute"
                  top={isMissionVisible ? "50%" : 0}
                  w="full"
                  h="50%"
                  bgColor="prussianBlue.500"
                />
              </Box>
            </HStack>

            <Box
              flex={2}
              h={{
                base: 56,
                lg: "auto",
              }}
            >
              <VStack alignItems="start" spacing={4}>
                <Show below="md">
                  <HStack justifyContent="space-between" w="full">
                    <HStack
                      spacing={2}
                      w="full"
                      alignItems="center"
                      justifyContent="start"
                    >
                      <Heading
                        as="h2"
                        fontSize={{
                          base: 22,
                          lg: 44,
                        }}
                        fontWeight="bold"
                        color="prussianBlue.200"
                        lineHeight={{
                          base: "base",
                          lg: "shorter",
                        }}
                        textAlign="center"
                      >
                        Our
                      </Heading>
                      <Box
                        position="relative"
                        w="120px"
                        h={{
                          base: "33px",
                          lg: "56px",
                        }}
                      >
                        <SlideFade in={isMissionVisible} offsetY={"-20px"}>
                          <Heading
                            position="absolute"
                            as="h2"
                            fontSize={{
                              base: 22,
                              lg: 44,
                            }}
                            fontWeight="bold"
                            color="prussianBlue.500"
                            lineHeight={{
                              base: "base",
                              lg: "shorter",
                            }}
                            textAlign="center"
                          >
                            mission
                          </Heading>
                        </SlideFade>

                        <SlideFade in={!isMissionVisible} offsetY="20px">
                          <Heading
                            position={"absolute"}
                            as="h2"
                            fontSize={{
                              base: 22,
                              lg: 44,
                            }}
                            fontWeight="bold"
                            color="prussianBlue.500"
                            lineHeight={{
                              base: "base",
                              lg: "shorter",
                            }}
                            textAlign="center"
                          >
                            vision
                          </Heading>
                        </SlideFade>
                      </Box>
                    </HStack>

                    <HStack spacing={4}>
                      <IconButton
                        aria-label="View our vision"
                        icon={<ChevronLeftIcon />}
                        fontSize={24}
                        isRound
                        colorScheme="prussianBlue"
                        isDisabled={!isMissionVisible}
                        size="sm"
                        onClick={() => setIsMissionVisible(false)}
                      />
                      <IconButton
                        aria-label="View our mission"
                        icon={<ChevronRightIcon />}
                        fontSize={24}
                        isRound
                        colorScheme="prussianBlue"
                        isDisabled={isMissionVisible}
                        onClick={() => setIsMissionVisible(true)}
                        size="sm"
                      />
                    </HStack>
                  </HStack>
                </Show>

                <Text
                  fontSize={{
                    base: 15,
                    lg: 24,
                  }}
                  fontWeight="medium"
                  color="prussianBlue.300"
                >
                  {isMissionVisible ? MISSION : VISION}
                </Text>
              </VStack>
            </Box>
          </HStack>
        </Section>
        <Section bgColor="gray.50">
          <VStack alignItems="center" w="full">
            <Heading
              as="h2"
              fontSize={{
                base: 26,
                lg: 52,
              }}
              fontWeight="bold"
              color="prussianBlue.200"
              lineHeight={{
                base: "base",
                md: "shorter",
              }}
              textAlign="center"
            >
              Values
            </Heading>
            <Accordion allowMultiple defaultIndex={[0]} w="full">
              {values.map(({ title, body }, index) => {
                return (
                  <AccordionItem
                    key={"title"}
                    borderTopWidth={index === 0 ? 0 : 1}
                    borderBottomWidth={0}
                    borderColor="gray.700"
                  >
                    <h2>
                      <AccordionButton>
                        <Box
                          as="span"
                          fontSize={{
                            base: 16,
                            lg: 32,
                          }}
                          fontFamily="inherit"
                          fontWeight="medium"
                          color="prussianBlue.500"
                          flex="1"
                          textAlign="left"
                          py={{
                            base: 2,
                            lg: 8,
                          }}
                        >
                          {title}
                        </Box>
                        <AccordionIcon
                          fontSize={{
                            base: 24,
                            lg: 36,
                          }}
                        />
                      </AccordionButton>
                    </h2>

                    <AccordionPanel
                      pb={8}
                      fontSize={{
                        base: 14,
                        lg: 24,
                      }}
                      fontWeight={{
                        base: 400,
                        lg: 500,
                      }}
                      color="gray.600"
                      textAlign="left"
                    >
                      {body}
                    </AccordionPanel>
                  </AccordionItem>
                );
              })}
            </Accordion>
          </VStack>
        </Section>
        <Section pt={8}>
          <VStack
            w="full"
            spacing={{
              base: 10,
              lg: 16,
            }}
          >
            <Heading
              as="h2"
              fontSize={{
                base: 22,
                md: 52,
              }}
              fontWeight="bold"
              color="prussianBlue.500"
              lineHeight={{
                base: "base",
                md: "shorter",
              }}
              textAlign="center"
              maxW={{
                base: "full",
                lg: "80%",
              }}
            >
              We have a great leadership team and are backed by leading
              investors and partners.
            </Heading>
            <Grid
              w="full"
              templateAreas={{
                base: `
                "first second"
                "third fourth"
                "fifth sixth"
                "seventh eighth"
                "ninth tenth"
                "eleventh twelfth"
                "thirteenth fourteenth"
                "fifteenth sixteenth"
              `,
                lg: `
                "first second third fourth"
                "fifth sixth seventh eighth"
                "ninth tenth eleventh twelfth"
                "thirteenth fourteenth fifteenth sixteenth"
                `,
              }}
              templateRows={{
                base: "repeat(6, 1fr)",
                lg: "repeat(3, 1fr)",
              }}
              templateColumns={{
                base: "repeat(2, 1fr)",
                lg: "repeat(4, 1fr)",
              }}
              gap={{
                base: 12,
                lg: 8,
              }}
            >
              <GridItem area={"first"}>
                <TeamMember
                  name="Oare Ehiemua-Ochui"
                  title="Chief Executive Officer"
                  image={Oare}
                />
              </GridItem>
              <GridItem area={"second"}>
                <TeamMember
                  name="Patrick Oladimeji"
                  title="Chief Technology Officer"
                  image={Patrick}
                />
              </GridItem>
              <GridItem area={"third"}>
                <TeamMember
                  name="Gary Schwartz"
                  title="Executive Advisor"
                  image={Gary}
                />
              </GridItem>
              <GridItem area={"fourth"}>
                <TeamMember
                  name="Henry Kaestner"
                  title="Executive Advisor"
                  image={Henry}
                />
              </GridItem>
              <GridItem area={"fifth"}>
                <TeamMember name="Future Africa" image={FutureAfrica} />
              </GridItem>
              <GridItem area={"sixth"}>
                <TeamMember
                  name="Founders Institute"
                  image={FoundersInstitute}
                />
              </GridItem>
              <GridItem area={"seventh"}>
                <TeamMember name="Techstars" image={Techstars} />
              </GridItem>
              <GridItem area={"eighth"}>
                <TeamMember name="OPL Academy" image={OPL} />
              </GridItem>
              <GridItem area={"ninth"}>
                <TeamMember name="Loyal VC" image={Loyal} />
              </GridItem>

              <GridItem area={"tenth"}>
                <TeamMember name="FUNEMA" image={FUNEMA} />
              </GridItem>
              <GridItem area={"eleventh"}>
                <TeamMember name="TASC" image={TASC} />
              </GridItem>
              <GridItem area={"twelfth"}>
                <TeamMember name="Kaleo VC" image={Kaleo} />
              </GridItem>
              <GridItem area={"thirteenth"}>
                <TeamMember name="Alumni Angel" image={AlumniAngel} />
              </GridItem>
              <GridItem area={"fourteenth"}>
                <TeamMember name="Samos VC" image={Samos} />
              </GridItem>
              <GridItem area={"fifteenth"}></GridItem>
              <GridItem area={"sixteenth"}></GridItem>
            </Grid>
          </VStack>
        </Section>
        <Section>
          <Stack
            w="full"
            direction={{
              base: "column",
              lg: "row",
            }}
            py={{
              base: 6,
              lg: 0,
            }}
            spacing={{
              base: 4,
              lg: 16,
            }}
          >
            <HStack
              w="full"
              flex={1}
              justifyContent={{
                base: "center",
                lg: "start",
              }}
            >
              <Heading
                as="h2"
                fontSize={{
                  base: 22,
                  md: 44,
                }}
                fontWeight="bold"
                color="prussianBlue.500"
                lineHeight={{
                  base: "base",
                  md: "shorter",
                }}
                textAlign={{
                  base: "center",
                  lg: "start",
                }}
                maxW={{
                  base: "60%",
                  md: "70%",
                }}
              >
                Perks of working with{" "}
                <Highlight
                  query={["LaborHack"]}
                  styles={{
                    padding: 0,
                    color: "flatteredFlamingo.500",
                  }}
                >
                  LaborHack
                </Highlight>
              </Heading>
            </HStack>
            <HStack
              w="full"
              flex={1}
              justifyContent={{
                base: "center",
                lg: "start",
              }}
            >
              <Text
                fontSize={{
                  base: 15,
                  lg: 24,
                }}
                lineHeight="base"
                fontWeight={{
                  base: "normal",
                  lg: "medium",
                }}
                color="gray.500"
                textAlign={{
                  base: "center",
                  lg: "start",
                }}
              >
                We&apos;re a passionate, collaborative team, and we&apos;re
                always working to enable the best possible environment so all
                LaborHackers can succeed.
              </Text>
            </HStack>
          </Stack>
        </Section>
        <Section bgColor="gray.50">
          <VStack
            w="full"
            spacing={{
              base: 12,
              lg: 20,
            }}
          >
            <Stack
              w="full"
              direction={{
                base: "column",
                lg: "row",
              }}
              spacing={{
                base: 12,
                lg: 16,
              }}
            >
              <Box flex={1}>
                <VStack
                  w={{
                    base: "full",
                  }}
                  alignItems="start"
                  spacing={{
                    base: 2,
                    lg: 4,
                  }}
                >
                  <Stack
                    direction={{
                      base: "row",
                      lg: "column",
                    }}
                    spacing={{
                      base: 4,
                      lg: 8,
                    }}
                    alignItems={{
                      base: "center",
                      lg: "start",
                    }}
                  >
                    <Image
                      src={Medical}
                      w={{
                        base: 10,
                        lg: 20,
                      }}
                    />
                    <Heading
                      as="h4"
                      fontSize={{
                        base: 18,
                        lg: 32,
                      }}
                      fontWeight="medium"
                    >
                      Medical cover
                    </Heading>
                  </Stack>

                  <Text
                    fontSize={{
                      base: 15,
                      lg: 24,
                    }}
                    lineHeight="base"
                    fontWeight={{
                      base: "normal",
                      lg: "medium",
                    }}
                    color="gray.500"
                  >
                    We provide comprehensive health insurance to cover your
                    medical needs.
                  </Text>
                </VStack>
              </Box>

              <Box flex={1}>
                <VStack
                  w={{
                    base: "full",
                  }}
                  alignItems="start"
                  spacing={{
                    base: 2,
                    lg: 4,
                  }}
                >
                  <Stack
                    direction={{
                      base: "row",
                      lg: "column",
                    }}
                    spacing={{
                      base: 4,
                      lg: 8,
                    }}
                    alignItems={{
                      base: "center",
                      lg: "start",
                    }}
                  >
                    <Image
                      src={Diverse}
                      w={{
                        base: 10,
                        lg: 20,
                      }}
                    />
                    <Heading
                      as="h4"
                      fontSize={{
                        base: 18,
                        lg: 32,
                      }}
                      fontWeight="medium"
                    >
                      Diverse team
                    </Heading>
                  </Stack>

                  <Text
                    fontSize={{
                      base: 15,
                      lg: 24,
                    }}
                    lineHeight="base"
                    fontWeight={{
                      base: "normal",
                      lg: "medium",
                    }}
                    color="gray.500"
                  >
                    Our team is composed of amazing talents and people with
                    unique perspectives.
                  </Text>
                </VStack>
              </Box>

              <Box flex={1}>
                <VStack
                  w={{
                    base: "full",
                  }}
                  alignItems="start"
                  spacing={{
                    base: 2,
                    lg: 4,
                  }}
                >
                  <Stack
                    direction={{
                      base: "row",
                      lg: "column",
                    }}
                    spacing={{
                      base: 4,
                      lg: 8,
                    }}
                    alignItems={{
                      base: "center",
                      lg: "start",
                    }}
                  >
                    <Image
                      src={Flexible}
                      w={{
                        base: 10,
                        lg: 20,
                      }}
                    />
                    <Heading
                      as="h4"
                      fontSize={{
                        base: 18,
                        lg: 32,
                      }}
                      fontWeight="medium"
                    >
                      Flexible working
                    </Heading>
                  </Stack>

                  <Text
                    fontSize={{
                      base: 15,
                      lg: 24,
                    }}
                    lineHeight="base"
                    fontWeight={{
                      base: "normal",
                      lg: "medium",
                    }}
                    color="gray.500"
                  >
                    We believe in a hybrid working model and flexible working
                    conditions.
                  </Text>
                </VStack>
              </Box>
            </Stack>
            <Stack
              w="full"
              direction={{
                base: "column",
                lg: "row",
              }}
              spacing={{
                base: 12,
                lg: 16,
              }}
            >
              <Box flex={1}>
                <VStack
                  w={{
                    base: "full",
                  }}
                  alignItems="start"
                  spacing={{
                    base: 2,
                    lg: 4,
                  }}
                >
                  <Stack
                    direction={{
                      base: "row",
                      lg: "column",
                    }}
                    spacing={{
                      base: 4,
                      lg: 8,
                    }}
                    alignItems={{
                      base: "center",
                      lg: "start",
                    }}
                  >
                    <Image
                      src={Paid}
                      w={{
                        base: 10,
                        lg: 20,
                      }}
                    />
                    <Heading
                      as="h4"
                      fontSize={{
                        base: 18,
                        lg: 32,
                      }}
                      fontWeight="medium"
                    >
                      Paid annual leave
                    </Heading>
                  </Stack>

                  <Text
                    fontSize={{
                      base: 15,
                      lg: 24,
                    }}
                    lineHeight="base"
                    fontWeight={{
                      base: "normal",
                      lg: "medium",
                    }}
                    color="gray.500"
                  >
                    We offer all team members 20 days of paid annual leave to
                    recharge their batteries.
                  </Text>
                </VStack>
              </Box>

              <Box flex={1}>
                <VStack
                  w={{
                    base: "full",
                  }}
                  alignItems="start"
                  spacing={{
                    base: 2,
                    lg: 4,
                  }}
                >
                  <Stack
                    direction={{
                      base: "row",
                      lg: "column",
                    }}
                    spacing={{
                      base: 4,
                      lg: 8,
                    }}
                    alignItems={{
                      base: "center",
                      lg: "start",
                    }}
                  >
                    <Image
                      src={Book}
                      w={{
                        base: 10,
                        lg: 20,
                      }}
                    />
                    <Heading
                      as="h4"
                      fontSize={{
                        base: 18,
                        lg: 32,
                      }}
                      fontWeight="medium"
                    >
                      Professional development
                    </Heading>
                  </Stack>

                  <Text
                    fontSize={{
                      base: 15,
                      lg: 24,
                    }}
                    lineHeight="base"
                    fontWeight={{
                      base: "normal",
                      lg: "medium",
                    }}
                    color="gray.500"
                  >
                    We invest in our people and support opportunities for
                    learning and growth.
                  </Text>
                </VStack>
              </Box>
              <Box flex={1}>
                <VStack
                  w={{
                    base: "full",
                  }}
                  alignItems="start"
                  spacing={{
                    base: 2,
                    lg: 4,
                  }}
                >
                  <Stack
                    direction={{
                      base: "row",
                      lg: "column",
                    }}
                    spacing={{
                      base: 4,
                      lg: 8,
                    }}
                    alignItems={{
                      base: "center",
                      lg: "start",
                    }}
                  >
                    <Image
                      src={Pension}
                      w={{
                        base: 10,
                        lg: 20,
                      }}
                    />
                    <Heading
                      as="h4"
                      fontSize={{
                        base: 18,
                        lg: 32,
                      }}
                      fontWeight="medium"
                    >
                      Employee pension
                    </Heading>
                  </Stack>

                  <Text
                    fontSize={{
                      base: 15,
                      lg: 24,
                    }}
                    lineHeight="base"
                    fontWeight={{
                      base: "normal",
                      lg: "medium",
                    }}
                    color="gray.500"
                  >
                    We provide all team members with an employee pension plan.
                  </Text>
                </VStack>
              </Box>
            </Stack>
          </VStack>
        </Section>

        <Section bg="prussianBlue.500">
          <VStack
            w="full"
            spacing={{
              base: 6,
              lg: 12,
            }}
            alignItems="start"
          >
            <HStack
              w="full"
              justifyContent={{
                base: "start",
                lg: "space-between",
              }}
            >
              <Heading
                as="h5"
                fontSize={{
                  base: "2xl",
                  md: "5xl",
                }}
                fontWeight="normal"
                color="white"
                lineHeight={{
                  base: "base",
                  md: "shorter",
                }}
                w={{
                  base: "full",
                  lg: "55%",
                }}
              >
                <strong>Want to join the team?</strong>
              </Heading>
              <Image
                src={ProBadge}
                alt="LaborHack professional badge"
                w={128}
                display={{
                  base: "none",
                  lg: "block",
                }}
              />
            </HStack>
            <HStack w="full">
              <Link
                href={LABORHACK_CAREERS_URL}
                w={{
                  base: "full",
                  lg: "40%",
                }}
              >
                <Button
                  w="full"
                  py={6}
                  size={{
                    base: "lg",
                    md: "xl",
                  }}
                >
                  See Open Roles
                </Button>
              </Link>
            </HStack>
          </VStack>
        </Section>
        <Footer />
      </VStack>
    </>
  );
};
