import React, { PropsWithChildren } from "react";
import { Avatar, Flex, Menu, MenuButton, MenuList } from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { User } from "@auth0/auth0-react";
import { LoggedInMenuContent } from "./logged-in-menu-content";

export interface LoggedInMenuProps {
  user: User;
  isClient: boolean;
  isPro: boolean;
  logout: () => void;
}

export const LoggedInMenu = ({
  user,
  isClient,
  isPro,
  logout,
}: PropsWithChildren<LoggedInMenuProps>) => {
  return (
    <Menu>
      <MenuButton>
        <Flex alignItems="center" gap={4}>
          <Avatar size="sm" name={user.name} src={user.picture} />
          <h5 className="text-lg">
            Hi, {user.given_name || user.nickname} <br />
          </h5>
          <ChevronDownIcon w="6" h="6" />
        </Flex>
      </MenuButton>
      <MenuList>
        <LoggedInMenuContent
          email={user.email || ""}
          isClient={isClient}
          isPro={isPro}
          logout={logout}
        />
      </MenuList>
    </Menu>
  );
};
