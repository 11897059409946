import React from "react";
import { createRoot } from "react-dom/client";
import { Auth0Provider } from "@auth0/auth0-react";
import { builder } from "@builder.io/react";
import { BrowserRouter } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { Wrapper as GoogleMapsWrapper } from "@googlemaps/react-wrapper";
import "semantic-ui-css/semantic.min.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import { components as AppComponents } from "./components/app";

import "./react-dates-overrides.css";
import "./index.css";
import theme from "./theme";
import { Global } from "@emotion/react";
import AeonikPro from "./assets/fonts/AeonikPro.ttf";

const Fonts = () => (
  <Global
    styles={`
  @font-face {
    font-family: 'AeonikPro';
    src: url(${AeonikPro}) format('truetype');
    font-weight: 100 900;
  }
`}
  />
);

builder.init(process.env.REACT_APP_BUILDER_KEY || "");

const { App } = AppComponents;

const container = document.getElementById("root");

const root = createRoot(container!);

root.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN || ""}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ""}
    audience={process.env.REACT_APP_AUTH0_AUDIENCE || ""}
    scope="openid profile email phone"
  >
    <BrowserRouter>
      <ChakraProvider theme={theme}>
        <Fonts />
        <GoogleMapsWrapper
          apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY!}
          libraries={["places"]}
        >
          <App />
        </GoogleMapsWrapper>
      </ChakraProvider>
    </BrowserRouter>
  </Auth0Provider>
);
