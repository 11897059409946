import React from "react";

import { Heading, Highlight, Stack, Text } from "@chakra-ui/react";

import { Section } from "pages/landing/components/Section";
import { HeroSectionCTA } from "./hero-section-cta";

export const HeroSection = () => {
  return (
    <Section>
      <Stack direction="column" w="full" align="center" spacing={12}>
        <Stack
          direction="column"
          w={{
            base: "full",
            lg: "75%",
          }}
          alignItems="center"
          spacing={{
            base: 6,
            lg: 8,
          }}
        >
          <Stack
            direction="column"
            w="full"
            alignItems="center"
            spacing={{
              base: 3,
              lg: 4,
            }}
          >
            <Heading
              as="h1"
              fontSize={{
                base: 26,
                md: 52,
              }}
              fontWeight="bold"
              color="prussianBlue.500"
              lineHeight={{
                base: "base",
                md: "shorter",
              }}
              textAlign="center"
            >
              Hire skilled{" "}
              <Highlight
                query={["artisans"]}
                styles={{
                  padding: 0,
                  color: "flatteredFlamingo.500",
                }}
              >
                artisans
              </Highlight>{" "}
              for
              <br /> your household tasks
            </Heading>
            <Text
              fontSize={{
                base: 15,
                lg: 24,
              }}
              lineHeight="base"
              fontWeight="medium"
              color="prussianBlue.500"
              textAlign="center"
              maxW={{
                base: "full",
                lg: "80%",
              }}
            >
              Find well-vetted and skilled workers to handle all your cleaning,
              electrical repairs, maintenance, and plumbing needs. Just post
              your job to get a quote.
            </Text>
          </Stack>

          <HeroSectionCTA />
        </Stack>
      </Stack>
    </Section>
  );
};
