import React, { FC, useEffect } from "react";
import { Container, Grid } from "semantic-ui-react";
import socialDistance from "../../../assets/social-distancing.png";
import protectionMask from "../../../assets/protection-mask.png";
import WashHands from "../../../assets/wash-your-hands.png";
import spray from "../../../assets/spray.png";
import ShowMore from "react-show-more";
import { Text1, Text2, Text3, Text4 } from "./../constants";
import { Helmet } from "react-helmet";
import { logEvent } from "helpers/analytics";
import { Footer } from "_components/Footer/Footer";

const Covid: FC = () => {
  useEffect(() => {
    logEvent("page_view");
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Covid Response</title>
      </Helmet>
      <div className="o-covid__page">
        <div className="covid-hero__section">
          <Container>
            <Grid>
              <div className="hero-content">
                <h4>COVID-19</h4>
                <h2>
                  More Safety Measures <br />
                  Than Ever
                </h2>
                <p>
                  From social distancing to stringent sanitizing and
                  disinfecting, we are here to work for your safety.
                </p>
              </div>
            </Grid>
          </Container>
        </div>
        <div className="covid-response__section">
          <Container>
            <div className="response-header">
              <h3>LaborHack COVID-19 Response</h3>
            </div>

            <Grid>
              <Grid.Row>
                <Grid.Column width="8" computer="8" mobile="16">
                  <div className="response-card">
                    <div className="response-left-icon">
                      <div className="icon_box">
                        <img src={WashHands} alt="wash-hands" />
                      </div>
                    </div>
                    <div className="response-right-content">
                      <h4>
                        Maintaining <br />
                        Hygiene
                      </h4>
                      <p>
                        <ShowMore
                          lines={2}
                          more="See more"
                          less="See less"
                          anchorClass=""
                        >
                          {Text1}
                        </ShowMore>
                      </p>
                    </div>
                  </div>
                </Grid.Column>
                <Grid.Column width="8" computer="8" mobile="16">
                  <div className="response-card">
                    <div className="response-left-icon">
                      <div className="icon_box">
                        <img src={protectionMask} alt="nose-mask" />
                      </div>
                    </div>
                    <div className="response-right-content">
                      <h4>
                        Wearing
                        <br />
                        Masks
                      </h4>
                      <p>
                        <ShowMore
                          lines={2}
                          more="See more"
                          less="See less"
                          anchorClass=""
                        >
                          {Text2}
                        </ShowMore>
                      </p>
                    </div>
                  </div>
                </Grid.Column>
                <Grid.Column width="8" computer="8" mobile="16">
                  <div className="response-card">
                    <div className="response-left-icon">
                      <div className="icon_box">
                        <img src={spray} alt="disinfectant" />
                      </div>
                    </div>
                    <div className="response-right-content">
                      <h4>
                        Disinfecting <br />
                        work areas
                      </h4>
                      <p>
                        <ShowMore
                          lines={2}
                          more="See more"
                          less="See less"
                          anchorClass=""
                        >
                          {Text3}
                        </ShowMore>
                      </p>
                    </div>
                  </div>
                </Grid.Column>
                <Grid.Column width="8" computer="8" mobile="16">
                  <div className="response-card">
                    <div className="response-left-icon">
                      <div className="icon_box">
                        <img src={socialDistance} alt="social-distancing" />
                      </div>
                    </div>
                    <div className="response-right-content">
                      <h4>
                        Social
                        <br />
                        Distancing
                      </h4>
                      <p>
                        <ShowMore
                          lines={2}
                          more="See more"
                          less="See less"
                          anchorClass=""
                        >
                          {Text4}
                        </ShowMore>
                      </p>
                    </div>
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Covid;
