import React, { FC, SyntheticEvent } from "react";

import styles from "./styles.module.scss";
import { Dropdown as SemanticDropdown, Form } from "semantic-ui-react";
import clsx from "clsx";

export interface DropdownProps {
  options: { key: string; text: string; value: string; description?: string }[];
  value?: string;
  onChange: (values: any, e?: SyntheticEvent<HTMLElement, Event>) => void;
  placeholder?: string;
  label?: string;
  required?: boolean;
  className?: string;
}

export const Dropdown: FC<DropdownProps> = ({
  options,
  label,
  value,
  onChange,
  placeholder,
  required,
  className,
}) => {
  return (
    <Form.Field required={required} className={clsx(styles.input, className)}>
      {label && <label>{label}</label>}
      <SemanticDropdown
        className={styles.selectInput}
        selection
        fluid
        placeholder={placeholder}
        onChange={(e, data) => {
          onChange(data.value, e);
        }}
        options={options}
        value={value}
      />
    </Form.Field>
  );
};
