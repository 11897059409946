import React from "react";
import { Input, InputProps } from "@chakra-ui/react";
import { PropsWithChildren } from "react";

export const ThemedInput = ({
  children,
  ...rest
}: PropsWithChildren<InputProps>) => {
  return <Input rounded="none" variant="filled" size="lg" {...rest} />;
};
