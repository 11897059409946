import {
  Box,
  Button,
  Heading,
  Highlight,
  HStack,
  Image,
  Link,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import FAQs from "../../_components/FAQs";
import Footer from "../../_components/Footer";
import { Section } from "../landing/components/Section";
import {
  generalFAQs,
  artisanFAQs,
  businessFAQs,
  homeownerFAQs,
} from "./constants";
import ProBadge from "../../assets/pro-badge.svg";
import { Link as RouterLink } from "react-router-dom";
import { Helmet } from "react-helmet";

export const FAQ = () => {
  const sections = [
    {
      title: "General",
      items: generalFAQs,
    },
    {
      title: "Business",
      items: businessFAQs,
    },
    {
      title: "Homeowner",
      items: homeownerFAQs,
    },
    {
      title: "Artisan",
      items: artisanFAQs,
    },
  ];

  return (
    <>
      <Helmet>
        <title>Frequently Asked Questions</title>
        <meta
          name="description"
          content="Got Questions? We're here to help!
Here are some of our frequently asked questions. For more enquiries, don't hesitate to contact us.
"
        />
      </Helmet>
      <VStack spacing={0}>
        <Section bg="gray.50">
          <VStack
            w="full"
            spacing={4}
            py={{
              base: 10,
              lg: 24,
            }}
          >
            <Heading
              fontSize={{
                base: 26,
                md: 52,
              }}
              textAlign="center"
              lineHeight="normal"
            >
              <Highlight
                query={["Got Questions?"]}
                styles={{
                  fontWeight: "normal",
                  color: "gray.400",
                }}
              >
                Got Questions?
              </Highlight>
              <br />
              We&apos;re here to help!
            </Heading>
            <Text
              fontSize={{
                base: 15,
                md: 24,
              }}
              fontWeight="medium"
              color="gray.500"
              w={{
                base: "80%",
                lg: "50%",
              }}
              textAlign="center"
            >
              Here are some of our frequently asked questions. For more
              enquiries, don&apos;t hesitate to contact us.
            </Text>
          </VStack>
        </Section>
        <HStack
          w="full"
          bgColor="prussianBlue.500"
          justifyContent="center"
          position="relative"
        >
          <Box
            w="full"
            position="absolute"
            bgColor="white"
            minH={{
              base: 150,
              lg: 300,
            }}
            top={0}
          />
          <VStack
            zIndex={99}
            px={5}
            pt={{
              base: 8,
              lg: 20,
            }}
            spacing={{
              base: 6,
              lg: 8,
            }}
            w="full"
            maxW="container.xl"
            alignItems="center"
          >
            <Tabs size="lg" variant="unstyled" w="full" align="center">
              <TabList>
                {sections.map(({ title }) => (
                  <Tab
                    fontSize={{
                      base: 15,
                      lg: 32,
                    }}
                    fontWeight="medium"
                    color="gray.400"
                    key={title}
                    _selected={{
                      color: "prussianBlue.900",
                    }}
                  >
                    {title}
                  </Tab>
                ))}
              </TabList>
              <TabPanels>
                {sections.map(({ items, title }) => (
                  <TabPanel key={title} px={0}>
                    <Box
                      w="full"
                      paddingX={{
                        base: 4,
                        lg: 16,
                      }}
                      paddingY={{
                        base: 4,
                        lg: 8,
                      }}
                      bgColor="flatteredFlamingo.50"
                    >
                      <FAQs items={items} />
                    </Box>
                  </TabPanel>
                ))}
              </TabPanels>
            </Tabs>
          </VStack>
        </HStack>
        <Section bg="prussianBlue.500">
          <VStack
            w="full"
            spacing={{
              base: 6,
              lg: 12,
            }}
            alignItems="start"
          >
            <HStack
              w="full"
              justifyContent={{
                base: "start",
                lg: "space-between",
              }}
            >
              <Heading
                as="h5"
                fontSize={{
                  base: "2xl",
                  md: "5xl",
                }}
                fontWeight="normal"
                color="white"
                lineHeight={{
                  base: "base",
                  md: "shorter",
                }}
                w={{
                  base: "full",
                  lg: "55%",
                }}
              >
                <strong>Get skilled professionals to care for</strong> all your
                home needs.
              </Heading>
              <Image
                src={ProBadge}
                alt="LaborHack professional badge"
                w={128}
                display={{
                  base: "none",
                  lg: "block",
                }}
              />
            </HStack>
            <HStack w="full">
              <Link
                as={RouterLink}
                to="/auth/register"
                w={{
                  base: "full",
                  lg: "40%",
                }}
              >
                <Button
                  w={"full"}
                  py={6}
                  size={{
                    base: "lg",
                    md: "xl",
                  }}
                >
                  Hire a Pro
                </Button>
              </Link>
            </HStack>
          </VStack>
        </Section>
        <Footer />
      </VStack>
    </>
  );
};
