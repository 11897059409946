import { useAuth0 } from "@auth0/auth0-react";
import firebase from "helpers/firebase";
import { useState, useEffect } from "react";
/**
 * Hook to subscribe to firbase AuthState observer.
 * Probably would only be used at the top level of the application.
 * There is a useSession hook (./useSessionHook) which can be used in child components to get the currentUser
 */

interface UseAuthResponse {
  initializing: boolean;
  user: firebase.User | null;
  idTokenResult?: firebase.auth.IdTokenResult;
}

export const useAuth = () => {
  const [state, setState] = useState<UseAuthResponse>(() => {
    // get current user if it exists
    const user = firebase.auth().currentUser;

    return {
      initializing: !user, // if there is no current user set initializing to true else false
      user,
    };
  });

  useEffect(() => {
    const unSubscribe = firebase.auth().onAuthStateChanged((user) => {
      user
        ? user.getIdTokenResult(true).then((idTokenResult) => {
            setState({
              initializing: false,
              user,
              idTokenResult,
            });
          })
        : setState({
            initializing: false,
            user,
          });
    });

    return () => unSubscribe();
  }, []);

  return state;
};
