import { menuAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

/**
 * I will explain after the call why var!!!
 */

var { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(menuAnatomy.keys);

// define the base component styles
var baseStyle = definePartsStyle({
  // define the part you're going to style
  button: {
    fontFamily: `'Inter', sans-serif`,
  },
});

export var menuTheme = defineMultiStyleConfig({ baseStyle });
