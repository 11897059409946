import React from "react";
import { StackProps, VStack } from "@chakra-ui/react";
import { PropsWithChildren } from "react";

export const SectionHeader = ({
  children,
  ...props
}: PropsWithChildren<StackProps>) => {
  return (
    <VStack
      w={{
        base: "90%",
        lg: "full",
      }}
      alignItems={{
        base: "center",
        lg: "start",
      }}
      spacing={{
        base: 3,
        lg: 4,
      }}
      {...props}
    >
      {children}
    </VStack>
  );
};
