import React, { FC } from "react";
import { Grid, Container } from "semantic-ui-react";

const NotFoundPage: FC = () => {
  return (
    <Container>
      <Grid>
        <Grid.Row>
          <div className="o-notFound__container">
            <div className="o-error__page-lg">
              <h1 className="glitch">404</h1>
              <p>Page Not Found</p>
            </div>
          </div>
        </Grid.Row>
      </Grid>
    </Container>
  );
};
export default NotFoundPage;
