import { gql, useMutation, useQuery } from "@apollo/client";
import React, { FC, useEffect } from "react";
import { useLocation } from "react-router-dom";
import RequestFailed from "../../_components/RequestFailed";
import orderConfirmed from "assets/order-confirmed.svg";
import Container from "../../_components/Container";
import Loading from "../../_components/Loading";
import { Logo } from "../../_components/Logo/Logo";
import { AccountActivation, Contact } from "./types";
import { AddContactForm } from "./AddContactForm";

const MARK_APPROVAL = gql`
  mutation MarkApproval(
    $token: String!
    $contacts: [CreateBusinessClientInput!]
  ) {
    markBusinessAsApproved(token: $token, contacts: $contacts) {
      id
      client {
        id
      }
    }
  }
`;

const GET_ACTIVATION_BY_TOKEN = gql`
  query GetActivation($token: String!) {
    accountActivationByToken(token: $token) {
      id
      client {
        id
      }
    }
  }
`;

export interface ActivateBusinessProps {}

export const ActivateBusiness: FC<ActivateBusinessProps> = () => {
  const { search } = useLocation();

  /** get the token from url */
  const query = new URLSearchParams(search);

  const token = query.get("token");

  const { loading, error, data } = useQuery<{
    accountActivationByToken: AccountActivation;
  }>(GET_ACTIVATION_BY_TOKEN, {
    variables: {
      token,
    },
  });

  const [markApproval, markApprovalResponse] = useMutation<{
    markBusinessClientAsApproved: AccountActivation;
  }>(MARK_APPROVAL, {
    variables: {
      token,
    },
  });

  useEffect(() => {
    if (data?.accountActivationByToken.client) {
      markApproval();
    }
  }, [data]);

  if (!token) {
    return <RequestFailed text="Invalid token" />;
  }

  if (loading) {
    return <Loading />;
  }

  if (error || markApprovalResponse.error) {
    return (
      <RequestFailed
        text={error?.message || markApprovalResponse.error?.message}
      />
    );
  }

  const handleSubmit = (contact: Contact) => {
    markApproval({
      variables: {
        token,
        contacts: [contact],
      },
    });
  };

  return (
    <>
      <div className="w-full flex justify-start py-4 px-8 bg-white">
        <Logo width={120} />
      </div>
      <Container className="bg-white">
        {markApprovalResponse.data ? (
          <div className="flex flex-col flex-wrap w-full justify-center items-center">
            <img
              className="w-60 md:w-80"
              src={orderConfirmed}
              alt="an illustration of a woman wearing a suit standing next to a giant phone with a checked sign on screen"
            />
            <article className="mt-8 text-center">
              <h1 className="text-xl text-primary-500 font-bold">
                Thank you for your interest in LaborHack. We look forward to
                staying in touch.
              </h1>
            </article>
          </div>
        ) : (
          <AddContactForm
            onSubmit={handleSubmit}
            loading={markApprovalResponse.loading}
          />
        )}
      </Container>
    </>
  );
};
