import { accordionAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

var multiStyleConfig = createMultiStyleConfigHelpers(accordionAnatomy.keys);

var definePartsStyle = multiStyleConfig.definePartsStyle;
var defineMultiStyleConfig = multiStyleConfig.defineMultiStyleConfig;

var baseStyle = definePartsStyle({
  panel: {
    fontFamily: `"Inter", sans-serif`,
  },
  button: {
    fontFamily: `"AeonikPro", sans-serif`,
  },
});

export var accordionTheme = defineMultiStyleConfig({
  baseStyle: baseStyle,
});
