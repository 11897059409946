import React, { ReactNode, useState } from "react";
import {
  Box,
  useBreakpointValue,
  ResponsiveValue,
  StackProps,
  Stack,
  Text,
  Flex,
} from "@chakra-ui/react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

interface Impact {
  imageSrc: string;
  description: ReactNode;
  imageLabel?: string;
}

interface ImpactProps extends StackProps {
  items: Impact[];
}

const ImpactCarousel: React.FC<ImpactProps> = ({ items, ...stackProps }) => {
  const [activeItem, setActiveItem] = useState(0);

  const isColumnLayout = useBreakpointValue<ResponsiveValue<any>>({
    base: true,
    md: true,
    lg: false,
  });

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplaySpeed: 20000,
    autoplay: true,
    arrows: isColumnLayout ? false : true,
  };

  return (
    <Stack
      direction="column"
      width="100%"
      p={0}
      {...stackProps}
      css={{
        ".slick-arrow": {
          borderRadius: "50%",
          top: "-126px",
        },
        ".slick-prev": {
          marginLeft: "90%",
          left: "-20px",
        },
        ".slick-next": {
          marginRight: "5%",
          right: "-23px",
        },
        ".slick-arrow:before": {
          color: "#121212 !important",
          opacity: "1 !important",
          fontSize: "64px",
        },
      }}
    >
      <Flex
        h="108px"
        w={{ base: "95%", md: "656px" }}
        alignItems={isColumnLayout && "center"}
        mb={{ base: 0, md: "48px" }}
        alignSelf={isColumnLayout && "center"}
      >
        <Text
          fontWeight={700}
          fontSize={{ base: "22px", md: "44px" }}
          fontFamily="AeonikPro"
          lineHeight={{ base: "30px", md: "54px" }}
          textAlign={isColumnLayout && "center"}
        >
          See how LaborHack has helped companies with their projects
        </Text>
      </Flex>
      <Slider {...settings}>
        {items?.map((item, index) => (
          <Stack
            key={item.imageSrc + item.description}
            direction={isColumnLayout ? "column" : "row"}
            width="100%"
            bgColor={isColumnLayout ? "white !important" : "#F8F8F8 !important"}
            display="flex !important"
          >
            <Box
              width={isColumnLayout ? "100%" : "45%"}
              bgImage={item.imageSrc}
              minH="462px"
              bgPosition="center"
              bgRepeat="no-repeat"
            >
              {item.imageLabel && (
                <Text
                  color="white"
                  fontSize={isColumnLayout ? "16px" : "24px"}
                  fontStyle="normal"
                  fontWeight={isColumnLayout ? "600" : "700"}
                  lineHeight="32px"
                  display="inline-flex"
                  padding={isColumnLayout ? "8px 16px" : "12px 16px"}
                  justifyContent="center"
                  alignItems="center"
                  gap="8px"
                  background="#404040"
                  position="relative"
                  bottom="-380px"
                  left="24px"
                >
                  {item.imageLabel}
                </Text>
              )}
            </Box>

            <Box
              width={isColumnLayout ? "100%" : "55%"}
              color="black"
              fontSize={isColumnLayout ? "13px" : "22px"}
              fontStyle="normal"
              fontWeight={isColumnLayout ? 500 : 400}
              lineHeight={isColumnLayout ? "24px" : "36px"}
              textAlign={isColumnLayout && "center"}
              padding="32px"
            >
              <Text>{item.description}</Text>
            </Box>
          </Stack>
        ))}
      </Slider>
    </Stack>
  );
};

export default ImpactCarousel;
