import React from "react";
import { Link, Text } from "@chakra-ui/react";
import { FAQProps } from "../../_components/FAQs/FAQs";

export const WHATSAPP_GROUP_LINK =
  "https://chat.whatsapp.com/HyWi2qzAeKo4GlGz6ool4b";

export const TELEGRAM_GROUP_LINK = "https://t.me/+1hHmxn4TrKQwMGNk";

export const getRegistrationFormUrl = (referrer?: string) => {
  const formId =
    process.env.REACT_APP_DEPLOY_ENV === "production"
      ? "1FAIpQLSdy_-RZ9Ii6gE3bTJt16SkLohmPifCGfbGzF_pkSEpDEDC6oQ"
      : "1FAIpQLSe2rsrRCM7BMDCiRsSCYePiXfMTSl7mZ6K8SFhSpL9zi-mBVg";

  if (!referrer) {
    return `https://docs.google.com/forms/d/e/${formId}/viewform?usp=sf_link`;
  }

  return `https://docs.google.com/forms/d/e/${formId}/viewform?usp=pp_url&entry.950941218=${referrer}`;
};

export const workAbroadFAQs: FAQProps["items"] = [
  {
    title: "What is LaborHack?",
    body: "LaborHack is a marketplace platform that connects companies with skilled artisans both domestically and internationally.",
  },
  {
    title: "How does LaborHack work?",
    body: "LaborHack works by finding reputable projects through its certified partners and matching artisans to the opportunities that fit their skill sets.",
  },
  {
    title: "How do I sign up for LaborHack's International Opportunities?",
    body: (
      <Text>
        Simply fill out the registration form{" "}
        <Link
          textDecoration="underline"
          isExternal
          href={getRegistrationFormUrl()}
        >
          here
        </Link>{" "}
        and enter the required information
      </Text>
    ),
  },
  {
    title: "Who is eligible for LaborHack's International Opportunities?",
    body: "We are currently recruiting Artisans in the following trades: Aluminium Workers, Form Workers, Welders, Tilers, Iron Benders and Plasterers",
  },
  {
    title: "Are there any costs related to this opportunity?",
    body: "You may be required to cover transportation costs depending on the opportunity in question",
  },
  {
    title: "How do I contact LaborHack if I have more questions?",
    body: (
      <Text>
        Simply join our community{" "}
        <Link textDecoration="underline" isExternal href={TELEGRAM_GROUP_LINK}>
          Telegram Group
        </Link>{" "}
        or{" "}
        <Link textDecoration="underline" isExternal href={WHATSAPP_GROUP_LINK}>
          Whatsapp Group
        </Link>{" "}
        to get your questions answered in real time.
      </Text>
    ),
  },
];
