import React from "react";
import { FaIdBadge } from "react-icons/fa6";
import {
  Badge,
  Button,
  Heading,
  HStack,
  Image,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react";
import {
  ProPortfolioBadgeStatus,
  ProPortfolioQuery,
} from "../../__generated__/graphql";
import IdVerificationBadge from "../../assets/id-verification-badge.svg";
import NoIdVerificationBadge from "../../assets/no-id-verification-badge.svg";
import BackgroundCheckBadge from "../../assets/background-check-badge.svg";
import NoBackgroundCheckBadge from "../../assets/no-background-check-badge.svg";
import clsx from "clsx";
import { ExternalLink, ShieldClose } from "lucide-react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { PortfolioSectionHeading } from "./portfolio-section-heading";
import { useApplicationConfig } from "../../context/ApplicationConfig";

interface PortfolioBadgeSectionProps {
  portfolio: NonNullable<ProPortfolioQuery["proPortfolio"]>;
  editMode?: boolean;
}

const getBadgeStatusColour = (status: ProPortfolioBadgeStatus) => {
  switch (status) {
    case ProPortfolioBadgeStatus.Completed:
      return "green";
    case ProPortfolioBadgeStatus.Pending:
      return "yellow";
    case ProPortfolioBadgeStatus.Declined:
      return "red";
    default:
      return "gray";
  }
};

export const PortfolioBadgeSection = ({
  portfolio,
  editMode,
}: PortfolioBadgeSectionProps) => {
  const { toggles } = useApplicationConfig();

  const idVerificationCompleted =
    portfolio.identityVerificationStatus === ProPortfolioBadgeStatus.Completed;

  const backgroundCheckCompleted =
    portfolio.backgroundCheckStatus === ProPortfolioBadgeStatus.Completed;

  const criminalRecordVerificationCompleted = false;

  return (
    <div className="p-6 flex border-b">
      <div className="flex flex-col items-start gap-6">
        <PortfolioSectionHeading>Badges</PortfolioSectionHeading>

        <div className="flex flex-col gap-3">
          <div className="flex gap-2 items-center">
            {idVerificationCompleted ? (
              <Image
                src={IdVerificationBadge}
                alt="ID Verification Badge"
                boxSize="30px"
              />
            ) : (
              <Image
                src={NoIdVerificationBadge}
                alt="No ID Verification Badge"
                boxSize="30px"
                className="opacity-30"
              />
            )}
            <div className="flex flex-col gap-1">
              <div className="flex gap-2 items-center">
                {idVerificationCompleted ? (
                  <p className="font-medium">ID Verified</p>
                ) : (
                  <p className="font-medium">ID not verified</p>
                )}

                {editMode &&
                  portfolio.identityVerificationStatus ===
                    ProPortfolioBadgeStatus.NotStarted && (
                    <Link
                      href={`${process.env.REACT_APP_PRO_APPLICATION_URL}/complete-profile?preferredTrack=LOCALGIG`}
                      isExternal
                      className="!underline !text-flatteredFlamingo-500"
                    >
                      Get Badge <ExternalLinkIcon mx="2px" />
                    </Link>
                  )}
                {editMode &&
                  portfolio.identityVerificationStatus !==
                    ProPortfolioBadgeStatus.NotStarted && (
                    <Badge
                      colorScheme={getBadgeStatusColour(
                        portfolio.identityVerificationStatus
                      )}
                    >
                      {portfolio.identityVerificationStatus}
                    </Badge>
                  )}
              </div>
              <p className="text-sm text-gray-500">
                Indicates a government issued ID has been verified
              </p>
            </div>
          </div>

          {toggles?.enable_criminal_record_badge_on_pro_portfolio && (
            <div className="flex gap-2 items-center">
              {criminalRecordVerificationCompleted ? (
                <Image
                  src={IdVerificationBadge}
                  alt="Criminal Record Verification Badge"
                  boxSize="30px"
                />
              ) : (
                <Image
                  src={NoIdVerificationBadge}
                  alt="No Criminal Record Verification Badge"
                  boxSize="30px"
                  className="opacity-30"
                />
              )}
              <div className="flex flex-col gap-1">
                <div className="flex gap-2 items-center">
                  {criminalRecordVerificationCompleted ? (
                    <p className="font-medium">Criminal record Verified</p>
                  ) : (
                    <p className="font-medium">Criminal record not verified</p>
                  )}
                  {editMode &&
                    portfolio.identityVerificationStatus !==
                      ProPortfolioBadgeStatus.NotStarted && (
                      <Badge
                        colorScheme={getBadgeStatusColour(
                          ProPortfolioBadgeStatus.NotStarted
                        )}
                      >
                        {ProPortfolioBadgeStatus.NotStarted}
                      </Badge>
                    )}
                </div>
                <p className="text-sm text-gray-500">
                  Indicates a criminal record check has been completed
                </p>
              </div>
            </div>
          )}

          {toggles?.enable_background_check_portfolio_badge && (
            <div className="flex gap-2 items-center">
              {backgroundCheckCompleted ? (
                <Image
                  src={BackgroundCheckBadge}
                  alt="Background Check Badge"
                  boxSize="30px"
                />
              ) : (
                <Image
                  src={NoBackgroundCheckBadge}
                  alt="No Background Check Badge"
                  boxSize="30px"
                  className="opacity-30"
                />
              )}

              <div className="flex gap-2 items-center">
                {backgroundCheckCompleted ? (
                  <p className="font-medium">Background vetted</p>
                ) : (
                  <p className="font-medium">Background not vetted</p>
                )}

                {editMode &&
                  portfolio.backgroundCheckStatus ===
                    ProPortfolioBadgeStatus.NotStarted && (
                    <Link
                      href={`${process.env.REACT_APP_PRO_APPLICATION_URL}/background-check?preferredTrack=LOCALGIG`}
                      isExternal
                      className="!underline !text-flatteredFlamingo-500"
                    >
                      Get Badge <ExternalLinkIcon mx="2px" />
                    </Link>
                  )}
                {editMode &&
                  portfolio.backgroundCheckStatus !==
                    ProPortfolioBadgeStatus.NotStarted && (
                    <Badge
                      colorScheme={getBadgeStatusColour(
                        portfolio.backgroundCheckStatus
                      )}
                    >
                      {portfolio.backgroundCheckStatus}
                    </Badge>
                  )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
