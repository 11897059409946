import { useQuery } from "@apollo/client";
import React, { FC, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Icon, Modal } from "semantic-ui-react";
import Button from "@laborhack/custom-button";
import { formatToNaira } from "../../helpers/utils/funtions";
import Loading from "../../_components/Loading";
import RequestFailed from "../../_components/RequestFailed";
import { GET_NEXT_INVOICE_PAYMENT } from "../invoice/graphql/query";
import { LABORHACK_SUPPORT_PHONE } from "../../constants";

const LABORHACK_ACCOUNT_NUMBER = "1547789250";

export const OfflinePayments: FC = () => {
  const { invoiceId } = useParams<{ invoiceId: string }>();

  const [copied, setCopied] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const history = useHistory();

  const {
    loading: nextInvoicePaymentLoading,
    data: nextInvoicePaymentData,
    error: nextInvoicePaymentError,
  } = useQuery(GET_NEXT_INVOICE_PAYMENT, {
    variables: {
      invoiceId,
    },
    fetchPolicy: "network-only",
  });

  if (nextInvoicePaymentLoading) {
    return <Loading />;
  }

  if (nextInvoicePaymentError) {
    return <RequestFailed />;
  }

  const total = Math.ceil(
    nextInvoicePaymentData.nextInvoicePayment.total *
      nextInvoicePaymentData.nextInvoicePayment.nextPaymentPercentage
  );

  const handleCopied = () => {
    setCopied(true);
    navigator.clipboard.writeText(LABORHACK_ACCOUNT_NUMBER);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  return (
    <>
      <Modal
        open={confirmationOpen}
        size="tiny"
        onClose={() => setConfirmationOpen(false)}
        closeIcon
        closeOnDimmerClick
      >
        <div className="p-8 text-center">
          <h4 className="font-bold text-3xl text-primary-500">Thank You 🎉</h4>
          <p className="text-primary-300">
            After confirmation on our end, you will receive a receipt in your
            email and the invoice would be marked as paid.
          </p>
          <p className="mt-8 text-sm text-primary-300">
            <strong>Note:</strong> As this is a bank transfer, confirmation may
            take up to 6 hours. For any inquiries, please contact us{" "}
            <a href={`tel:${LABORHACK_SUPPORT_PHONE}`}>
              {LABORHACK_SUPPORT_PHONE}
            </a>
          </p>
        </div>
      </Modal>
      <div className="w-screen h-screen bg-slate-300 p-8 flex justify-center items-center">
        <div className="bg-white w-full md:w-1/2 lg:w-1/3 rounded shadow relative ">
          <Icon
            className="absolute -top-6 -right-6 text-slate-100 cursor-pointer"
            name="remove circle"
            onClick={() => history.goBack()}
          />
          <div className="my-8 mx-8">
            <img
              src="https://laborhackimages.s3.amazonaws.com/LaborHackTransfer.svg"
              className="h-14"
            />
            <div className="mt-8">
              <div className="flex justify-end items-center">
                <p className="m-0 mr-1 text-primary-300 font-semibold">Pay</p>
                <p className="!text-primary-300 font-bold text-lg m-0">
                  {formatToNaira(total)}
                </p>
              </div>

              <div className="my-4">
                <p className="text-primary-300">
                  Please make payment to the bank account below
                </p>
              </div>
              <div>
                <div className="border-b border-solid border-primary-150 w-60 pl-2 pb-1 mb-4">
                  <p className="m-0 text-primary-200 font-semibold text-sm">
                    Bank Name
                  </p>
                  <p className="m-0 text-xl font-bold text-primary-300">
                    Access Bank
                  </p>
                </div>
                <div className="border-b border-solid border-primary-150 w-60 pl-2 pb-1 mb-4">
                  <p className="m-0 text-primary-200 font-semibold text-sm">
                    Account Number
                  </p>
                  <p className="m-0 text-xl font-bold text-primary-300">
                    {LABORHACK_ACCOUNT_NUMBER}

                    <span className="m-0 text-base font-semibold text-success inline-block ml-2">
                      {copied ? (
                        "Copied!"
                      ) : (
                        <Icon
                          className="inline-block ml-4 cursor-pointer text-sm text-primary-300"
                          name="clone"
                          onClick={handleCopied}
                        />
                      )}
                    </span>
                  </p>
                </div>
                <div className="border-b border-solid border-primary-150 w-60 pl-2 pb-1 mb-4">
                  <p className="m-0 text-primary-200 font-semibold text-sm">
                    Account Name
                  </p>
                  <p className="m-0 text-xl font-bold text-primary-300">
                    LABORHACK LIMITED
                  </p>
                </div>
              </div>
            </div>
            <Button
              fullWidth
              className="!mt-8"
              disabled={confirmationOpen}
              onClick={() => setConfirmationOpen(true)}
            >
              I&apos;ve Paid
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
