import React from "react";
import { Icon } from "semantic-ui-react";

export const AccordionItem = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <div className="w-full py-6 flex flex-col gap-6">
      <div className="flex justify-between w-full items-center">
        <h6 className="text-4xl">What is LaborHack?</h6>
        <Icon name="plus" />
      </div>
      {isOpen && (
        <div className="w-5/6">
          <p className="text-lg">
            LaborHack is a 48-hour hackathon where students from all over the
            country come together to solve problems in the labor industry.
          </p>
        </div>
      )}

      <div className="w-full h-[1px] bg-primary-500" />
    </div>
  );
};
