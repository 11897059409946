import React, { FC, useEffect } from "react";
import { Helmet } from "react-helmet";
import { logEvent } from "helpers/analytics";

import PDFRenderer from "../../_components/PDFRenderer";
import { LABORHACK_COOKIE_POLICY_URL } from "../../constants";
import Footer from "../../_components/Footer";

export interface CookieProps {}

export const Cookie: FC<CookieProps> = ({}) => {
  useEffect(() => {
    logEvent("page_view");
  }, []);

  return (
    <>
      <Helmet>
        <title>Cookie Policy | LaborHack</title>
      </Helmet>
      <PDFRenderer fileUrl={LABORHACK_COOKIE_POLICY_URL} />
      <Footer />
    </>
  );
};
