import React, { FC } from "react";
import Button from "@laborhack/custom-button";

import warning from "assets/not-verified.svg";

import styles from "./styles.module.scss";

export interface RequestFailedProps {
  onRetry?: () => void;
  text?: string;
}

export const RequestFailed: FC<RequestFailedProps> = ({ onRetry, text }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.body}>
        <h2>Something Went Wrong!</h2>
        <img src={warning} />
        <div className="flex flex-col items-center gap-4">
          {text && <p className=" text-lg">{text}</p>}
          <Button variant="basic" onClick={onRetry}>
            Try again
          </Button>
        </div>
      </div>
    </div>
  );
};
