import { ApolloClient } from "@apollo/client";
import Joi from "@hapi/joi";
import firebase from "firebase";

import {
  GET_BUSINESS_BY_EMAIL,
  GET_USER_BY_EMAIL,
  GET_USER_BY_PHONENUMBER,
} from "graphql/query.constants";
import { Job } from "../../pages/invoice/types";
import { Invoice, TransactionStatus } from "../../types";

const DAY_IN_MILLISECONDS = 86400000;

export const formatToNaira = (amount: number, passedDecimalPlaces?: number) => {
  const decimalPlaces = passedDecimalPlaces ?? 2;

  return `₦${amount.toLocaleString("en-NG", {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  })}`;
};

/**
 *
 * Returns the formatted time elapsed since the date object passed.
 *
 * @example
 * Here's a simple example
 * ```
 *
 * const time = new Date();
 *
 * // prints "1 sec ago"
 * console.log(formatRelativeTime(time))
 * ```
 *
 * @param date - a date object
 * @returns a string representation of the time elapsed since
 */
export const formatRelativeTime = (date: Date) => {
  const currentTimestamp = new Date().getTime();

  const timeDifference = currentTimestamp - new Date(date).getTime();

  const timeBoxes: [string, number][] = [
    ["week", 604800000],
    ["day", 86400000],
    ["hr", 3600000],
    ["min", 60000],
    ["sec", 1000],
  ];

  const result = {
    relative: {
      greater: true,
      lesser: true,
    },
    timebox: ["sec", 0],
    count: 0,
    multiple: false,
  };

  if (timeDifference > timeBoxes[0][1]) {
    result.timebox = timeBoxes[0];
  } else {
    if (timeDifference > timeBoxes[1][1]) {
      result.timebox = timeBoxes[1];
    } else {
      if (timeDifference > timeBoxes[2][1]) {
        result.timebox = timeBoxes[2];
      } else {
        if (timeDifference > timeBoxes[3][1]) {
          result.timebox = timeBoxes[3];
        } else {
          result.timebox = timeBoxes[4];
        }
      }
    }
  }

  const count = timeDifference / Number(result.timebox[1]);
  const actualCount = Math.round(count);

  result.count = actualCount;

  if (actualCount >= count) {
    result.relative.lesser = false;
  } else {
    result.relative.greater = false;
  }

  if (actualCount > 1) {
    result.multiple = true;
  }

  return `${result.count} ${result.timebox[0]}${
    result.multiple ? "s" : ""
  } ago`;
};

export const truncateString = (value: string, maxLength: number) => {
  return `${value.substr(0, maxLength)}${
    value.length > maxLength ? "..." : ""
  }`;
};

export const nonEmpty = (value: string) => !!value.length;

export const addDays = (days: number, date: Date) => {
  const timeInMilliseconds = date.getTime();

  return new Date(timeInMilliseconds + days * DAY_IN_MILLISECONDS);
};

export const isLoggedIn = (user: firebase.User | null) => {
  return user && !user.isAnonymous;
};

export const isClient = (
  user: firebase.User | null,
  idTokenResult?: firebase.auth.IdTokenResult
) => {
  const userRoles: string[] = idTokenResult?.claims?.roles;

  return (
    user &&
    !user.isAnonymous &&
    !!userRoles?.find((role: string) => role === "CLIENT")
  );
};

export const isLaborHackPro = (
  user: firebase.User | null,
  idTokenResult?: firebase.auth.IdTokenResult
) => {
  const userRoles: string[] = idTokenResult?.claims?.roles;

  return (
    user &&
    !user.isAnonymous &&
    !!userRoles?.find((role: string) => role === "TRADESMAN")
  );
};

export const isAdmin = (
  user: firebase.User | null,
  idTokenResult?: firebase.auth.IdTokenResult
) => {
  const allowedRoles = new Set([
    "CUSTOMERSERVICE",
    "FINANCE",
    "PROJECTMANAGER",
    "ROOT",
  ]);

  const userRoles: string[] = idTokenResult?.claims.roles
    ? idTokenResult.claims.roles
    : [idTokenResult?.claims.role];

  return (
    user &&
    !user.isAnonymous &&
    !!userRoles.find((role) => allowedRoles.has(role))
  );
};

export const allCustomTasks = (cartItems: any[]) => {
  const customTask = cartItems.filter((item) => {
    return item.estimatedCost === null;
  });
  return customTask;
};

export const base64ToBlob = (base64: string) => {
  const [type, data] = base64.split(";base64,");

  const byteCharacters = atob(data);
  const byteArrays = [];

  const size = 512;

  for (let offset = 0; offset < byteCharacters.length; offset += size) {
    const slice = byteCharacters.slice(offset, offset + size);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: type.split(":")[1] });
  return blob;
};

export const invoiceIsPaid = (invoice: Invoice) => {
  const { payment, splitPayments } = invoice;

  return (
    (splitPayments?.length &&
      !splitPayments.find(
        ({ payment }) => payment?.status !== TransactionStatus.SUCCESS
      )) ||
    (!splitPayments?.length && payment?.status === TransactionStatus.SUCCESS)
  );
};

export const startsWithZero = (value: string): boolean => {
  return value[0] === "0";
};

export const phoneNumberCheck = (value: string) => {
  const pattern = startsWithZero(value)
    ? new RegExp(/^0(70|(80|81)|(90|91))\d{8}$/)
    : new RegExp(/^(70|(80|81)|(90|91))\d{8}$/);

  return !Joi.string().pattern(pattern).validate(value).error;
};

export const emailCheck = (value: string) => {
  return !Joi.string()
    .email({
      minDomainSegments: 2,
      tlds: { allow: false },
    })
    .validate(value).error;
};

export const emailDoesNotExist = async (
  client: ApolloClient<any>,
  email: string
) => {
  try {
    await client.query({
      query: GET_USER_BY_EMAIL,
      variables: {
        email,
      },
    });

    return false;
  } catch (error) {
    return true;
  }
};

export const phoneNumberDoesNotExist = async (
  client: ApolloClient<any>,
  phoneNumber: string
) => {
  try {
    await client.query({
      query: GET_USER_BY_PHONENUMBER,
      variables: {
        phoneNumber:
          "+234" +
          (startsWithZero(phoneNumber)
            ? phoneNumber.substring(1)
            : phoneNumber),
      },
    });

    return false;
  } catch (error) {
    return true;
  }
};

export const businessEmailDoesNotExist = async (
  client: ApolloClient<any>,
  email: string
) => {
  try {
    await client.query({
      query: GET_BUSINESS_BY_EMAIL,
      variables: {
        email,
      },
    });

    return false;
  } catch (error) {
    console.log(error);
    return true;
  }
};

export const addPhoneNumberPrefix = (phoneNumber: string) => {
  return (
    "+234" +
    (startsWithZero(phoneNumber) ? phoneNumber.substring(1) : phoneNumber)
  );
};

/**
 * Positive timezones make the ISO date the day before the actual date.
 *
 * @param date
 * @returns
 */
export const forceActualDay = (date: Date) => {
  const oneIndexedMonth = date.getMonth() + 1;

  const month = oneIndexedMonth < 10 ? `0${oneIndexedMonth}` : oneIndexedMonth;
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();

  return new Date(`${date.getFullYear()}-${month}-${day}`);
};
