import { Text } from "@chakra-ui/react";
import React from "react";
import NotFound from "../../assets/not-found.png";
export const ProNotFound = () => {
  return (
    <div className="flex flex-col items-center justify-center h-full gap-8 mt-12">
      <Text
        fontSize={{
          base: 22,
          lg: 24,
        }}
        textColor="#003952"
        fontWeight="bold"
      >
        Pro Not Found
      </Text>
      <img
        src={NotFound}
        alt="Not Found"
        className="w-4/5 md:w-4/12 rounded-xl"
      />
    </div>
  );
};
