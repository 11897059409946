import clsx from "clsx";
import React, { FC } from "react";
import { FaCheckCircle } from "react-icons/fa";
import CustomBadge from "_components/CustomBadge";

import styles from "./styles.module.scss";

export interface PaymentProviderCardProps {
  name: string;
  logo: string;
  transactionFee?: number;
  onClick: () => void;
  selected?: boolean;
  disabled?: boolean;
}

export const PaymentProviderCard: FC<PaymentProviderCardProps> = ({
  name,
  logo,
  onClick,
  selected,
  disabled,
}) => {
  return (
    <div
      className={clsx(styles.paymentProviderCard, {
        [styles.selected]: selected,
        [styles.disabled]: disabled,
      })}
      onClick={() => !disabled && onClick()}
    >
      <FaCheckCircle className={clsx(styles.stickTopRight, styles.checkIcon)} />
      {disabled && (
        <CustomBadge
          className={styles.stickTopRight}
          badgeText="disabled"
          badgeType="basic"
          size="small"
          inverted
        />
      )}

      <div className={styles.body}>
        <div className={styles.header}>
          <h3 className={styles.name}>Pay with {name}</h3>
        </div>
        <div className="h-12 w-3/5 mt-16">
          <img src={logo} alt={name} className="my-0 mx-auto" />
        </div>
      </div>
    </div>
  );
};
