import { Fade, useDisclosure } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { FaWhatsapp } from "react-icons/fa";
import { logEvent } from "../../helpers/analytics";

export const ChatBubble = () => {
  const { isOpen, onOpen } = useDisclosure();

  useEffect(() => {
    setTimeout(() => {
      onOpen();
    }, 2000);
  }, []);

  return (
    <Fade
      in={isOpen}
      transition={{ exit: { delay: 1 }, enter: { duration: 0.5 } }}
      className="fixed z-[999] bottom-10 right-4"
      onClick={() => {
        logEvent("chat_bubble/click");
      }}
    >
      <a
        href="https://wa.me/2349131598380?text=Hello%20LaborHack"
        target="_blank"
        rel="noreferrer"
      >
        <div className="p-4 rounded-full bg-[#25D366] animate-bounce">
          <FaWhatsapp size={32} color="white" />
        </div>
      </a>
    </Fade>
  );
};
