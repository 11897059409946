import Container from "@laborhack/container";
import React, { FC, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Icon } from "semantic-ui-react";
import { logEvent } from "../../helpers/analytics";
import { PageSkeletion } from "../../_components/PageSkeleton/PageSkeletion";

import styles from "./styles.module.scss";

export interface CovidSelfDeclarationProps {}

export const CovidSelfDeclaration: FC<CovidSelfDeclarationProps> = ({}) => {
  useEffect(() => {
    logEvent("page_view");
  }, []);

  return (
    <PageSkeletion>
      <Helmet>
        <title>COVID-19 Self Declaration</title>
        <meta
          name="description"
          content="LaborHack's COVID-19 client self declaration"
        />
      </Helmet>
      <Container>
        <div className={styles.heading}>
          <h2>I am safe and healthy.</h2>
          <p>
            COVID-19 Self Health Declaration: Your honest response will ensure
            your safety as well as that of our professionals
          </p>
        </div>
        <ul className={styles.list}>
          <li>
            <Icon name="check"></Icon>
            <p>
              I am not suffering from flu-like symptoms e.g. fever, dry cough,
              sore throat, etc.
            </p>
          </li>
          <li>
            <Icon name="check"></Icon>
            <p>
              I have not traveled domestically or internationally I the past 14
              days
            </p>
          </li>
          <li>
            <Icon name="check"></Icon>
            <p>
              I have not come in contact with any COVID-19 case in the past 14
              days
            </p>
          </li>
          <li>
            <Icon name="check"></Icon>
            <p>
              I understand that while Laborhack is taking all necessary safety
              and hygiene precautions, it cannot be held liable in the
              unfortunate event that a customer or professional is diagnosed
              with the virus
            </p>
          </li>
        </ul>
      </Container>
    </PageSkeletion>
  );
};
