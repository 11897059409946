import React, { PropsWithChildren } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputLeftAddon,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Textarea,
} from "@chakra-ui/react";
import { Plus, Upload } from "lucide-react";
import { useApplicationConfig } from "../../context/ApplicationConfig";
import { gql } from "../../__generated__";
import { useMutation } from "@apollo/client";
import { isBefore } from "date-fns";

const ADD_CERTIFICATION = gql(`
  mutation AddPortfolioCertification(
    $name: String!
    $issuedBy: String!
    $issuerCertificationId: String
    $issuedAt: DateTimeISO!
  ) {
    addPortfolioCertification(
      name: $name
      issuedBy: $issuedBy
      issuerCertificationId: $issuerCertificationId
      issuedAt: $issuedAt
    )
  }
`);

const formSchema = z.object({
  name: z.string().min(3),
  issuedBy: z.string().min(3),
  issuerCertificationId: z.string().optional(),
  issuedAt: z.string().refine((value) => {
    if (!value) return true;

    const date = new Date(value);

    return isBefore(date, new Date());
  }, "Date must be today or in the past"),
});

interface CertificationCreatorProps {
  onClose: () => void;
}

export const CertificationCreator = (
  props: PropsWithChildren<CertificationCreatorProps>
) => {
  const { toggles } = useApplicationConfig();

  const [addCertificationItem, addCertificationItemHandle] = useMutation(
    ADD_CERTIFICATION,
    {
      onCompleted: () => {
        props.onClose();
      },
      refetchQueries: ["ProPortfolio"],
      awaitRefetchQueries: true,
    }
  );

  const {
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
  });

  const onSubmit = (data: z.infer<typeof formSchema>) => {
    addCertificationItem({
      variables: {
        name: data.name,
        issuedBy: data.issuedBy,
        issuerCertificationId: data.issuerCertificationId,
        issuedAt: new Date(data.issuedAt),
      },
    });
  };

  return (
    <div className="flex flex-col gap-4 w-full">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-8">
          <div className="flex flex-col gap-4">
            <FormControl isInvalid={!!errors.name} isRequired>
              <FormLabel htmlFor="name">Name</FormLabel>
              <Input
                id="name"
                placeholder="Example: Health and Safety Certification"
                {...register("name")}
                variant="outline"
              />
              <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.issuerCertificationId}>
              <FormLabel htmlFor="issuedBy">Certification ID</FormLabel>
              <Input
                id="issuerCertificationId"
                placeholder="Example: 101223456"
                {...register("issuerCertificationId")}
                variant="outline"
              />
              <FormErrorMessage>
                {errors.issuerCertificationId?.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.issuedBy} isRequired>
              <FormLabel htmlFor="issuedBy">Issuer</FormLabel>
              <Input
                id="issuer"
                placeholder="Example: UK ECITB"
                {...register("issuedBy")}
                variant="outline"
              />
              <FormErrorMessage>{errors.issuedBy?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.issuedAt} isRequired>
              <FormLabel htmlFor="issuedAt">Date of Issue</FormLabel>
              <Input
                id="issuedAt"
                {...register("issuedAt")}
                type="date"
                variant="outline"
              />
              <FormErrorMessage>{errors.issuedAt?.message}</FormErrorMessage>
            </FormControl>
          </div>

          <div className="flex justify-end">
            <Button
              colorScheme="flatteredFlamingo"
              size="lg"
              type="submit"
              isLoading={addCertificationItemHandle.loading}
            >
              Save
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};
