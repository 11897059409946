import {
  FormControl,
  FormErrorMessage,
  Radio,
  RadioGroup,
  Stack,
} from "@chakra-ui/react";
import { useApplicationConfig } from "context/ApplicationConfig";
import { addDays, addHours, setHours, startOfDay } from "date-fns";
import React, { useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { useRouteMatch } from "react-router-dom";
import { HomeownerHireContext } from "../../../context/homeowners/homeowner-hire.context";
import { logEvent } from "../../../helpers/analytics";
import { usePageView } from "../../../hooks/usePageView";
import { isValidHomeownerHireStartDate } from "../../../lib/utils";
import {
  HireLayout,
  HireLayoutContent,
  HireLayoutFooter,
  HireLayoutHeader,
} from "./layout";
import { ThemedFormLabel } from "./themed-form-label";
import { ThemedInput } from "./themed-input";

type StartDateType = "custom" | "tomorrow";

const convertToInputDate = (date: Date) => {
  return date.toISOString().split("T")[0];
};

export const StartDate = () => {
  usePageView();

  const {toggles, loading: togglesLoading} = useApplicationConfig();

  const match = useRouteMatch();

  const { formData, setFormData, isLoading, client } =
    useContext(HomeownerHireContext);

  const tomorrow = addHours(addDays(startOfDay(new Date()), 1), 12);

  const [type, setType] = React.useState<StartDateType | undefined>(
    formData.startDate ? "custom" : undefined
  );

  const [customStartDate, setCustomStartDate] = useState<string | undefined>(
    formData.startDate ? convertToInputDate(formData.startDate) : undefined
  );

  const [showError, setShowError] = React.useState(false);

  let backToPath;

  if (toggles?.handle_subscription_payments && formData.isPaid) {
    backToPath = undefined; // user has paid for subscription and should not be able to go back
  } else {
    backToPath = match.url.replace(/\/[^/]+$/, "/address-selector");
  }

  const showPicker = type === "custom";

  const handleTypeChange = (value: string) => {
    setType(value as StartDateType);

    if (value === "custom") {
      setCustomStartDate(convertToInputDate(tomorrow));
    } else {
      setCustomStartDate(undefined);
    }

    logEvent("homeowner/hire/start_date_option_selected");
  };

  const isValidCustomStartDate =
    !!customStartDate &&
    isValidHomeownerHireStartDate(new Date(customStartDate));

  const canProceed = type === "tomorrow" || !!customStartDate;

  const handleProceed = () => {
    if (!canProceed) {
      return;
    }

    if (type === "custom" && !isValidCustomStartDate) {
      setShowError(true);
      return;
    }

    logEvent("homeowner/hire/start_date_set", {
      isCustom: type === "custom",
    });

    setFormData(
      {
        startDate:
          type === "tomorrow"
            ? tomorrow
            : setHours(new Date(customStartDate!), 12),
      },
      client ? "confirmation" : "profile"
    );
  };

  return (
    <>
      <Helmet>
        <title>
          Start Date | LaborHack Homeowners - Hire certified artisans for your
          home needs
        </title>
      </Helmet>
      <HireLayout>
        <HireLayoutHeader backTo={backToPath} isLoading={isLoading}>
          When would you like to start the project?
        </HireLayoutHeader>
        <HireLayoutContent>
          <Stack spacing={8}>
            <RadioGroup onChange={handleTypeChange} value={type}>
              <Stack>
                <Radio value="tomorrow">Tomorrow</Radio>
                <Radio value="custom">Custom Date</Radio>
              </Stack>
            </RadioGroup>
            {showPicker && (
              <FormControl isInvalid={!isValidCustomStartDate && showError}>
                <ThemedFormLabel>Date</ThemedFormLabel>
                <ThemedInput
                  type="date"
                  value={customStartDate}
                  min={convertToInputDate(tomorrow)}
                  onChange={(event) => {
                    console.log(event.target.value);
                    setCustomStartDate(event.target.value);
                  }}
                />
                <FormErrorMessage>
                  Please select a date after tomorrow
                </FormErrorMessage>
              </FormControl>
            )}
          </Stack>
        </HireLayoutContent>
        <HireLayoutFooter
          isDisabled={!canProceed}
          isLoading={isLoading}
          onClick={handleProceed}
        >
          Proceed
        </HireLayoutFooter>
      </HireLayout>
    </>
  );
};
