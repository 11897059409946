import { gql, useMutation } from "@apollo/client";
import { useCallback } from "react";

const LOG_SERVER_EVENT = gql`
  mutation LogServerEvent(
    $event: EventTypes!
    $data: [LogEventContextFieldArgs!]!
  ) {
    logEvent(event: $event, context: $data)
  }
`;

export const useLogServerEvent = () => {
  const [logEvent, { loading }] = useMutation(LOG_SERVER_EVENT);

  const logger = useCallback(
    (event: string, data: Record<string, string>, onCompleted?: () => void) => {
      logEvent({
        variables: {
          event,
          data: Object.entries(data).map(([key, value]) => ({
            key,
            value,
          })),
        },
        onCompleted,
      });
    },
    [logEvent]
  );

  return { logServerEvent: logger, loading };
};
