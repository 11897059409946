import { Box, Button, HStack, Stack, Text } from "@chakra-ui/react";
import { HomeownerHireContext } from "context/homeowners/homeowner-hire.context";
import React, { useContext } from "react";
import { useHistory, useRouteMatch } from "react-router";
import { HireLayout, HireLayoutContent, HireLayoutHeader } from "./layout";

export const CantFindWhatYouWant = () => {
  const match = useRouteMatch();

  const { formData, setFormData, isLoading } = useContext(HomeownerHireContext);

  const backToPath = match.url.replace(/\/[^/]+$/, "/select-category");

  const history = useHistory();

  const removeHomeownerLeadSource = () => {
    localStorage.removeItem("homeowner_lead_source");
  }

  const proceedWithOneTimeRequest = () => {
    removeHomeownerLeadSource();
    setFormData({
      ...formData,
      isSubscriptionRequest: false,
    });
    history.push("/homeowners/hire/new");
  }

  return (
    <>
      <HireLayout>
        <HireLayoutHeader
          backTo={backToPath}
          isLoading={isLoading}
        >
          {"Can't find what you're looking for?"}
        </HireLayoutHeader>
        <HireLayoutContent>
          <Stack gap={8}>
            <Box>
              <Text>
                {"If the trade you need isn't listed, you can request a one-time service or contact LaborHack for further assistance."}
              </Text>
            </Box>
            <HStack spacing={4}>
              <Button
                colorScheme="black"
                _hover={{
                  bg: "flatteredFlamingo.600",
                  color: "#FEFEFE",
                }}
                variant="outline"
                size={"lg"}
                fontWeight={400}
                onClick={proceedWithOneTimeRequest}
              >
                Proceed with a one time request
              </Button>
              <Button
                colorScheme="black"
                _hover={{
                  bg: "flatteredFlamingo.600",
                  color: "#FEFEFE",
                }}
                variant="outline"
                size={"lg"}
                fontWeight={400}
                onClick={() => {
                  removeHomeownerLeadSource();
                  history.push("/contact");
                }}
              >
                Contact LaborHack
              </Button>
            </HStack>
          </Stack>
        </HireLayoutContent>
      </HireLayout>
    </>
  );
}