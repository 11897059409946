import {
  Box,
  Button,
  Center,
  Heading,
  HStack,
  Icon,
  Image,
  Stack,
  Text,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { logEvent } from "../../helpers/analytics";
import {
  Section,
  SectionContent,
  SectionHeader,
  SectionImage,
  SectionText,
  SectionTitle,
} from "../landing/components/Section";

import VerifiedSectionicon from "../../assets/verified-section-icon.svg";
import EarnSectionIcon from "../../assets/earn-section-icon.svg";

import Craftmen from "../../assets/craftmen.jpg";

import PassageLogo from "../../assets/passage-logo.svg";

import { BsAirplaneFill } from "react-icons/bs";
import YouTube from "react-youtube";
import FAQs from "../../_components/FAQs";
import { Logo } from "../../_components/Logo/Logo";
import { FaHandshake } from "react-icons/fa";
import Footer from "../../_components/Footer";

export default function PassagePage() {
  useEffect(() => {
    logEvent("page_view");
  }, []);

  const logoWidth = useBreakpointValue({
    base: 110,
    lg: 216,
  });

  const logoHeight = useBreakpointValue({
    base: 16,
    lg: 30,
  });

  return (
    <>
      <Helmet>
        <title>Passage | LaborHack</title>
      </Helmet>
      <VStack w="full" spacing={0}>
        {/* hero */}
        <Section
          bg="prussianBlue.500"
          paddingY={{
            base: 16,
            lg: 0,
          }}
        >
          <VStack w="full" spacing={12}>
            <VStack
              w={{
                base: "full",
                lg: "60%",
              }}
              alignItems="center"
              spacing={{
                base: 6,
                lg: 8,
              }}
            >
              <VStack
                w="full"
                alignItems="center"
                spacing={{
                  base: 3,
                  lg: 4,
                }}
              >
                <HStack
                  align="start"
                  gap={{
                    base: 2,
                    lg: 6,
                  }}
                >
                  <Image
                    height={{
                      base: 6,
                      lg: 12,
                    }}
                    src={PassageLogo}
                  />
                  <Text
                    fontFamily="AeonikPro"
                    fontSize={{
                      base: "sm",
                      lg: "2xl",
                    }}
                    fontWeight="bold"
                    color="white"
                  >
                    x
                  </Text>
                  <Logo width={logoWidth} height={logoHeight} fill="#ED6D5C" />
                </HStack>
                <Heading
                  as="h1"
                  fontSize={{
                    base: 26,
                    md: 52,
                  }}
                  fontWeight="bold"
                  color="white"
                  lineHeight={{
                    base: "base",
                    md: "shorter",
                  }}
                  textAlign="center"
                >
                  Get access to high-paying trades jobs in Canada 🇨🇦
                </Heading>
                <Text
                  fontSize={{
                    base: 15,
                    lg: 24,
                  }}
                  lineHeight="base"
                  fontWeight="medium"
                  color="white"
                  textAlign="center"
                >
                  Passage has partnered with a top Canadian college to prepare
                  prospective immigrants for in-demand jobs, with fewer
                  qualified local applicants.
                </Text>
              </VStack>

              <HStack w="full" spacing={4} justifyContent="center">
                <Button
                  as="a"
                  href={`${process.env.REACT_APP_PRO_APPLICATION_URL}?preferredTrack=STUDYWORK`}
                  colorScheme="flatteredFlamingo"
                  paddingY={{
                    base: 6,
                    lg: 10,
                  }}
                  paddingX={{
                    base: 8,
                    lg: 14,
                  }}
                  fontSize={{
                    base: 15,
                    lg: 22,
                  }}
                >
                  Register Now
                </Button>
              </HStack>
            </VStack>
          </VStack>
        </Section>

        <Section bgColor="gray.50">
          <VStack
            w="full"
            spacing={{
              base: 12,
              lg: 20,
            }}
          >
            <Stack
              w="full"
              direction={{
                base: "column",
                lg: "row",
              }}
              spacing={{
                base: 12,
                lg: 16,
              }}
            >
              <Box flex={1}>
                <VStack
                  w={{
                    base: "full",
                  }}
                  alignItems="start"
                  spacing={{
                    base: 2,
                    lg: 4,
                  }}
                >
                  <Stack
                    direction={{
                      base: "row",
                      lg: "column",
                    }}
                    spacing={{
                      base: 4,
                      lg: 8,
                    }}
                    alignItems={{
                      base: "center",
                      lg: "start",
                    }}
                  >
                    <Image
                      src={VerifiedSectionicon}
                      w={{
                        base: 10,
                        lg: 20,
                      }}
                    />
                    <Heading
                      as="h4"
                      fontSize={{
                        base: 18,
                        lg: 32,
                      }}
                      fontWeight="medium"
                    >
                      Vetted programs
                    </Heading>
                  </Stack>

                  <Text
                    fontSize={{
                      base: 15,
                      lg: 24,
                    }}
                    lineHeight="base"
                    fontWeight={{
                      base: "normal",
                      lg: "medium",
                    }}
                    color="gray.500"
                  >
                    Carefully selected programs to maximize your job prospects
                    in high-demand fields.
                  </Text>
                </VStack>
              </Box>
              <Box flex={1}>
                <VStack
                  w={{
                    base: "full",
                  }}
                  alignItems="start"
                  spacing={{
                    base: 2,
                    lg: 4,
                  }}
                >
                  <Stack
                    direction={{
                      base: "row",
                      lg: "column",
                    }}
                    spacing={{
                      base: 4,
                      lg: 8,
                    }}
                    alignItems={{
                      base: "center",
                      lg: "start",
                    }}
                  >
                    <Image
                      src={EarnSectionIcon}
                      w={{
                        base: 10,
                        lg: 20,
                      }}
                    />
                    <Heading
                      as="h4"
                      fontSize={{
                        base: 18,
                        lg: 32,
                      }}
                      fontWeight="medium"
                    >
                      Education Loans
                    </Heading>
                  </Stack>

                  <Text
                    fontSize={{
                      base: 15,
                      lg: 24,
                    }}
                    lineHeight="base"
                    fontWeight={{
                      base: "normal",
                      lg: "medium",
                    }}
                    color="gray.500"
                  >
                    Customized education loans based on your program to ensure
                    affordability.
                  </Text>
                </VStack>
              </Box>
            </Stack>
            <Stack
              w="full"
              direction={{
                base: "column",
                lg: "row",
              }}
              spacing={{
                base: 12,
                lg: 16,
              }}
            >
              <Box flex={1}>
                <VStack
                  w={{
                    base: "full",
                  }}
                  alignItems="start"
                  spacing={{
                    base: 2,
                    lg: 4,
                  }}
                >
                  <Stack
                    direction={{
                      base: "row",
                      lg: "column",
                    }}
                    spacing={{
                      base: 4,
                      lg: 8,
                    }}
                    alignItems={{
                      base: "center",
                      lg: "start",
                    }}
                  >
                    <Center
                      rounded="full"
                      bg="white"
                      w={{
                        base: 10,
                        lg: 20,
                      }}
                      h={{
                        base: 10,
                        lg: 20,
                      }}
                    >
                      <Icon
                        as={BsAirplaneFill}
                        color="flatteredFlamingo.500"
                        w={{
                          base: 5,
                          lg: 10,
                        }}
                        h={{
                          base: 5,
                          lg: 10,
                        }}
                      />
                    </Center>
                    <Heading
                      as="h4"
                      fontSize={{
                        base: 18,
                        lg: 32,
                      }}
                      fontWeight="medium"
                    >
                      Fast Processing
                    </Heading>
                  </Stack>

                  <Text
                    fontSize={{
                      base: 15,
                      lg: 24,
                    }}
                    lineHeight="base"
                    fontWeight={{
                      base: "normal",
                      lg: "medium",
                    }}
                    color="gray.500"
                  >
                    Reduced wait times for school acceptances by 80%.
                  </Text>
                </VStack>
              </Box>

              <Box flex={1}>
                <VStack
                  w={{
                    base: "full",
                  }}
                  alignItems="start"
                  spacing={{
                    base: 2,
                    lg: 4,
                  }}
                >
                  <Stack
                    direction={{
                      base: "row",
                      lg: "column",
                    }}
                    spacing={{
                      base: 4,
                      lg: 8,
                    }}
                    alignItems={{
                      base: "center",
                      lg: "start",
                    }}
                  >
                    <Center
                      rounded="full"
                      bg="white"
                      w={{
                        base: 10,
                        lg: 20,
                      }}
                      h={{
                        base: 10,
                        lg: 20,
                      }}
                    >
                      <Icon
                        as={FaHandshake}
                        color="flatteredFlamingo.500"
                        w={{
                          base: 5,
                          lg: 10,
                        }}
                        h={{
                          base: 5,
                          lg: 10,
                        }}
                      />
                    </Center>
                    <Heading
                      as="h4"
                      fontSize={{
                        base: 18,
                        lg: 32,
                      }}
                      fontWeight="medium"
                    >
                      Efficient Tuition Handling
                    </Heading>
                  </Stack>

                  <Text
                    fontSize={{
                      base: 15,
                      lg: 24,
                    }}
                    lineHeight="base"
                    fontWeight={{
                      base: "normal",
                      lg: "medium",
                    }}
                    color="gray.500"
                  >
                    Faster and cheaper tuition deposits. Plus, an easy refund
                    process if plans change.
                  </Text>
                </VStack>
              </Box>
            </Stack>
          </VStack>
        </Section>

        <Section bgColor="galleonBlue.50">
          <SectionContent>
            <SectionHeader>
              <SectionTitle>
                Canada&apos;s skilled trades sector faces a critical shortage of
                workers
              </SectionTitle>
              <SectionText>
                Canada&apos;s skilled trades sector is facing a significant
                worker shortage, with projections showing around{" "}
                <strong>700,000 trades workers retiring by 2028</strong>. This
                unprecedented retirement wave opens vast opportunities for new
                talent! In 2019, formally certified tradespersons in Canada
                earned a <strong>median income of $60,450</strong>, after their
                certification in 2018. Median earnings of powerline technicians
                and crane operators was over <strong>$100,000</strong>. Step
                into a field where your skills not only fill essential roles but
                also offer job stability and rewarding salaries.
              </SectionText>
            </SectionHeader>
          </SectionContent>
          <SectionImage src={Craftmen} alt="two construction workers" />
        </Section>

        <Section>
          <VStack
            w="full"
            spacing={{
              base: 8,
              lg: 10,
            }}
          >
            <VStack
              w={{
                base: "80%",
                lg: "80%",
              }}
              spacing={{
                base: 3,
                lg: 6,
              }}
            >
              <Heading
                as="h2"
                fontSize={{
                  base: 22,
                  md: 44,
                }}
                fontWeight="bold"
                color="prussianBlue.500"
                lineHeight={{
                  base: "base",
                  md: "shorter",
                }}
                textAlign={{
                  base: "center",
                }}
              >
                Why Passage?
              </Heading>
              <Text
                fontSize={{
                  base: 15,
                  lg: 24,
                }}
                lineHeight="base"
                fontWeight="medium"
                textAlign="center"
                color="prussianBlue.300"
              >
                Passage&apos;s mission is clear: By 2030, we aim to make quality
                opportunities accessible to everyone, regardless of geographic
                location and financial background.
              </Text>
            </VStack>
            <Stack
              direction={{
                base: "column",
                lg: "row",
              }}
              w="full"
              spacing={6}
            >
              {/* https://www.youtube.com/watch?v=1HswLLOjBXw&t=1326s */}
              <VStack flex={1} spacing={0}>
                <YouTube
                  className="w-full"
                  videoId="1HswLLOjBXw"
                  opts={{
                    width: "100%",
                    playerVars: {
                      start: 1326,
                      controls: 0,
                      loop: 1,
                      modestbranding: 1,
                    },
                  }}
                />
              </VStack>
            </Stack>
          </VStack>
        </Section>
        <HStack
          w="full"
          bgColor="prussianBlue.500"
          justifyContent="center"
          position="relative"
          hidden
        >
          <Box
            w="full"
            position="absolute"
            bgColor="white"
            minH={{
              base: 150,
              lg: 300,
            }}
            top={0}
          />
          <VStack
            zIndex={99}
            px={5}
            py={{
              base: 8,
              lg: 20,
            }}
            spacing={{
              base: 6,
              lg: 8,
            }}
            w="full"
            maxW="container.xl"
            alignItems="center"
          >
            <Heading
              as="h2"
              fontSize={{
                base: 22,
                md: 44,
              }}
              fontWeight="bold"
              color="prussianBlue.500"
              lineHeight={{
                base: "base",
                md: "shorter",
              }}
              textAlign={{
                base: "center",
              }}
            >
              Frequently Asked Questions
            </Heading>

            <Box
              w="full"
              paddingX={{
                base: 4,
                lg: 16,
              }}
              paddingY={{
                base: 4,
                lg: 8,
              }}
              bgColor="flatteredFlamingo.50"
            >
              <FAQs items={[]} />
            </Box>
          </VStack>
        </HStack>
        <Section>
          <Stack
            w="full"
            direction={{
              base: "column",
              lg: "row",
            }}
            py={{
              base: 6,
              lg: 0,
            }}
            spacing={{
              base: 4,
              lg: 16,
            }}
          >
            <HStack
              w="full"
              flex={1}
              justifyContent={{
                base: "center",
                lg: "start",
              }}
            >
              <Heading
                as="h2"
                fontSize={{
                  base: 22,
                  md: 44,
                }}
                fontWeight="bold"
                color="prussianBlue.500"
                lineHeight={{
                  base: "base",
                  md: "shorter",
                }}
                textAlign={{
                  base: "center",
                  lg: "start",
                }}
              >
                Pursue rewarding trades careers in Canada with Passage
              </Heading>
            </HStack>
            <HStack
              w="full"
              flex={1}
              justifyContent={{
                base: "center",
                lg: "start",
              }}
            >
              <Text
                fontSize={{
                  base: 15,
                  lg: 24,
                }}
                lineHeight="base"
                fontWeight={{
                  base: "normal",
                  lg: "medium",
                }}
                color="gray.500"
                textAlign={{
                  base: "center",
                  lg: "start",
                }}
              >
                Get hassle-free education loans and rest assured with faster
                processing as well as efficient tuition handling.
              </Text>
            </HStack>
          </Stack>
        </Section>
      </VStack>
      <Footer />
    </>
  );
}
