import React, { FC, useEffect, useState } from "react";
import Button from "@laborhack/custom-button";
import firebase from "../../../helpers/firebase";
import verifiedImage from "../../../assets/verified.svg";
import notVerifiedImage from "../../../assets/not-verified.svg";
import { useHistory, useLocation } from "react-router-dom";
import { useApplicationConfig } from "../../../context/ApplicationConfig";
import { useSession } from "../../../hooks";

const EmailVerified: FC<{
  actionCode: string;
}> = ({ actionCode }) => {
  const history = useHistory();
  const { toggles } = useApplicationConfig();
  const { user } = useSession();

  // get the continue url from the emailed link
  const { search } = useLocation();
  const query = new URLSearchParams(search);

  const continueUrl = query.get("continueUrl");

  const [verified, setVerified] = useState<{
    loading: boolean;
    success: boolean;
  }>({
    loading: true,
    success: false,
  });

  useEffect(() => {
    if (actionCode) {
      firebase
        .auth()
        .applyActionCode(actionCode)
        .then((res) => {
          setVerified({
            loading: false,
            success: true,
          });
        })
        .catch((error) => {
          setVerified({
            loading: false,
            success: false,
          });
        });
    }
  }, [actionCode]);

  const { success, loading } = verified;

  if (loading) {
    return (
      <div className="o-emailVerified__wrapper">
        <div className="o-emailVerified__container">
          <h5>Checking...</h5>
        </div>
      </div>
    );
  }

  if (success) {
    if (continueUrl) {
      window.open(continueUrl, "_self");
    }

    return (
      <div className="o-emailVerified__wrapper">
        <div className="o-emailVerified__container">
          <img src={verifiedImage} alt="two people holding a verified sign" />
          <h5>Your email has been verified.</h5>
          {user && !user.isAnonymous ? (
            <div className="o-emailVerified__actions">
              {toggles && toggles.enable_client_dashboard_access && (
                <Button
                  fullWidth
                  onClick={() => {
                    history.push("/dashboard/home");
                  }}
                >
                  <p>Go To Dashboard</p>
                </Button>
              )}
              <Button
                type="link"
                variant="basic"
                fullWidth
                onClick={() => {
                  history.push("/");
                }}
              >
                <p>Back to Home</p>
              </Button>
            </div>
          ) : (
            <p>You can close this now</p>
          )}
        </div>
      </div>
    );
  } else {
    return (
      <div className="o-emailVerified__wrapper">
        <div className="o-emailVerified__container">
          <img src={notVerifiedImage} alt="woman holding a red flag" />
          <h5>
            Oops!. This link may have expired
            <br />
            Please try again.
          </h5>
        </div>
      </div>
    );
  }
};

export default EmailVerified;
