import React, { useEffect } from "react";
import {
  FormControl,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Icon,
  FormErrorMessage,
  Divider,
  Box,
  AbsoluteCenter,
  Text,
  HStack,
  Button,
} from "@chakra-ui/react";
import { isValidEmail } from "../../lib/utils";
import {
  FaEnvelope,
  FaPhoneAlt,
  FaTelegramPlane,
  FaWhatsapp,
} from "react-icons/fa";
import { gql, useMutation } from "@apollo/client";
import { useQuery } from "../../hooks";
import { TELEGRAM_GROUP_LINK, WHATSAPP_GROUP_LINK } from "./constants";

export interface WorkAbroadRegistrationFormProps {
  setEmail: (email: string) => void;
  setPhone: (phone: string) => void;
  isLoading?: boolean;
}

const validCountries = [
  {
    name: "Nigeria",
    code: "NG",
    dialCode: "+234",
    flag: "🇳🇬",
    regex: /^(\+?234|0)?[789][01]\d{8}$/,
    placeholder: "8000000000",
  },
  {
    name: "Kenya",
    code: "KE",
    dialCode: "+254",
    flag: "🇰🇪",
    regex: /^(\+?254|0)?[7]\d{8}$/,
    placeholder: "700000000",
  },
  {
    name: "Uganda",
    code: "UG",
    dialCode: "+256",
    flag: "🇺🇬",
    regex: /^(\+?256|0)?[7]\d{8}$/,
    placeholder: "700000000",
  },
];

const cleanPhoneNumber = (phoneNumber: string) => {
  let cleaned = phoneNumber.trim();

  // remove braces, dashes and spaces
  cleaned = cleaned.replace(/-|\s|\(|\)/g, "");

  // remove leading 0
  if (cleaned.startsWith("0")) {
    cleaned = cleaned.slice(1);
  }

  return cleaned;
};

export const isValidPhoneNumber = (
  phoneNumberString: string,
  regex: RegExp
) => {
  const cleaned = cleanPhoneNumber(phoneNumberString);

  if (!regex.test(cleaned)) {
    return false;
  }

  return true;
};

const CREATE_LEAD = gql`
  mutation CreateLead(
    $phoneNumber: String
    $email: String
    $referrer: String
    $source: String!
  ) {
    createLead(
      phone: $phoneNumber
      email: $email
      referrer: $referrer
      source: $source
    ) {
      id
    }
  }
`;

export const WorkAbroadRegistrationForm = () => {
  const [createLead, { data, loading, error }] = useMutation(CREATE_LEAD);

  const [email, setEmail] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");

  const [country, setCountry] = React.useState(validCountries[1].code);

  const [showErrors, setShowErrors] = React.useState(false);

  const overridePhoneNumberExtension = phoneNumber.startsWith("+");

  const regex = overridePhoneNumberExtension
    ? /^\+/
    : validCountries.find((c) => c.code === country)?.regex || /^\+/;

  useEffect(() => {
    if (isValidPhoneNumber(phoneNumber, regex) || isValidEmail(email)) {
      setShowErrors(false);
    }
  }, [email, phoneNumber]);

  /**
   * Get the referrer from the url using hooks
   */
  const query = useQuery();
  const referrer = query.get("referrer");

  const handleSubmit = () => {
    if (!isValidEmail(email) && !isValidPhoneNumber(phoneNumber, regex)) {
      setShowErrors(true);
      return;
    } else {
      setShowErrors(false);
    }

    const phoneNumberToSend = overridePhoneNumberExtension
      ? cleanPhoneNumber(phoneNumber)
      : `${
          validCountries.find((c) => c.code === country)?.dialCode
        }${cleanPhoneNumber(phoneNumber)}`;

    createLead({
      variables: {
        ...(isValidPhoneNumber(phoneNumber, regex) && {
          phoneNumber: phoneNumberToSend,
        }),
        ...(isValidEmail(email) && { email }),
        ...(referrer && { referrer }),
        source: "israel-work-abroad",
      },
    });
  };

  if (data) {
    return (
      <Box
        padding={{
          base: 8,
          lg: 12,
        }}
        textAlign="center"
      >
        <Text fontSize="2xl" fontWeight="bold">
          Thank you for your interest!
        </Text>
        <Text fontSize="lg">
          We will contact you shortly with more information. To stay up to date
          please join our telegram or whatsapp group below
        </Text>

        <Stack py={16}>
          <Button
            as="a"
            href={TELEGRAM_GROUP_LINK}
            target="_blank"
            rel="noopener noreferrer"
            colorScheme="telegram"
            rightIcon={<Icon w={8} h={8} as={FaTelegramPlane} />}
            py={8}
          >
            <p className="text-xl">Join Telegram</p>
          </Button>
          <Button
            as="a"
            href={WHATSAPP_GROUP_LINK}
            target="_blank"
            rel="noopener noreferrer"
            colorScheme="whatsapp"
            rightIcon={<Icon w={8} h={8} as={FaWhatsapp} />}
            py={8}
          >
            <p className="text-xl">Join Whatsapp</p>
          </Button>
        </Stack>
      </Box>
    );
  }

  /**
   * A registration form that asks for either email or phone number
   */
  return (
    <Stack
      spacing={{
        base: 4,
        lg: 6,
      }}
      w="full"
    >
      <FormControl
        isInvalid={!isValidEmail(email) && showErrors}
        isDisabled={loading}
      >
        <InputGroup>
          <InputLeftElement
            py={{
              base: 4,
              lg: 8,
            }}
            px={{
              base: 6,
              lg: 12,
            }}
            h="full"
          >
            <Icon
              as={FaEnvelope}
              w={{
                base: 4,
                lg: 6,
              }}
              h={{
                base: 4,
                lg: 6,
              }}
              color="gray.400"
            />
          </InputLeftElement>
          <Input
            type="email"
            py={{
              base: 4,
              lg: 8,
            }}
            pl={{
              base: 12,
              lg: 20,
            }}
            fontSize={{
              base: 14,
              lg: 24,
            }}
            fontWeight="semibold"
            placeholder="Your email address"
            fontFamily="AeonikPro"
            rounded="none"
            _placeholder={{
              fontWeight: "medium",
            }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </InputGroup>
        <FormErrorMessage>Please enter a valid email address</FormErrorMessage>
      </FormControl>

      <Box
        position="relative"
        padding={{
          base: "4",
          lg: "6",
        }}
      >
        <Divider />
        <AbsoluteCenter bg="white" px="4">
          <Text size="xl">OR</Text>
        </AbsoluteCenter>
      </Box>

      <FormControl
        isInvalid={!isValidPhoneNumber(phoneNumber, regex) && showErrors}
        isDisabled={loading}
      >
        <HStack>
          {!overridePhoneNumberExtension && (
            <>
              <select
                className="bg-transparent border-none outline-none px-1 text-lg sm:text-2xl"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
              >
                {validCountries.map((country) => (
                  <option key={country.code} value={country.code}>
                    {`${country.code} ${country.flag}`}
                  </option>
                ))}
              </select>
              <p className="text-lg sm:text-3xl">
                {validCountries.find((c) => c.code === country)?.dialCode}
              </p>
            </>
          )}
          <InputGroup>
            {overridePhoneNumberExtension && (
              <InputLeftElement
                py={{
                  base: 4,
                  lg: 8,
                }}
                px={{
                  base: 6,
                  lg: 12,
                }}
                h="full"
              >
                <Icon
                  as={FaPhoneAlt}
                  w={{
                    base: 4,
                    lg: 6,
                  }}
                  h={{
                    base: 4,
                    lg: 6,
                  }}
                  color="gray.400"
                />
              </InputLeftElement>
            )}
            <Input
              type="tel"
              py={{
                base: 4,
                lg: 8,
              }}
              pl={
                overridePhoneNumberExtension
                  ? {
                      base: 12,
                      lg: 20,
                    }
                  : {
                      base: 2,
                      lg: 2,
                    }
              }
              fontSize={{
                base: 14,
                lg: 24,
              }}
              fontWeight="semibold"
              placeholder={
                overridePhoneNumberExtension
                  ? "Your phone number"
                  : validCountries.find((c) => c.code === country)?.placeholder
              }
              fontFamily="AeonikPro"
              rounded="none"
              _placeholder={{
                fontWeight: "medium",
              }}
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </InputGroup>
        </HStack>

        <FormErrorMessage>
          Please enter a valid{" "}
          {validCountries.find((c) => c.code === country)?.code} phone number
        </FormErrorMessage>
      </FormControl>

      <Button
        size={{
          base: "lg",
          lg: "xl",
        }}
        fontFamily="AeonikPro"
        px={{
          base: 12,
          lg: 20,
        }}
        colorScheme="flatteredFlamingo"
        isLoading={loading}
        onClick={handleSubmit}
      >
        Register for Webinar
      </Button>
    </Stack>
  );
};
