import React from "react";
import { Box, StackProps, VStack } from "@chakra-ui/react";
import { PropsWithChildren } from "react";

export const SectionContent = ({
  children,
  ...props
}: PropsWithChildren<StackProps>) => {
  return (
    <Box flex={1} w="full" order={2} {...props}>
      <VStack
        w={{
          base: "full",
          lg: "90%",
        }}
        alignItems={{
          base: "center",
          lg: "start",
        }}
        spacing={{
          base: 6,
          lg: 8,
        }}
      >
        {children}
      </VStack>
    </Box>
  );
};
