import React, { useEffect, useRef } from "react";
import { register } from "swiper/element/bundle";

register();

declare global {
  namespace JSX {
    interface IntrinsicElements {
      ["swiper-container"]: any;
      ["swiper-slide"]: any;
    }
  }
}

export const Carousel = () => {
  const swiperElRef = useRef<any>(null);

  useEffect(() => {
    // listen for Swiper events using addEventListener
    swiperElRef.current.addEventListener("progress", (e: any) => {
      const [swiper, progress] = e.detail;
      console.log(progress);
    });

    swiperElRef.current.addEventListener("slidechange", (e: any) => {
      console.log("slide changed");
    });
  }, []);

  return (
    <div className="flex w-full">
      <div className="flex flex-col w-60">
        <button>1. Go to slide 1</button>
        <button>2. Go to slide 2</button>
      </div>
      <div className=" relative">
        <swiper-container
          ref={swiperElRef}
          slides-per-view="1"
          autoplay={true}
          effect="fade"
        >
          <swiper-slide>
            <div className="bg-white">Slide 1</div>
          </swiper-slide>
          <swiper-slide>
            <div className="bg-white">Slide 2</div>
          </swiper-slide>
          <swiper-slide>
            <div className="bg-white">Slide 3</div>
          </swiper-slide>
        </swiper-container>
      </div>
    </div>
  );
};
