export const DEFAULT_TASK_IMAGE =
  "https://res.cloudinary.com/laborhack/image/upload/v1616078821/laborhack-custom_keczes.png";

export const LABORHACK_SUPPORT_PHONE = "+234 913 159 8380";

export const LABORHACK_SUPPORT_EMAIL = "support@laborhack.com";

export const CLAIMS_NAMESPACE = "https://api.laborhack.com";

export const LABORHACK_PRO_TERMS_OF_USE_URL =
  "https://laborhack-assets.s3.amazonaws.com/legal/pro-terms-of-use.pdf";

export const LABORHACK_PRIVACY_POLICY_URL =
  "https://laborhack-assets.s3.amazonaws.com/legal/privacy-policy.pdf";

export const LABORHACK_TERMS_OF_USE_URL =
  "https://laborhack-assets.s3.amazonaws.com/legal/terms-of-use.pdf";

export const LABORHACK_COOKIE_POLICY_URL =
  "https://laborhack-assets.s3.amazonaws.com/legal/cookie-policy.pdf";

export const LABORHACK_CAREERS_URL = "https://laborhack.grovehr.com/careers";

export const LABORHACK_SCHEDULE_DEMO_URL =
  "https://calendly.com/laborhacksupport/laborhack-discovery-call";

export const HOMEOWNER_HIRE_FORM_DATA_LOCAL_STORE_KEY =
  "homeowner-hire-form-data";

export const brandColors = {
  flatteredFlamingo: {
    50: "#FBE2DE",
    100: "#F9CFC9",
    200: "#F6B6AD",
    300: "#F39E92",
    400: "#F08677",
    500: "#ED6E5C",
    600: "#C55C4D",
    700: "#9E493D",
    800: "#77372E",
    900: "#4F251F",
  },
  prussianBlue: {
    50: "#D0D6D9",
    100: "#B1BBC0",
    200: "#8A98A1",
    300: "#637682",
    400: "#3C5462",
    500: "#153243",
    600: "#122A38",
    700: "#0E212D",
    800: "#0B1922",
    900: "#071116",
  },
  galleonBlue: {
    50: "#DAEAF3",
    100: "#C1DCEB",
    200: "#A1CAE1",
    300: "#82B8D8",
    400: "#63A7CE",
    500: "#4495C4",
    600: "#397CA3",
    700: "#2D6383",
    800: "#224B62",
    900: "#173241",
  },
  lavender: {
    50: "#EEE6F8",
    100: "#E2D5F4",
    200: "#D3C0EE",
    300: "#C5ACE8",
    400: "#B697E3",
    500: "#A882DD",
    600: "#8C6CB8",
    700: "#705793",
    800: "#54416F",
    900: "#382B4A",
  },
  white: "#FEFEFE",
  lawnGreen: {
    50: "#EAFDDC",
    100: "#DCFCC5",
    200: "#CAFAA7",
    300: "#B9F88A",
    400: "#A7F76D",
    500: "#96F550",
    600: "#7DCC43",
    700: "#64A335",
    800: "#4B7B28",
    900: "#32521B",
  },
  black: "#121212",
  inactiveTextColor: "#949494",
};
