import {
  Button,
  FormControl,
  Icon,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  getTextSearchPredictions,
  getNewSessionToken,
  getPlaceDetails,
  getLagosTextSearchPredictions,
} from "../../../lib/google-maps";
import {
  HireLayout,
  HireLayoutContent,
  HireLayoutFooter,
  HireLayoutHeader,
} from "./layout";
import { ThemedFormLabel } from "./themed-form-label";
import { AsyncSelect as CRAsyncSelect } from "chakra-react-select";
import { HomeownerHireContext } from "../../../context/homeowners/homeowner-hire.context";
import { AddressPayload } from "../../../types";
import { ThemedInput } from "./themed-input";
import { BsChevronRight } from "react-icons/bs";
import { BiMap } from "react-icons/bi";
import { logEvent } from "../../../helpers/analytics";
import { Helmet } from "react-helmet";
import { usePageView } from "../../../hooks/usePageView";
import { useApplicationConfig } from "context/ApplicationConfig";
import Loading from "_components/Loading";
import { gql, useMutation } from "@apollo/client";

const LOG_OUT_OF_LAGOS_HOMEOWNER_REQUEST_ATTEMPT = gql`
  mutation LogOutOfLagosHomeownerRequestAttempt(
    $state: String!
    $city: String!
    $address: String!
    $email: String!
    $homeownerRequestId: String!
  ) {
    logOutOfLagosHomeownerRequestAttempt(
      state: $state
      city: $city
      address: $address
      email: $email
      homeownerRequestId: $homeownerRequestId
    )
  }
`;

export const AddressSelector = () => {
  usePageView();

  const match = useRouteMatch();

  const { formData, setFormData, isLoading, client } =
    useContext(HomeownerHireContext);

  const [location, setLocation] = useState<AddressPayload | undefined>();

  const [locationIsOutsideLagos, setLocationIsOutsideLagos] = useState(false);

  const [locationIsNotDescriptive, setLocationIsNotDescriptive] =
    useState(false);

  const [isEditing, setIsEditing] = useState(formData.address ? false : true);

  const [logOutOfLagosHomeownerRequestAttempt] = useMutation(
    LOG_OUT_OF_LAGOS_HOMEOWNER_REQUEST_ATTEMPT
  );

  const handleLogOutOfLagosHomeownerRequestAttempt = async (
    email: string,
    address: string,
    city: string,
    state: string,
    homeownerRequestId: string
  ) => {
    logOutOfLagosHomeownerRequestAttempt({
      variables: {
        email,
        address,
        city,
        state,
        homeownerRequestId,
      },
    });
  };

  const history = useHistory();

  let prevPath;

  if (client) {
    prevPath = formData.isSubscriptionRequest
      ? "/subscription-details"
      : "/describe-task";
  } else {
    prevPath = "/contact-information";
  }

  const backToPath = match.url.replace(/\/[^/]+$/, prevPath);

  const [sessionToken, setSessionToken] =
    useState<google.maps.places.AutocompleteSessionToken | null>(null);

  useEffect(() => {
    setSessionToken(getNewSessionToken());
  }, []);

  const [loading, setLoading] = useState(false);

  const getPredictions = useCallback(
    async (value: string) => {
      logEvent("homeowner/hire/getting_address_predictions");

      const predictions = await getTextSearchPredictions(value, sessionToken!);

      if (predictions.length === 0) {
        logEvent("homeowner/hire/no_address_predictions_found");
      } else {
        logEvent("homeowner/hire/address_predictions_found");
      }

      return predictions.map((prediction) => ({
        label: prediction.description,
        value: prediction.place_id,
      }));
    },
    [sessionToken]
  );

  const handlePredictionClick = async (placeId: string) => {
    logEvent("homeowner/hire/address_prediction_selected", {
      placeId,
    });

    setLoading(true);

    const place = await getPlaceDetails(placeId, sessionToken!);

    const state = place.address_components!.find((component) =>
      component.types.includes("administrative_area_level_1")
    );

    const city = place.address_components!.find((component) =>
      component.types.includes("administrative_area_level_2")
    );

    if (!state?.long_name || !city?.long_name || !place.name) {
      setLocationIsNotDescriptive(true);
      setLoading(false);
      return;
    } else {
      setLocationIsNotDescriptive(false);
    }

    if (state?.long_name && state?.long_name !== "Lagos") {
      logEvent("homeowner/hire/address_outside_lagos", {
        placeId,
        state: state.long_name,
      });

      setLocationIsOutsideLagos(true);

      setLoading(false);

      if (formData.email && formData.id) {
        handleLogOutOfLagosHomeownerRequestAttempt(
          formData.email,
          place.name,
          city?.long_name,
          state?.long_name,
          formData.id
        );
      }

      return;
    } else {
      setLocationIsOutsideLagos(false);
    }

    const result: AddressPayload = {
      coordinates: [
        place.geometry!.location!.lng(),
        place.geometry!.location!.lat(),
      ],
      address: place.name,
      state: state.long_name,
      city: city.long_name,
    };

    setLocation(result);

    setLoading(false);
  };

  const handleProceed = () => {
    let nextPath;

    if (!!formData.plan) {
      nextPath = "subscription-payment";
    } else {
      nextPath = "start-date";
    }

    if (!isEditing) {
      history.push(`${match.url.replace(/\/[^/]+$/, `/${nextPath}`)}`);
    }

    if (!location) {
      return;
    }

    logEvent("homeowner/hire/address_selected", {
      isNewAddress: true,
    });

    logEvent("homeowner/hire/new_address_selected");

    setFormData(
      {
        address: location,
      },
      nextPath
    );
  };

  return (
    <>
      <Helmet>
        <title>
          Address Selector | LaborHack Homeowners - Hire certified artisans for
          your home needs
        </title>
      </Helmet>
      <HireLayout>
        <HireLayoutHeader backTo={backToPath} isLoading={isLoading}>
          What&apos;s the address where this service
          <br /> will be performed?
        </HireLayoutHeader>
        <HireLayoutContent>
          <Stack spacing={8}>
            <Stack>
              <FormControl>
                <ThemedFormLabel>Enter Address</ThemedFormLabel>
                {isEditing ? (
                  <CRAsyncSelect
                    components={{
                      DropdownIndicator: null,
                      IndicatorSeparator: null,
                    }}
                    placeholder="e.g Plot 15, Ayodele Fanoiki street, Magodo"
                    cacheOptions={false}
                    defaultOptions={[]}
                    loadOptions={getPredictions}
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        handlePredictionClick(selectedOption.value);
                      }
                    }}
                  />
                ) : (
                  <InputGroup size="md">
                    <ThemedInput
                      value={`${formData.address!.address}, ${
                        formData.address!.city
                      }, ${formData.address!.state}`}
                      isReadOnly
                    />
                    <InputRightElement width="4.5rem">
                      <Button
                        h="1.75rem"
                        size="sm"
                        onClick={() => {
                          logEvent("homeowner/hire/address_edit_clicked");
                          setIsEditing(true);
                        }}
                      >
                        Edit
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                )}
              </FormControl>
              {locationIsOutsideLagos && (
                <Text color="red.500">
                  Sorry, our services are currently not available in your area.
                </Text>
              )}
              {locationIsNotDescriptive && (
                <Text color="red.500">
                  Please select a more descriptive address.
                </Text>
              )}
              <Stack
                direction="row"
                width="full"
                justifyContent="flex-end"
                py={4}
              >
                <Popover>
                  <PopoverTrigger>
                    <Button
                      variant="link"
                      textDecoration="underline"
                      color="gray.700"
                      onClick={() => {
                        logEvent("homeowner/hire/address_why_clicked");
                      }}
                    >
                      Why we need your address?
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverBody>
                      We use this to make sure we match you with the closest and
                      best artisan in your area.
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </Stack>
            </Stack>

            {isEditing && client && client.addresses.length >= 1 && (
              <Button
                variant="ghost"
                justifyContent="space-between"
                width="full"
                rightIcon={<Icon as={BsChevronRight} w={4} h={4} />}
                onClick={() => {
                  logEvent("homeowner/hire/saved_addresses_clicked");
                  history.push(`${match.url}/saved-addresses`);
                }}
              >
                <Stack direction="row" align="center">
                  <Icon
                    as={BiMap}
                    w={{
                      base: 4,
                      lg: 6,
                    }}
                    h={{
                      base: 4,
                      lg: 6,
                    }}
                  />
                  <Text>Select from saved addresses</Text>
                </Stack>
              </Button>
            )}
          </Stack>
        </HireLayoutContent>
        <HireLayoutFooter
          isLoading={isLoading || loading}
          isDisabled={
            (!location && isEditing) ||
            locationIsOutsideLagos ||
            locationIsNotDescriptive
          }
          onClick={handleProceed}
        >
          Proceed
        </HireLayoutFooter>
      </HireLayout>
    </>
  );
};
