import { HStack, Stack, StackProps } from "@chakra-ui/react";
import React, { PropsWithChildren } from "react";

export interface SectionProps extends StackProps {}

export const Section = ({
  children,
  ...props
}: PropsWithChildren<SectionProps>) => {
  return (
    <HStack
      {...{ w: "full", bgColor: "white", justifyContent: "center", ...props }}
    >
      <Stack
        px={5}
        py={{
          base: 8,
          lg: 20,
        }}
        spacing={{
          base: 12,
          lg: 0,
        }}
        direction={{
          base: "column",
          lg: "row",
        }}
        w="full"
        maxW="container.xl"
        alignItems={{
          base: "start",
          lg: "center",
        }}
      >
        {children}
      </Stack>
    </HStack>
  );
};
