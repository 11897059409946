import React, { FC, useEffect } from "react";

import styles from "./styles.module.scss";
import { useAuth0ApolloClient } from "../../../hooks";
import { CompletionForm } from "./CompletionForm";
import { useHistory, useLocation } from "react-router-dom";
import Loading from "_components/Loading";
import { handleSignIn } from "../helper";
import { useApplicationConfig } from "../../../context/ApplicationConfig";
import { startsWithZero } from "../../../helpers/utils/funtions";
import { useMutation } from "@apollo/client";
import { REGISTER_CLIENT } from "../../../graphql/mutation.constants";
import { AccountCreationInput } from "./types";
import { useAuth0 } from "@auth0/auth0-react";

export const CLAIMS_NAMESPACE = "https://api.laborhack.com";

export interface CompleteProfileProps {}

export const Auth0CompleteProfile: FC<CompleteProfileProps> = ({}) => {
  const { isLoading, user } = useAuth0();
  const history = useHistory();
  const { toggles, loading } = useApplicationConfig();

  const { search } = useLocation();

  const query = new URLSearchParams(search);

  const { client } = useAuth0ApolloClient();

  const [registerClient, { loading: loadingRegisterClient }] = useMutation(
    REGISTER_CLIENT,
    {
      client,
    }
  );

  /**
   * If the user is not new, we check if the user is already completed the profile
   * if the user has completed the profile, handle sign in
   */

  useEffect(() => {
    if (
      toggles &&
      user &&
      user[`${CLAIMS_NAMESPACE}/roles`].includes(`CLIENT`)
    ) {
      handleSignIn(history, toggles);
    }
  }, [user, toggles]);

  const phoneNumber = query.get("phoneNumber") || undefined;

  const marketingTag = sessionStorage.getItem("marketing_tag");

  const leadSourceFromLocalStorage =
    sessionStorage.getItem("lead_source") || undefined;

  const leadSource: string | undefined = marketingTag
    ? "GoogleAd"
    : leadSourceFromLocalStorage;

  const missingFieldExists = !user?.given_name || !phoneNumber || !leadSource;

  const handleAccountCreation = async ({
    firstName,
    lastName,
    phoneNumber,
    leadSource,
  }: AccountCreationInput) => {
    const data = await registerClient({
      variables: {
        data: {
          marketingTag,
          leadSource,
          ...(firstName &&
            lastName && {
              firstName,
              lastName,
            }),
          ...(phoneNumber && {
            phoneNumber:
              "+234" +
              (startsWithZero(phoneNumber)
                ? phoneNumber.substring(1)
                : phoneNumber),
          }),
        },
      },
    });

    if (data) {
      // reload user after server adds user claims
      window.location.reload();
    }

    handleSignIn(history, toggles);
  };

  useEffect(() => {
    /**
     * If missing fields do not exist, complete the account registration on our servers and handle sign in
     */
    if (!missingFieldExists && leadSource) {
      handleAccountCreation({
        phoneNumber: phoneNumber,
        leadSource,
      });
    }
  }, [missingFieldExists, leadSource]);

  return (
    <div className={styles.complete}>
      {(loading || isLoading || loadingRegisterClient) && <Loading />}
      <h1>Complete Your Profile</h1>
      <CompletionForm
        handleAccountCreation={handleAccountCreation}
        injectedFields={{
          phoneNumber: query.get("phoneNumber") || undefined,
          leadSource,
        }}
        requestedFields={
          missingFieldExists
            ? {
                name: !user?.given_name,
                phoneNumber: !user?.phoneNumber,
                leadSource: !leadSource,
              }
            : undefined
        }
      />
    </div>
  );
};
