import { gql } from "@apollo/client";

export const GET_PAYMENT_PROVIDERS = gql`
  query GetPaymentProviders($amount: Float!) {
    paymentProviders {
      id
      name
      logo
      transactionFee(amount: $amount)
      isActive
    }
  }
`;

export const GET_NEXT_INVOICE_PAYMENT = gql`
  query GetNextInvoicePayment($invoiceId: String!) {
    nextInvoicePayment(invoiceId: $invoiceId) {
      total
      serviceCharge
      discount
      subTotal
      materials
      labor
      laborHackFee
      nextPaymentPercentage
      laborCommission
      materialCommission
      tax
    }
  }
`;

export const GET_INVOICE = gql`
  query GetInvoice($invoiceId: String!) {
    getInvoiceById(invoiceId: $invoiceId) {
      status
      message
      data {
        id
        type
        canceled
        inspectionAmount
        invoiceNumber
        invoiceDate
        isFullyPaid
        hasAtLeastOnePayment
        renderingData {
          headers
          sections {
            title
            rows {
              cells {
                subText
                text
              }
            }
          }
        }
        pricing {
          options {
            hideServiceCharge
          }
          serviceCharge
          cost
        }
        reimbursements {
          id
          excludeTax
          payout {
            id
            worksheet {
              contract {
                pro {
                  firstName
                  lastName
                }
              }
              payroll {
                period {
                  start
                  end
                }
              }
            }
            breakdown {
              gross
              net
              tax
            }
          }
        }
        marketplaceRequest {
          id
          jobs {
            trade {
              name
            }
            matches {
              pro {
                id
              }
            }
          }
        }
        recruitment {
          id
          jobs {
            category {
              name
            }
            matches {
              pro {
                id
              }
            }
          }
        }
        recruitmentData {
          contract {
            id
            contractInterval
            contractLength
            pro {
              firstName
              lastName
              categoryId
            }
            proLevel
          }
          period {
            start
            end
          }
          numberOfBilledDays
          agreedDailyRate
        }
        allowedPaymentProviders
        due
        discount
        appliedDiscount {
          id
          code
          value
        }
        assignments {
          id
          progress
          order {
            id
            orderCode
            inspectionPayment {
              status
              amount
            }
            locationSchedules {
              locationId
              scheduledDate
            }
            isEmergency
          }
          jobs {
            id
            jobCode
            custom {
              name
              description
              resourceUrl
              subCategoryId
              categoryId
            }
            task {
              id
              name
              description
              estimatedCost
              categoryId
              subCategoryId
              imageUrl
            }
            finalCost
            status
            completedAt

            payment {
              transactionReference
              transactionDate
              amount
              status
            }
            contact {
              notes
              location {
                id
                buildingNumber
                streetName
                area
                location
                landmark
                city
              }
            }
            invoiceDetails {
              materials {
                name
                unitPrice
                quantity
              }
              labor
            }
          }
          date
        }
        recipient {
          name
          email
          phoneNumber
          address
        }
        payment {
          id
          transactionDate
          transactionReference
          amount
          status
        }
        splitPayments {
          percentage
          payment {
            id
            transactionDate
            transactionReference
            amount
            status
          }
        }
      }
    }
  }
`;
