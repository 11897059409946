import clsx from "clsx";
import React, { FC, PropsWithChildren } from "react";
import styles from "./styles.module.scss";

export interface ContainerProps {
  className?: string;
  noPadding?: boolean;
}

export const Container = ({
  className,
  noPadding,
  children,
}: PropsWithChildren<ContainerProps>) => {
  return (
    <div
      className={clsx(
        styles.container,
        noPadding && styles.noPadding,
        className
      )}
    >
      <div>{children}</div>
    </div>
  );
};
