import React from "react";
import {
  Avatar,
  Box,
  Flex,
  HStack,
  VStack,
  Text,
  Image,
  StackProps,
} from "@chakra-ui/react";
import star from "./star.svg";

export interface ReviewCardProps extends StackProps {
  name: string;
  title: string;
  imageSrc: string;
  rating: number;
  review: string;
}

const ReviewCard: React.FC<ReviewCardProps> = ({
  name,
  title,
  imageSrc,
  rating,
  review,
  ...stackProps
}) => {
  return (
    <VStack border="1px solid #D6D6D6" w="full" h="full" {...stackProps}>
      <HStack
        padding={{ base: "16px", md: "32px" }}
        alignSelf="flex-start"
        bgColor="#FFF8E4"
        w="100%"
      >
        <Avatar w="64px" h="64px" name={name} src={imageSrc} />

        <Box ml={4}>
          <VStack alignItems="start" spacing={0.5}>
            <Text
              fontFamily="AeonikPro"
              fontWeight="600"
              fontSize={{ base: "18px", md: "24px" }}
              m={0}
            >
              {name}
            </Text>
            <Text
              fontFamily="AeonikPro"
              fontWeight="500"
              fontSize={{ base: "16px" }}
              m={0}
            >
              {title}
            </Text>
          </VStack>

          <HStack pt={2}>
            {Array.from({ length: rating }, (_, index) => (
              <Image
                key={index}
                src={star}
                h={{ base: "24px", md: "40px" }}
                w="24px"
              />
            ))}
          </HStack>
        </Box>
      </HStack>

      <Text
        width="100%"
        fontWeight="500"
        fontSize={{ base: "14px", md: "20px" }}
        lineHeight={{ base: "21px", md: "32px" }}
        fontStyle="normal"
        color="#5E5E5E"
        p={{ base: "16px", md: "32px" }}
      >
        {review}
      </Text>
    </VStack>
  );
};

export default ReviewCard;
