import React, { useState } from "react";
import {
  Flex,
  Image,
  Text,
  Box,
  useBreakpointValue,
  ResponsiveValue,
} from "@chakra-ui/react";
import { FlexProps } from "@chakra-ui/react";

interface Step {
  label: string;
  sublabel?: string;
  imageSrc: string;
}

interface HowItWorksTabsProps extends FlexProps {
  title?: string;
  steps: Step[];
}

export const HowItWorksTabs: React.FC<HowItWorksTabsProps> = ({
  title,
  steps,
  ...rest
}) => {
  const [activeStep, setActiveStep] = useState(0);

  const isColumnLayout = useBreakpointValue<ResponsiveValue<any>>({
    base: true,
    md: true,
    lg: false,
  });

  return (
    <Flex direction="column" width="100%" p={0} {...rest}>
      <Flex
        h="108px"
        w={{ base: "95%", md: "700px" }}
        alignItems={isColumnLayout && "center"}
        mb={{ base: 0, md: "48px" }}
        alignSelf={isColumnLayout && "center"}
      >
        <Text
          fontWeight={700}
          fontSize={{ base: "22px", md: "44px" }}
          fontFamily="AeonikPro"
          lineHeight={{ base: "30px", md: "54px" }}
          textAlign={isColumnLayout && "center"}
        >
          {title}
        </Text>
      </Flex>
      <Flex
        direction={isColumnLayout ? "column" : "row"}
        minH="464px"
        w="100%"
        justifyContent={isColumnLayout && "center"}
        gap="32px"
      >
        {isColumnLayout && (
          <Flex
            w={{ base: "100%", md: "50%" }}
            bgColor="#FEFEFE"
            alignSelf="center"
          >
            <Image
              src={steps[activeStep].imageSrc}
              alt={steps[activeStep].label}
            />
          </Flex>
        )}
        <Box w={isColumnLayout ? "100%" : "50%"} mt="28px">
          <Flex
            direction="column"
            gap="28px"
            borderLeft="4px solid"
            borderLeftColor="inactiveTextColor"
          >
            {steps.map((step, index) => (
              <Flex
                key={step.label}
                borderLeftColor={
                  index === activeStep ? "black" : "inactiveTextColor"
                }
                direction={"column"}
              >
                <Text
                  position="relative"
                  left="-4px"
                  fontWeight={500}
                  fontSize={{ base: "18px", md: "32px" }}
                  fontFamily="AeonikPro"
                  lineHeight={{ base: "24px", md: "42px" }}
                  pl="15px"
                  borderLeft={`4px solid`}
                  color={index === activeStep ? "black" : "inactiveTextColor"}
                  cursor="pointer"
                  onClick={() => setActiveStep(index)}
                >
                  {step.label}
                </Text>
                {step.sublabel && (
                  <Text
                    position="relative"
                    left="-4px"
                    fontWeight={400}
                    fontSize={{ base: "16px", md: "24px" }}
                    fontFamily="AeonikPro"
                    lineHeight={{ base: "20px", md: "36px" }}
                    pl="15px"
                    borderLeft={`4px solid`}
                    color={index === activeStep ? "black" : "inactiveTextColor"}
                    cursor="pointer"
                    display={index === activeStep ? "block" : "none"}
                    onClick={() => setActiveStep(index)}
                  >
                    {step.sublabel}
                  </Text>
                )}
              </Flex>
            ))}
          </Flex>
        </Box>
        {!isColumnLayout && (
          <Flex
            w={{ base: "100%", md: "50%" }}
            bgColor="#FEFEFE"
            alignSelf="center"
            justifyContent="center"
          >
            <Image
              src={steps[activeStep].imageSrc}
              alt={steps[activeStep].label}
            />
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};
