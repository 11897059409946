import { listAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

var multiStyleConfig = createMultiStyleConfigHelpers(listAnatomy.keys);

var definePartsStyle = multiStyleConfig.definePartsStyle;
var defineMultiStyleConfig = multiStyleConfig.defineMultiStyleConfig;

var baseStyle = definePartsStyle({
  item: {
    fontFamily: `"Inter", sans-serif`,
  },
});

export var listTheme = defineMultiStyleConfig({
  baseStyle: baseStyle,
});
