import React, { useState, useEffect } from "react";
import axios from "axios";

export interface FeatureToggles {
  [key: string]: boolean;
}
export interface ApplicationConfigContextType {
  toggles?: FeatureToggles;
  environment: string;
  loading?: boolean;
}

const initialContextValue = {
  environment: process.env.REACT_APP_DEPLOY_ENV || "development",
};

export const ApplicationConfig =
  React.createContext<ApplicationConfigContextType>(initialContextValue);

export function useApplicationConfig() {
  const [applicationConfig, setApplicationConfig] =
    useState<ApplicationConfigContextType>({
      ...initialContextValue,
      loading: true,
    });

  useEffect(() => {
    const url = `https://laborhack-feature-toggles.s3.amazonaws.com/toggles/${
      applicationConfig.environment
    }.json?v=${Date.now()}`;
    axios
      .get(url)
      .then((response) => {
        setApplicationConfig({
          ...initialContextValue,
          toggles: response.data,
          loading: false,
        });
      })
      .catch(() => {
        setApplicationConfig({
          ...initialContextValue,
          loading: false,
        });
      });
  }, [setApplicationConfig]);

  return applicationConfig;
}
