import clsx from "clsx";
import { Star, StarHalf } from "lucide-react";
import React from "react";

interface RatingProps {
  rating: number;
  size?: "sm" | "md" | "lg";
}

export function Rating({ rating, size }: RatingProps) {
  return (
    <div className="flex items-center gap-1">
      {Array.from({ length: 5 }).map((_, index) => {
        const currentBar = index + 1;

        const isFilled = currentBar <= rating;

        if (isFilled) {
          return (
            <Star
              key={index}
              className={clsx("fill-yellow-500 stroke-yellow-500", {
                "w-4 h-4": size === "sm",
                "w-5 h-5": !size || size === "md",
                "w-6 h-6": size === "lg",
              })}
            />
          );
        }

        const isHalf = currentBar - rating < 1;

        if (isHalf) {
          return (
            <div
              className={clsx("relative", {
                "w-4 h-4": size === "sm",
                "w-5 h-5": !size || size === "md",
                "w-6 h-6": size === "lg",
              })}
              key={index}
            >
              <Star
                className={clsx(
                  "fill-gray-200 stroke-gray-200 absolute top-0 left-0",
                  {
                    "w-4 h-4": size === "sm",
                    "w-5 h-5": !size || size === "md",
                    "w-6 h-6": size === "lg",
                  }
                )}
              />
              <StarHalf
                className={clsx(
                  "fill-yellow-500 stroke-yellow-500 absolute top-0 left-0",
                  {
                    "w-4 h-4": size === "sm",
                    "w-5 h-5": !size || size === "md",
                    "w-6 h-6": size === "lg",
                  }
                )}
              />
            </div>
          );
        }

        return (
          <Star
            key={index}
            className={clsx("fill-gray-200 stroke-gray-200", {
              "w-4 h-4": size === "sm",
              "w-5 h-5": !size || size === "md",
              "w-6 h-6": size === "lg",
            })}
          />
        );
      })}
    </div>
  );
}
