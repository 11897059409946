import {
  Button,
  ButtonProps,
  Heading,
  Icon,
  IconButton,
  Progress,
  Stack,
  StackProps,
} from "@chakra-ui/react";
import React, { PropsWithChildren } from "react";
import { BiX } from "react-icons/bi";
import { BsArrowLeft } from "react-icons/bs";
import { Link as RouterLink, useRouteMatch } from "react-router-dom";
import { HIRE_ROUTES } from "./constants";

const leadSourceToRoute: { [key: string]: string } = {
  subscriptions_landing_page: "/subscriptions",
  cleaning_subscriptions_landing_page: "/subscriptions/cleaning",
  airconditioning_subscriptions_landing_page: "/subscriptions/air-conditioning",
  fumigation_subscriptions_landing_page: "/subscriptions/fumigation",
  homeowners_landing_page: "/homeowners",
  homeowner_dashboard: `${process.env.REACT_APP_HOMEOWNER_DASHBOARD_URL}`,
};

export interface HireLayoutProps extends StackProps {}

export const HireLayout = ({
  children,
  ...rest
}: PropsWithChildren<HireLayoutProps>) => {
  return (
    <Stack w="full" align="center" spacing={8} {...rest}>
      {children}
    </Stack>
  );
};

export const HireLayoutHeader = ({
  children,
  backTo,
  isLoading,
}: PropsWithChildren<{
  backTo?: string;
  isLoading?: boolean;
}>) => {
  const match = useRouteMatch();

  const currentPath = match.url.split("/").pop();

  const progressIndex = HIRE_ROUTES.findIndex((route) => route === currentPath);

  const progressValue = (progressIndex + 1) * (100 / HIRE_ROUTES.length);

  const leadSource: string | null = localStorage.getItem("homeowner_lead_source");

  const rerouteToLeadSource = () => {

    localStorage.removeItem("homeowner_lead_source");

    if (leadSource) {
      window.open(leadSourceToRoute[leadSource], "_self");
    }
  }


  return (
    <Stack width="full">
      <Stack
        direction="row"
        justify={backTo ? "space-between" : "flex-end"}
        align="center"
        p={5}
      >
        {!!backTo && (
          <Button
            variant="link"
            leftIcon={
              <Icon
                as={BsArrowLeft}
                w={{
                  base: 4,
                  lg: 6,
                }}
                h={{
                  base: 4,
                  lg: 6,
                }}
              />
            }
            as={RouterLink}
            to={backTo}
            color="gray.900"
            fontSize="lg"
          >
            Back
          </Button>
        )}
        <IconButton
          as={RouterLink}
          to="/homeowners"
          isRound={true}
          variant="outline"
          aria-label="close"
          fontSize="24px"
          borderColor="gray.300"
          icon={
            <Icon
              as={BiX}
              w={{
                base: 6,
                lg: 8,
              }}
              h={{
                base: 6,
                lg: 8,
              }}
            />
          }
          onClick={() => {
            if (leadSource) {
              rerouteToLeadSource();
            }
          }}
        />
      </Stack>
      {children && (
        <Stack width="full">
          <Stack direction="row" px={5} py={2}>
            <Heading
              as="h2"
              fontSize={{
                base: "xl",
                lg: "2xl",
              }}
              fontWeight="medium"
            >
              {children}
            </Heading>
          </Stack>
          <Progress
            value={progressValue}
            size="xs"
            colorScheme="flatteredFlamingo"
            borderRadius={0}
            isIndeterminate={isLoading}
          />
        </Stack>
      )}
    </Stack>
  );
};

export const HireLayoutContent = ({ children }: PropsWithChildren<{}>) => {
  return (
    <Stack width="full" p={5}>
      {children}
    </Stack>
  );
};

export const HireLayoutFooter = ({
  children,
  ...rest
}: PropsWithChildren<ButtonProps>) => {
  return (
    <Stack width="full" p={5}>
      <Button size="lg" width="full" colorScheme="flatteredFlamingo" {...rest}>
        {children}
      </Button>
    </Stack>
  );
};
