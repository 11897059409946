import React, { FC, useEffect } from "react";
import {
  Link as RouterLink,
  Route,
  Switch,
  useRouteMatch,
} from "react-router-dom";
import { Helmet } from "react-helmet";
import { logEvent } from "../../helpers/analytics";
import { Footer } from "../../_components/Footer/Footer";
import {
  HStack,
  Heading,
  Image,
  Button,
  VStack,
  Text,
  Highlight,
  Stack,
  Box,
  Link,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  ModalContent,
} from "@chakra-ui/react";
import ProBadge from "../../assets/pro-badge.svg";
import { Section } from "pages/landing/components/Section";
import VerifiedSectionicon from "../../assets/verified-section-icon.svg";
import ClockSectionIcon from "../../assets/clock-section-icon.svg";
import ReceiptIcon from "../../assets/receipt.svg";
import MonitorMobileIcon from "../../assets/monitor-mobile.svg";
import HappyEmojiIcon from "../../assets/happy-emoji.svg";
import ShieldTickIcon from "../../assets/shield-tick.svg";
import CategoriesMarquee from "_components/CategoriesMarquee";
import HowItWorksTabs from "_components/HowItWorksTabs";
import SubmitRequestImage from "../../assets/02-submit-request.png";
import ScheduleAnInspectionImage from "../../assets/03-schedule-an-inspection.png";
import ReceiveAQuoteImage from "../../assets/04-receive-a-quote.png";
import ProjectDeliveredImage from "../../assets/05-project-delivered.png";
import SkilledWorker1Image from "../../assets/skilled-worker-1.png";
import SkilledWorker2Image from "../../assets/skilled-worker-2.png";
import SkilledWorker3Image from "../../assets/skilled-worker-3.png";
import SkilledWorker4Image from "../../assets/skilled-worker-4.png";
import { FAQs } from "_components/FAQs/FAQs";
import { homeownerFAQs } from "pages/faqs/constants";
import { ReviewCardWrapper } from "../../_components/ReviewCard/ReviewCardWrapper";
import { HeroSection } from "../../features/homeowners/hero-section";
import { Root } from "../../features/homeowners/hire/root";

const howToHireSteps = [
  {
    label: "01. Submit request",
    sublabel:
      "Share your requirements, preferences, and any specific details about the task and we'll match you with the best artisan around you. It's simple and free.",
    imageSrc: SubmitRequestImage,
  },
  {
    label: "02. Arrange an Inspection",
    sublabel:
      "After submitting your request, we'll promptly schedule an inspection at your convenience. Our team will visit your location to assess the task firsthand and ensure we fully understand your needs.",
    imageSrc: ScheduleAnInspectionImage,
  },
  {
    label: "03. Receive a quote",
    sublabel:
      "After the inspection is completed, you will receive a detailed quote within hours. This quote will encompass all relevant aspects of the job.",
    imageSrc: ReceiveAQuoteImage,
  },
  {
    label: "04. Project delivered",
    sublabel:
      "Upon acceptance of the quote and payment, we will deliver your task within the agreed timeframe, ensuring the highest quality standards are met.",
    imageSrc: ProjectDeliveredImage,
  },
];

const reviews = [
  {
    name: "Christiana Okere",
    title: "Homeowner",
    imageSrc: "",
    review: `LaborHack was hassle-free to use. The artisan they 
    sent to my house was knowledgeable and 
    professional. He did a great job. 
    I highly recommend them`,
    rating: 5,
  },
  {
    name: "Sarah Keffas",
    title: "Homeowner",
    imageSrc: "",
    review: `After servicing my Air Conditioners with LaborHack, 
    they performed optimally, almost like brand new ones. 
    Service was great and quiet professional. I highly 
    recommend using LaborHack`,
    rating: 5,
  },
];

export interface HomeOwnersProps {}

export const HomeOwners: FC<HomeOwnersProps> = ({}) => {
  useEffect(() => {
    logEvent("page_view");
  }, []);

  const match = useRouteMatch();

  return (
    <>
      <Helmet>
        <title>
          LaborHack Homeowners - Hire certified artisans for your home needs
        </title>
        <meta
          name="description"
          content="Don't let home tasks take over your precious time. Hire well-vetted workers and artisans to handle all your electrical, plumbing, cleaning, fumigation and AC needs."
        />
      </Helmet>

      <VStack w="full" spacing={0}>
        {/* Homeowner hero */}
        <HeroSection />
        <Switch>
          <Route path={`${match.path}/hire/:requestId`}>
            <Root />
          </Route>
        </Switch>
        {/* Household tasks */}
        <Section
          bgColor="#310B05"
          maxH={{ lg: "570px" }}
          paddingTop={{ lg: "70px" }}
        >
          <VStack
            w="full"
            spacing={{
              base: 12,
              lg: 20,
            }}
          >
            <Heading
              as="h2"
              fontSize={{
                base: 22,
                md: 44,
              }}
              fontWeight="bold"
              color="#FEFEFE"
              lineHeight={{
                base: "base",
                md: "shorter",
              }}
              textAlign="center"
            >
              Get your{" "}
              <Highlight
                query={["household"]}
                styles={{
                  padding: 0,
                  color: "flatteredFlamingo.500",
                }}
              >
                household
              </Highlight>{" "}
              <br /> tasks done with ease
            </Heading>
            <HStack
              gap={0}
              position="relative"
              bottom="-30px"
              pb="0px !important"
            >
              <Image
                src={SkilledWorker1Image}
                boxSize={{
                  base: "calc(24vw - 10px)",
                  md: "calc(24vw - 10px)",
                  lg: "calc(24vw - 10px)",
                  xl: "auto",
                }}
              />
              <Image
                src={SkilledWorker2Image}
                boxSize={{
                  base: "calc(24vw - 10px)",
                  md: "calc(24vw - 10px)",
                  lg: "calc(24vw - 10px)",
                  xl: "auto",
                }}
              />
              <Image
                src={SkilledWorker3Image}
                boxSize={{
                  base: "calc(24vw - 10px)",
                  md: "calc(24vw - 10px)",
                  lg: "calc(24vw - 10px)",
                  xl: "auto",
                }}
              />
              <Image
                src={SkilledWorker4Image}
                boxSize={{
                  base: "calc(24vw - 10px)",
                  md: "calc(24vw - 10px)",
                  lg: "calc(24vw - 10px)",
                  xl: "auto",
                }}
              />
            </HStack>
          </VStack>
        </Section>

        <CategoriesMarquee zIndex="overlay" />

        <Section>
          <Stack
            w="full"
            direction={{
              base: "column",
              lg: "row",
            }}
            py={{
              base: 6,
              lg: 0,
            }}
            spacing={{
              base: 4,
              lg: 0,
            }}
          >
            <HStack
              w="full"
              flex={1}
              justifyContent={{
                base: "center",
                lg: "start",
              }}
            >
              <Heading
                as="h2"
                fontSize={{
                  base: 22,
                  md: 44,
                }}
                fontWeight="bold"
                color="prussianBlue.500"
                lineHeight={{
                  base: "base",
                  md: "shorter",
                }}
                textAlign={{
                  base: "center",
                  lg: "start",
                }}
              >
                Enjoy the
                <br />{" "}
                <Highlight
                  query={["LaborHack"]}
                  styles={{
                    padding: 0,
                    color: "flatteredFlamingo.500",
                  }}
                >
                  LaborHack
                </Highlight>{" "}
                experience
              </Heading>
            </HStack>
            <HStack
              w="full"
              flex={1}
              justifyContent={{
                base: "center",
                lg: "start",
              }}
            >
              <Text
                fontSize={{
                  base: 15,
                  lg: 24,
                }}
                lineHeight="base"
                fontWeight={{
                  base: "normal",
                  lg: "medium",
                }}
                color="gray.500"
                textAlign={{
                  base: "center",
                  lg: "start",
                }}
              >
                Whether it&apos;s plumbing, electrical, carpentry or general
                repairs, we ensure you receive quality services. That&apos;s the
                LaborHack Guarantee!
              </Text>
            </HStack>
          </Stack>
        </Section>
        <Section bgColor="gray.50">
          <VStack
            w="full"
            spacing={{
              base: 12,
              lg: 20,
            }}
          >
            <Stack
              w="full"
              direction={{
                base: "column",
                lg: "row",
              }}
              spacing={{
                base: 12,
                lg: 0,
              }}
            >
              <Box flex={1}>
                <VStack
                  w={{
                    base: "full",
                    lg: "92%",
                  }}
                  alignItems="start"
                  spacing={{
                    base: 2,
                    lg: 4,
                  }}
                >
                  <Stack
                    direction={{
                      base: "row",
                      lg: "column",
                    }}
                    spacing={{
                      base: 4,
                      lg: 8,
                    }}
                    alignItems={{
                      base: "center",
                      lg: "start",
                    }}
                  >
                    <Image
                      src={VerifiedSectionicon}
                      w={{
                        base: 10,
                        lg: 20,
                      }}
                    />
                    <Heading
                      as="h4"
                      fontSize={{
                        base: 18,
                        lg: 32,
                      }}
                      fontWeight="medium"
                    >
                      Trusted and certified Artisans
                    </Heading>
                  </Stack>

                  <Text
                    fontSize={{
                      base: 15,
                      lg: 24,
                    }}
                    lineHeight="base"
                    fontWeight={{
                      base: "normal",
                      lg: "medium",
                    }}
                    color="gray.500"
                    w={{
                      base: "100%",
                      lg: "95%",
                    }}
                  >
                    We carefully vet and certify our artisans to ensure they
                    meet the highest standards of skill and professionalism.
                  </Text>
                </VStack>
              </Box>

              <Box flex={1}>
                <VStack
                  w={{
                    base: "full",
                    lg: "92%",
                  }}
                  alignItems="start"
                  spacing={{
                    base: 2,
                    lg: 4,
                  }}
                >
                  <Stack
                    direction={{
                      base: "row",
                      lg: "column",
                    }}
                    spacing={{
                      base: 4,
                      lg: 8,
                    }}
                    alignItems={{
                      base: "center",
                      lg: "start",
                    }}
                  >
                    <Image
                      src={MonitorMobileIcon}
                      w={{
                        base: 10,
                        lg: 20,
                      }}
                    />
                    <Heading
                      as="h4"
                      fontSize={{
                        base: 18,
                        lg: 32,
                      }}
                      fontWeight="medium"
                    >
                      Convenient booking
                    </Heading>
                  </Stack>

                  <Text
                    fontSize={{
                      base: 15,
                      lg: 24,
                    }}
                    lineHeight="base"
                    fontWeight={{
                      base: "normal",
                      lg: "medium",
                    }}
                    color="gray.500"
                    w={{
                      base: "100%",
                      lg: "95%",
                    }}
                  >
                    Our user-friendly platform allows you to book services on a
                    date and time that works best for you.
                  </Text>
                </VStack>
              </Box>
            </Stack>
            <Stack
              w="full"
              direction={{
                base: "column",
                lg: "row",
              }}
              spacing={{
                base: 12,
                lg: 0,
              }}
            >
              <Box flex={1}>
                <VStack
                  w={{
                    base: "full",
                    lg: "92%",
                  }}
                  alignItems="start"
                  spacing={{
                    base: 2,
                    lg: 4,
                  }}
                >
                  <Stack
                    direction={{
                      base: "row",
                      lg: "column",
                    }}
                    spacing={{
                      base: 4,
                      lg: 8,
                    }}
                    alignItems={{
                      base: "center",
                      lg: "start",
                    }}
                  >
                    <Image
                      src={ClockSectionIcon}
                      w={{
                        base: 10,
                        lg: 20,
                      }}
                    />
                    <Heading
                      as="h4"
                      fontSize={{
                        base: 18,
                        lg: 32,
                      }}
                      fontWeight="medium"
                    >
                      Prompt and reliable service
                    </Heading>
                  </Stack>

                  <Text
                    fontSize={{
                      base: 15,
                      lg: 24,
                    }}
                    lineHeight="base"
                    fontWeight={{
                      base: "normal",
                      lg: "medium",
                    }}
                    color="gray.500"
                    w={{
                      base: "100%",
                      lg: "95%",
                    }}
                  >
                    We understand the importance of deadlines. Our artisans
                    arrive on time and complete the job efficiently, minimizing
                    disruptions to your daily life.
                  </Text>
                </VStack>
              </Box>

              <Box flex={1}>
                <VStack
                  w={{
                    base: "full",
                    lg: "92%",
                  }}
                  alignItems="start"
                  spacing={{
                    base: 2,
                    lg: 4,
                  }}
                >
                  <Stack
                    direction={{
                      base: "row",
                      lg: "column",
                    }}
                    spacing={{
                      base: 4,
                      lg: 8,
                    }}
                    alignItems={{
                      base: "center",
                      lg: "start",
                    }}
                  >
                    <Image
                      src={ReceiptIcon}
                      w={{
                        base: 10,
                        lg: 20,
                      }}
                    />
                    <Heading
                      as="h4"
                      fontSize={{
                        base: 18,
                        lg: 32,
                      }}
                      fontWeight="medium"
                    >
                      Transparent pricing
                    </Heading>
                  </Stack>

                  <Text
                    fontSize={{
                      base: 15,
                      lg: 24,
                    }}
                    lineHeight="base"
                    fontWeight={{
                      base: "normal",
                      lg: "medium",
                    }}
                    color="gray.500"
                    w={{
                      base: "100%",
                      lg: "95%",
                    }}
                  >
                    No hidden costs or surprises. You&apos;ll receive upfront
                    pricing, so you know exactly what to expect.
                  </Text>
                </VStack>
              </Box>
            </Stack>

            <Stack
              w="full"
              direction={{
                base: "column",
                lg: "row",
              }}
              spacing={{
                base: 12,
                lg: 0,
              }}
            >
              <Box flex={1}>
                <VStack
                  w={{
                    base: "full",
                    lg: "92%",
                  }}
                  alignItems="start"
                  spacing={{
                    base: 2,
                    lg: 4,
                  }}
                >
                  <Stack
                    direction={{
                      base: "row",
                      lg: "column",
                    }}
                    spacing={{
                      base: 4,
                      lg: 8,
                    }}
                    alignItems={{
                      base: "center",
                      lg: "start",
                    }}
                  >
                    <Image
                      src={ShieldTickIcon}
                      w={{
                        base: 10,
                        lg: 20,
                      }}
                    />
                    <Heading
                      as="h4"
                      fontSize={{
                        base: 18,
                        lg: 32,
                      }}
                      fontWeight="medium"
                    >
                      Peace of mind
                    </Heading>
                  </Stack>

                  <Text
                    fontSize={{
                      base: 15,
                      lg: 24,
                    }}
                    lineHeight="base"
                    fontWeight={{
                      base: "normal",
                      lg: "medium",
                    }}
                    color="gray.500"
                    w={{
                      base: "100%",
                      lg: "95%",
                    }}
                  >
                    With LaborHack, you can know that trustworthy professionals
                    are working on your home. No stories, no disappointment.
                  </Text>
                </VStack>
              </Box>

              <Box flex={1}>
                <VStack
                  w={{
                    base: "full",
                    lg: "92%",
                  }}
                  alignItems="start"
                  spacing={{
                    base: 2,
                    lg: 4,
                  }}
                >
                  <Stack
                    direction={{
                      base: "row",
                      lg: "column",
                    }}
                    spacing={{
                      base: 4,
                      lg: 8,
                    }}
                    alignItems={{
                      base: "center",
                      lg: "start",
                    }}
                  >
                    <Image
                      src={HappyEmojiIcon}
                      w={{
                        base: 10,
                        lg: 20,
                      }}
                    />
                    <Heading
                      as="h4"
                      fontSize={{
                        base: 18,
                        lg: 32,
                      }}
                      fontWeight="medium"
                    >
                      Customer satisfaction
                    </Heading>
                  </Stack>

                  <Text
                    fontSize={{
                      base: 15,
                      lg: 24,
                    }}
                    lineHeight="base"
                    fontWeight={{
                      base: "normal",
                      lg: "medium",
                    }}
                    color="gray.500"
                    w={{
                      base: "100%",
                      lg: "95%",
                    }}
                  >
                    We prioritise customer satisfaction and strive to exceed
                    expectations with every project we undertake.
                  </Text>
                </VStack>
              </Box>
            </Stack>
          </VStack>
        </Section>

        {/* How to hire */}
        <Section bgColor="#F7F7F7">
          <HowItWorksTabs
            title="How to hire Artisans on LaborHack for your home needs"
            steps={howToHireSteps}
          />
        </Section>

        {/* Testimonials */}
        <Section bgColor="white">
          <VStack
            marginLeft="auto"
            marginRight="auto"
            spacing={{
              base: 8,
              lg: 12,
            }}
          >
            <Heading
              as="h2"
              fontSize={{
                base: 22,
                md: 44,
              }}
              fontWeight="bold"
              color="#0E0302"
              lineHeight={{
                base: "base",
                md: "shorter",
              }}
              textAlign="center"
              width={{
                base: "80%",
                lg: "60%",
              }}
            >
              See what our Homeowners have to say about LaborHack
            </Heading>
            <ReviewCardWrapper items={reviews} />
          </VStack>
        </Section>

        {/* FAQs */}
        <HStack
          w="full"
          bgColor="prussianBlue.500"
          justifyContent="center"
          position="relative"
        >
          <Box
            w="full"
            position="absolute"
            bgColor="white"
            minH={{
              base: 150,
              lg: 300,
            }}
            top={0}
          />
          <VStack
            zIndex={99}
            px={5}
            py={{
              base: 8,
              lg: 20,
            }}
            spacing={{
              base: 6,
              lg: 8,
            }}
            w="full"
            maxW="container.xl"
            alignItems="center"
          >
            <Heading
              as="h2"
              fontSize={{
                base: 22,
                md: 44,
              }}
              fontWeight="bold"
              color="prussianBlue.500"
              lineHeight={{
                base: "base",
                md: "shorter",
              }}
              textAlign={{
                base: "center",
              }}
            >
              Frequently Asked Questions
            </Heading>

            <Box
              w="full"
              paddingX={{
                base: 4,
                lg: 16,
              }}
              paddingY={{
                base: 4,
                lg: 8,
              }}
              bgColor="flatteredFlamingo.50"
            >
              <FAQs items={homeownerFAQs} />
            </Box>
          </VStack>
        </HStack>

        <HStack w="full" bgColor="prussianBlue.500" justifyContent="center">
          <VStack
            px={5}
            py={{
              base: 8,
              lg: 20,
            }}
            spacing={{
              base: 6,
              lg: 12,
            }}
            w="full"
            maxW="container.xl"
            alignItems="start"
          >
            <HStack
              w="full"
              justifyContent={{
                base: "start",
                lg: "space-between",
              }}
            >
              <Heading
                as="h5"
                fontSize={{
                  base: "2xl",
                  md: "5xl",
                }}
                fontWeight="normal"
                color="white"
                lineHeight={{
                  base: "base",
                  md: "shorter",
                }}
              >
                <strong>
                  Get skilled professionals to care
                  <br className="block md:hidden" />
                  <br className="hidden md:block" /> for
                </strong>{" "}
                all you home needs
              </Heading>
              <Image
                src={ProBadge}
                alt="LaborHack professional badge"
                w={128}
                display={{
                  base: "none",
                  lg: "block",
                }}
              />
            </HStack>
            <HStack w="full">
              <Link flex={1} as={RouterLink} to="/homeowners/hire/new">
                <Button
                  w={{
                    base: "full",
                    lg: "40%",
                  }}
                  py={6}
                  size={{
                    base: "lg",
                    md: "xl",
                  }}
                >
                  Hire a Pro
                </Button>
              </Link>
            </HStack>
          </VStack>
        </HStack>
      </VStack>

      <Footer />
    </>
  );
};
