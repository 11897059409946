import { gql, useMutation } from "@apollo/client";
import React, { FC, useState } from "react";
import { Form, Icon } from "semantic-ui-react";
import CustomInput from "@laborhack/input";
import Button from "@laborhack/custom-button";
import { FieldValue } from "../../../types";
import { useHistory } from "react-router-dom";
import RequestFailed from "../../../_components/RequestFailed";
import { SuccessfulVerification } from "./SuccessfulVerification";

const RESEND = gql`
  mutation RESEND($id: String!) {
    resendWaitlistedBusinessVerificationCode(id: $id) {
      id
    }
  }
`;

const VERIFY = gql`
  mutation VERIFY($id: String!, $code: String!) {
    verifyWaitlistedBusinessVerificationCode(id: $id, code: $code) {
      id
      contacts {
        emailVerified
      }
    }
  }
`;

export interface VerifyEmailProps {
  email: string;
  id: string;
}

export const VerifyEmail: FC<VerifyEmailProps> = () => {
  const { location } = useHistory();

  const { email, id } = location.state as Record<string, any>;

  const [verificationEmailSent, setVerificationEmailSent] = useState(false);

  const [
    resend,
    { loading: resendLoading, error: resendError, reset: resendReset },
  ] = useMutation(RESEND, {
    variables: {
      id,
    },
    onCompleted: () => {
      setVerificationEmailSent(true);
    },
  });

  const [
    verify,
    { loading: verifyLoading, data, error: verifyError, reset: verifyReset },
  ] = useMutation(VERIFY);

  const defaultField = {
    value: "",
    isValid: false,
  };

  /**
   * Company Details
   */
  const [code, setCode] = useState<FieldValue<string>>(defaultField);

  const handleResendVerification = () => {
    resend();
  };

  const handleVerification = () => {
    verify({
      variables: {
        id,
        code: code.value,
      },
    });
  };

  if (resendError) {
    return <RequestFailed text={resendError.message} onRetry={resendReset} />;
  }

  if (verifyError) {
    return <RequestFailed text={verifyError.message} onRetry={verifyReset} />;
  }

  if (data) {
    return <SuccessfulVerification />;
  }

  return (
    <div className="flex flex-col items-center h-screen justify-center">
      <h1 className="text-3xl text-primary-500 font-bold mb-4">
        Verify Your Email
      </h1>
      <p className="text-primary-300 mb-8 text-center max-w-lg">
        An email has been sent to <strong>{email}</strong> with a verification
        code, please enter the code in the email below
      </p>

      <Form>
        <CustomInput
          autoComplete="name"
          required
          textLabel="Enter Code"
          value={code.value}
          onChange={(value: string, isValid: boolean) => {
            setCode({ value, isValid });
          }}
          validate={{
            check: (value) => value.length === 6,
            message: "Invalid Code",
          }}
          placeholder=""
        />
      </Form>

      <div className="flex flex-col items-center mt-8">
        {verificationEmailSent ? (
          <div className="mb-4 flex items-center">
            <Icon name="check circle" className="text-success" size="large" />
            <p className="text-success font-bold">Sent</p>
          </div>
        ) : (
          <Button
            className="mb-4"
            type="link"
            loading={resendLoading}
            onClick={handleResendVerification}
          >
            Resend Code
          </Button>
        )}

        <Button
          type="circular"
          size="big"
          loading={verifyLoading}
          disabled={!code.isValid}
          onClick={handleVerification}
        >
          Verify
        </Button>
      </div>
    </div>
  );
};
