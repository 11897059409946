import { RecruitmentJob } from "@laborhack/invoice/lib/types";
import { RecruitmentType } from "pages/dashboard/Recruitments/types";

export interface Recipient {
  name: string;

  address: string[];

  phoneNumber: string;

  email: string;
}

export enum TransactionStatus {
  PENDING = "PENDING",
  SUCCESS = "SUCCESS",
  FAILURE = "FAILURE",
}

export interface Payment {
  id: string;
  transactionReference: string;
  transactionDate: Date;
  status: TransactionStatus;
  amount: number;
}

export interface OrderUser {
  userId: string;
  name: string;
  phoneNumber: string;
  email: string;
}

export interface Location {
  id: string;
  buildingNumber: string;
  streetName: string;
  landmark: string;
  area: string;
  location: string;
  city: string;
}

export enum Status {
  ACTIVE = "ACTIVE",
  PAUSED = "PAUSED",
  ARCHIVED = "ARCHIVED",
}

export enum Availability {
  AVAILABLE = "AVAILABLE",
  TENTATIVE = "TENTATIVE",
  ENGAGED = "ENGAGED",
}

export enum JobProgress {
  NOT_STARTED = "NOT_STARTED",
  PRE_INSPECTION = "PRE_INSPECTION",
  FINAL_QUOTE = "FINAL_QUOTE",
  MATERIALS_PURCHASED = "MATERIALS_PURCHASED",
  CLIENT_INSPECTION = "CLIENT_INSPECTION",
  COMPLETED = "COMPLETED",
}

export interface JobProgressLog {
  user: string;
  update: JobProgress;
}

export interface Task {
  id: string;
  description: string;
  name: string;
  estimatedCost: number;
  requestCount: number;
  categoryId: string;
  subCategoryId: string;
  imageUrl: string;
}

export interface Tradesman {
  id: string;
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  location: Location;
  level: number;
  imageUrl: string;
  categoryId: string;
  referralCode: string;
  availability?: Availability;
}

export interface Contact {
  notes?: string;
  location: Location;
}

export interface JobCustomTask {
  name: string;
  description: string;
  resourceUrl: string;
  subCategoryId: string;
  categoryId: string;
}

export interface InvoiceMaterial {
  name: string;
  unitPrice: number;
  quantity: number;
}

export interface Job {
  id: string;
  jobCode: string;
  custom?: JobCustomTask;
  assignedTradesman?: Tradesman;
  task?: Task;
  finalCost: number;
  status: Status;
  progress: JobProgress;
  completedAt: number;
  payment?: Payment;
  contact: Contact;
  progressLog?: JobProgressLog[];
  invoiceDetails?: {
    materials?: InvoiceMaterial[];
    labor?: number;
  };
}

export interface LocationSchedule {
  locationId: string;
  scheduledDate: Date;
}

export interface Order {
  id: string;
  orderCode: string;
  inspectionPayment: Payment;
  jobs: Job[];
  client: OrderUser;
  createdByUid: string;
  locationSchedules: LocationSchedule[];
  createdAt: Date;
  isEmergency: boolean;
  suggestedTradesmen?: Tradesman[];
}

export enum InvoiceType {
  INSPECTION = "INSPECTION",
  MATERIALS = "MATERIALS",
  MARKETPLACE_REQUEST_MATCH = "MARKETPLACE_REQUEST_MATCH",
}

export interface SplitPayment {
  percentage: number;
  payment?: Payment;
}

export enum ContractInterval {
  MONTHS = "MONTHS",
  WEEKS = "WEEKS",
  DAYS = "DAYS",
}

export enum RecruitmentRequestStatus {
  PENDING = "PENDING",
  QUOTATION_SENT = "QUOTATION_SENT",
  ASSIGNING = "ASSIGNING",
  FULFILLED = "FULFILLED",
}

export interface ContractDetails {
  length: number;
  interval: ContractInterval;
}

export interface RecruitmentRequest {
  /**
   * The number of professionals to recruit
   */

  proCount: number;

  /**
   * The level of the professionals to recruit
   */

  proLevel: number;

  categoryId: string;

  contractDetails: ContractDetails;

  proposedStartDate: Date;
}

export enum QuotationStatus {
  NOT_SENT = "NOT_SENT",
  AWAITING_APPROVAL = "AWAITING_APPROVAL",
  APPROVED = "APPROVED",
  CANCELLED = "CANCELLED",
}

export interface ContractQuotationItem {
  isActive: boolean;

  categoryId: string;

  proCount: number;

  level: number;

  contractDetails: ContractDetails;

  workdays: number;

  requestedStartDate: Date;

  /**
   * Daily Rate
   */
  rate?: number;
}

export interface ContractQuotation {
  id: string;

  contractRecruitmentId: string;

  items: ContractQuotationItem[];

  status: QuotationStatus;

  createdAt: Date;
}

export interface Client {
  id: string;
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}

export interface ContractRecruitment {
  id: string;

  client: Client;

  requests: RecruitmentRequest[];

  activeQuotation?: ContractQuotation;

  quotations?: ContractQuotation[];

  paymentSettings: {
    payBeforeService: boolean;
  };

  status: RecruitmentRequestStatus;

  jobs: RecruitmentJob[];

  createdAt: Date;

  recruitmentType: any;
}

export type WorkingDays =
  | "Monday"
  | "Tuesday"
  | "Wednesday"
  | "Thursday"
  | "Friday"
  | "Saturday"
  | "Sunday";

export interface NonBillableDate {
  startDate: Date;
  /**
   * End date is optional
   *
   * If not provided, the date is assumed to be a single day
   */
  endDate?: Date;
}

export interface Contract {
  id: string;

  pro: Tradesman;

  proId?: string;

  recruitment: ContractRecruitment;

  recruitmentId: string;

  proLevel: number; // requested pro level for this contract

  categoryId: string; // requested category for this contract

  agreedRate: number; // agreed daily rate for this contract

  startDate: Date;

  endDate: Date;

  requestedStartDate: Date;

  contractLength: number;

  contractInterval: ContractInterval;

  /**
   * Dates that do not count during payment
   *
   * This is a string array with entries representing a single date or a date range.
   * For example:
   * 2022-03-31T00:00:00.000Z--2022-04-01T00:00:00.000Z would represent the date range 31st of March to the 1st of April.
   *
   * This is to reduce storage space and to avoid having to store every single date in a range of 3 months for instance.
   */
  nonBillableDates?: string[];

  /**
   * Days that do not count during payment
   *
   */
  nonWorkingDays?: WorkingDays[];

  /**
   * Holds non-working day dates where the pro was called in
   */
  overtimeDates?: Date[];

  status: ContractStatus;

  terminatedAt?: Date;

  createdAt: Date;
}

export enum ContractStatus {
  PENDING = "PENDING",
  ACTIVE = "ACTIVE",
  CANCELLED = "CANCELLED",
}

export interface Period {
  start: Date;
  end: Date;
}

export interface RecruitmentData {
  contract: Contract;
  period: Period;
  numberOfBilledDays: number;
  agreedDailyRate: number;
}

export interface InvoiceDiscount {
  id: string;
  code: string;
  description: string;
  /**
   * The value of the discount. This is between 0 and 1.
   */
  value: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface IInvoice {
  id: string;

  type: InvoiceType;

  canceled: boolean;

  invoiceNumber: number;

  invoiceDate: Date;

  recruitment: ContractRecruitment;

  recruitmentData: RecruitmentData[];

  allowedPaymentProviders: string[];

  due?: Date;

  inspectionAmount: number;

  recipient: Recipient;

  discount: number; // float below 1.0 for discount percentage

  appliedDiscount?: InvoiceDiscount; // float below 1.0 for discount percentage

  assignments: TradesmanAssignment[];

  payment?: Payment;

  splitPayments?: SplitPayment[];

  isFullyPaid: boolean;

  hasAtLeastOnePayment: boolean;

  reimbursements:
    | {
        id: string;
        excludeTax?: boolean;
        payout: {
          id: string;
          worksheet: {
            contract: {
              pro: {
                firstName: string;
                lastName: string;
              };
            };
            payroll: {
              period: {
                start: string;
                end: string;
              };
            };
          };
          breakdown: {
            gross: number;
            net: number;
            tax?: number;
          };
        };
        __typename: "ContractPayoutReimbursement";
      }[]
    | null;
}

export interface TradesmanAssignment {
  id: string;
  order: Order;
  jobs: Job[];
  tradesman: Tradesman;
  date: Date;
  progress: JobProgress;
  invoice?: IInvoice;
}

export interface PaymentProvider {
  id: string;
  name: string;
  logo: string;
  transactionFee: number;
  isActive: boolean;
}

export interface InvoicePayment {
  total: number;
  subTotal: number;
  materials?: number;
  labor?: number;
  laborHackFee?: number;
  nextPaymentPercentage: number;
}
