import React from "react";
import { HStack, StackProps } from "@chakra-ui/react";
import { PropsWithChildren } from "react";

export const SectionActions = ({
  children,
  ...props
}: PropsWithChildren<StackProps>) => {
  return (
    <HStack w="full" spacing={4} {...props}>
      {children}
    </HStack>
  );
};
