import React from "react";
import { Heading, HeadingProps } from "@chakra-ui/react";
import { PropsWithChildren } from "react";

export const SectionTitle = ({
  children,
  ...props
}: PropsWithChildren<HeadingProps>) => {
  return (
    <Heading
      as="h2"
      fontSize={{
        base: 22,
        md: 44,
      }}
      fontWeight="bold"
      color="prussianBlue.500"
      lineHeight={{
        base: "short",
        md: "shorter",
      }}
      textAlign={{
        base: "center",
        lg: "start",
      }}
      {...props}
    >
      {children}
    </Heading>
  );
};
