import React, { FC, useState } from "react";
import { Form } from "semantic-ui-react";
import CustomInput from "@laborhack/input";
import Button from "@laborhack/custom-button";

import styles from "./styles.module.scss";
import { FieldValue } from "types";
import {
  nonEmpty,
  phoneNumberCheck,
  phoneNumberDoesNotExist,
} from "helpers/utils/funtions";
import { useApolloClient } from "@apollo/client";
import RequestFailed from "../../../_components/RequestFailed";
import Dropdown from "../../../_components/Dropdown";
import { AccountCreationInput } from "./types";

const leadSourceOptions: {
  key: string;
  text: string;
  value: string;
}[] = [
  {
    key: "friend",
    text: "Word of mouth",
    value: "WordOfMouth",
  },
  {
    key: "google",
    text: "Google Ad",
    value: "GoogleAd",
  },
  {
    key: "socials",
    text: "Social Media",
    value: "SocialMedia",
  },
  {
    key: "radio",
    text: "Radio",
    value: "Radio",
  },
  {
    key: "newspaper",
    text: "Newspaper",
    value: "Newspaper",
  },
  {
    key: "flyer",
    text: "Marketing Flyer",
    value: "MarketingFlyer",
  },
  {
    key: "marketer",
    text: "LaborHack Marketer",
    value: "LaborHackMarketer",
  },
  {
    key: "herconomy",
    text: "Herconomy",
    value: "Herconomy",
  },
  {
    key: "other",
    text: "Other",
    value: "Other",
  },
];

export interface CompletionFormProps {
  injectedFields: {
    phoneNumber?: string;
    leadSource?: string;
  };
  requestedFields?: {
    name?: boolean;
    phoneNumber?: boolean;
    leadSource?: boolean;
  };
  handleAccountCreation: (data: AccountCreationInput) => Promise<void>;
}

export const CompletionForm: FC<CompletionFormProps> = ({
  requestedFields,
  injectedFields,
  handleAccountCreation,
}) => {
  const client = useApolloClient();

  const defaultField = {
    value: "",
    isValid: false,
  };

  const [firstName, setFirstName] = useState<FieldValue<string>>(defaultField);
  const [lastName, setLastName] = useState<FieldValue<string>>(defaultField);
  const [phoneNumber, setPhoneNumber] = useState<FieldValue<string>>(
    injectedFields.phoneNumber
      ? { value: injectedFields.phoneNumber, isValid: true }
      : defaultField
  );
  const [leadSource, setLeadSource] = useState<FieldValue<string | undefined>>(
    injectedFields.leadSource
      ? { value: injectedFields.leadSource, isValid: true }
      : { ...defaultField, value: undefined }
  );

  const [submitting, setSubmitting] = useState(false);
  const [submissionError, setSubmissionError] = useState<string>();

  const createAccountDisabled = () => {
    if (requestedFields?.name && (!firstName.isValid || !lastName.isValid)) {
      return true;
    }

    if (requestedFields?.phoneNumber && !phoneNumber.isValid) {
      return true;
    }

    if (requestedFields?.leadSource && !leadSource.isValid) {
      return true;
    }
  };

  const handleSubmit = async () => {
    try {
      if (!leadSource.value) throw new Error("lead source is required");
      setSubmitting(true);

      await handleAccountCreation({
        firstName: requestedFields?.name ? firstName.value : undefined,
        lastName: requestedFields?.name ? lastName.value : undefined,
        phoneNumber: requestedFields?.phoneNumber
          ? phoneNumber.value
          : undefined,
        leadSource: leadSource.value,
      });
    } catch (error: any) {
      setSubmissionError(error.message);
    }
  };

  if (submissionError) {
    return (
      <RequestFailed
        text={submissionError}
        onRetry={() => {
          setSubmissionError(undefined);
          setSubmitting(false);
        }}
      />
    );
  }

  return (
    <Form>
      {requestedFields?.name && (
        <Form.Group widths="equal">
          <CustomInput
            autoComplete="given-name"
            required
            textLabel="First Name"
            value={firstName.value}
            onChange={(value: string, isValid: boolean) => {
              setFirstName({ value, isValid });
            }}
            validate={{
              check: nonEmpty,
              message: "Invalid Name",
            }}
            placeholder="First Name"
          />
          <CustomInput
            autoComplete="family-name"
            required
            textLabel="Last Name"
            value={lastName.value}
            onChange={(value: string, isValid: boolean) => {
              setLastName({ value, isValid });
            }}
            validate={{
              check: nonEmpty,
              message: "Invalid Name",
            }}
            placeholder="Last Name"
          />
        </Form.Group>
      )}
      {requestedFields?.phoneNumber && !injectedFields.phoneNumber && (
        <CustomInput
          autoComplete="tel"
          required
          textLabel="Phone Number"
          label="+234"
          type="tel"
          value={phoneNumber.value}
          onChange={(value: string, isValid: boolean) => {
            setPhoneNumber({ value, isValid });
          }}
          validate={{
            check: phoneNumberCheck,
            message: "Invalid Phone Number",
          }}
          asyncValidate={{
            check: (value: string) => phoneNumberDoesNotExist(client, value),
            message: "This phone number already exists",
          }}
          placeholder="8000000000"
        />
      )}
      {requestedFields?.leadSource && (
        <Dropdown
          value={leadSource.value}
          label="How did you hear about us?"
          onChange={(value) => {
            setLeadSource({
              value,
              isValid: true,
            });
          }}
          required
          placeholder="Select One"
          options={leadSourceOptions}
        />
      )}

      <Button
        className={styles.submit}
        loading={submitting}
        disabled={createAccountDisabled()}
        onClick={handleSubmit}
        variant="success"
        fullWidth
      >
        Complete Profile
      </Button>
    </Form>
  );
};
