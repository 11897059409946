import React, { PropsWithChildren } from "react";
import { Heading, HeadingProps } from "@chakra-ui/react";
import clsx from "clsx";

interface PortfolioSectionHeadingProps extends HeadingProps {}

export const PortfolioSectionHeading = ({
  children,
  className,
  ...props
}: PropsWithChildren<PortfolioSectionHeadingProps>) => {
  return (
    <Heading
      as="h3"
      className={clsx(
        "bg-gray-100 px-4 py-2 !font-black tracking-wider uppercase text-gray-500 !text-lg lg:!text-xl",
        className
      )}
      {...props}
    >
      {children}
    </Heading>
  );
};
