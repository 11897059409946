import React, { FC, useState } from "react";
import { Form } from "semantic-ui-react";
import Input from "@laborhack/input";
import { FieldValue } from "../../types";
import { useApolloClient } from "@apollo/client";
import {
  emailDoesNotExist,
  nonEmpty,
  phoneNumberCheck,
  phoneNumberDoesNotExist,
} from "../../helpers/utils/funtions";
import Joi from "@hapi/joi";
import Button from "@laborhack/custom-button";
import { Contact } from "./types";

export interface AddContactFormProps {
  loading?: boolean;
  onSubmit: (contact: Contact) => void;
}

export const AddContactForm: FC<AddContactFormProps> = ({
  loading,
  onSubmit,
}) => {
  const client = useApolloClient();

  const defaultField = {
    value: "",
    isValid: false,
  };

  const [firstName, setFirstName] = useState<FieldValue<string>>(defaultField);
  const [lastName, setLastName] = useState<FieldValue<string>>(defaultField);
  const [phoneNumber, setPhoneNumber] =
    useState<FieldValue<string>>(defaultField);
  const [email, setEmail] = useState<FieldValue<string>>(defaultField);

  const createAccountEnabled =
    firstName.isValid && lastName.isValid && email.isValid;

  const createAccount = () => {
    createAccountEnabled &&
      onSubmit({
        firstName: firstName.value,
        lastName: lastName.value,
        email: email.value,
        phoneNumber: phoneNumber.isValid
          ? "+234" + phoneNumber.value
          : undefined,
      });
  };

  return (
    <div className="mt-8 p-8 bg-white rounded">
      <h1 className="text-2xl md:text-3xl mb-4 font-bold text-primary-500">
        One more thing...
      </h1>
      <h3 className="md:text-lg mb-4 font-semibold text-primary-300">
        We would like to reach out directly next time
      </h3>

      <Form>
        <Form.Group widths="equal">
          <Input
            autoComplete="given-name"
            required
            textLabel="First Name"
            value={firstName.value}
            onChange={(value: string, isValid: boolean) => {
              setFirstName({ value, isValid });
            }}
            validate={{
              check: nonEmpty,
              message: "Invalid Name",
            }}
            placeholder="First Name"
          />
          <Input
            autoComplete="family-name"
            required
            textLabel="Last Name"
            value={lastName.value}
            onChange={(value: string, isValid: boolean) => {
              setLastName({ value, isValid });
            }}
            validate={{
              check: nonEmpty,
              message: "Invalid Name",
            }}
            placeholder="Last Name"
          />
        </Form.Group>

        <Input
          autoComplete="email"
          type="email"
          required
          textLabel="Email Address"
          value={email.value}
          onChange={(value: string, isValid: boolean) => {
            setEmail({ value, isValid });
          }}
          validate={{
            check: (value: string) => {
              return !Joi.string()
                .email({
                  minDomainSegments: 2,
                  tlds: { allow: false },
                })
                .validate(value).error;
            },
            message: "Invalid Email",
          }}
          asyncValidate={{
            check: (value: string) => emailDoesNotExist(client, value),
            message: "This email already exists",
          }}
          placeholder="Enter Email Address"
        />
        <Input
          autoComplete="tel"
          textLabel="Mobile Phone Number"
          label="+234"
          type="tel"
          value={phoneNumber.value}
          onChange={(value: string, isValid: boolean) => {
            setPhoneNumber({ value, isValid });
          }}
          validate={{
            check: phoneNumberCheck,
            message: "Invalid Phone Number",
          }}
          asyncValidate={{
            check: (value: string) => phoneNumberDoesNotExist(client, value),
            message: "This phone number already exists",
          }}
          placeholder="8000000000"
        />
        <div className="mt-4">
          <Button
            loading={loading}
            disabled={!createAccountEnabled}
            onClick={createAccount}
            fullWidth
          >
            Submit
          </Button>
        </div>
      </Form>
    </div>
  );
};
