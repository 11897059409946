import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import PDFRenderer from "../../_components/PDFRenderer";
import { LABORHACK_PRO_TERMS_OF_USE_URL } from "../../constants";
import { logEvent } from "../../helpers/analytics";
import Footer from "../../_components/Footer";

export const ProTermsOfUse = () => {
  useEffect(() => {
    logEvent("page_view");
  });

  return (
    <>
      <Helmet>
        <title>Pro Terms of Use | LaborHack</title>
      </Helmet>
      <PDFRenderer fileUrl={LABORHACK_PRO_TERMS_OF_USE_URL} />
      <Footer />
    </>
  );
};
