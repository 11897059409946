import React, { FC } from "react";
import { useLocation } from "react-router-dom";
import EmailVerified from "./EmailVerified";
import PasswordReset from "../../reset-password/components/ResetAuth";
import CompleteEmailSignIn from "pages/auth/completeEmailSignIn";

const RegisterAuth: FC = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);

  const actionCode = query.get("oobCode");
  const mode = query.get("mode");

  if (mode && actionCode) {
    switch (mode) {
      case "verifyEmail":
        return <EmailVerified actionCode={actionCode} />;
      case "resetPassword":
        return <PasswordReset actionCode={actionCode} />;
      case "signIn":
        return <CompleteEmailSignIn />;
      case "recoverEmail":
        // reset password email component handler
        break;
    }

    return <div>mode not found</div>;
  } else {
    return <div>No parameters</div>;
  }
};

export default RegisterAuth;
