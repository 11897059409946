import { gql } from "@apollo/client";

export const CONFIRM_INVOICE_PAYMENT = gql`
  mutation ConfirmInvoicePayment($invoiceId: String!, $transactionId: String) {
    confirmInvoicePayment(
      invoiceId: $invoiceId
      transactionId: $transactionId
    ) {
      status
      message
    }
  }
`;

export const ACTIVATE_JOB = gql`
  mutation ActivateJob($jobId: String!) {
    activateJob(jobId: $jobId) {
      status
      message
    }
  }
`;

export const ARCHIVE_JOB = gql`
  mutation ArchiveJob($jobId: String!) {
    archiveJob(jobId: $jobId) {
      status
      message
    }
  }
`;

export const GET_INVOICE_PAYMENT_LINK = gql`
  mutation GetInvoicePaymentLink(
    $invoiceId: String!
    $providerId: String!
    $callback: String
  ) {
    getInvoicePaymentLink(
      invoiceId: $invoiceId
      providerId: $providerId
      callback: $callback
    ) {
      status
      message
      data {
        paymentUrl
      }
    }
  }
`;

export const APPLY_DISCOUNT = gql`
  mutation ApplyDiscount($invoiceId: String!, $discountCode: String!) {
    applyInvoiceDiscountCode(
      invoiceId: $invoiceId
      discountCode: $discountCode
    ) {
      id
      type
      discount
      appliedDiscount {
        id
        code
        value
      }
    }
  }
`;
