import React from "react";
import {
  Flex,
  Text,
  Box,
  Image,
  useBreakpointValue,
  FlexProps,
} from "@chakra-ui/react";
import Marquee from "react-fast-marquee";
import asterisk from "./asterisk.svg";

const categories: string[] = [
  "Electricians",
  "Cleaners",
  "Plumbers",
  "Welders",
  "AC Technicians",
  "Carpenters",
  "Masons",
  "Painters",
  "Solar Technicians",
  "POP Workers",
  "Aluminium Workers",
];

export const CategoriesMarquee: React.FC<FlexProps> = ({ ...flexProps }) => {
  const height = useBreakpointValue({ base: "44px", md: "88px" });
  const fontSize = useBreakpointValue({ base: "18px", md: "34px" });
  const fontWeight = useBreakpointValue({ base: 400, md: 500 });

  return (
    <Flex
      width="100%"
      height={height}
      bgColor="flatteredFlamingo.500"
      {...flexProps}
    >
      <Marquee autoFill={true}>
        {categories.map((category: string) => (
          <Flex key={category} alignItems="center">
            <Text
              color="#FEFEFE"
              fontSize={fontSize}
              fontWeight={fontWeight}
              fontFamily="AeonikPro"
              fontStyle="normal"
              lineHeight="40px"
              ml={{ base: "16px", md: "24px" }}
            >
              {category}
            </Text>
            <Image
              width={{ base: "18px", md: "32px" }}
              src={asterisk}
              alt="*"
              ml={{ base: "16px", md: "24px" }}
            />
          </Flex>
        ))}
      </Marquee>
    </Flex>
  );
};
