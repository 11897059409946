import { useEffect } from "react";
import { logEvent } from "../helpers/analytics";

export const usePageView = () => {
  useEffect(() => {
    setTimeout(() => {
      logEvent("page_view");
    }, 0);
  }, []);
};
