import React from "react";
import { HStack, Image, ImageProps } from "@chakra-ui/react";

export const SectionImage = (props: ImageProps) => {
  return (
    <HStack
      flex={1}
      justifyContent="center"
      w={{
        base: "full",
        lg: "90%",
      }}
      order={3}
    >
      <Image
        h={{
          base: 240,
          lg: 384,
        }}
        fit="contain"
        {...props}
      />
    </HStack>
  );
};
