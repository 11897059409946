import { useAuth0 } from "@auth0/auth0-react";
import React, { FC, useEffect, useState } from "react";
import { useApplicationConfig } from "../../context/ApplicationConfig";
import { useSession } from "../../hooks";
import Container from "../../_components/Container";
import Loading from "../../_components/Loading";

const $: any = window;

export interface HireFormProps {}

export const HireForm: FC<HireFormProps> = ({}) => {
  const { user: firebaseUser } = useSession();
  const { user: auth0User } = useAuth0();
  const { toggles, loading: applicationConfigLoading } = useApplicationConfig();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    localStorage.removeItem("continue_url_after_signin");
  }, []);

  const userId = toggles?.use_auth0_explicitly
    ? auth0User?.sub
    : firebaseUser?.uid;

  useEffect(() => {
    if (!applicationConfigLoading && userId) {
      $.EmbedManager?.embed({
        key: `https://fs9.formsite.com/res/showFormEmbed?EParam=${process.env.REACT_APP_FORMSITE_EPARAM}&${process.env.REACT_APP_FORMSITE_FORM_ID}`,
        width: "100%",
        prePopulate: { id125: userId },
        resizeCallback: () => {
          setLoading(false);
        },
      });
    }
  }, [$.EmbedManager, userId, applicationConfigLoading]);

  return (
    <Container>
      {loading && <Loading />}
      <a id={`formAnchor${process.env.REACT_APP_FORMSITE_FORM_ID}`}></a>
    </Container>
  );
};
