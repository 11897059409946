import React, { FC, PropsWithChildren } from "react";
import { Footer } from "../Footer/Footer";

export interface PageSkeletionProps {}

export const PageSkeletion = ({
  children,
}: PropsWithChildren<PageSkeletionProps>) => {
  return (
    <>
      {children}
      <Footer />
    </>
  );
};
