import { ApolloClient, InMemoryCache, HttpLink, split } from "@apollo/client";
import {
  getMainDefinition,
  relayStylePagination,
} from "@apollo/client/utilities";
import { setContext } from "@apollo/client/link/context";
import { WebSocketLink } from "@apollo/client/link/ws";
import firebase from "./firebase";

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_BACKEND_URL}/graphql`,
});

const wsLink = new WebSocketLink({
  uri: `${process.env.REACT_APP_WEBSOCKET_URL}/graphql`,
  options: {
    lazy: true,
    reconnect: true,
    connectionParams: async () => {
      const token = await firebase.auth().currentUser?.getIdToken();

      return {
        authorization: token || "",
      };
    },
  },
});

const authLink = setContext((_, { headers }) => {
  return new Promise(async (resolve) => {
    const token = await firebase.auth().currentUser?.getIdToken();

    resolve({
      headers: {
        ...headers,
        authorization: token || "",
      },
    });
  });
});

// The split function takes three parameters:
//
// * A function that's called for each operation to execute
// * The Link to use for an operation if the function returns a "truthy" value
// * The Link to use for an operation if the function returns a "falsy" value
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  authLink.concat(httpLink)
);

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        orders: {
          ...relayStylePagination(),
          read: (existing, { args }) => {
            if (!args) {
              return existing;
            }

            return existing;
          },
        },
      },
    },
  },
});

export const client = new ApolloClient({
  cache,
  link: splitLink,
});
