import React, { useState, FC, useEffect } from "react";
import firebase from "./../../../helpers/firebase";
import { Button, Form, Grid, Message } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import greencheck from "../../../assets/greencheck.svg";
import { validator, resetPasswordValidator } from "./validator";
import { IFormFieldState } from "../types";

const PasswordReset: FC<{
  actionCode: string;
}> = ({ actionCode }) => {
  const [state, setState] = useState({
    email: "",
    password: "",
    error: "",
    success: false,
    showPassword: false,
  });
  const [passwordField, setPasswordField] = useState<IFormFieldState>({
    error: false,
    initial: true,
    value: "",
  });
  const [passwordReset, setPasswordReset] = useState<boolean>(false);

  const history = useHistory();

  useEffect(() => {
    if (actionCode) {
      firebase.auth().verifyPasswordResetCode(actionCode);
    }
  }, [actionCode]);

  const submitPassword = () => {
    firebase
      .auth()
      .confirmPasswordReset(actionCode, passwordField.value)
      .then((res) => {
        setState({
          ...state,
          success: true,
        });
        setPasswordReset(true);
      })
      .catch((error) => {
        setState({
          ...state,
          error: error,
        });
      });
  };

  return (
    <Grid textAlign="center" style={{ height: "80vh" }} verticalAlign="middle">
      <Grid.Column className="reset-card">
        {/* <Header as="h2">Laborhack</Header> */}
        <div className="reset-form__wrapper">
          <div className="form-title">
            <h3>Reset Password</h3>
          </div>
          <div className="form-text">
            {state.error !== "" && (
              <Message
                error
                header="Error"
                content="An error occur, reset link is invalid!"
              />
            )}
            {passwordReset ? (
              <div className="success-text">
                <img
                  src={greencheck}
                  alt="green check vrifying successful change"
                />
                <p>Your password has been reset successfully.</p>
              </div>
            ) : (
              <div>
                <p>Enter a new password.</p>
                <Message content="Password must not be less than 8 characters and must contain special character (only @,$,!,%,*,? or &), uppercase letter, lowercase letter and number" />
              </div>
            )}
          </div>
          <div className="form-field">
            {passwordReset ? (
              <div>
                <Button
                  fluid
                  size="large"
                  onClick={() => {
                    history.push("/auth/login");
                  }}
                >
                  Login
                </Button>
              </div>
            ) : (
              <div>
                {" "}
                <Form.Input
                  fluid
                  {...(!passwordField.initial && {
                    icon: !!validator.validate({
                      password: passwordField.value,
                    }).error
                      ? {
                          name: "remove",
                          color: "red",
                        }
                      : {
                          name: "check",
                          color: "green",
                        },
                  })}
                  onChange={(e) => {
                    const { value } = e.target;
                    setPasswordField((state) => {
                      return {
                        ...state,
                        value,
                        initial: false,
                      };
                    });
                  }}
                  placeholder="Password"
                  type="password"
                  value={passwordField.value}
                  error={passwordField.error}
                  onBlur={() => {
                    const { error } = validator.validate({
                      password: passwordField.value,
                    });
                    if (error) {
                      setPasswordField((state) => {
                        return {
                          ...state,
                          error: {
                            content:
                              "Invalid Password. Please make sure it has a special character, uppercase letter, lowercase letter, number and is above 8 characters",
                            pointing: "above",
                          },
                        };
                      });
                    }
                  }}
                  onFocus={() => {
                    setPasswordField((state) => {
                      return {
                        ...state,
                        error: false,
                      };
                    });
                  }}
                />
                <Button
                  fluid
                  size="large"
                  onClick={submitPassword}
                  {...(resetPasswordValidator.validate({
                    password: passwordField.value,
                  }).error && { disabled: true })}
                >
                  Reset Password
                </Button>
              </div>
            )}
          </div>
        </div>

        {/* ========================= */}
      </Grid.Column>
    </Grid>
  );
};
export default PasswordReset;
