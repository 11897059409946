import { Button, HStack, VStack } from "@chakra-ui/react";
import React from "react";
import { AccordionItem } from "./AccordionItem";

export const Accordion = () => {
  return (
    <div className="flex flex-col w-full">
      <AccordionItem />
      <AccordionItem />
      <AccordionItem />
      <HStack spacing={4}>
        <Button
          colorScheme="prussianBlue"
          size="xl"
          variant="solid-dark"
          w="full"
        >
          Click me
        </Button>
      </HStack>
    </div>
  );
};
