import {
  Radio,
  RadioGroup,
  Stack,
} from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { useRouteMatch } from "react-router-dom";
import { HomeownerHireContext } from "../../../context/homeowners/homeowner-hire.context";
import { logEvent } from "../../../helpers/analytics";
import { usePageView } from "../../../hooks/usePageView";
import {
  HireLayout,
  HireLayoutContent,
  HireLayoutFooter,
  HireLayoutHeader,
} from "./layout";

export const IsSubscriptionRequest = () => {
  usePageView();

  const match = useRouteMatch();

  const { formData, setFormData, isLoading, client } =
    useContext(HomeownerHireContext);

  const [type, setType] = React.useState<string | undefined>(
    formData.isSubscriptionRequest === true ? "Subscription request" : formData.isSubscriptionRequest === false ? "One time request" : undefined
  );

  const [isSubscriptionRequest, setIsSubscriptionRequest] = React.useState <
    boolean | undefined
  > (formData.isSubscriptionRequest ? formData.isSubscriptionRequest : undefined);

  const backToPath = match.url.replace(/\/[^/]+$/, "/select-category");

  const handleTypeChange = (value: string) => {
    setType(value);

    const choice = value === "Subscription request" ? true : value === "One time request" ? false : undefined;

    setIsSubscriptionRequest(choice);

    logEvent("homeowner/hire/is_subscription_request_selected");
  };

  const canProceed = type !== undefined;

  const handleProceed = () => {
    if (!canProceed) {
      return;
    }

    logEvent("homeowner/hire/is_subscription_request_set", {
      isSubscriptionRequest,
    });

    setFormData(
      {
        ...formData,
        isSubscriptionRequest,
      },
      isSubscriptionRequest ? "subscription-details" : "describe-task"
    );
  };

  return (
    <>
      <Helmet>
        <title>
          Start Date | LaborHack Homeowners - Hire certified artisans for your
          home needs
        </title>
      </Helmet>
      <HireLayout>
        <HireLayoutHeader backTo={backToPath} isLoading={isLoading}>
          What type of request do you want?
        </HireLayoutHeader>
        <HireLayoutContent>
          <Stack spacing={8}>
            <RadioGroup onChange={handleTypeChange} value={type}>
              <Stack>
                <Radio value="One time request">One time request</Radio>
                <Radio value="Subscription request">Subscription request</Radio>
              </Stack>
            </RadioGroup>
          </Stack>
        </HireLayoutContent>
        <HireLayoutFooter
          isDisabled={!canProceed}
          isLoading={isLoading}
          onClick={handleProceed}
        >
          Proceed
        </HireLayoutFooter>
      </HireLayout>
    </>
  );
};
