import { useQuery } from "@apollo/client";
import { GET_CATEGORIES } from "../graphql/query.constants";
import { Category } from "../pages/dashboard/types";

export const useCategories = () => {
  const { data, error, loading } = useQuery<{
    categories: Category[];
  }>(GET_CATEGORIES, {
    fetchPolicy: "cache-first",
    variables: { includeCustomPackages: false },
  });

  return { categories: data?.categories, loading, error };
};
