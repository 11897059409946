import React, { FC, ReactElement, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { GET_INVOICE, GET_NEXT_INVOICE_PAYMENT } from "./graphql/query";
import { IInvoice, InvoicePayment, InvoiceType } from "./types";
import {
  ACTIVATE_JOB,
  APPLY_DISCOUNT,
  ARCHIVE_JOB,
  CONFIRM_INVOICE_PAYMENT,
} from "./graphql/mutation";
import InvoiceTemplate from "@laborhack/invoice";
import { Modal } from "semantic-ui-react";
import { SelectPaymentProvider } from "./SelectPaymentProvider/SelectPaymentProvider";
import RequestFailed from "../../_components/RequestFailed";
import { GET_CATEGORIES } from "../../graphql/query.constants";
import Button from "@laborhack/custom-button";
import { useApplicationConfig } from "../../context/ApplicationConfig";
import Loading from "../../_components/Loading";

export const Invoice: FC = () => {
  const { id } = useParams<{ id: string }>();

  const { search } = useLocation();
  const { toggles } = useApplicationConfig();

  const params = new URLSearchParams(search);

  const [paymentProviderSelectorOpen, setPaymentProviderSelectorOpen] =
    React.useState(false);

  const [errorMessage, setErrorMessage] = React.useState<string>();

  const { data: getCategoriesData, loading: getCategoriesLoading } =
    useQuery<{
      categories: {
        id: string;
        name: string;
        proTitle: string;
      }[];
    }>(GET_CATEGORIES);

  const { loading, error, data, refetch } = useQuery(GET_INVOICE, {
    variables: {
      invoiceId: id,
    },
    notifyOnNetworkStatusChange: true,
  });

  const {
    loading: nextInvoicePaymentLoading,
    data: nextInvoicePaymentData,
    error: nextInvoicePaymentError,
    refetch: nextInvoicePaymentRefetch,
  } = useQuery<{ nextInvoicePayment: InvoicePayment }>(
    GET_NEXT_INVOICE_PAYMENT,
    {
      variables: {
        invoiceId: id,
      },
      fetchPolicy: "network-only",
    }
  );

  const [confirmInvoicePayment, confirmInvoicePaymentResult] = useMutation(
    CONFIRM_INVOICE_PAYMENT,
    {
      onCompleted: (data) => {
        data?.confirmInvoicePayment.status && refetch();
      },
      onError: (error) => {
        setErrorMessage(error.message);
      },
    }
  );

  const [archiveJob, archiveJobResult] = useMutation(ARCHIVE_JOB, {
    onCompleted: () => {
      refetch?.();
      nextInvoicePaymentRefetch?.();
    },
  });

  const [activateJob, activateJobResult] = useMutation(ACTIVATE_JOB, {
    onCompleted: () => {
      refetch?.();
      nextInvoicePaymentRefetch?.();
    },
  });

  const [applyDiscount, applyDiscountResult] = useMutation(APPLY_DISCOUNT, {
    awaitRefetchQueries: true,
    refetchQueries: [GET_NEXT_INVOICE_PAYMENT],
  });

  const removeJob = (id: string) => {
    archiveJob({
      variables: {
        jobId: id,
      },
    });
  };

  const addJob = (id: string) => {
    activateJob({
      variables: {
        jobId: id,
      },
    });
  };

  const handleApplyDiscount = (discountCode: string) => {
    applyDiscount({
      variables: {
        invoiceId: id,
        discountCode: discountCode.toUpperCase(),
      },
    });
  };

  const invoice: IInvoice | undefined = data?.getInvoiceById.data;

  const categories = getCategoriesData?.categories;

  const paymentStatus = invoice?.payment?.status;

  useEffect(() => {
    if (!!invoice && !invoice.canceled) {
      // only run if the url has query params
      if (params.get("status") || params.get("trxref")) {
        confirmInvoicePayment({
          variables: {
            invoiceId: id,
            transactionId: params.get("transaction_id"),
          },
        });
      }
    }
    // eslint-disable-next-line
  }, [paymentStatus, invoice?.splitPayments]);

  if (!data || !invoice || confirmInvoicePaymentResult.loading) {
    return <div>loading</div>;
  }

  if (error) {
    return <div>{error.message}</div>;
  }

  if (invoice.canceled) {
    return <p>This invoice has been canceled by LaborHack</p>;
  }

  if (errorMessage) {
    return <RequestFailed onRetry={() => setErrorMessage(undefined)} />;
  }

  if (nextInvoicePaymentLoading) return <Loading />;

  if (nextInvoicePaymentError) return <RequestFailed />;

  const actions: ReactElement[] = [];

  // attach the back to dashboard button if the toggle allows it
  toggles?.enable_client_dashboard_access &&
    invoice.type !== InvoiceType.MARKETPLACE_REQUEST_MATCH &&
    actions.push(
      <Button
        key="toDashboard"
        onClick={() => window.open(`${window.origin}/dashboard/home`)}
      >
        Go To Dashboard
      </Button>
    );

  if (applyDiscountResult.error) {
    return (
      <RequestFailed
        onRetry={applyDiscountResult.reset}
        text={applyDiscountResult.error.message}
      />
    );
  }

  const canApplyDiscount =
    !invoice.appliedDiscount &&
    invoice.type === InvoiceType.MATERIALS &&
    !invoice.hasAtLeastOnePayment;

  return (
    <div className="bg-white">
      <Modal
        open={paymentProviderSelectorOpen}
        closeOnDimmerClick
        closeIcon
        destroyOnClose
        onClose={() => setPaymentProviderSelectorOpen(false)}
      >
        <SelectPaymentProvider
          invoicePaymentSummary={nextInvoicePaymentData!.nextInvoicePayment}
          invoice={invoice}
        />
      </Modal>
      <InvoiceTemplate
        actions={actions}
        invoice={invoice}
        categories={categories}
        loading={
          loading ||
          activateJobResult.loading ||
          archiveJobResult.loading ||
          nextInvoicePaymentLoading ||
          applyDiscountResult.loading
        }
        summary={nextInvoicePaymentData!.nextInvoicePayment}
        applyDiscount={canApplyDiscount ? handleApplyDiscount : undefined}
        activateJob={addJob}
        pauseJob={removeJob}
        getPaymentLink={(_: string) => setPaymentProviderSelectorOpen(true)}
      />
    </div>
  );
};
