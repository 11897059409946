import {
  Box,
  Button,
  Center,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  Image,
  Link,
  List,
  ListIcon,
  ListItem,
  Stack,
  Text,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { BsAirplaneFill, BsArrowRight } from "react-icons/bs";
import { logEvent } from "../../helpers/analytics";
import Footer from "../../_components/Footer";
import {
  Section,
  SectionActions,
  SectionContent,
  SectionHeader,
  SectionText,
  SectionTitle,
} from "../landing/components/Section";
import Hero from "assets/work-abroad-hero.jpg";

import VerifiedSectionicon from "../../assets/verified-section-icon.svg";
import YouTube from "react-youtube";
import {
  getRegistrationFormUrl,
  TELEGRAM_GROUP_LINK,
} from "../../features/work-abroad/constants";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { useQuery } from "../../hooks";
import { CourseCard } from "../../features/study-work-abroad/course-card";
import Slider from "react-slick";
import { STUDY_WORK_COURSES } from "../../features/study-work-abroad/constants";
import { MdCheck } from "react-icons/md";
import PassageLogo from "../../assets/passage-logo.svg";
import CustomerService from "../../assets/study-work-abroad/customer-service.png";
import EligibilityChecklist from "../../assets/eligibility-checklist.jpeg"

import { FaHandshake, FaMoneyBillAlt } from "react-icons/fa";

export const StudyWorkAbroadPage = () => {
  const carouselSettings = useBreakpointValue({
    base: {
      slidesToShow: 1,
      dots: true,
    },
    lg: {},
  });

  /**
   * Get the referrer from the url using hooks
   */
  const query = useQuery();
  const referrer = query.get("referrer") || undefined;

  /**
   * Get referrer from pro.laborhack.com
   */
  const referredBy = query.get("referredBy") || undefined;

  const applicationUrl = `${process.env.REACT_APP_PRO_APPLICATION_URL}?preferredTrack=STUDYWORK${referredBy ? `&referredBy=${referredBy}` : ""}`

  useEffect(() => {
    logEvent("page_view");
  }, []);

  return (
    <>
      <Helmet>
        <title>Study/Work Abroad | LaborHack</title>
      </Helmet>
      <VStack w="full" spacing={0}>
        {/* hero */}
        <Section>
          <VStack w="full" spacing={12}>
            <VStack
              w={{
                base: "full",
                lg: "60%",
              }}
              alignItems="center"
              spacing={{
                base: 6,
                lg: 8,
              }}
            >
              <VStack
                w="full"
                alignItems="center"
                spacing={{
                  base: 3,
                  lg: 4,
                }}
              >
                <Heading
                  as="h1"
                  fontSize={{
                    base: 26,
                    md: 52,
                  }}
                  fontWeight="bold"
                  color="prussianBlue.500"
                  lineHeight={{
                    base: "base",
                    md: "shorter",
                  }}
                  textAlign="center"
                >
                  Transform your Canadian Dreams into Reality
                </Heading>
                <Text
                  fontSize={{
                    base: 15,
                    lg: 24,
                  }}
                  lineHeight="base"
                  fontWeight="medium"
                  color="prussianBlue.500"
                  textAlign="center"
                >
                  Get access to loans to power your education in Canada. We
                  provide you with expert-level support and advice every step of
                  the way - on the visa process, flight, insurance, and housing.
                </Text>
              </VStack>

              <HStack w="full" spacing={4} justifyContent="center">
                <Button
                  as="a"
                  href={applicationUrl}
                  colorScheme="flatteredFlamingo"
                  paddingY={{
                    base: 6,
                    lg: 10,
                  }}
                  paddingX={{
                    base: 8,
                    lg: 14,
                  }}
                  fontSize={{
                    base: 15,
                    lg: 22,
                  }}
                  rightIcon={<Icon as={BsArrowRight} w={6} h={6} />}
                  className="hover:text-white"
                >
                  Apply Now
                </Button>
              </HStack>
            </VStack>
          </VStack>
        </Section>
        <img
          src={Hero}
          alt="Work Abroad Hero"
          className="w-full h-[200px] sm:h-[400px] object-cover"
        />
        <Section>
          <Stack
            w="full"
            spacing={{
              base: 8,
              lg: 16,
            }}
          >
            <Stack w="full">
              <Heading
                as="h2"
                fontSize={{
                  base: 22,
                  md: 44,
                }}
                fontWeight="bold"
                color="prussianBlue.500"
                lineHeight={{
                  base: "base",
                  md: "shorter",
                }}
                textAlign="center"
              >
                Growing demand for skilled workers in Canada
              </Heading>
              <Text
                fontSize={{
                  base: 15,
                  lg: 24,
                }}
                lineHeight="base"
                fontWeight={{
                  base: "normal",
                  lg: "medium",
                }}
                color="gray.500"
                textAlign="center"
              >
                Canada&apos;s skilled trades sector faces a severe worker
                shortage, with 700,000 retiring by 2028, creating a demand for
                256,000 more skilled workers. The median income for certified
                skilled workers in Canada is C$60,450, with powerline
                technicians and crane operators earning over C$100,000. We offer
                carefully selected programs to maximise your job prospects in
                high-demand fields.
              </Text>
            </Stack>
            <Grid
              w="full"
              templateRows={{
                base: "repeat(3, 1fr)",
                lg: "repeat(1, 1fr)",
              }}
              templateColumns={{
                base: "repeat(1, 1fr)",
                lg: "repeat(3, 1fr)",
              }}
              gap={{
                base: 4,
                lg: 16,
              }}
            >
              <GridItem>
                <Stack align="center" spacing={2}>
                  <Text
                    fontFamily="AeonikPro"
                    fontSize={{
                      base: 36,
                      lg: 64,
                    }}
                    lineHeight="none"
                    fontWeight="bold"
                    color="prussianBlue.500"
                    m={0}
                  >
                    256,000
                  </Text>
                  <Text
                    fontSize={{
                      base: 15,
                      lg: 20,
                    }}
                    lineHeight="base"
                    fontWeight={{
                      base: "normal",
                      lg: "medium",
                    }}
                    color="prussianBlue.300"
                  >
                    Apprentices needed by 2028
                    <sup>
                      <Link href={"#footnote1"} color="blue.400">
                        1
                      </Link>
                    </sup>
                  </Text>
                </Stack>
              </GridItem>
              <GridItem>
                <Stack align="center" spacing={2}>
                  <Text
                    fontFamily="AeonikPro"
                    fontSize={{
                      base: 36,
                      lg: 64,
                    }}
                    lineHeight="none"
                    fontWeight="bold"
                    color="prussianBlue.500"
                    m={0}
                  >
                    C$60,450
                  </Text>
                  <Text
                    fontSize={{
                      base: 15,
                      lg: 20,
                    }}
                    lineHeight="base"
                    fontWeight={{
                      base: "normal",
                      lg: "medium",
                    }}
                    color="prussianBlue.300"
                  >
                    Median Income
                    <sup>
                      <Link href={"#footnote2"} color="blue.400">
                        2
                      </Link>
                    </sup>
                  </Text>
                </Stack>
              </GridItem>
              <GridItem>
                <Stack align="center" spacing={2}>
                  <Text
                    fontFamily="AeonikPro"
                    fontSize={{
                      base: 36,
                      lg: 64,
                    }}
                    lineHeight="none"
                    fontWeight="bold"
                    color="prussianBlue.500"
                    m={0}
                  >
                    C$100,000
                  </Text>
                  <Text
                    fontSize={{
                      base: 15,
                      lg: 20,
                    }}
                    lineHeight="base"
                    fontWeight={{
                      base: "normal",
                      lg: "medium",
                    }}
                    color="prussianBlue.300"
                    textAlign="center"
                  >
                    Powerline technicians and Crane operators Income
                  </Text>
                </Stack>
              </GridItem>
            </Grid>
          </Stack>
        </Section>
        <Section>
          <Stack
            w="full"
            spacing={{
              base: 8,
              lg: 16,
            }}
            py={8}
          >
            <Stack w="full">
              <Heading
                as="h2"
                fontSize={{
                  base: 22,
                  md: 44,
                }}
                fontWeight="bold"
                color="prussianBlue.500"
                lineHeight={{
                  base: "base",
                  md: "shorter",
                }}
                textAlign="center"
              >
                Featured on-demand programs
              </Heading>
              <Text
                fontSize={{
                  base: 15,
                  lg: 24,
                }}
                lineHeight="base"
                fontWeight={{
                  base: "normal",
                  lg: "medium",
                }}
                color="gray.500"
                textAlign="center"
              >
                Choose from construction-specific courses aligning with your
                skills.
              </Text>
            </Stack>
            <Stack w="full" spacing={8}>
              <Stack direction="row" w="full" justify="end">
                <Button
                  size="lg"
                  as="a"
                  href={applicationUrl}
                  variant="unstyled"
                  rightIcon={<Icon as={BsArrowRight} w={4} h={4} />}
                  borderBottom="1px"
                  py={2}
                >
                  View All Courses
                </Button>
              </Stack>

              <Slider
                dots={false}
                infinite={true}
                speed={3000}
                slidesToShow={3}
                slidesToScroll={1}
                autoplay={true}
                autoplaySpeed={3000}
                pauseOnHover={true}
                {...carouselSettings}
              >
                {STUDY_WORK_COURSES.map((course) => (
                  <CourseCard
                    key={course.title}
                    title={course.title}
                    image={course.image}
                  />
                ))}
              </Slider>
            </Stack>
          </Stack>
        </Section>

        <Section bgColor="galleonBlue.50">
          <SectionContent>
            <SectionHeader>
              <SectionTitle>Access to loans for your education</SectionTitle>
              <SectionText>
                Get hassle-free and flexible education loans that cover tuition,
                accommodation, and living expenses. We have partnered with
                Passage, a leading loan provider, to make your dreams of
                studying and working in Canada a reality.
              </SectionText>
              <List
                textAlign={{
                  base: "center",
                  lg: "start",
                }}
                spacing={2}
              >
                <ListItem
                  fontSize={{
                    base: 15,
                    lg: 24,
                  }}
                  fontWeight="medium"
                  color="gray.500"
                >
                  <ListIcon as={MdCheck} color="prussianBlue.500" />
                  Loan for tuition and living expenses based on your program
                </ListItem>
                <ListItem
                  fontSize={{
                    base: 15,
                    lg: 24,
                  }}
                  fontWeight="medium"
                  color="gray.500"
                >
                  <ListIcon as={MdCheck} color="prussianBlue.500" />
                  Easy refund and payback
                </ListItem>
                <ListItem
                  fontSize={{
                    base: 15,
                    lg: 24,
                  }}
                  fontWeight="medium"
                  color="gray.500"
                >
                  <ListIcon as={MdCheck} color="prussianBlue.500" />
                  Up to 300 slots available
                </ListItem>
              </List>
            </SectionHeader>
            <SectionActions
              justifyContent={{
                base: "center",
                lg: "start",
              }}
            >
              <Link href={applicationUrl}>
                <Button
                  as="a"
                  href={applicationUrl}
                  rightIcon={<Icon as={BsArrowRight} w={6} h={6} />}
                  colorScheme="prussianBlue"
                  paddingY={{
                    base: 6,
                    lg: 8,
                  }}
                  paddingX={{
                    base: 12,
                    lg: 20,
                  }}
                  fontSize={{
                    base: 15,
                    lg: 22,
                  }}
                >
                  Apply Now
                </Button>
              </Link>
            </SectionActions>
          </SectionContent>
          <HStack
            flex={1}
            justifyContent={{
              base: "center",
              lg: "end",
            }}
            w={{
              base: "full",
              lg: "80%",
            }}
            order={3}
          >
            <Stack w="full" bgColor="prussianBlue.500">
              <YouTube
                className="w-full"
                videoId="1HswLLOjBXw"
                opts={{
                  width: "100%",
                  playerVars: {
                    start: 1326,
                    controls: 0,
                    loop: 1,
                    modestbranding: 1,
                  },
                }}
              />
              <HStack
                align="start"
                justify="center"
                py={4}
                gap={{
                  base: 2,
                  lg: 6,
                }}
              >
                <Image
                  height={{
                    base: 6,
                    lg: 10,
                  }}
                  src={PassageLogo}
                />
              </HStack>
            </Stack>
          </HStack>
        </Section>
        <Section bgColor="prussianBlue.50">
          <SectionContent>
            <SectionHeader>
              <SectionTitle>Eligibility Criteria</SectionTitle>
              <List
                textAlign={{
                  base: "center",
                  lg: "start",
                }}
                spacing={2}
              >
                <ListItem
                  fontSize={{
                    base: 15,
                    lg: 24,
                  }}
                  fontWeight="medium"
                  color="gray.500"
                >
                  <ListIcon as={MdCheck} color="prussianBlue.500" />
                  Age: 18 to 30 years old
                </ListItem>
                <ListItem
                  fontSize={{
                    base: 15,
                    lg: 24,
                  }}
                  fontWeight="medium"
                  color="gray.500"
                >
                  <ListIcon as={MdCheck} color="prussianBlue.500" />
                  Education: Must have completed secondary school (high school or up to grade 12), with no more than a 5 year education gap
                </ListItem>
                <ListItem
                  fontSize={{
                    base: 15,
                    lg: 24,
                  }}
                  fontWeight="medium"
                  color="gray.500"
                >
                  <ListIcon as={MdCheck} color="prussianBlue.500" />
                  Travel Documents: Must have an international passport
                </ListItem>
                <ListItem
                  fontSize={{
                    base: 15,
                    lg: 24,
                  }}
                  fontWeight="medium"
                  color="gray.500"
                >
                  <ListIcon as={MdCheck} color="prussianBlue.500" />
                  Grades: Average of 75% or higher. Applicants with lower averages may be considered if they have a compelling reason that makes us believe they will be a successful student.
                </ListItem>
                <ListItem
                  fontSize={{
                    base: 15,
                    lg: 24,
                  }}
                  fontWeight="medium"
                  color="gray.500"
                >
                  <ListIcon as={MdCheck} color="prussianBlue.500" />
                  Coursework: Must have taken Mathematics and English in Grade 12 / SS3.
                </ListItem>
                <ListItem
                  fontSize={{
                    base: 15,
                    lg: 24,
                  }}
                  fontWeight="medium"
                  color="gray.500"
                >
                  <ListIcon as={MdCheck} color="prussianBlue.500" />
                  Financial Commitment: Ability to make a downpayment of between $9,000 and $15,000.
                </ListItem>
              </List>
            </SectionHeader>
            <SectionActions
              justifyContent={{
                base: "center",
                lg: "start",
              }}
            >
              <Link href={applicationUrl}>
                <Button
                  as="a"
                  href={applicationUrl}
                  rightIcon={<Icon as={BsArrowRight} w={6} h={6} />}
                  colorScheme="prussianBlue"
                  paddingY={{
                    base: 6,
                    lg: 8,
                  }}
                  paddingX={{
                    base: 12,
                    lg: 20,
                  }}
                  fontSize={{
                    base: 15,
                    lg: 22,
                  }}
                >
                  Apply Now
                </Button>
              </Link>
            </SectionActions>
          </SectionContent>
          <HStack
            flex={1}
            justifyContent={{
              base: "center",
              lg: "end",
            }}
            w={{
              base: "full",
              lg: "100%",
            }}
            order={3}
          >
            <Image
              h={{
                base: 240,
                lg: 384,
              }}
              fit="contain"
              src={EligibilityChecklist}
              alt="a checklist"
            />
          </HStack>
        </Section>
        <Section bgColor="lavender.50">
          <HStack
            flex={1}
            justifyContent={{
              base: "center",
              lg: "start",
            }}
            w={{
              base: "full",
              lg: "80%",
            }}
            order={3}
          >
            <Image
              h={{
                base: 240,
                lg: 384,
              }}
              fit="contain"
              src={CustomerService}
              alt="an artisan talking to a customer"
            />
          </HStack>
          <SectionContent
            order={{
              base: 1,
              lg: 3,
            }}
          >
            <SectionHeader>
              <SectionTitle>
                Get expert advice and support with your relocation.
              </SectionTitle>
              <SectionText>
                In addition to loans, we provide you with expert-level support
                and advice to make your relocation process smooth and
                stress-free.
              </SectionText>
              <List
                textAlign={{
                  base: "center",
                  lg: "start",
                }}
                spacing={2}
              >
                <ListItem
                  fontSize={{
                    base: 15,
                    lg: 24,
                  }}
                  fontWeight="medium"
                  color="gray.500"
                >
                  <ListIcon as={MdCheck} color="prussianBlue.500" />
                  Fast processing and reduced wait times for school acceptances
                  by 80%
                </ListItem>
                <ListItem
                  fontSize={{
                    base: 15,
                    lg: 24,
                  }}
                  fontWeight="medium"
                  color="gray.500"
                >
                  <ListIcon as={MdCheck} color="prussianBlue.500" />
                  Visa processing
                </ListItem>
                <ListItem
                  fontSize={{
                    base: 15,
                    lg: 24,
                  }}
                  fontWeight="medium"
                  color="gray.500"
                >
                  <ListIcon as={MdCheck} color="prussianBlue.500" />
                  Flight & Insurance
                </ListItem>
                <ListItem
                  fontSize={{
                    base: 15,
                    lg: 24,
                  }}
                  fontWeight="medium"
                  color="gray.500"
                >
                  <ListIcon as={MdCheck} color="prussianBlue.500" />
                  Housing support
                </ListItem>
              </List>
            </SectionHeader>
          </SectionContent>
        </Section>
        <Section>
          <VStack
            w="full"
            spacing={{
              base: 12,
              lg: 20,
            }}
          >
            <Stack
              w="full"
              direction={{
                base: "column",
                lg: "row",
              }}
              spacing={{
                base: 12,
                lg: 16,
              }}
            >
              <Box flex={1}>
                <VStack
                  w={{
                    base: "full",
                  }}
                  alignItems="start"
                  spacing={{
                    base: 2,
                    lg: 4,
                  }}
                >
                  <Stack
                    direction={{
                      base: "row",
                      lg: "column",
                    }}
                    spacing={{
                      base: 4,
                      lg: 8,
                    }}
                    alignItems={{
                      base: "center",
                      lg: "start",
                    }}
                  >
                    <Center
                      rounded="full"
                      bg="white"
                      w={{
                        base: 10,
                        lg: 20,
                      }}
                      h={{
                        base: 10,
                        lg: 20,
                      }}
                    >
                      <Icon
                        as={BsAirplaneFill}
                        color="flatteredFlamingo.500"
                        w={{
                          base: 5,
                          lg: 10,
                        }}
                        h={{
                          base: 5,
                          lg: 10,
                        }}
                      />
                    </Center>
                    <Heading
                      as="h4"
                      fontSize={{
                        base: 18,
                        lg: 32,
                      }}
                      fontWeight="medium"
                    >
                      Relocation Support
                    </Heading>
                  </Stack>

                  <Text
                    fontSize={{
                      base: 15,
                      lg: 24,
                    }}
                    lineHeight="base"
                    fontWeight={{
                      base: "normal",
                      lg: "medium",
                    }}
                    color="gray.500"
                  >
                    From academic admission to job placement, we guide you
                    through every step.
                  </Text>
                </VStack>
              </Box>
              <Box flex={1}>
                <VStack
                  w={{
                    base: "full",
                  }}
                  alignItems="start"
                  spacing={{
                    base: 2,
                    lg: 4,
                  }}
                >
                  <Stack
                    direction={{
                      base: "row",
                      lg: "column",
                    }}
                    spacing={{
                      base: 4,
                      lg: 8,
                    }}
                    alignItems={{
                      base: "center",
                      lg: "start",
                    }}
                  >
                    <Image
                      src={VerifiedSectionicon}
                      w={{
                        base: 10,
                        lg: 20,
                      }}
                    />
                    <Heading
                      as="h4"
                      fontSize={{
                        base: 18,
                        lg: 32,
                      }}
                      fontWeight="medium"
                    >
                      Vetted Programs
                    </Heading>
                  </Stack>

                  <Text
                    fontSize={{
                      base: 15,
                      lg: 24,
                    }}
                    lineHeight="base"
                    fontWeight={{
                      base: "normal",
                      lg: "medium",
                    }}
                    color="gray.500"
                  >
                    We offer carefully selected programs to maximise your job
                    prospects in high-demand fields.
                  </Text>
                </VStack>
              </Box>
            </Stack>
            <Stack
              w="full"
              direction={{
                base: "column",
                lg: "row",
              }}
              spacing={{
                base: 12,
                lg: 16,
              }}
            >
              <Box flex={1}>
                <VStack
                  w={{
                    base: "full",
                  }}
                  alignItems="start"
                  spacing={{
                    base: 2,
                    lg: 4,
                  }}
                >
                  <Stack
                    direction={{
                      base: "row",
                      lg: "column",
                    }}
                    spacing={{
                      base: 4,
                      lg: 8,
                    }}
                    alignItems={{
                      base: "center",
                      lg: "start",
                    }}
                  >
                    <Center
                      rounded="full"
                      bg="white"
                      w={{
                        base: 10,
                        lg: 20,
                      }}
                      h={{
                        base: 10,
                        lg: 20,
                      }}
                    >
                      <Icon
                        as={FaHandshake}
                        color="flatteredFlamingo.500"
                        w={{
                          base: 5,
                          lg: 10,
                        }}
                        h={{
                          base: 5,
                          lg: 10,
                        }}
                      />
                    </Center>
                    <Heading
                      as="h4"
                      fontSize={{
                        base: 18,
                        lg: 32,
                      }}
                      fontWeight="medium"
                    >
                      Certified Partnerships
                    </Heading>
                  </Stack>

                  <Text
                    fontSize={{
                      base: 15,
                      lg: 24,
                    }}
                    lineHeight="base"
                    fontWeight={{
                      base: "normal",
                      lg: "medium",
                    }}
                    color="gray.500"
                  >
                    We collaborate with trusted institutions globally for a
                    seamless journey.
                  </Text>
                </VStack>
              </Box>

              <Box flex={1}>
                <VStack
                  w={{
                    base: "full",
                  }}
                  alignItems="start"
                  spacing={{
                    base: 2,
                    lg: 4,
                  }}
                >
                  <Stack
                    direction={{
                      base: "row",
                      lg: "column",
                    }}
                    spacing={{
                      base: 4,
                      lg: 8,
                    }}
                    alignItems={{
                      base: "center",
                      lg: "start",
                    }}
                  >
                    <Center
                      rounded="full"
                      bg="white"
                      w={{
                        base: 10,
                        lg: 20,
                      }}
                      h={{
                        base: 10,
                        lg: 20,
                      }}
                    >
                      <Icon
                        as={FaMoneyBillAlt}
                        color="flatteredFlamingo.500"
                        w={{
                          base: 5,
                          lg: 10,
                        }}
                        h={{
                          base: 5,
                          lg: 10,
                        }}
                      />
                    </Center>
                    <Heading
                      as="h4"
                      fontSize={{
                        base: 18,
                        lg: 32,
                      }}
                      fontWeight="medium"
                    >
                      Student Loans
                    </Heading>
                  </Stack>

                  <Text
                    fontSize={{
                      base: 15,
                      lg: 24,
                    }}
                    lineHeight="base"
                    fontWeight={{
                      base: "normal",
                      lg: "medium",
                    }}
                    color="gray.500"
                  >
                    Get hassle-free education loans based on your program in
                    partnership with Passage.
                  </Text>
                </VStack>
              </Box>
            </Stack>
          </VStack>
        </Section>
        {false && (
          <Section bgColor="gray.50">
            <VStack
              w="full"
              spacing={{
                base: 8,
                lg: 10,
              }}
            >
              <VStack
                w={{
                  base: "80%",
                  lg: "80%",
                }}
                spacing={{
                  base: 3,
                  lg: 6,
                }}
              >
                <Heading
                  as="h2"
                  fontSize={{
                    base: 22,
                    md: 44,
                  }}
                  fontWeight="bold"
                  color="prussianBlue.500"
                  lineHeight={{
                    base: "base",
                    md: "shorter",
                  }}
                  textAlign={{
                    base: "center",
                  }}
                >
                  Get started in 4 easy steps
                </Heading>
              </VStack>
              <Grid
                w="full"
                templateRows="repeat(4, 1fr)"
                templateColumns={{
                  base: "repeat(1, 1fr)",
                  lg: "repeat(4, 1fr)",
                }}
                gap={4}
              >
                <GridItem rowSpan={1} colSpan={1}>
                  <HStack gap={4}>
                    <Heading
                      as="h3"
                      fontSize={{
                        base: "8xl",
                        lg: "9xl",
                      }}
                      color="prussianBlue.500"
                    >
                      01
                    </Heading>
                    <Text
                      as="a"
                      href={getRegistrationFormUrl(referrer)}
                      textDecoration="underline"
                      fontFamily="AeonikPro"
                      fontSize="2xl"
                    >
                      Complete Registration Form
                      <ExternalLinkIcon mx="2" />
                    </Text>
                  </HStack>
                </GridItem>
                <GridItem
                  colStart={2}
                  rowSpan={1}
                  colSpan={1}
                  className="relative"
                  display={{
                    base: "none",
                    lg: "block",
                  }}
                >
                  <div className="absolute border-r border-t border-red-600 w-20 h-20 bottom-0"></div>
                </GridItem>
                <GridItem
                  colStart={{
                    base: 1,
                    lg: 2,
                  }}
                  rowStart={2}
                  rowSpan={1}
                  colSpan={1}
                >
                  <HStack gap={4}>
                    <Heading
                      as="h3"
                      fontSize={{
                        base: "8xl",
                        lg: "9xl",
                      }}
                      color="prussianBlue.500"
                    >
                      02
                    </Heading>
                    <Text
                      fontFamily="AeonikPro"
                      fontSize="2xl"
                      as="a"
                      href={TELEGRAM_GROUP_LINK}
                      textDecoration="underline"
                    >
                      Join Community <ExternalLinkIcon mx="2" />
                    </Text>
                  </HStack>
                </GridItem>
                <GridItem
                  colStart={{
                    base: 1,
                    lg: 3,
                  }}
                  rowStart={2}
                  rowSpan={1}
                  colSpan={1}
                  className="relative"
                  display={{
                    base: "none",
                    lg: "block",
                  }}
                >
                  <div className="absolute border-r border-t border-red-600 w-20 h-20 bottom-0"></div>
                </GridItem>
                <GridItem
                  colStart={{
                    base: 1,
                    lg: 3,
                  }}
                  rowStart={3}
                  rowSpan={1}
                  colSpan={1}
                >
                  <HStack gap={4}>
                    <Heading
                      as="h3"
                      fontSize={{
                        base: "8xl",
                        lg: "9xl",
                      }}
                      color="prussianBlue.500"
                    >
                      03
                    </Heading>
                    <Text fontFamily="AeonikPro" fontSize="2xl">
                      Attend In-Person Skills Assesement
                    </Text>
                  </HStack>
                </GridItem>
                <GridItem
                  colStart={4}
                  rowStart={3}
                  rowSpan={1}
                  colSpan={1}
                  className="relative"
                  display={{
                    base: "none",
                    lg: "block",
                  }}
                >
                  <div className="absolute border-r border-t border-red-600 w-20 h-20 bottom-0"></div>
                </GridItem>
                <GridItem
                  colStart={{
                    base: 1,
                    lg: 4,
                  }}
                  rowStart={4}
                  rowSpan={1}
                  colSpan={1}
                >
                  <HStack gap={4}>
                    <Heading
                      as="h3"
                      fontSize={{
                        base: "8xl",
                        lg: "9xl",
                      }}
                      color="prussianBlue.500"
                    >
                      04
                    </Heading>
                    <Text fontFamily="AeonikPro" fontSize="2xl">
                      Resume Work Abroad
                    </Text>
                  </HStack>
                </GridItem>
              </Grid>
            </VStack>
          </Section>
        )}
        <HStack w="full" bgColor="prussianBlue.500" justifyContent="center">
          <Stack
            px={5}
            py={{
              base: 8,
              lg: 20,
            }}
            spacing={{
              base: 12,
              lg: 24,
            }}
            w="full"
            maxW="container.xl"
            alignItems="start"
          >
            <VStack
              spacing={{
                base: 6,
                lg: 12,
              }}
            >
              <HStack
                w="full"
                justifyContent={{
                  base: "start",
                  lg: "space-between",
                }}
              >
                <Heading
                  as="h5"
                  fontSize={{
                    base: "2xl",
                    md: "6xl",
                  }}
                  fontWeight="bold"
                  color="white"
                  lineHeight={{
                    base: "base",
                    md: "shorter",
                  }}
                >
                  Ready to move to Canada in 2024?
                </Heading>
              </HStack>
              <HStack w="full">
                <Button
                  as="a"
                  href={applicationUrl}
                  w={{
                    base: "full",
                    lg: "40%",
                  }}
                  py={6}
                  size={{
                    base: "lg",
                    md: "xl",
                  }}
                  rightIcon={<Icon as={BsArrowRight} w={8} h={8} />}
                  className="hover:text-blue-950"
                >
                  Apply Now
                </Button>
              </HStack>
            </VStack>
            <Stack>
              <Box
                id="footnote1"
                className="before:content-['*'] before:block before:-mt-24 before:h-24 before:invisible"
              >
                <Text color="white">
                  1. Canada would require a projected number of 256,000
                  apprentices by 2028. Source:{" "}
                  <Link
                    href={
                      "https://www.canada.ca/en/employment-social-development/campaigns/skilled-trades.html"
                    }
                    color="white"
                    textDecoration="underline"
                  >
                    Canada.ca
                  </Link>
                </Text>
              </Box>

              <Text color="white" id="footnote2">
                2. Certified tradesmen have a median income of C$60,450. Source:{" "}
                <Link
                  href={
                    "https://www150.statcan.gc.ca/n1/daily-quotidien/220310/dq220310b-eng.htm"
                  }
                  color="white"
                  textDecoration="underline"
                >
                  StatCan
                </Link>
              </Text>
            </Stack>
          </Stack>
        </HStack>
      </VStack>
      <Footer />
    </>
  );
};
