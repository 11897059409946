import React from "react";
import { VStack, Image, Text } from "@chakra-ui/react";

export interface TeamMemberProps {
  name: string;
  title?: string;
  image: string;
}

export const TeamMember = ({ name, title, image }: TeamMemberProps) => {
  return (
    <VStack spacing={6}>
      <Image w="full" src={image} />
      <VStack spacing={2}>
        <Text
          fontWeight="semibold"
          fontSize={{
            base: 16,
            lg: 24,
          }}
        >
          {name}
        </Text>
        {title && (
          <Text
            fontSize={{
              base: 12,
              lg: 20,
            }}
            color="gray.500"
          >
            {title}
          </Text>
        )}
      </VStack>
    </VStack>
  );
};
