import {
  defineStyleConfig,
  extendTheme,
  createMultiStyleConfigHelpers,
} from "@chakra-ui/react";
import { inputTheme } from "./theme/Input";
import { menuTheme } from "./theme/Menu";
import { selectTheme } from "./theme/Select";
import { accordionTheme } from "./theme/Accordion";
import { tabsTheme } from "./theme/Tabs";
import { listTheme } from "./theme/List";
import { radioAnatomy } from "@chakra-ui/anatomy";
import { Radio } from "semantic-ui-react";
import { brandColors } from "./constants";

const {
  definePartsStyle: defineRadioPartsStyle,
  defineMultiStyleConfig: defineRadioMultiStyleConfig,
} = createMultiStyleConfigHelpers(radioAnatomy.keys);

var Button = defineStyleConfig({
  baseStyle: {
    fontWeight: "500",
    rounded: "none",
    fontFamily: `"Inter", sans-serif`,
    boxSizing: "border-box",
  },
  sizes: {
    xl: {
      fontSize: "3xl",
      py: 8,
    },
  },
  variants: {
    "solid-dark": (props) => {
      return {
        bg: `${props.colorScheme}.500`,
        textColor: "gray.900",
      };
    },
    outline: (props) => ({
      borderColor: `${props.colorScheme}.500`,
      color: `${props.colorScheme}.500`,
    }),
    subtle: (props) => {
      return {
        bg: `${props.colorScheme}.50`,
        textColor: `${props.colorScheme}.500`,
      };
    },
  },
});

const radioStyle = defineRadioPartsStyle({
  control: {
    borderRadius: "full",
    borderColor: "#949494",
    p: "0px",
    _checked: {
      bg: "white",
      color: "flatteredFlamingo.500",
      p: "0px",
      borderColor: "flatteredFlamingo.500",
    },
  },
});

export const radioTheme = defineRadioMultiStyleConfig({
  baseStyle: radioStyle,
});

var theme = extendTheme({
  styles: {
    global: {
      a: {
        fontFamily: `"Inter", sans-serif`,
      },
      button: {
        span: {
          fontFamily: `"Inter", sans-serif`,
        },
      },
    },
  },
  fonts: {
    heading: `'AeonikPro', sans-serif`,
    body: `'Inter', sans-serif`,
  },
  colors: brandColors,
  components: {
    Button,
    Radio: radioTheme,
    Text: {
      baseStyle: {
        fontFamily: `"Inter", sans-serif`,
      },
    },
    Accordion: accordionTheme,
    Input: inputTheme,
    Select: selectTheme,
    Menu: menuTheme,
    Tabs: tabsTheme,
    List: listTheme,
  },
  breakpoints: {
    width1428px: "1428px",
    width1279px: "1279px",
  },
});

export default theme;
