import React from "react";
import { Text, TextProps } from "@chakra-ui/react";
import { PropsWithChildren } from "react";

export const SectionText = ({
  children,
  ...props
}: PropsWithChildren<TextProps>) => {
  return (
    <Text
      fontSize={{
        base: 15,
        lg: 24,
      }}
      lineHeight="base"
      fontWeight="medium"
      color="gray.500"
      textAlign={{
        base: "center",
        lg: "start",
      }}
      {...props}
    >
      {children}
    </Text>
  );
};
