import React, { FC } from "react";

export interface PDFRendererProps {
  fileUrl: string;
}

export const PDFRenderer: FC<PDFRendererProps> = ({ fileUrl }) => {
  return (
    <iframe
      src={fileUrl}
      width="100%"
      className="min-h-[calc(100vh-60px)]"
      style={{paddingTop: "120px"}}
    ></iframe>
  );
};
