import React, { FC, useEffect } from "react";
import Footer from "../../_components/Footer";
import { logEvent } from "helpers/analytics";
import { Helmet } from "react-helmet";
import {
  Heading,
  HStack,
  VStack,
  Button,
  Image,
  Stack,
  Highlight,
  Text,
  Icon,
  Box,
  Grid,
  GridItem,
  useBreakpointValue,
  Link,
  IconButton,
  Center,
  Tag,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import ProBadge from "../../assets/pro-badge.svg";

// Images
import HouseholdValueProposition from "../../assets/household-value-prop.png";
import BusinessValueProposition from "../../assets/business-value-prop.png";
import ArtisanValueProposition from "../../assets/artisan-value-prop.png";
import HouseholdValuePropositionMobile from "../../assets/household-value-prop-sm.png";
import BusinessValuePropositionMobile from "../../assets/business-value-prop-sm.png";
import ArtisanValuePropositionMobile from "../../assets/artisan-value-prop-sm.png";
import Hero from "../../assets/hero-new.png";

import VerifiedSectionicon from "../../assets/verified-section-icon.svg";
import ClockSectionIcon from "../../assets/clock-section-icon.svg";
import HospitalSectionIcon from "../../assets/hospital-section-icon.svg";
import FlashSectionIcon from "../../assets/flash-section-icon.svg";
import { BsArrowRight } from "react-icons/bs";
import {
  Section,
  SectionActions,
  SectionContent,
  SectionHeader,
  SectionImage,
  SectionText,
  SectionTitle,
} from "./components/Section";

// Logos
import Jobberman from "../../assets/jobberman.png";
import Megawatts from "../../assets/megawatts.png";
import Spacefinish from "../../assets/spacefinish.png";
import Spleet from "../../assets/spleet.png";
import Cappa from "../../assets/cappa.png";
import Daystar from "../../assets/daystar.png";
import Silverbird from "../../assets/silverbird.jpg";
import GroomingCenter from "../../assets/grooming-center.png";

import CategoriesMarquee from "../../_components/CategoriesMarquee";
import { LABORHACK_SCHEDULE_DEMO_URL } from "../../constants";
import { FaPlayCircle } from "react-icons/fa";
import { ReviewCardWrapper } from "../../_components/ReviewCard/ReviewCardWrapper";
import clsx from "clsx";

const reviews = [
  {
    name: "Frank Atsagbede",
    title: "CEO, Orritech Nig. Ltd",
    imageSrc: "",
    review: `I have worked with LaborHack on past projects and have seen a marked difference in the quality of talent they provide. The certification platform is a real game changer for the industry. `,
    rating: 5,
  },
  {
    name: "Anna Kovie Evi-Parker",
    title: "Homeowner",
    imageSrc: "",
    review: `Highly recommend Laborhack if you need artisans. I've been using their services for about a year (mostly for carpentry and furniture work), with no complaints. Their artisans are professional and deliver promptly. Laborhack takes on the responsibility of managing the artisans on my behalf, so I don't have to deal with that.`,
    rating: 5,
  },
  {
    name: "Temitope Olaoluwa",
    title: "LaborHack Pro",
    imageSrc: "",
    review:
      "LaborHack's certification process is extremely competitive. The tests are not easy if you do not have practical experience on the field, but they are a great way to prove your skills, access more jobs and earn better pay.",
    rating: 5,
  },
];

export const Landing: FC = () => {
  const [isPlaying, setIsPlaying] = React.useState(false);

  const videoRef = React.useRef<HTMLVideoElement>(null);

  const BusinessImage = useBreakpointValue({
    base: BusinessValuePropositionMobile,
    lg: BusinessValueProposition,
  });

  const HouseholdImage = useBreakpointValue({
    base: HouseholdValuePropositionMobile,
    lg: HouseholdValueProposition,
  });

  const ArtisanImage = useBreakpointValue({
    base: ArtisanValuePropositionMobile,
    lg: ArtisanValueProposition,
  });

  useEffect(() => {
    logEvent("page_view");
  }, []);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener("play", (e) => {
        setIsPlaying(true);
      });

      videoRef.current.addEventListener("pause", (e) => {
        setIsPlaying(false);
      });

      return () => {
        videoRef.current?.removeEventListener("play", () => {});
        videoRef.current?.removeEventListener("pause", () => {});
      };
    }
  });

  const handleHeroCTAClick = () => {
    logEvent("hero_cta_clicked");
  };

  const handleHireAWorkerCTAClick = () => {
    logEvent("business_section_cta_clicked");
  };

  const handleTryLaborHackCTAClick = () => {
    logEvent("artisan_section_cta_clicked");
  };

  const handlePlayVideo = () => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  };

  return (
    <>
      <Helmet>
        <title>
          LaborHack - Hire skilled workers for your home or business
        </title>
        <meta
          name="description"
          content="Hire well-vetted workers and artisans to handle all your electrical, plumbing, cleaning, carpentry, painting, fumigation, AC/HVAC, solar, and DSTV installation needs."
        />
      </Helmet>
      <VStack w="full" spacing={0}>
        {/* hero */}
        <HStack w="full" bgColor="white" justifyContent="center">
          <Stack
            px={5}
            py={{
              base: 8,
              lg: 20,
            }}
            spacing={{
              base: 12,
              lg: 0,
            }}
            direction={{
              base: "column",
              lg: "row",
            }}
            w="full"
            maxW="container.xl"
            alignItems={{
              base: "start",
              lg: "center",
            }}
          >
            <Box flex={1} w="full">
              <VStack
                w={{
                  base: "full",
                  lg: "90%",
                }}
                alignItems="start"
                spacing={{
                  base: 6,
                  lg: 8,
                }}
              >
                <VStack
                  w="full"
                  alignItems="start"
                  spacing={{
                    base: 3,
                    lg: 4,
                  }}
                >
                  <Heading
                    as="h1"
                    fontSize={{
                      base: 26,
                      md: 52,
                    }}
                    fontWeight="semibold"
                    color="prussianBlue.500"
                    lineHeight={{
                      base: "base",
                      md: "shorter",
                    }}
                  >
                    Find skilled workers
                    <br /> for your{" "}
                    <Highlight
                      query={["home", "business."]}
                      styles={{
                        padding: 0,
                        color: "flatteredFlamingo.500",
                      }}
                    >
                      home or business.
                    </Highlight>
                  </Heading>
                  <Text
                    fontSize={{
                      base: 15,
                      lg: 24,
                    }}
                    lineHeight="base"
                    fontWeight="medium"
                    color="prussianBlue.500"
                  >
                    LaborHack connects businesses and homeowners with skilled
                    and certified workers.
                  </Text>
                </VStack>

                <HStack w="full" spacing={4}>
                  <Link flex={1} as={RouterLink} to="/homeowners/hire/new">
                    <Button
                      colorScheme="flatteredFlamingo"
                      w="full"
                      paddingY={{
                        base: 6,
                        lg: 8,
                      }}
                      fontSize={{
                        base: 15,
                        lg: 22,
                      }}
                      onClick={handleHeroCTAClick}
                      rightIcon={<Icon as={BsArrowRight} w={6} h={6} />}
                    >
                      Hire a Pro
                    </Button>
                  </Link>
                  <Link
                    flex={1}
                    href={process.env.REACT_APP_PRO_APPLICATION_URL}
                  >
                    <Button
                      colorScheme="flatteredFlamingo"
                      variant="outline"
                      w="full"
                      paddingY={{
                        base: 6,
                        lg: 8,
                      }}
                      fontSize={{
                        base: 15,
                        lg: 22,
                      }}
                      onClick={handleHeroCTAClick}
                    >
                      Become a Pro
                    </Button>
                  </Link>
                </HStack>
              </VStack>
            </Box>
            <HStack flex={1} w="full" justifyContent="end">
              <Box
                w="full"
                maxW={612}
                minH={{
                  base: 200,
                  lg: 520,
                }}
                position="relative"
              >
                <Box
                  className="w-[calc(100%-8px)] h-[calc(100%-8px)] lg:w-[calc(100%-16px)] lg:h-[calc(100%-16px)]"
                  bgColor="flatteredFlamingo.50"
                  position="absolute"
                  bottom={0}
                  left={0}
                />
                <Box
                  className="w-[calc(100%-8px)] h-[calc(100%-8px)] lg:w-[calc(100%-16px)] lg:h-[calc(100%-16px)]"
                  position="absolute"
                  top={0}
                  right={0}
                >
                  <Center
                    zIndex={800}
                    w="full"
                    h="full"
                    position="absolute"
                    hidden={isPlaying}
                  >
                    <IconButton
                      icon={<FaPlayCircle />}
                      colorScheme="white"
                      aria-label="play"
                      fontSize="56px"
                      onClick={handlePlayVideo}
                    />
                  </Center>

                  <video
                    className="w-full h-full bg-black object-contain"
                    muted
                    loop
                    ref={videoRef}
                    controls={isPlaying}
                    poster={Hero}
                  >
                    <source
                      src="https://laborhack-assets.s3.amazonaws.com/videos/home-header.mp4"
                      type="video/mp4"
                    />
                  </video>
                  {/* <Image src={Hero} w="full" h="full" fit="cover" /> */}
                  {/* <Box
                      position="absolute"
                      top={0}
                      w="full"
                      h="full"
                      bgColor="gray.900"
                      opacity="0.4"
                    />
                    <Center position="absolute" top={0} w="full" h="full">
                      <IconButton
                        rounded="full"
                        variant="ghost"
                        aria-label="play-hero-video"
                        icon={<FaPlayCircle />}
                        fontSize="56px"
                        color="white"
                      />
                    </Center> */}
                </Box>
              </Box>
            </HStack>
          </Stack>
        </HStack>
        {/* trusted */}
        <Section>
          <VStack
            py={{
              base: 6,
              lg: 12,
            }}
            spacing={{
              base: 6,
              lg: 12,
            }}
            direction={{
              base: "column",
              lg: "row",
            }}
            w="full"
            maxW="container.xl"
            alignItems={{
              base: "start",
              lg: "center",
            }}
          >
            <HStack w="full" justifyContent="center">
              <Heading
                as="h2"
                fontSize={{
                  base: 15,
                  md: 32,
                }}
                w="80%"
                fontWeight={{
                  base: "normal",
                  md: "medium",
                }}
                color="prussianBlue.300"
                lineHeight={{
                  base: "base",
                  md: "shorter",
                }}
                textAlign="center"
              >
                Trusted by top companies and contractors
              </Heading>
            </HStack>
            <Grid
              w={{
                base: "full",
                lg: "100%",
              }}
              templateAreas={{
                base: `
                  "first second third fourth"
                  "fifth sixth seventh eighth"
                `,
                lg: `
                  "first second third fourth"
                  "fifth sixth seventh eighth"
                `,
              }}
              templateRows={{
                base: "repeat(2, 1fr)",
                lg: "repeat(2, 1fr)",
              }}
              templateColumns={{
                base: "repeat(4, 1fr)",
                lg: "repeat(4, 1fr)",
              }}
              gap={{
                base: 4,
                lg: 8,
              }}
            >
              <GridItem
                area={"first"}
                display="flex"
                justifyContent="center"
                alignItems={"center"}
              >
                <Image src={Jobberman} objectFit="contain" />
              </GridItem>
              <GridItem
                area={"second"}
                display="flex"
                justifyContent="center"
                alignItems={"center"}
              >
                <Image src={Megawatts} objectFit="contain" />
              </GridItem>
              <GridItem
                area="third"
                display="flex"
                justifyContent="center"
                alignItems={"center"}
              >
                <Image src={Spacefinish} objectFit="contain" maxW="100%" />
              </GridItem>
              <GridItem
                area="fourth"
                display="flex"
                justifyContent="center"
                alignItems={"center"}
              >
                <Image src={Spleet} objectFit="contain" />
              </GridItem>
              <GridItem
                area="fifth"
                display="flex"
                justifyContent="center"
                alignItems={"center"}
              >
                <Image src={Cappa} objectFit="contain" />
              </GridItem>
              <GridItem
                area="sixth"
                display="flex"
                justifyContent="center"
                alignItems={"center"}
              >
                <Image src={Daystar} objectFit="contain" />
              </GridItem>
              <GridItem
                area="seventh"
                display="flex"
                justifyContent="center"
                alignItems={"center"}
              >
                <Image
                  src={Silverbird}
                  className="filter grayscale"
                  objectFit="contain"
                />
              </GridItem>
              <GridItem
                area="eighth"
                display="flex"
                justifyContent="center"
                alignItems={"center"}
              >
                <Image
                  src={GroomingCenter}
                  className="filter grayscale"
                  objectFit="contain"
                />
              </GridItem>
            </Grid>
          </VStack>
        </Section>

        {/* business */}
        <Section bgColor="flatteredFlamingo.50">
          <SectionContent>
            <SectionHeader>
              <SectionTitle>
                Hire certified skilled workers for your{" "}
                <br className="hidden lg:block" />
                business with LaborHack
              </SectionTitle>
              <SectionText>
                We connect you with trained, certified, and vetted artisans to
                get the job done. Now you can save time, reduce risk, and meet
                your deadlines with ease.
              </SectionText>
            </SectionHeader>
            <SectionActions>
              <Link flex={1} href={process.env.REACT_APP_MARKETPLACE_URL}>
                <Button
                  colorScheme="flatteredFlamingo"
                  variant="solid-dark"
                  w="full"
                  paddingY={{
                    base: 6,
                    lg: 8,
                  }}
                  fontSize={{
                    base: 15,
                    lg: 22,
                  }}
                  onClick={handleHeroCTAClick}
                  rightIcon={<Icon as={BsArrowRight} w={6} h={6} />}
                >
                  Hire a Pro
                </Button>
              </Link>
              <Link flex={1} href={LABORHACK_SCHEDULE_DEMO_URL}>
                <Button
                  colorScheme="black"
                  variant="outline"
                  w="full"
                  paddingY={{
                    base: 6,
                    lg: 8,
                  }}
                  fontSize={{
                    base: 15,
                    lg: 22,
                  }}
                >
                  Schedule a Demo
                </Button>
              </Link>
            </SectionActions>
          </SectionContent>
          <SectionImage
            src={BusinessImage}
            alt="an artisan climbing a ladder with quotes of his name and occupation"
          />
        </Section>
        {/* household */}
        <Section bgColor="lavender.50">
          <SectionImage
            src={HouseholdImage}
            alt="an artisan talking to a customer"
          />
          <SectionContent
            order={{
              base: 1,
              lg: 3,
            }}
          >
            <SectionHeader>
              <SectionTitle>
                Take the stress out
                <br className="hidden lg:block" /> of household tasks
              </SectionTitle>
              <SectionText>
                Don&apos;t let home tasks take over your time. We provide
                well-vetted workers to handle all your electrical, plumbing,
                cleaning, fumigation and AC needs.
              </SectionText>
            </SectionHeader>
            <SectionActions>
              <Link flex={1} as={RouterLink} to="/homeowners/hire/new">
                <Button
                  colorScheme="lavender"
                  variant="solid-dark"
                  w="full"
                  paddingY={{
                    base: 6,
                    lg: 8,
                  }}
                  fontSize={{
                    base: 15,
                    lg: 22,
                  }}
                  onClick={handleHeroCTAClick}
                  rightIcon={<Icon as={BsArrowRight} w={6} h={6} />}
                >
                  Hire a Pro
                </Button>
              </Link>
              <Link flex={1} href={LABORHACK_SCHEDULE_DEMO_URL}>
                <Button
                  colorScheme="black"
                  variant="outline"
                  w="full"
                  paddingY={{
                    base: 6,
                    lg: 8,
                  }}
                  fontSize={{
                    base: 15,
                    lg: 22,
                  }}
                >
                  Schedule a Demo
                </Button>
              </Link>
            </SectionActions>
          </SectionContent>
        </Section>
        {/* artisan */}
        <Section bgColor="galleonBlue.50">
          <SectionContent>
            <SectionHeader>
              <SectionTitle>
                Stand out from the crowd
                <br /> and get hired 10x faster
                <br /> by top companies
              </SectionTitle>
              <SectionText>
                Get certified internationally and hired faster by top companies
                that value your skills.
              </SectionText>
            </SectionHeader>
            <SectionActions
              justifyContent={{
                base: "center",
                lg: "start",
              }}
            >
              <Link href={process.env.REACT_APP_PRO_APPLICATION_URL}>
                <Button
                  colorScheme="prussianBlue"
                  paddingY={{
                    base: 6,
                    lg: 8,
                  }}
                  paddingX={{
                    base: 12,
                    lg: 20,
                  }}
                  fontSize={{
                    base: 15,
                    lg: 22,
                  }}
                  onClick={handleHeroCTAClick}
                >
                  Sign Up
                </Button>
              </Link>
            </SectionActions>
          </SectionContent>
          <SectionImage
            src={ArtisanImage}
            alt="a female artisan holding a jig saw"
          />
        </Section>
        <Section>
          <Stack
            w="full"
            direction={{
              base: "column",
              lg: "row",
            }}
            py={{
              base: 6,
              lg: 0,
            }}
            spacing={{
              base: 4,
              lg: 0,
            }}
          >
            <HStack
              w="full"
              flex={1}
              justifyContent={{
                base: "center",
                lg: "start",
              }}
            >
              <Heading
                as="h2"
                fontSize={{
                  base: 22,
                  md: 44,
                }}
                fontWeight="bold"
                color="prussianBlue.500"
                lineHeight={{
                  base: "base",
                  md: "shorter",
                }}
                textAlign={{
                  base: "center",
                  lg: "start",
                }}
              >
                Enjoy the
                <br />{" "}
                <Highlight
                  query={["LaborHack"]}
                  styles={{
                    padding: 0,
                    color: "flatteredFlamingo.500",
                  }}
                >
                  LaborHack
                </Highlight>{" "}
                experience
              </Heading>
            </HStack>
            <HStack
              w="full"
              flex={1}
              justifyContent={{
                base: "center",
                lg: "start",
              }}
            >
              <Text
                fontSize={{
                  base: 15,
                  lg: 24,
                }}
                lineHeight="base"
                fontWeight={{
                  base: "normal",
                  lg: "medium",
                }}
                color="gray.500"
                textAlign={{
                  base: "center",
                  lg: "start",
                }}
              >
                We simplify the hiring process, offer on-the-job insurance and
                give the Laborhack Guarantee to ensure our clients&apos; peace
                of mind.
              </Text>
            </HStack>
          </Stack>
        </Section>
        <Section bgColor="gray.50">
          <VStack
            w="full"
            spacing={{
              base: 12,
              lg: 20,
            }}
          >
            <Stack
              w="full"
              direction={{
                base: "column",
                lg: "row",
              }}
              spacing={{
                base: 12,
                lg: 0,
              }}
            >
              <Box flex={1}>
                <VStack
                  w={{
                    base: "full",
                    lg: "92%",
                  }}
                  alignItems="start"
                  spacing={{
                    base: 2,
                    lg: 4,
                  }}
                >
                  <Stack
                    direction={{
                      base: "row",
                      lg: "column",
                    }}
                    spacing={{
                      base: 4,
                      lg: 8,
                    }}
                    alignItems={{
                      base: "center",
                      lg: "start",
                    }}
                  >
                    <Image
                      src={VerifiedSectionicon}
                      w={{
                        base: 10,
                        lg: 20,
                      }}
                    />
                    <Heading
                      as="h4"
                      fontSize={{
                        base: 18,
                        lg: 32,
                      }}
                      fontWeight="medium"
                    >
                      Hire trusted & vetted workers instantly
                    </Heading>
                  </Stack>

                  <Text
                    fontSize={{
                      base: 15,
                      lg: 24,
                    }}
                    lineHeight="base"
                    fontWeight={{
                      base: "normal",
                      lg: "medium",
                    }}
                    color="gray.500"
                    w={{
                      base: "100%",
                      lg: "95%",
                    }}
                  >
                    We understand the importance of having reliable and skilled
                    artisans for your business. We connect you with certified,
                    vetted and insured artisans to get the job done.
                  </Text>
                </VStack>
              </Box>

              <Box flex={1}>
                <VStack
                  w={{
                    base: "full",
                    lg: "92%",
                  }}
                  alignItems="start"
                  spacing={{
                    base: 2,
                    lg: 4,
                  }}
                >
                  <Stack
                    direction={{
                      base: "row",
                      lg: "column",
                    }}
                    spacing={{
                      base: 4,
                      lg: 8,
                    }}
                    alignItems={{
                      base: "center",
                      lg: "start",
                    }}
                  >
                    <Image
                      src={ClockSectionIcon}
                      w={{
                        base: 10,
                        lg: 20,
                      }}
                    />
                    <Heading
                      as="h4"
                      fontSize={{
                        base: 18,
                        lg: 32,
                      }}
                      fontWeight="medium"
                    >
                      Save time through simplified hiring
                    </Heading>
                  </Stack>

                  <Text
                    fontSize={{
                      base: 15,
                      lg: 24,
                    }}
                    lineHeight="base"
                    fontWeight={{
                      base: "normal",
                      lg: "medium",
                    }}
                    color="gray.500"
                    w={{
                      base: "100%",
                      lg: "95%",
                    }}
                  >
                    We save you time by providing a streamlined hiring process
                    and payroll management, allowing you to focus on other
                    important business needs.
                  </Text>
                </VStack>
              </Box>
            </Stack>
            <Stack
              w="full"
              direction={{
                base: "column",
                lg: "row",
              }}
              spacing={{
                base: 12,
                lg: 0,
              }}
            >
              <Box flex={1}>
                <VStack
                  w={{
                    base: "full",
                    lg: "92%",
                  }}
                  alignItems="start"
                  spacing={{
                    base: 2,
                    lg: 4,
                  }}
                >
                  <Stack
                    direction={{
                      base: "row",
                      lg: "column",
                    }}
                    spacing={{
                      base: 4,
                      lg: 8,
                    }}
                    alignItems={{
                      base: "center",
                      lg: "start",
                    }}
                  >
                    <Image
                      src={HospitalSectionIcon}
                      w={{
                        base: 10,
                        lg: 20,
                      }}
                    />
                    <Heading
                      as="h4"
                      fontSize={{
                        base: 18,
                        lg: 32,
                      }}
                      fontWeight="medium"
                    >
                      Manage and reduce risk
                    </Heading>
                  </Stack>

                  <Text
                    fontSize={{
                      base: 15,
                      lg: 24,
                    }}
                    lineHeight="base"
                    fontWeight={{
                      base: "normal",
                      lg: "medium",
                    }}
                    color="gray.500"
                    w={{
                      base: "100%",
                      lg: "95%",
                    }}
                  >
                    We provide medical insurance to cover all our LaborHack Pros
                    while working on the job; one less thing for you to worry
                    about.
                  </Text>
                </VStack>
              </Box>

              <Box flex={1}>
                <VStack
                  w={{
                    base: "full",
                    lg: "92%",
                  }}
                  alignItems="start"
                  spacing={{
                    base: 2,
                    lg: 4,
                  }}
                >
                  <Stack
                    direction={{
                      base: "row",
                      lg: "column",
                    }}
                    spacing={{
                      base: 4,
                      lg: 8,
                    }}
                    alignItems={{
                      base: "center",
                      lg: "start",
                    }}
                  >
                    <Image
                      src={FlashSectionIcon}
                      w={{
                        base: 10,
                        lg: 20,
                      }}
                    />
                    <Heading
                      as="h4"
                      fontSize={{
                        base: 18,
                        lg: 32,
                      }}
                      fontWeight="medium"
                    >
                      Instant replacement
                    </Heading>
                  </Stack>

                  <Text
                    fontSize={{
                      base: 15,
                      lg: 24,
                    }}
                    lineHeight="base"
                    fontWeight={{
                      base: "normal",
                      lg: "medium",
                    }}
                    color="gray.500"
                    w={{
                      base: "100%",
                      lg: "95%",
                    }}
                  >
                    We ensure minimal disruption to the client&apos;s project by
                    replacing workers on short notice and at no cost.
                  </Text>
                </VStack>
              </Box>
            </Stack>
          </VStack>
        </Section>
        <Section>
          <VStack
            alignItems={{
              base: "center",
              lg: "start",
            }}
            spacing={8}
          >
            <Heading
              as="h2"
              fontSize={{
                base: 22,
                md: 44,
              }}
              fontWeight="bold"
              color="prussianBlue.500"
              lineHeight={{
                base: "base",
                md: "shorter",
              }}
              textAlign={{
                base: "center",
                lg: "start",
              }}
            >
              See what our customers have to say about LaborHack
            </Heading>
            {/* <Stack
                direction={{ base: "column", lg: "row" }}
                spacing={{
                  base: 4,
                  lg: 8,
                }}
                alignItems="stretch"
              >
                {reviews.map((reviewDetails) => (
                  <ReviewCard key={reviewDetails.name} {...reviewDetails} />
                ))}
              </Stack> */}
            <ReviewCardWrapper items={reviews} />
          </VStack>
        </Section>
        <CategoriesMarquee />
        <HStack w="full" bgColor="prussianBlue.500" justifyContent="center">
          <VStack
            px={5}
            py={{
              base: 8,
              lg: 20,
            }}
            spacing={{
              base: 6,
              lg: 12,
            }}
            w="full"
            maxW="container.xl"
            alignItems="start"
          >
            <HStack
              w="full"
              justifyContent={{
                base: "start",
                lg: "space-between",
              }}
            >
              <Heading
                as="h5"
                fontSize={{
                  base: "2xl",
                  md: "5xl",
                }}
                fontWeight="normal"
                color="white"
                lineHeight={{
                  base: "base",
                  md: "shorter",
                }}
              >
                <strong>
                  Hire for your next project
                  <br className="block md:hidden" /> and get
                  <br className="hidden md:block" /> the
                </strong>{" "}
                job done
                <br className="block md:hidden" /> right the first time.
              </Heading>
              <Image
                src={ProBadge}
                alt="LaborHack professional badge"
                w={128}
                display={{
                  base: "none",
                  lg: "block",
                }}
              />
            </HStack>
            <HStack w="full">
              <Link flex={1} as={RouterLink} to="/auth/register">
                <Button
                  w={{
                    base: "full",
                    lg: "40%",
                  }}
                  py={6}
                  size={{
                    base: "lg",
                    md: "xl",
                  }}
                >
                  Get Started
                </Button>
              </Link>
            </HStack>
          </VStack>
        </HStack>
      </VStack>
      <Footer />
    </>
  );
};
