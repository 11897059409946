import React, { FC } from "react";
import { Icon } from "semantic-ui-react";

import styles from "./styles.module.scss";

export interface LoadingProps {}

export const Loading: FC<LoadingProps> = ({}) => {
  return (
    <div className={styles.loading}>
      <Icon size="huge" name="spinner" loading />
    </div>
  );
};
