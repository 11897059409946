import React, { FC, useEffect } from "react";
import { Helmet } from "react-helmet";
import { logEvent } from "helpers/analytics";
import { LABORHACK_PRIVACY_POLICY_URL } from "../../constants";
import PDFRenderer from "../../_components/PDFRenderer";
import Footer from "../../_components/Footer";

export interface PrivacyProps {}

export const Privacy: FC<PrivacyProps> = ({}) => {
  useEffect(() => {
    logEvent("page_view");
  }, []);

  return (
    <>
      <Helmet>
        <title>Privacy Policy</title>
      </Helmet>
      <PDFRenderer fileUrl={LABORHACK_PRIVACY_POLICY_URL} />
      <Footer />
    </>
  );
};
