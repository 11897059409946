import {
  Divider,
  Stack,
  HStack,
  VStack,
  Icon,
  Link,
  Text,
  Heading,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import React, { FC } from "react";
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";
import {
  LABORHACK_CAREERS_URL,
  LABORHACK_SUPPORT_EMAIL,
  LABORHACK_SUPPORT_PHONE,
} from "../../constants";
import { LaborHackSocialLinks } from "../../pages/contact";
import { Logo } from "../Logo/Logo";

export const Footer: FC = () => {
  const { facebook, twitter, instagram, linkedin } = LaborHackSocialLinks;

  const logoWidth = useBreakpointValue({
    base: 110,
    lg: 216,
  });

  const logoHeight = useBreakpointValue({
    base: 16,
    lg: 30,
  });

  return (
    <HStack justifyContent="center" backgroundColor="prussianBlue.500" w="full">
      <VStack
        w="full"
        maxW="container.xl"
        spacing={{
          base: 8,
          lg: 14,
        }}
        px={5}
        pb={10}
      >
        <Divider
          w="full"
          borderColor={{
            base: "gray.600",
            lg: "white",
          }}
        />
        <Stack
          direction={{
            base: "column",
            lg: "row",
          }}
          justifyContent="space-between"
          w="full"
          alignItems="start"
          spacing={{
            base: 10,
          }}
        >
          {/* company */}
          <VStack
            alignItems="start"
            spacing={{
              base: 6,
              lg: 8,
            }}
          >
            <Heading
              as="h6"
              fontSize={{
                base: "lg",
                lg: "2xl",
              }}
              color="white"
              fontWeight={{
                base: "medium",
                lg: "semibold",
              }}
              textAlign="center"
            >
              Company
            </Heading>
            <VStack
              alignItems="start"
              spacing={{
                base: 4,
                lg: 5,
              }}
            >
              <Link as={RouterLink} to="/about">
                <Text
                  fontSize={{
                    base: "md",
                    lg: "xl",
                  }}
                  color="white"
                >
                  About Us
                </Text>
              </Link>
              <Link href="https://blog.laborhack.com">
                <Text
                  fontSize={{
                    base: "md",
                    lg: "xl",
                  }}
                  color="white"
                >
                  Blog
                </Text>
              </Link>
              <Link as={RouterLink} to="/contact">
                <Text
                  fontSize={{
                    base: "md",
                    lg: "xl",
                  }}
                  color="white"
                >
                  Contact Us
                </Text>
              </Link>
              <Link href={LABORHACK_CAREERS_URL}>
                <Text
                  fontSize={{
                    base: "md",
                    lg: "xl",
                  }}
                  color="white"
                >
                  Careers
                </Text>
              </Link>
              <Link as={RouterLink} to="/faqs">
                <Text
                  fontSize={{
                    base: "md",
                    lg: "xl",
                  }}
                  color="white"
                >
                  FAQs
                </Text>
              </Link>
            </VStack>
          </VStack>
          {/* Services  */}
          <VStack
            alignItems="start"
            spacing={{
              base: 6,
              lg: 8,
            }}
          >
            <Heading
              as="h6"
              fontSize={{
                base: "lg",
                lg: "2xl",
              }}
              color="white"
              fontWeight={{
                base: "medium",
                lg: "semibold",
              }}
              textAlign="center"
            >
              Services
            </Heading>
            <VStack alignItems="start" spacing={5}>
              <Link as={RouterLink} to="/artisans">
                <Text
                  fontSize={{
                    base: "md",
                    lg: "xl",
                  }}
                  color="white"
                >
                  LaborHack Artisans
                </Text>
              </Link>
              <Link as={RouterLink} to="/business">
                <Text
                  fontSize={{
                    base: "md",
                    lg: "xl",
                  }}
                  color="white"
                >
                  LaborHack Business
                </Text>
              </Link>
              <Link as={RouterLink} to="/homeowners">
                <Text
                  fontSize={{
                    base: "md",
                    lg: "xl",
                  }}
                  color="white"
                >
                  LaborHack Homeowners
                </Text>
              </Link>
              <Link as={RouterLink} to="/subscriptions">
                <Text
                  fontSize={{
                    base: "md",
                    lg: "xl",
                  }}
                  color="white"
                >
                  LaborHack Subscriptions
                </Text>
              </Link>

              {/* <Link as={RouterLink} to="/study-work-abroad">
                <Text
                  fontSize={{
                    base: "md",
                    lg: "xl",
                  }}
                  color="white"
                >
                  Study & Work Abroad
                </Text>
              </Link> */}
            </VStack>
          </VStack>

          {/* legal */}
          <VStack
            alignItems="start"
            spacing={{
              base: 6,
              lg: 8,
            }}
          >
            <Heading
              as="h6"
              fontSize={{
                base: "lg",
                lg: "2xl",
              }}
              color="white"
              fontWeight={{
                base: "medium",
                lg: "semibold",
              }}
              textAlign="center"
            >
              Legal
            </Heading>
            <VStack alignItems="start" spacing={5}>
              <Link as={RouterLink} to="/privacy">
                <Text
                  fontSize={{
                    base: "md",
                    lg: "xl",
                  }}
                  color="white"
                >
                  Privacy Policy
                </Text>
              </Link>
              <Link as={RouterLink} to="/terms">
                <Text
                  fontSize={{
                    base: "md",
                    lg: "xl",
                  }}
                  color="white"
                >
                  Terms of Service
                </Text>
              </Link>
              <Link as={RouterLink} to="/pro-terms">
                <Text
                  fontSize={{
                    base: "md",
                    lg: "xl",
                  }}
                  color="white"
                >
                  Artisan Terms of Service
                </Text>
              </Link>
              <Link as={RouterLink} to="/cookie">
                <Text
                  fontSize={{
                    base: "md",
                    lg: "xl",
                  }}
                  color="white"
                >
                  Cookie Policy
                </Text>
              </Link>
            </VStack>
          </VStack>

          {/* contact us */}
          <VStack
            alignItems="start"
            spacing={{
              base: 6,
              lg: 8,
            }}
          >
            <Heading
              as="h6"
              fontSize={{
                base: "lg",
                lg: "2xl",
              }}
              color="white"
              fontWeight={{
                base: "medium",
                lg: "semibold",
              }}
              textAlign="center"
            >
              Contact Us
            </Heading>
            <VStack alignItems="start" spacing={5}>
              <Link
                href={`mailto:${LABORHACK_SUPPORT_EMAIL}?subject=Hello%20LaborHack`}
              >
                <Text
                  fontSize={{
                    base: "md",
                    lg: "xl",
                  }}
                  color="white"
                >
                  {LABORHACK_SUPPORT_EMAIL}
                </Text>
              </Link>
              <Link href={`tel:${LABORHACK_SUPPORT_PHONE}`}>
                <Text
                  fontSize={{
                    base: "md",
                    lg: "xl",
                  }}
                  color="white"
                >
                  {LABORHACK_SUPPORT_PHONE}
                </Text>
              </Link>

              <Text
                fontSize={{
                  base: "md",
                  lg: "xl",
                }}
                color="white"
              >
                Dominion House 207,
                <br />
                Igbosere Road, Lagos Island,
                <br />
                Lagos
              </Text>
            </VStack>
          </VStack>
        </Stack>
        <Divider
          w="full"
          borderColor={{
            base: "gray.600",
            lg: "white",
          }}
        />
        <VStack
          w="full"
          spacing={{
            base: 8,
            lg: 10,
          }}
        >
          <Stack
            w="full"
            justifyContent={{
              base: "center",
              lg: "space-between",
            }}
            alignItems="center"
            direction={{
              base: "column",
              lg: "row",
            }}
            spacing={{
              base: 5,
              lg: undefined,
            }}
          >
            <Logo width={logoWidth} height={logoHeight} fill="#FFF" />
            <HStack spacing={6}>
              <Link href={instagram}>
                <Icon
                  as={FaInstagram}
                  w={{
                    base: 6,
                    lg: 8,
                  }}
                  h={{
                    base: 6,
                    lg: 8,
                  }}
                  color="white"
                />
              </Link>
              <Link href={twitter}>
                <Icon
                  as={FaTwitter}
                  w={{
                    base: 6,
                    lg: 8,
                  }}
                  h={{
                    base: 6,
                    lg: 8,
                  }}
                  color="white"
                />
              </Link>
              <Link href={linkedin}>
                <Icon
                  as={FaLinkedin}
                  w={{
                    base: 6,
                    lg: 8,
                  }}
                  h={{
                    base: 6,
                    lg: 8,
                  }}
                  color="white"
                />
              </Link>
              <Link href={facebook}>
                <Icon
                  as={FaFacebook}
                  w={{
                    base: 6,
                    lg: 8,
                  }}
                  h={{
                    base: 6,
                    lg: 8,
                  }}
                  color="white"
                />
              </Link>
            </HStack>
          </Stack>

          <Text
            fontSize={{
              base: "sm",
              lg: "xl",
            }}
            color="prussianBlue.50"
          >
            2023 &copy; LaborHack. All Rights Reserved.
          </Text>
        </VStack>
      </VStack>
    </HStack>
  );
};
