import Joi from "@hapi/joi";
import { addDays, isAfter, startOfDay } from "date-fns";

export const getCappedAmount = (amount: number) => {
  const gates = [
    1000, 5000, 10000, 50000, 100000, 500000, 1000000, 5000000, 10000000,
  ];

  let lastGate = 0;

  for (const gate of gates) {
    if (amount < gate) {
      return lastGate;
    }

    lastGate = gate;
  }

  return lastGate;
};

export const validCountries = [
  {
    name: "Nigeria",
    code: "NG",
    dialCode: "+234",
    flag: "🇳🇬",
  },
  {
    name: "Kenya",
    code: "KE",
    dialCode: "+254",
    flag: "🇰🇪",
  },
  {
    name: "Uganda",
    code: "UG",
    dialCode: "+256",
    flag: "🇺🇬",
  },
];

export const cleanPhoneNumber = (phoneNumberString: string) => {
  let cleaned = phoneNumberString.trim();

  // remove +234
  cleaned = cleaned.replace(/^\+234/, "");

  // remove braces, dashes and spaces
  cleaned = cleaned.replace(/-|\s|\(|\)/g, "");

  if (cleaned.startsWith("0")) {
    cleaned = cleaned.replace(/^0/, "");
  }

  return cleaned;
};

export const isValidText = (text: string) => {
  const cleaned = text.trim();

  if (cleaned.length === 0) {
    return false;
  }

  return true;
};

export const isValidPhoneNumber = (
  phoneNumberString: string,
  regex: RegExp = /^((70|71)|(80|81)|(90|91))\d{8}$/
) => {
  const cleaned = cleanPhoneNumber(phoneNumberString);

  // Check if the cleaned number is 10 digits long
  if (cleaned.length !== 10) {
    return false;
  }

  if (!regex.test(cleaned)) {
    return false;
  }

  // If it's not 10 digits long, it's not a number.
  return true;
};

export const schema = Joi.string().email({
  minDomainSegments: 2,
  tlds: { allow: false },
});

export const isValidEmail = (emailString: string) => {
  const { error } = schema.validate(emailString);

  if (error) {
    return false;
  }

  return true;
};

export const isValidHomeownerHireStartDate = (date: Date) => {
  return isAfter(date, addDays(startOfDay(new Date()), 1));
};
