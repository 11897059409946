import React, { FC, useEffect } from "react";
import { Helmet } from "react-helmet";
import { logEvent } from "helpers/analytics";

import PDFRenderer from "../../_components/PDFRenderer";
import { LABORHACK_TERMS_OF_USE_URL } from "../../constants";
import Footer from "../../_components/Footer";

export interface TermsProps {}

export const Terms: FC<TermsProps> = ({}) => {
  useEffect(() => {
    logEvent("page_view");
  }, []);

  return (
    <>
      <Helmet>
        <title>Terms and Conditions</title>
      </Helmet>
      <PDFRenderer fileUrl={LABORHACK_TERMS_OF_USE_URL} />
      <Footer />
    </>
  );
};
