import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Flex,
  Image,
  Text,
  Box,
  useBreakpointValue,
  ResponsiveValue,
  StackProps,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Stack,
  Link,
} from "@chakra-ui/react";
import LinkArrowRightImage from "../../../../assets/link-arrow-right.svg";

interface Service {
  title: string;
  description: string;
  imageSrc: string;
}

interface LaborHackServicesTabsProps extends StackProps {
  items: Service[];
}

const LaborHackServicesTabs: React.FC<LaborHackServicesTabsProps> = ({
  items,
  ...stackProps
}) => {
  const [activeItem, setActiveItem] = useState(0);

  const isColumnLayout = useBreakpointValue<ResponsiveValue<any>>({
    base: true,
    md: true,
    lg: false,
  });

  return (
    <Stack
      direction={isColumnLayout ? "column" : "row"}
      width="100%"
      gap="53px"
      {...stackProps}
    >
      <Box
        width={isColumnLayout ? "100%" : "40%"}
        bgImage={items[activeItem].imageSrc}
        minH="462px"
        bgPosition="center"
        bgRepeat="no-repeat"
      ></Box>

      <Accordion
        allowToggle
        defaultIndex={0}
        width={isColumnLayout ? "100%" : "50%"}
        borderLeft="4px solid #C8C8C8"
      >
        {items.map((item, index) => (
          <AccordionItem
            key={item.title + index}
            position="relative"
            left="-4px"
            borderLeftColor={
              index === activeItem ? "black !important" : "#C8C8C8 !important"
            }
            borderLeft={`4px solid`}
            borderTop="none"
            borderBottom="none"
          >
            <h2>
              <AccordionButton pl={isColumnLayout ? "16px" : "53px"} pr="0px">
                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  fontFamily="AeonikPro"
                  fontSize={{
                    base: "18px",
                    md: "32px",
                  }}
                  fontStyle="normal"
                  fontWeight={500}
                  lineHeight={{
                    base: "24px",
                    md: "42px",
                  }}
                  color={index === activeItem ? "prussianBlue.500" : "#B1B1B1"}
                  onClick={() => setActiveItem(index)}
                >
                  {item.title}
                </Box>
              </AccordionButton>
            </h2>
            <AccordionPanel
              pb={4}
              pl={isColumnLayout ? "16px" : "53px"}
              pr="0px"
              color="#5E5E5E"
              fontSize={{
                base: "15px",
                md: "22px",
              }}
              fontStyle="normal"
              fontWeight={500}
              lineHeight={{
                base: "25px",
                md: "34px",
              }}
            >
              {item.description}
              <Link
                color="#5E5E5E"
                href={process.env.REACT_APP_MARKETPLACE_URL}
                _hover={{ textDecoration: "none" }}
              >
                <Flex mt="12px">
                  <Text
                    fontSize={{
                      base: "15px",
                      md: "24px",
                    }}
                    fontStyle="normal"
                    fontWeight={{
                      base: 500,
                      md: 600,
                    }}
                    lineHeight={{
                      base: "14px",
                      md: "38px",
                    }}
                    textDecoration="underline"
                    color="black"
                    textUnderlineOffset="4px"
                  >
                    Hire a Pro
                  </Text>{" "}
                  <Image
                    src={LinkArrowRightImage}
                    width={{ base: "24px", md: "auto" }}
                    mt={{ base: "-4px", md: "0px" }}
                    alt="arrow-right"
                    textDecoration="none"
                  />
                </Flex>
              </Link>
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Stack>
  );
};

export default LaborHackServicesTabs;
