import React, { FC, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import { logEvent } from "../../helpers/analytics";
import { Footer } from "../../_components/Footer/Footer";
import {
  HStack,
  Heading,
  Image,
  Button,
  VStack,
  Text,
  Highlight,
  Stack,
  Box,
  Icon,
  Link,
  useBreakpointValue,
} from "@chakra-ui/react";
import ProBadge from "../../assets/pro-badge.svg";
import {
  Section,
  SectionImage,
  SectionActions,
  SectionContent,
  SectionTitle,
  SectionHeader,
  SectionText,
} from "pages/landing/components/Section";
import VerifiedSectionicon from "../../assets/verified-section-icon.svg";
import ClockSectionIcon from "../../assets/clock-section-icon.svg";
import HospitalSectionIcon from "../../assets/hospital-section-icon.svg";
import FlashSectionIcon from "../../assets/flash-section-icon.svg";
import BusinessValueProposition from "../../assets/business-value-prop.png";
import BusinessValuePropositionMobile from "../../assets/business-value-prop-sm.png";
import { BsArrowRight } from "react-icons/bs";
import { FAQs } from "_components/FAQs/FAQs";
import CategoriesMarquee from "_components/CategoriesMarquee";
import HowItWorksTabs from "_components/HowItWorksTabs";
import CreateABusinessAccount from "../../assets/01-create-an-account.png";
import SubmitRequestImage from "../../assets/02-submit-request.png";
import GetMatchedImage from "../../assets/03-get-matched.png";
import MatchingSuccessfulImage from "../../assets/04-matching-successful.png";
import SkilledWorker1Image from "../../assets/skilled-worker-1.png";
import SkilledWorker2Image from "../../assets/skilled-worker-2.png";
import SkilledWorker3Image from "../../assets/skilled-worker-3.png";
import SkilledWorker4Image from "../../assets/skilled-worker-4.png";
import LaborHackServicesTabs from "./components/LaborHackServicesTabs/LaborHackServicesTabs";
import LaborHackForConstructionImage from "../../assets/laborhack-for-construction.png";
import LaborHackForMaintenanceImage from "../../assets/laborhack-for-maintenance.png";
import LaborHackForFacilityManagementImage from "../../assets/laborhack-for-facility-management.png";
import LaborHackServicesForInstallations from "../../assets/laborhack-for-installations.png";
import ImpactCarousel from "./components/ImpactCarousel/ImpactCarousel";
import ImpactStoryChelseaImage from "../../assets/impact-story-chelsea.png";
import ImpactStoryMiesianBeforeImage from "../../assets/impact-story-miesian-before.png";
import ImpactStoryMiesianAfterImage from "../../assets/impact-story-miesian-after.png";
import ImpactStoryEmelImage from "../../assets/impact-story-emel.png";
import { businessFAQs } from "pages/faqs/constants";
import { LABORHACK_SCHEDULE_DEMO_URL } from "../../constants";

const laborHackServices = [
  {
    title: "LaborHack for Construction",
    description: `From small projects to large-scale developments, 
LaborHack ensures you have access to a reliable 
workforce to meet your labour needs. We have helped 
companies of different sizes with their construction needs.`,
    imageSrc: LaborHackForConstructionImage,
  },
  {
    title: "LaborHack for Maintenance",
    description: `We understand the importance of a well- functioning space and offer a readily accessible workforce to take care of your maintenance and repair needs, saving you time and ensuring the smooth functioning of your home or business.`,
    imageSrc: LaborHackForMaintenanceImage,
  },
  {
    title: "LaborHack for Facility Managers",
    description:
      "Connect with skilled professionals to support your facility management operations. You can also outsource your service requirements to us to avoid disappointing your clients in case of a shortage of manpower.",
    imageSrc: LaborHackForFacilityManagementImage,
  },
  {
    title: "LaborHack for Installations",
    description: `Connect with skilled professionals for your 
installation projects. LaborHack ensures that 
your installation projects are completed to a 
high standard, allowing you to focus on other 
important aspects of your business or 
personal life.`,
    imageSrc: LaborHackServicesForInstallations,
  },
];

const impactStories = [
  {
    imageSrc: ImpactStoryChelseaImage,
    description: `
        The Project Director of the Chelsea Hotel construction team approached
        LaborHack to assemble a team to handle the construction, wiring and
        plumbing work on three floors of the 16-floor hotel. LaborHack put
        together a team of 43 LaborHack Pros comprising plumbers, carpenters,
        electricians, and masons. The team worked in varying numbers over a
        period of 9 months.
      `,
  },
  {
    imageSrc: ImpactStoryMiesianBeforeImage,
    description: `Miesian Limited contracted LaborHack Pros to carry 
  out remedial work on ceiling piping work done at beach 
  house project.
  
  Team of six LaborHack Pros completed the job in ten days`,
    imageLabel: "Before",
  },
  {
    imageSrc: ImpactStoryMiesianAfterImage,
    description: `Miesian Limited contracted LaborHack Pros to carry 
  out remedial work on ceiling piping work done at beach 
  house project.
  
  Team of six LaborHack Pros completed the job in ten days`,
    imageLabel: "After",
  },

  {
    imageSrc: ImpactStoryEmelImage,
    description: `Emel Building Material contracted LaborHack Pros to install
  frameless glass balustrade (handrails) in the 16-storey 
  Lekki Blue Waters residential building. 
  
  Work was successfully completed according to project 
  specifications`,
  },
];

const howToHireSteps = [
  {
    label: "01. Create an account",
    imageSrc: CreateABusinessAccount,
  },
  { label: "02. Submit request", imageSrc: SubmitRequestImage },
  { label: "03. Get matched", imageSrc: GetMatchedImage },
  {
    label: "04. Matching successful",
    imageSrc: MatchingSuccessfulImage,
  },
];

export interface BusinessProps {}

export const Business: FC<BusinessProps> = ({}) => {
  const heroImage = useBreakpointValue({
    base: BusinessValuePropositionMobile,
    lg: BusinessValueProposition,
  });

  useEffect(() => {
    logEvent("page_view");
  }, []);

  const handleHeroCTAClicked = () => {
    logEvent("hero_cta_clicked");
  };

  return (
    <>
      <Helmet>
        <title>
          LaborHack Business - Hire certified artisans for your project
        </title>
        <meta
          name="description"
          content="Don't let unreliable workers hold back construction or project. Simplify your hiring process, meet your deadlines and reduce risk. Hire certified artisans in minutes."
        />
      </Helmet>
      <VStack w="full" spacing={0}>
        <Section bgColor="#FEFEFE">
          <SectionContent>
            <SectionHeader>
              <SectionTitle
                w={{ base: "sm", md: "70%", lg: "auto" }}
                fontSize={{
                  base: 26,
                  md: 52,
                }}
              >
                Hire{" "}
                <Highlight
                  query={["Certified", "Minutes", "Weeks"]}
                  styles={{
                    padding: 0,
                    color: "flatteredFlamingo.500",
                  }}
                >
                  Certified Skilled Workers in Minutes, not Weeks.
                </Highlight>
              </SectionTitle>
              <SectionText color="prussianBlue.500">
                Don&apos;t let unreliable workers hold you back. Simplify your
                hiring process, meet your deadlines and reduce risk.
              </SectionText>
            </SectionHeader>
            <SectionActions>
              <Link flex={1} href={process.env.REACT_APP_MARKETPLACE_URL}>
                <Button
                  colorScheme="flatteredFlamingo"
                  variant="solid-dark"
                  w="full"
                  flex={1}
                  paddingY={{
                    base: 6,
                    lg: 8,
                  }}
                  fontSize={{
                    base: 15,
                    lg: 22,
                  }}
                  onClick={handleHeroCTAClicked}
                  rightIcon={<Icon as={BsArrowRight} w={6} h={6} />}
                  color="#FEFEFE"
                  border="2px solid"
                  borderColor="flatteredFlamingo.500"
                >
                  Hire a Pro
                </Button>
              </Link>
              <Link flex={1} href={LABORHACK_SCHEDULE_DEMO_URL}>
                <Button
                  colorScheme="black"
                  border="2px solid"
                  variant="outline"
                  flex={1}
                  paddingY={{
                    base: 6,
                    lg: 8,
                  }}
                  fontSize={{
                    base: 15,
                    lg: 22,
                  }}
                  onClick={handleHeroCTAClicked}
                  width="100%"
                >
                  Schedule a Demo
                </Button>
              </Link>
            </SectionActions>
          </SectionContent>
          <SectionImage
            src={heroImage}
            alt="an artisan climbing a ladder with quotes of his name and occupation"
            ml="auto"
            mr={{
              base: "auto",
              lg: "0px",
            }}
          />
        </Section>

        {/* Powering Business */}
        <Section
          bgColor="#310B05"
          maxH={{ lg: "570px" }}
          paddingTop={{ lg: "70px" }}
        >
          <VStack
            w="full"
            spacing={{
              base: 12,
              lg: 20,
            }}
          >
            <Heading
              as="h2"
              fontSize={{
                base: 22,
                md: 44,
              }}
              fontWeight="bold"
              color="#FEFEFE"
              lineHeight={{
                base: "base",
                md: "shorter",
              }}
              textAlign="center"
            >
              Powering{" "}
              <Highlight
                query={["businesses"]}
                styles={{
                  padding: 0,
                  color: "flatteredFlamingo.500",
                }}
              >
                businesses
              </Highlight>{" "}
              with <br /> top-skilled workers
            </Heading>
            <HStack
              gap={0}
              position="relative"
              bottom="-30px"
              pb="0px !important"
            >
              <Image
                src={SkilledWorker1Image}
                boxSize={{
                  base: "calc(24vw - 10px)",
                  md: "calc(24vw - 10px)",
                  lg: "calc(24vw - 10px)",
                  xl: "auto",
                }}
              />
              <Image
                src={SkilledWorker2Image}
                boxSize={{
                  base: "calc(24vw - 10px)",
                  md: "calc(24vw - 10px)",
                  lg: "calc(24vw - 10px)",
                  xl: "auto",
                }}
              />
              <Image
                src={SkilledWorker3Image}
                boxSize={{
                  base: "calc(24vw - 10px)",
                  md: "calc(24vw - 10px)",
                  lg: "calc(24vw - 10px)",
                  xl: "auto",
                }}
              />
              <Image
                src={SkilledWorker4Image}
                boxSize={{
                  base: "calc(24vw - 10px)",
                  md: "calc(24vw - 10px)",
                  lg: "calc(24vw - 10px)",
                  xl: "auto",
                }}
              />
            </HStack>
          </VStack>
        </Section>

        <CategoriesMarquee zIndex="overlay" />

        <Section>
          <Stack
            w="full"
            direction={{
              base: "column",
              lg: "row",
            }}
            py={{
              base: 6,
              lg: 0,
            }}
            spacing={{
              base: 4,
              lg: 0,
            }}
          >
            <HStack
              w="full"
              flex={1}
              justifyContent={{
                base: "center",
                lg: "start",
              }}
            >
              <Heading
                as="h2"
                fontSize={{
                  base: 22,
                  md: 44,
                }}
                fontWeight="bold"
                color="prussianBlue.500"
                lineHeight={{
                  base: "base",
                  md: "shorter",
                }}
                textAlign={{
                  base: "center",
                  lg: "start",
                }}
              >
                Enjoy the
                <br />{" "}
                <Highlight
                  query={["LaborHack"]}
                  styles={{
                    padding: 0,
                    color: "flatteredFlamingo.500",
                  }}
                >
                  LaborHack
                </Highlight>{" "}
                experience
              </Heading>
            </HStack>
            <HStack
              w="full"
              flex={1}
              justifyContent={{
                base: "center",
                lg: "start",
              }}
            >
              <Text
                fontSize={{
                  base: 15,
                  lg: 24,
                }}
                lineHeight="base"
                fontWeight={{
                  base: "normal",
                  lg: "medium",
                }}
                color="gray.500"
                textAlign={{
                  base: "center",
                  lg: "start",
                }}
              >
                We simplify the hiring process, offer on-the-job insurance and
                give the Laborhack Guarantee to ensure our clients&apos; peace
                of mind.
              </Text>
            </HStack>
          </Stack>
        </Section>
        <Section bgColor="gray.50">
          <VStack
            w="full"
            spacing={{
              base: 12,
              lg: 20,
            }}
          >
            <Stack
              w="full"
              direction={{
                base: "column",
                lg: "row",
              }}
              spacing={{
                base: 12,
                lg: 0,
              }}
            >
              <Box flex={1}>
                <VStack
                  w={{
                    base: "full",
                    lg: "92%",
                  }}
                  alignItems="start"
                  spacing={{
                    base: 2,
                    lg: 4,
                  }}
                >
                  <Stack
                    direction={{
                      base: "row",
                      lg: "column",
                    }}
                    spacing={{
                      base: 4,
                      lg: 8,
                    }}
                    alignItems={{
                      base: "center",
                      lg: "start",
                    }}
                  >
                    <Image
                      src={VerifiedSectionicon}
                      w={{
                        base: 10,
                        lg: 20,
                      }}
                    />
                    <Heading
                      as="h4"
                      fontSize={{
                        base: 18,
                        lg: 32,
                      }}
                      fontWeight="medium"
                    >
                      Hire trusted & vetted workers instantly
                    </Heading>
                  </Stack>

                  <Text
                    fontSize={{
                      base: 15,
                      lg: 24,
                    }}
                    lineHeight="base"
                    fontWeight={{
                      base: "normal",
                      lg: "medium",
                    }}
                    color="gray.500"
                    w={{
                      base: "100%",
                      lg: "95%",
                    }}
                  >
                    We understand the importance of having reliable and skilled
                    artisans for your business. We connect you with certified,
                    vetted and insured artisans to get the job done.
                  </Text>
                </VStack>
              </Box>

              <Box flex={1}>
                <VStack
                  w={{
                    base: "full",
                    lg: "92%",
                  }}
                  alignItems="start"
                  spacing={{
                    base: 2,
                    lg: 4,
                  }}
                >
                  <Stack
                    direction={{
                      base: "row",
                      lg: "column",
                    }}
                    spacing={{
                      base: 4,
                      lg: 8,
                    }}
                    alignItems={{
                      base: "center",
                      lg: "start",
                    }}
                  >
                    <Image
                      src={ClockSectionIcon}
                      w={{
                        base: 10,
                        lg: 20,
                      }}
                    />
                    <Heading
                      as="h4"
                      fontSize={{
                        base: 18,
                        lg: 32,
                      }}
                      fontWeight="medium"
                    >
                      Save time through simplified hiring
                    </Heading>
                  </Stack>

                  <Text
                    fontSize={{
                      base: 15,
                      lg: 24,
                    }}
                    lineHeight="base"
                    fontWeight={{
                      base: "normal",
                      lg: "medium",
                    }}
                    color="gray.500"
                    w={{
                      base: "100%",
                      lg: "95%",
                    }}
                  >
                    We save you time by providing a streamlined hiring process
                    and payroll management, allowing you to focus on other
                    important business needs.
                  </Text>
                </VStack>
              </Box>
            </Stack>
            <Stack
              w="full"
              direction={{
                base: "column",
                lg: "row",
              }}
              spacing={{
                base: 12,
                lg: 0,
              }}
            >
              <Box flex={1}>
                <VStack
                  w={{
                    base: "full",
                    lg: "92%",
                  }}
                  alignItems="start"
                  spacing={{
                    base: 2,
                    lg: 4,
                  }}
                >
                  <Stack
                    direction={{
                      base: "row",
                      lg: "column",
                    }}
                    spacing={{
                      base: 4,
                      lg: 8,
                    }}
                    alignItems={{
                      base: "center",
                      lg: "start",
                    }}
                  >
                    <Image
                      src={HospitalSectionIcon}
                      w={{
                        base: 10,
                        lg: 20,
                      }}
                    />
                    <Heading
                      as="h4"
                      fontSize={{
                        base: 18,
                        lg: 32,
                      }}
                      fontWeight="medium"
                    >
                      Manage and reduce risk
                    </Heading>
                  </Stack>

                  <Text
                    fontSize={{
                      base: 15,
                      lg: 24,
                    }}
                    lineHeight="base"
                    fontWeight={{
                      base: "normal",
                      lg: "medium",
                    }}
                    color="gray.500"
                    w={{
                      base: "100%",
                      lg: "95%",
                    }}
                  >
                    We provide medical insurance to cover all our LaborHack Pros
                    while working on the job; one less thing for you to worry
                    about.
                  </Text>
                </VStack>
              </Box>

              <Box flex={1}>
                <VStack
                  w={{
                    base: "full",
                    lg: "92%",
                  }}
                  alignItems="start"
                  spacing={{
                    base: 2,
                    lg: 4,
                  }}
                >
                  <Stack
                    direction={{
                      base: "row",
                      lg: "column",
                    }}
                    spacing={{
                      base: 4,
                      lg: 8,
                    }}
                    alignItems={{
                      base: "center",
                      lg: "start",
                    }}
                  >
                    <Image
                      src={FlashSectionIcon}
                      w={{
                        base: 10,
                        lg: 20,
                      }}
                    />
                    <Heading
                      as="h4"
                      fontSize={{
                        base: 18,
                        lg: 32,
                      }}
                      fontWeight="medium"
                    >
                      Instant replacement
                    </Heading>
                  </Stack>

                  <Text
                    fontSize={{
                      base: 15,
                      lg: 24,
                    }}
                    lineHeight="base"
                    fontWeight={{
                      base: "normal",
                      lg: "medium",
                    }}
                    color="gray.500"
                    w={{
                      base: "100%",
                      lg: "95%",
                    }}
                  >
                    We ensure minimal disruption to the client&apos;s project by
                    replacing workers on short notice and at no cost.
                  </Text>
                </VStack>
              </Box>
            </Stack>
          </VStack>
        </Section>

        <Section>
          <LaborHackServicesTabs items={laborHackServices} />
        </Section>

        {/*Impact story */}
        <Section bgColor="white" direction="row" pb="10px">
          <ImpactCarousel items={impactStories} />
        </Section>

        {/* How to hire */}
        <Section bgColor="#F7F7F7">
          <HowItWorksTabs
            title="How to hire Artisans on LaborHack for your Business"
            steps={howToHireSteps}
          />
        </Section>

        {/* FAQs */}
        <HStack
          w="full"
          bgColor="prussianBlue.500"
          justifyContent="center"
          position="relative"
        >
          <Box
            w="full"
            position="absolute"
            bgColor="white"
            minH={{
              base: 150,
              lg: 300,
            }}
            top={0}
          />
          <VStack
            zIndex={99}
            px={5}
            py={{
              base: 8,
              lg: 20,
            }}
            spacing={{
              base: 6,
              lg: 8,
            }}
            w="full"
            maxW="container.xl"
            alignItems="center"
          >
            <Heading
              as="h2"
              fontSize={{
                base: 22,
                md: 44,
              }}
              fontWeight="bold"
              color="prussianBlue.500"
              lineHeight={{
                base: "base",
                md: "shorter",
              }}
              textAlign={{
                base: "center",
              }}
            >
              Frequently Asked Questions
            </Heading>

            <Box
              w="full"
              paddingX={{
                base: 4,
                lg: 16,
              }}
              paddingY={{
                base: 4,
                lg: 8,
              }}
              bgColor="flatteredFlamingo.50"
            >
              <FAQs items={businessFAQs} />
            </Box>
          </VStack>
        </HStack>
        <HStack w="full" bgColor="prussianBlue.500" justifyContent="center">
          <VStack
            px={5}
            py={{
              base: 8,
              lg: 20,
            }}
            spacing={{
              base: 6,
              lg: 12,
            }}
            w="full"
            maxW="container.xl"
            alignItems="start"
          >
            <HStack
              w="full"
              justifyContent={{
                base: "start",
                lg: "space-between",
              }}
            >
              <Heading
                as="h5"
                fontSize={{
                  base: "2xl",
                  md: "5xl",
                }}
                fontWeight="normal"
                color="white"
                lineHeight={{
                  base: "base",
                  md: "shorter",
                }}
              >
                <strong>
                  Hire for your next project
                  <br className="block md:hidden" /> and get
                  <br className="hidden md:block" /> the
                </strong>{" "}
                job done
                <br className="block md:hidden" /> right the first time.
              </Heading>
              <Image
                src={ProBadge}
                alt="LaborHack professional badge"
                w={128}
                display={{
                  base: "none",
                  lg: "block",
                }}
              />
            </HStack>
            <HStack w="full">
              <Link flex={1} href={process.env.REACT_APP_MARKETPLACE_URL}>
                <Button
                  w={{
                    base: "full",
                    lg: "40%",
                  }}
                  py={6}
                  size={{
                    base: "lg",
                    md: "xl",
                  }}
                >
                  Hire a Pro
                </Button>
              </Link>
            </HStack>
          </VStack>
        </HStack>
      </VStack>

      <Footer />
    </>
  );
};
