import { FormLabel, FormLabelProps } from "@chakra-ui/react";
import React, { PropsWithChildren } from "react";

export const ThemedFormLabel = ({
  children,
  ...rest
}: PropsWithChildren<FormLabelProps>) => {
  return (
    <FormLabel
      {...rest}
      mb={2}
      color="gray.500"
      fontFamily="Inter"
      fontWeight="normal"
    >
      {children}
    </FormLabel>
  );
};
