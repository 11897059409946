import React, { FC } from "react";
import Button from "@laborhack/custom-button";
import { Link } from "react-router-dom";

export interface SuccessfulVerificationProps {}

export const SuccessfulVerification: FC<SuccessfulVerificationProps> = ({}) => {
  return (
    <div className="flex flex-col items-center h-screen justify-center">
      <h1 className="text-3xl text-primary-500 font-bold mb-4">
        You are now officially on the waitlist - stay tuned!
      </h1>
      <p className="text-primary-300 mb-8 text-center max-w-lg">
        You will receive an email once our marketplace launches.
      </p>

      <Link to="/">
        <Button
          className="mt-8 !bg-primary-500 !text-white"
          type="circular"
          size="big"
          variant="basic"
        >
          Back Home
        </Button>
      </Link>
    </div>
  );
};
