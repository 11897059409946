import React, { FC, useEffect, useState } from "react";
import Input from "@laborhack/input";
import Button from "@laborhack/custom-button";
import { Form } from "semantic-ui-react";
import Joi from "@hapi/joi";
import { useLocation } from "react-router-dom";

import firebase from "helpers/firebase";
import styles from "./styles.module.scss";
import Loading from "_components/Loading";
import RequestFailed from "_components/RequestFailed";

export interface CompleteEmailSignInProps {}

export const CompleteEmailSignIn: FC<CompleteEmailSignInProps> = ({}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const continueUrl = query.get("continueUrl");

  const [confirmationEmail, setConfirmationEmail] = useState({
    isValid: false,
    value: "",
  });

  const handleSignIn = (email: string) => {
    setLoading(true);

    firebase
      .auth()
      .signInWithEmailLink(email, window.location.href)
      .then(() => {
        setLoading(false);
        localStorage.removeItem("login_email");
        continueUrl && window.open(continueUrl, "_self");
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  };

  useEffect(() => {
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      setLoading(true);
      // if the link is opened on a different browser of device, it wouldn't exist in local storage so just confirm the identity
      const email = localStorage.getItem("login_email");

      if (!email) {
        setLoading(false);
        return;
      }

      handleSignIn(email);
    }
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className={styles.wrapper}>
      {error ? (
        <RequestFailed
          onRetry={() => {
            setError(false);
          }}
        />
      ) : (
        <div className={styles.body}>
          <h1>Confirm your Email Address</h1>
          <p>
            To confirm your identity please enter your email. We couldn&apos;t
            complete your sign-in automatically because the link sent to your
            email was opened on a different browser or device.
          </p>
          <Form>
            <Input
              autoComplete="email"
              type="email"
              required
              textLabel="Email Address"
              value={confirmationEmail.value}
              onChange={(value: string, isValid: boolean) => {
                setConfirmationEmail({ value, isValid });
              }}
              validate={{
                check: (value: string) => {
                  return !Joi.string()
                    .email({
                      minDomainSegments: 2,
                      tlds: { allow: false },
                    })
                    .validate(value).error;
                },
                message: "Invalid Email",
              }}
              placeholder="Enter Email Address"
            />
            <Button
              loading={loading}
              disabled={!confirmationEmail.isValid}
              className={styles.submit}
              variant="success"
              fullWidth
              onClick={() => handleSignIn(confirmationEmail.value)}
            >
              Complete Sign In
            </Button>
          </Form>
        </div>
      )}
    </div>
  );
};
