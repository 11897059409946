import React, { FC, useState } from "react";
import { Redirect } from "react-router-dom";
import Button from "@laborhack/custom-button";

import styles from "./styles.module.scss";
import messageSent from "assets/message-sent.svg";
import firebase from "helpers/firebase";
import RequestFailed from "_components/RequestFailed";

export interface CheckEmailProps {}

export const CheckEmail: FC<CheckEmailProps> = ({}) => {
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(false);

  const email = localStorage.getItem("login_email");

  if (!email) {
    return <Redirect to="/auth/register"></Redirect>;
  }

  const handleResendSignInEmail = () => {
    const marketingTag = sessionStorage.getItem("marketing_tag");
    const actionCodeSettings = {
      url:
        window.location.origin +
        "/auth/finishSignIn" +
        (marketingTag ? `?marketing_tag=${marketingTag}` : ""),
      handleCodeInApp: true,
    };

    firebase
      .auth()
      .sendSignInLinkToEmail(email, actionCodeSettings)
      .then(() => {
        setSent(true);
      })
      .catch(() => {
        setError(true);
      });
  };

  return (
    <div className={styles.verify}>
      {error ? (
        <RequestFailed
          onRetry={() => {
            setSent(false);
            setError(false);
          }}
        />
      ) : (
        <>
          <h1>Check Your Email</h1>
          <img src={messageSent} alt="an illustration of a sent message" />
          <section>
            <p>
              A sign-in link email has been sent to your email address{" "}
              <strong>{localStorage.getItem("login_email")}</strong>.
            </p>
            <p>
              If you can’t find the email, check your <strong>spam</strong>{" "}
              folder. Still can’t find it?
              {sent ? (
                <span>Sent</span>
              ) : (
                <Button
                  className={styles.inlineButton}
                  type="link"
                  onClick={handleResendSignInEmail}
                >
                  Resend
                </Button>
              )}
            </p>
          </section>
        </>
      )}
    </div>
  );
};
