import {
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  Heading,
  HStack,
  Icon,
  Image,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useRouteMatch } from "react-router-dom";
import { HomeownerHireContext } from "../../../context/homeowners/homeowner-hire.context";
import { usePageView } from "../../../hooks/usePageView";
import {
  HireLayout,
  HireLayoutContent,
  HireLayoutFooter,
  HireLayoutHeader,
} from "./layout";
import SubscriptionPaymentIcon from "../../../assets/subscription-payment.svg";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import Loading from "_components/Loading";
import { HomeownerRequest, InvoiceDiscount, InvoiceType } from "types";
import RequestFailed from "_components/RequestFailed";
import { logEvent } from "helpers/analytics";
import { GET_INVOICE_PAYMENT_LINK } from "../../../pages/invoice/graphql/mutation";
import { BsArrowRight } from "react-icons/bs";
import { MdArrowOutward } from "react-icons/md";
import { ThemedInput } from "./themed-input";
import { ThemedFormLabel } from "./themed-form-label";

const START_HOMEOWNER_REQUEST_PAYMENT = gql`
  mutation StartHomeownerRequestPayment($id: String!, $discountCode: String) {
    startHomeownerRequestPayment(id: $id, discountCode: $discountCode) {
      id
      invoice {
        id
        appliedDiscount {
          id
          code
          value
        }
      }
      isPaid
    }
  }
`;

const GET_PLAN = gql`
  query CategorySubscriptionPackagePlan(
    $id: String!
    $discount: CategorySubscriptionPackagePlanCostBreakdownDiscountOptions
  ) {
    categorySubscriptionPackagePlan(planId: $id) {
      id
      package {
        id
        name
      }
      costBreakdown(discount: $discount) {
        subTotal
        discount
        tax
        total
        transactionFee
      }
    }
  }
`;

const GET_AUTO_APPLIED_INVOICE_DISCOUNT = gql`
  query AvailableAutoAppliedInvoiceDiscount($invoiceType: InvoiceType) {
    availableAutoAppliedInvoiceDiscount(invoiceType: $invoiceType) {
      id
      code
      value
      valueCap
      balance
    }
  }
`;

const CONFIRM_HOMEOWNER_REQUEST_PAYMENT = gql`
  mutation ConfirmHomeownerRequestPayment(
    $homeownerRequestId: String!
    $transactionReference: String!
  ) {
    confirmHomeownerRequestPayment(
      homeownerRequestId: $homeownerRequestId
      transactionReference: $transactionReference
    ) {
      id
      isPaid
      firstName
      email
      plan {
        id
      }
    }
  }
`;

const GET_INVOICE_DISCOUNT_BY_CODE = gql`
  query GetInvoiceDiscountByCode($code: String!) {
    invoiceDiscountByCode(code: $code) {
      id
      code
      description
      value
      balance
    }
  }
`;

const CAN_APPLY_DISCOUNT = gql`
  query CanApplyDiscount($code: String!, $invoiceType: InvoiceType) {
    canApplyInvoiceDiscount(discountCode: $code, invoiceType: $invoiceType)
  }
`;

const formatPrice = (price: number) => {
  const priceString = price.toFixed(2);

  const priceStringWithSeparator = priceString.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ","
  );

  return `NGN ${priceStringWithSeparator}`;
};

export const SubscriptionPayment = () => {
  usePageView();

  const match = useRouteMatch();

  const [paymentFailed, setPaymentFailed] = React.useState(false);

  const {
    isOpen: isDiscountModalOpen,
    onOpen: onDiscountModalOpen,
    onClose: onDiscountModalClose,
  } = useDisclosure();

  const [discountCode, setDiscountCode] = React.useState("");

  const [invalidDiscountCode, setInvalidDiscountCode] = React.useState(false);

  // grab reference and status from homeowner hire context

  const {
    formData,
    setFormData,
    isLoading,
    categories,
    transactionReference,
    homeownerRequest,
    client,
  } = useContext(HomeownerHireContext);

  const getAutoAppliedInvoiceDiscountHandle = useQuery<{
    availableAutoAppliedInvoiceDiscount: InvoiceDiscount | null;
  }>(GET_AUTO_APPLIED_INVOICE_DISCOUNT, {
    variables: {
      invoiceType: InvoiceType.SUBSCRIPTION,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    onError: (error) => {
      logEvent("homeowner/hire/auto_applied_discount_error", {
        error: error.message,
      });
    },
  });

  const [canApplyDiscount, canApplyDiscountHandle] = useLazyQuery<{
    canApplyInvoiceDiscount: boolean;
  }>(CAN_APPLY_DISCOUNT, {
    onCompleted: (data) => {
      if (data.canApplyInvoiceDiscount) {
        setInvalidDiscountCode(false);
        onDiscountModalClose();
      } else {
        setInvalidDiscountCode(true);
      }
    },
  });

  const [getInvoiceDiscount, getInvoiceDiscountHandle] = useLazyQuery<{
    invoiceDiscountByCode: InvoiceDiscount | null;
  }>(GET_INVOICE_DISCOUNT_BY_CODE, {
    onCompleted: (data) => {
      if (!data.invoiceDiscountByCode) {
        setInvalidDiscountCode(true);
        return;
      }

      if (!client) {
        onDiscountModalClose();
        return;
      }

      canApplyDiscount({
        variables: {
          code: data.invoiceDiscountByCode.code,
          invoiceType: InvoiceType.SUBSCRIPTION,
        },
      });
    },
  });

  const availableDiscount =
    getAutoAppliedInvoiceDiscountHandle.data
      ?.availableAutoAppliedInvoiceDiscount;

  const pulledDiscount = getInvoiceDiscountHandle.data?.invoiceDiscountByCode;

  let shouldApplyDiscount = !!pulledDiscount;

  if (client) {
    shouldApplyDiscount =
      !!canApplyDiscountHandle.data?.canApplyInvoiceDiscount;
  }

  const inUseDiscount =
    availableDiscount || (shouldApplyDiscount ? pulledDiscount : undefined);

  const [getPlan, getPlanHandle] =
    useLazyQuery<{
      categorySubscriptionPackagePlan: {
        id: string;
        package: {
          id: string;
          name: string;
        };
        costBreakdown: {
          subTotal: number;
          discount: number;
          tax: number;
          total: number;
          transactionFee: number;
        };
      };
    }>(GET_PLAN);

  useEffect(() => {
    if (formData.plan) {
      getPlan({
        variables: {
          id: formData.plan,
          ...(inUseDiscount && {
            discount: {
              percentage: inUseDiscount.value,
              maxAmount: inUseDiscount.balance,
            },
          }),
        },
      });
    }
  }, [formData.plan, inUseDiscount]);

  const [getPaymentLink, getPaymentLinkHandle] = useMutation<{
    getInvoicePaymentLink: {
      status: string;
      message: string;
      data: {
        paymentUrl: string;
      };
    };
  }>(GET_INVOICE_PAYMENT_LINK, {
    onCompleted: (data) => {
      if (data.getInvoicePaymentLink.data.paymentUrl) {
        logEvent("homeowner/hire/subscription_payment_link_generated");
        window.open(data.getInvoicePaymentLink.data.paymentUrl, "_self");
      }
    },
  });

  const [confirmHomeownerRequestPayment, confirmHomeownerRequestPaymentResult] =
    useMutation<{
      confirmHomeownerRequestPayment: HomeownerRequest;
    }>(CONFIRM_HOMEOWNER_REQUEST_PAYMENT, {
      onCompleted: (data) => {
        if (!data.confirmHomeownerRequestPayment.isPaid) {
          setPaymentFailed(true);
        }
      },
    });

  const [startHomeownerRequestPayment, startHomeownerRequestPaymentHandle] =
    useMutation<{
      startHomeownerRequestPayment: HomeownerRequest;
    }>(START_HOMEOWNER_REQUEST_PAYMENT, {
      onCompleted: (data) => {
        getPaymentLink({
          variables: {
            invoiceId: data.startHomeownerRequestPayment.invoice?.id,
            providerId: "paystack",
            callback: `${window.location.origin}/homeowners/hire/${data.startHomeownerRequestPayment.id}/subscription-payment`,
          },
        });
      },
    });

  useEffect(() => {
    if (transactionReference && formData.id) {
      logEvent("homeowner/hire/confirming_subscription_payment");

      confirmHomeownerRequestPayment({
        variables: {
          homeownerRequestId: formData.id,
          transactionReference,
        },
      });
    }
  }, [transactionReference, formData.id]);

  const category = formData?.category;

  const categoryObject = categories?.find((cat) => cat.id === category);

  const backToPath = match.url.replace(/\/[^/]+$/, "/address-selector");

  const handlePayment = () => {
    logEvent("homeowner_subscription_payment_initiated", {
      homeownerRequestId: formData.id,
      category: categoryObject?.name,
    });

    if (homeownerRequest?.invoice?.id) {
      getPaymentLink({
        variables: {
          invoiceId: homeownerRequest.invoice.id,
          providerId: "paystack",
          callback: `${window.location.origin}/homeowners/hire/${homeownerRequest.id}/subscription-payment`,
        },
      });
    } else {
      startHomeownerRequestPayment({
        variables: {
          id: formData.id,
          ...(shouldApplyDiscount &&
            !!pulledDiscount && { discountCode: pulledDiscount.code }),
        },
      });
    }
  };

  const homeownerRequestIsPaid =
    confirmHomeownerRequestPaymentResult.data?.confirmHomeownerRequestPayment
      .isPaid;

  useEffect(() => {
    if (homeownerRequestIsPaid) {
      setFormData(
        {
          ...formData,
        },
        "start-date"
      );
    }
  }, [homeownerRequestIsPaid]);

  if (
    isLoading ||
    confirmHomeownerRequestPaymentResult.loading ||
    getAutoAppliedInvoiceDiscountHandle.loading ||
    !formData
  ) {
    return <Loading />;
  }

  if (paymentFailed) {
    return <RequestFailed onRetry={window.location.reload} />;
  }

  const breakdown =
    getPlanHandle.data?.categorySubscriptionPackagePlan.costBreakdown;

  const subTotal = breakdown?.subTotal || 0;
  const transactionFee = breakdown?.transactionFee || 0;
  const total = breakdown?.total || 0;
  const discountValue = breakdown?.discount || 0;
  const tax = breakdown?.tax || 0;
  const amountToPay = total + transactionFee;

  const checkingDiscountCode =
    getInvoiceDiscountHandle.loading || canApplyDiscountHandle.loading;

  return (
    <>
      <Helmet>
        <title>
          Subscription Payment | LaborHack Homeowners - Hire certified artisans
          for your home needs
        </title>
      </Helmet>
      <HireLayout>
        <HireLayoutHeader
          backTo={homeownerRequest?.invoice?.id ? undefined : backToPath}
          isLoading={
            isLoading ||
            getPaymentLinkHandle.loading ||
            startHomeownerRequestPaymentHandle.loading ||
            checkingDiscountCode
          }
        >
          Summary
        </HireLayoutHeader>
        <HireLayoutContent>
          <Stack justifyContent="center" alignItems="center" gap={2}>
            <Image
              src={SubscriptionPaymentIcon}
              alt="subscription payment icon"
              width={{
                base: 24,
                md: 32,
              }}
            />
            <Heading fontWeight={500} fontSize={25}>
              {formatPrice(amountToPay)}
            </Heading>
            <Divider />
            <Box
              width="100%"
              display="flex"
              flexDirection="column"
              backgroundColor="#F4F4F4"
              p={{ base: 4, md: 6 }}
              mt="15px"
              mb="15px"
              rowGap="15px"
            >
              <HStack justifyContent="space-between">
                <Text fontWeight={400} fontSize={16}>
                  Sub Total
                </Text>
                <Text fontWeight={500} fontSize={16}>
                  {formatPrice(subTotal)}
                </Text>
              </HStack>
              {!!inUseDiscount && (
                <HStack justifyContent="space-between">
                  <Text fontWeight={400} fontSize={16}>
                    Discount ({inUseDiscount?.code})
                  </Text>
                  <Text fontWeight={500} fontSize={16}>
                    -{formatPrice(discountValue)}
                  </Text>
                </HStack>
              )}
              <HStack justifyContent="space-between">
                <Text fontWeight={400} fontSize={16}>
                  Tax
                </Text>
                <Text fontWeight={500} fontSize={16}>
                  {formatPrice(tax)}
                </Text>
              </HStack>

              <HStack justifyContent="space-between">
                <Text fontWeight={400} fontSize={16}>
                  Total
                </Text>
                <Text fontWeight={500} fontSize={16}>
                  {formatPrice(total)}
                </Text>
              </HStack>

              <Divider />
              <HStack justifyContent="space-between">
                <Text fontWeight={400} fontSize={16}>
                  Transaction Charge
                </Text>
                <Text fontWeight={500} fontSize={16}>
                  {formatPrice(transactionFee)}
                </Text>
              </HStack>
              <HStack justifyContent="space-between">
                <Text fontWeight={400} fontSize={16}>
                  Amount to Pay
                </Text>
                <Text fontWeight={500} fontSize={16}>
                  {formatPrice(amountToPay)}
                </Text>
              </HStack>
            </Box>
            <Divider />
            {!isDiscountModalOpen && !inUseDiscount && !!client && (
              <Button
                onClick={onDiscountModalOpen}
                variant="unstyled"
                rightIcon={<Icon as={MdArrowOutward} w={4} h={4} />}
              >
                Apply Discount
              </Button>
            )}
            {isDiscountModalOpen && !inUseDiscount && (
              <FormControl
                isInvalid={invalidDiscountCode && !checkingDiscountCode}
              >
                <ThemedFormLabel>Enter Discount Code</ThemedFormLabel>
                <Stack direction="row" spacing={2} alignItems="center">
                  <ThemedInput
                    value={discountCode}
                    onChange={(e) => setDiscountCode(e.target.value)}
                    placeholder="Discount Code"
                    isReadOnly={checkingDiscountCode}
                  />
                  <Button
                    px={6}
                    rightIcon={<Icon as={BsArrowRight} w={4} h={4} />}
                    colorScheme="flatteredFlamingo"
                    onClick={() => {
                      logEvent("homeowner/hire/applying_discount_code", {
                        discountCode,
                      });

                      getInvoiceDiscount({
                        variables: {
                          code: discountCode,
                        },
                      });
                    }}
                    isLoading={checkingDiscountCode}
                  >
                    Apply
                  </Button>
                </Stack>
                {invalidDiscountCode && !checkingDiscountCode && (
                  <FormErrorMessage>Invalid discount code</FormErrorMessage>
                )}
              </FormControl>
            )}
          </Stack>
        </HireLayoutContent>
        {(!transactionReference || !homeownerRequestIsPaid) && (
          <HireLayoutFooter
            isLoading={
              isLoading ||
              getPaymentLinkHandle.loading ||
              startHomeownerRequestPaymentHandle.loading ||
              checkingDiscountCode
            }
            onClick={handlePayment}
            height="61px"
          >
            {`Pay ${formatPrice(amountToPay)}`}
          </HireLayoutFooter>
        )}
      </HireLayout>
    </>
  );
};
