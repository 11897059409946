import {
  Box,
  Button,
  Center,
  Divider,
  Heading,
  Highlight,
  HStack,
  Icon,
  Image,
  Link,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useMemo } from "react";
import YouTube from "react-youtube";
import { Helmet } from "react-helmet";
import { BsArrowRight } from "react-icons/bs";

import VerifiedSectionicon from "../../assets/verified-section-icon.svg";
import InsuranceSectionIcon from "../../assets/hospital-section-icon.svg";
import EarnSectionIcon from "../../assets/earn-section-icon.svg";
import JobSectionIcon from "../../assets/job-section-icon.svg";

import ProLevelOne from "../../assets/pro-level-one.svg";
import ProLevelTwo from "../../assets/pro-level-two.svg";
import ProLevelThree from "../../assets/pro-level-three.svg";
import ProLevelFour from "../../assets/pro-level-four.svg";
import ProBadge from "../../assets/pro-badge.svg";

import Footer from "../../_components/Footer";
import { Section } from "../landing/components/Section";
import FAQs from "../../_components/FAQs";
import HowItWorksTabs from "../../_components/HowItWorksTabs";
import { artisanFAQs } from "../faqs/constants";

// Promo Image
import Promo from "../../assets/canada-artisan-promo.png";

// How it works tab images
import StepOne from "../../assets/artisan/1.jpg";
import StepTwo from "../../assets/artisan/2.jpg";
import StepThree from "../../assets/artisan/3.jpg";
import StepFour from "../../assets/artisan/4.jpg";
import { Logo } from "../../_components/Logo/Logo";
import { logEvent } from "../../helpers/analytics";
import { useQuery } from "../../hooks";

const tabItems = [
  {
    label: "01. Create an account",
    imageSrc: StepOne,
  },
  {
    label: "02. Take an online assesment",
    imageSrc: StepTwo,
  },
  {
    label: "03. Receive your LaborHack certificate",
    imageSrc: StepThree,
  },
  {
    label: "04. Get matched to projects",
    imageSrc: StepFour,
  },
];

export const Artisans = () => {
  const [promoModalOpen, setPromoModalOpen] = React.useState(false);

  const youtubePlayerHeight = useBreakpointValue({
    base: "192",
    lg: "436",
  });

  /**
   * Get the referrer from the url using hooks
   */
  const query = useQuery();

  /**
   * Get referrer from pro.laborhack.com
   */
  const referredBy = query.get("referredBy") || undefined;

  const applicationUrl = `${process.env.REACT_APP_PRO_APPLICATION_URL}${
    referredBy ? `?referredBy=${referredBy}` : ""
  }`;

  return (
    <>
      <Helmet>
        <title>LaborHack for Artisans - Get hired as an artisan faster</title>
        <meta
          name="description"
          content="Get certified internationally and hired faster by top companies that value your skills within and outside Nigeria."
        />
      </Helmet>
      <Modal
        isOpen={promoModalOpen}
        onClose={() => setPromoModalOpen(false)}
        closeOnOverlayClick
        size={{
          base: "md",
          lg: "2xl",
        }}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />

          <VStack w="full" justify="start" spacing={0}>
            <VStack
              w="full"
              align="start"
              spacing={8}
              backgroundColor="flatteredFlamingo.50"
              paddingX={{
                base: 8,
                lg: 16,
              }}
              paddingTop={{
                base: 8,
                lg: 16,
              }}
            >
              <Heading w="full" color="prussianBlue.500">
                Work or study
                <br /> in Canada
                <br />
                <Highlight
                  query={["without stress!"]}
                  styles={{
                    padding: 0,
                    color: "flatteredFlamingo.500",
                  }}
                >
                  without stress!
                </Highlight>
              </Heading>

              <Button
                as="a"
                href="/study-work-abroad"
                colorScheme="flatteredFlamingo"
                variant="outline"
                size="sm"
                rightIcon={<Icon as={BsArrowRight} w={6} h={6} />}
                paddingX={{
                  base: 6,
                  lg: 6,
                }}
                paddingY={{
                  base: 6,
                  lg: 6,
                }}
                fontSize={{
                  base: 15,
                  lg: 18,
                }}
                fontWeight="semibold"
                borderWidth={{
                  base: 2,
                  lg: 3,
                }}
                backgroundColor="white"
                onClick={() => logEvent("clicked_canada_migration_promo_cta")}
              >
                Learn more
              </Button>
            </VStack>
            <VStack
              w="full"
              align="center"
              justifyContent="center"
              spacing={8}
              backgroundColor="white"
              position="relative"
              minH={{
                base: 150,
                lg: 250,
              }}
            >
              <Box
                w="full"
                position="absolute"
                h={{
                  base: 120,
                  lg: 200,
                }}
                top={0}
                backgroundColor="flatteredFlamingo.50"
              />
              <Box
                w="full"
                position="absolute"
                h={{
                  base: 30,
                  lg: 50,
                }}
                bottom={0}
              />
              <Image
                src={Promo}
                w="full"
                zIndex={99}
                paddingX={{
                  base: 8,
                  lg: 16,
                }}
              />
            </VStack>
            <VStack
              w="full"
              align="start"
              spacing={8}
              backgroundColor="white"
              padding={{
                base: 8,
                lg: 16,
              }}
            >
              <HStack w="full" justifyContent="space-between">
                <Logo width={110} height={16} />
                <Text
                  fontFamily={`"AeonikPro", sans-serif`}
                  color="flatteredFlamingo.500"
                >
                  www.laborhack.com
                </Text>
              </HStack>
            </VStack>
          </VStack>
        </ModalContent>
      </Modal>
      <VStack w="full" spacing={0}>
        {/* hero */}
        <Section>
          <VStack w="full" spacing={12}>
            <VStack
              w={{
                base: "full",
                lg: "60%",
              }}
              alignItems="center"
              spacing={{
                base: 6,
                lg: 8,
              }}
            >
              <VStack
                w="full"
                alignItems="center"
                spacing={{
                  base: 3,
                  lg: 4,
                }}
              >
                <Heading
                  as="h1"
                  fontSize={{
                    base: 26,
                    md: 52,
                  }}
                  fontWeight="bold"
                  color="prussianBlue.500"
                  lineHeight={{
                    base: "base",
                    md: "shorter",
                  }}
                  textAlign="center"
                >
                  Get{" "}
                  <Highlight
                    query={["hired"]}
                    styles={{
                      padding: 0,
                      color: "flatteredFlamingo.500",
                    }}
                  >
                    hired
                  </Highlight>{" "}
                  <br /> 10x faster and earn more
                </Heading>
                <Text
                  fontSize={{
                    base: 15,
                    lg: 24,
                  }}
                  lineHeight="base"
                  fontWeight="medium"
                  color="prussianBlue.500"
                  textAlign="center"
                >
                  Stand out from the crowd with internationally recognized
                  certification. Get hired and enjoy benefits like micro-credit,
                  savings, and micro-health insurance.
                </Text>
              </VStack>

              <HStack w="full" spacing={4} justifyContent="center">
                <Link href={applicationUrl}>
                  <Button
                    colorScheme="flatteredFlamingo"
                    paddingY={{
                      base: 6,
                      lg: 10,
                    }}
                    paddingX={{
                      base: 8,
                      lg: 14,
                    }}
                    fontSize={{
                      base: 15,
                      lg: 22,
                    }}
                    rightIcon={<Icon as={BsArrowRight} w={6} h={6} />}
                  >
                    Become a Pro
                  </Button>
                </Link>
              </HStack>
            </VStack>
            <HStack flex={1} w="full" justifyContent="end">
              <Box
                w="full"
                minH={{
                  base: 200,
                  lg: 460,
                }}
                position="relative"
              >
                <Box
                  className="w-[calc(100%-8px)] h-[calc(100%-8px)] lg:w-[calc(100%-24px)] lg:h-[calc(100%-24px)]"
                  bgColor="flatteredFlamingo.50"
                  position="absolute"
                  bottom={0}
                  left={0}
                />
                <Box
                  className="w-[calc(100%-8px)] h-[calc(100%-8px)] lg:w-[calc(100%-24px)] lg:h-[calc(100%-24px)]"
                  position="absolute"
                  top={0}
                  right={0}
                >
                  <YouTube
                    videoId="BrL9YesC8JQ"
                    opts={{
                      width: "100%",
                      height: youtubePlayerHeight,
                      playerVars: {
                        loop: 1,
                        modestbranding: 1,
                      },
                    }}
                  />
                </Box>
              </Box>
            </HStack>
          </VStack>
        </Section>
        <Section>
          <Stack
            w="full"
            direction={{
              base: "column",
              lg: "row",
            }}
            py={{
              base: 6,
              lg: 0,
            }}
            spacing={{
              base: 4,
              lg: 16,
            }}
          >
            <HStack
              w="full"
              flex={1}
              justifyContent={{
                base: "center",
                lg: "start",
              }}
            >
              <Heading
                as="h2"
                fontSize={{
                  base: 22,
                  md: 44,
                }}
                fontWeight="bold"
                color="prussianBlue.500"
                lineHeight={{
                  base: "base",
                  md: "shorter",
                }}
                textAlign={{
                  base: "center",
                  lg: "start",
                }}
              >
                Enjoy the
                <br />{" "}
                <Highlight
                  query={["LaborHack"]}
                  styles={{
                    padding: 0,
                    color: "flatteredFlamingo.500",
                  }}
                >
                  LaborHack
                </Highlight>{" "}
                experience
              </Heading>
            </HStack>
            <HStack
              w="full"
              flex={1}
              justifyContent={{
                base: "center",
                lg: "start",
              }}
            >
              <Text
                fontSize={{
                  base: 15,
                  lg: 24,
                }}
                lineHeight="base"
                fontWeight={{
                  base: "normal",
                  lg: "medium",
                }}
                color="gray.500"
                textAlign={{
                  base: "center",
                  lg: "start",
                }}
              >
                We help you get hired faster, get paid and offer on-the-job
                insurance. That&apos;s the LaborHack Guarantee.
              </Text>
            </HStack>
          </Stack>
        </Section>
        <Section bgColor="gray.50">
          <VStack
            w="full"
            spacing={{
              base: 12,
              lg: 20,
            }}
          >
            <Stack
              w="full"
              direction={{
                base: "column",
                lg: "row",
              }}
              spacing={{
                base: 12,
                lg: 16,
              }}
            >
              <Box flex={1}>
                <VStack
                  w={{
                    base: "full",
                  }}
                  alignItems="start"
                  spacing={{
                    base: 2,
                    lg: 4,
                  }}
                >
                  <Stack
                    direction={{
                      base: "row",
                      lg: "column",
                    }}
                    spacing={{
                      base: 4,
                      lg: 8,
                    }}
                    alignItems={{
                      base: "center",
                      lg: "start",
                    }}
                  >
                    <Image
                      src={EarnSectionIcon}
                      w={{
                        base: 10,
                        lg: 20,
                      }}
                    />
                    <Heading
                      as="h4"
                      fontSize={{
                        base: 18,
                        lg: 32,
                      }}
                      fontWeight="medium"
                    >
                      Earn more money
                    </Heading>
                  </Stack>

                  <Text
                    fontSize={{
                      base: 15,
                      lg: 24,
                    }}
                    lineHeight="base"
                    fontWeight={{
                      base: "normal",
                      lg: "medium",
                    }}
                    color="gray.500"
                  >
                    Certified artisans earn more than their uncertified
                    counterparts as they access higher-paying jobs.
                  </Text>
                </VStack>
              </Box>

              <Box flex={1}>
                <VStack
                  w={{
                    base: "full",
                  }}
                  alignItems="start"
                  spacing={{
                    base: 2,
                    lg: 4,
                  }}
                >
                  <Stack
                    direction={{
                      base: "row",
                      lg: "column",
                    }}
                    spacing={{
                      base: 4,
                      lg: 8,
                    }}
                    alignItems={{
                      base: "center",
                      lg: "start",
                    }}
                  >
                    <Image
                      src={InsuranceSectionIcon}
                      w={{
                        base: 10,
                        lg: 20,
                      }}
                    />
                    <Heading
                      as="h4"
                      fontSize={{
                        base: 18,
                        lg: 32,
                      }}
                      fontWeight="medium"
                    >
                      On-the-job insurance
                    </Heading>
                  </Stack>

                  <Text
                    fontSize={{
                      base: 15,
                      lg: 24,
                    }}
                    lineHeight="base"
                    fontWeight={{
                      base: "normal",
                      lg: "medium",
                    }}
                    color="gray.500"
                  >
                    We provide insurance coverage for all certified artisans
                    while they are at work.
                  </Text>
                </VStack>
              </Box>
            </Stack>
            <Stack
              w="full"
              direction={{
                base: "column",
                lg: "row",
              }}
              spacing={{
                base: 12,
                lg: 16,
              }}
            >
              <Box flex={1}>
                <VStack
                  w={{
                    base: "full",
                  }}
                  alignItems="start"
                  spacing={{
                    base: 2,
                    lg: 4,
                  }}
                >
                  <Stack
                    direction={{
                      base: "row",
                      lg: "column",
                    }}
                    spacing={{
                      base: 4,
                      lg: 8,
                    }}
                    alignItems={{
                      base: "center",
                      lg: "start",
                    }}
                  >
                    <Image
                      src={JobSectionIcon}
                      w={{
                        base: 10,
                        lg: 20,
                      }}
                    />
                    <Heading
                      as="h4"
                      fontSize={{
                        base: 18,
                        lg: 32,
                      }}
                      fontWeight="medium"
                    >
                      Access to more jobs
                    </Heading>
                  </Stack>

                  <Text
                    fontSize={{
                      base: 15,
                      lg: 24,
                    }}
                    lineHeight="base"
                    fontWeight={{
                      base: "normal",
                      lg: "medium",
                    }}
                    color="gray.500"
                  >
                    LaborHack-certified artisans will gain access to our online
                    marketplace, where they can connect with potential clients
                    and find contracts.
                  </Text>
                </VStack>
              </Box>

              <Box flex={1}>
                <VStack
                  w={{
                    base: "full",
                  }}
                  alignItems="start"
                  spacing={{
                    base: 2,
                    lg: 4,
                  }}
                >
                  <Stack
                    direction={{
                      base: "row",
                      lg: "column",
                    }}
                    spacing={{
                      base: 4,
                      lg: 8,
                    }}
                    alignItems={{
                      base: "center",
                      lg: "start",
                    }}
                  >
                    <Image
                      src={VerifiedSectionicon}
                      w={{
                        base: 10,
                        lg: 20,
                      }}
                    />
                    <Heading
                      as="h4"
                      fontSize={{
                        base: 18,
                        lg: 32,
                      }}
                      fontWeight="medium"
                    >
                      Internationally recognized certification
                    </Heading>
                  </Stack>

                  <Text
                    fontSize={{
                      base: 15,
                      lg: 24,
                    }}
                    lineHeight="base"
                    fontWeight={{
                      base: "normal",
                      lg: "medium",
                    }}
                    color="gray.500"
                  >
                    With our internationally recognized certification, you can
                    work anywhere in the world, giving you an edge in a
                    competitive job market.
                  </Text>
                </VStack>
              </Box>
            </Stack>
          </VStack>
        </Section>
        <Section>
          <VStack
            w="full"
            spacing={{
              base: 10,
              lg: 16,
            }}
          >
            <VStack
              w={{
                base: "90%",
                lg: "80%",
              }}
              spacing={{
                base: 3,
                lg: 6,
              }}
            >
              <Heading
                as="h2"
                fontSize={{
                  base: 22,
                  md: 44,
                }}
                fontWeight="bold"
                color="prussianBlue.500"
                lineHeight={{
                  base: "base",
                  md: "shorter",
                }}
                textAlign="center"
              >
                Certification designed by local
                <br className="hidden lg:block" /> experts to meet international
                standards
              </Heading>
              <Text
                fontSize={{
                  base: 15,
                  lg: 24,
                }}
                lineHeight="base"
                fontWeight="medium"
                color="gray.500"
                textAlign="center"
              >
                With our internationally recognised certificate, you can work
                anywhere in the world, giving you an edge to earn more in a
                competitive job market.
              </Text>
            </VStack>
            <Stack
              direction={{
                base: "column",
                lg: "row",
              }}
              spacing={{
                base: 3,
                lg: 16,
              }}
            >
              <VStack
                spacing={{
                  base: 3,
                  lg: 6,
                }}
              >
                <Image
                  src={ProLevelOne}
                  w={{
                    base: 152,
                    lg: 224,
                  }}
                />
                <Text
                  fontFamily="AeonikPro"
                  fontSize={{
                    base: 18,
                    lg: 32,
                  }}
                  fontWeight="medium"
                >
                  Level 1
                </Text>
              </VStack>
              <Center h={16} className="lg:!hidden">
                <Divider orientation="vertical" borderStyle="dashed" />
              </Center>
              <VStack
                spacing={{
                  base: 3,
                  lg: 6,
                }}
              >
                <Image
                  src={ProLevelTwo}
                  w={{
                    base: 152,
                    lg: 224,
                  }}
                />
                <Text
                  fontFamily="AeonikPro"
                  fontSize={{
                    base: 18,
                    lg: 32,
                  }}
                  fontWeight="medium"
                >
                  Level 2
                </Text>
              </VStack>
              <Center h={16} className="lg:!hidden">
                <Divider orientation="vertical" borderStyle="dashed" />
              </Center>
              <VStack
                spacing={{
                  base: 3,
                  lg: 6,
                }}
              >
                <Image
                  src={ProLevelThree}
                  w={{
                    base: 152,
                    lg: 224,
                  }}
                />
                <Text
                  fontFamily="AeonikPro"
                  fontSize={{
                    base: 18,
                    lg: 32,
                  }}
                  fontWeight="medium"
                >
                  Level 3
                </Text>
              </VStack>
              <Center h={16} className="lg:!hidden">
                <Divider orientation="vertical" borderStyle="dashed" />
              </Center>
              <VStack
                spacing={{
                  base: 3,
                  lg: 6,
                }}
              >
                <Image
                  src={ProLevelFour}
                  w={{
                    base: 152,
                    lg: 224,
                  }}
                />
                <Text
                  fontFamily="AeonikPro"
                  fontSize={{
                    base: 18,
                    lg: 32,
                  }}
                  fontWeight="medium"
                >
                  Level 4
                </Text>
              </VStack>
            </Stack>
          </VStack>
        </Section>
        <Section bgColor="gray.50">
          <HowItWorksTabs
            title="Get certified as a LaborHack
              Pro and earn more"
            steps={tabItems}
          />
        </Section>
        <Section>
          <VStack
            w="full"
            spacing={{
              base: 8,
              lg: 10,
            }}
          >
            <VStack
              w={{
                base: "80%",
                lg: "80%",
              }}
              spacing={{
                base: 3,
                lg: 6,
              }}
            >
              <Heading
                as="h2"
                fontSize={{
                  base: 22,
                  md: 44,
                }}
                fontWeight="bold"
                color="prussianBlue.500"
                lineHeight={{
                  base: "base",
                  md: "shorter",
                }}
                textAlign={{
                  base: "center",
                }}
              >
                Hear what our Artisans have
                <br className="hidden lg:block" /> to say about LaborHack
              </Heading>
            </VStack>
            <Stack
              direction={{
                base: "column",
                lg: "row",
              }}
              w="full"
              spacing={6}
            >
              <VStack flex={1} spacing={0}>
                <YouTube
                  className="w-full"
                  videoId="qKxcQyiEMrc"
                  opts={{
                    width: "100%",
                    playerVars: {
                      mute: 1,
                      controls: 0,
                      loop: 1,
                      modestbranding: 1,
                    },
                  }}
                />
                <HStack
                  paddingX={5}
                  paddingY={6}
                  backgroundColor="lawnGreen.50"
                  w="full"
                >
                  <Text
                    fontSize={{
                      base: 16,
                      lg: 24,
                    }}
                    fontWeight="semibold"
                  >
                    Clement Osayande
                  </Text>
                </HStack>
              </VStack>
              <VStack flex={1} spacing={0}>
                <YouTube
                  className="w-full"
                  videoId="wdroFH9NsxQ"
                  opts={{
                    width: "100%",
                    playerVars: {
                      mute: 1,
                      controls: 0,
                      loop: 1,
                      modestbranding: 1,
                    },
                  }}
                />
                <HStack
                  paddingX={5}
                  paddingY={6}
                  backgroundColor="galleonBlue.50"
                  w="full"
                >
                  <Text
                    fontSize={{
                      base: 16,
                      lg: 24,
                    }}
                    fontWeight="semibold"
                  >
                    Kayode Anthony
                  </Text>
                </HStack>
              </VStack>
              <VStack flex={1} spacing={0}>
                <YouTube
                  className="w-full"
                  videoId="HbHXLNIljbk"
                  opts={{
                    width: "100%",
                    playerVars: {
                      mute: 1,
                      controls: 0,
                      loop: 1,
                      modestbranding: 1,
                    },
                  }}
                />
                <HStack
                  paddingX={5}
                  paddingY={6}
                  backgroundColor="flatteredFlamingo.50"
                  w="full"
                >
                  <Text
                    fontSize={{
                      base: 16,
                      lg: 24,
                    }}
                    fontWeight="semibold"
                  >
                    Michael Ogunnaike
                  </Text>
                </HStack>
              </VStack>
            </Stack>
          </VStack>
        </Section>
        <HStack
          w="full"
          bgColor="prussianBlue.500"
          justifyContent="center"
          position="relative"
        >
          <Box
            w="full"
            position="absolute"
            bgColor="white"
            minH={{
              base: 150,
              lg: 300,
            }}
            top={0}
          />
          <VStack
            zIndex={99}
            px={5}
            py={{
              base: 8,
              lg: 20,
            }}
            spacing={{
              base: 6,
              lg: 8,
            }}
            w="full"
            maxW="container.xl"
            alignItems="center"
          >
            <Heading
              as="h2"
              fontSize={{
                base: 22,
                md: 44,
              }}
              fontWeight="bold"
              color="prussianBlue.500"
              lineHeight={{
                base: "base",
                md: "shorter",
              }}
              textAlign={{
                base: "center",
              }}
            >
              Frequently Asked Questions
            </Heading>

            <Box
              w="full"
              paddingX={{
                base: 4,
                lg: 16,
              }}
              paddingY={{
                base: 4,
                lg: 8,
              }}
              bgColor="flatteredFlamingo.50"
            >
              <FAQs items={artisanFAQs} />
            </Box>
          </VStack>
        </HStack>
        <Section bg="prussianBlue.500">
          <VStack
            w="full"
            spacing={{
              base: 6,
              lg: 12,
            }}
            alignItems="start"
          >
            <HStack
              w="full"
              justifyContent={{
                base: "start",
                lg: "space-between",
              }}
            >
              <Heading
                as="h5"
                fontSize={{
                  base: "2xl",
                  md: "5xl",
                }}
                fontWeight="normal"
                color="white"
                lineHeight={{
                  base: "base",
                  md: "shorter",
                }}
                w={{
                  base: "full",
                  lg: "55%",
                }}
              >
                <strong>Stand out from the crowd and get</strong> hired 10x
                faster
              </Heading>
              <Image
                src={ProBadge}
                alt="LaborHack professional badge"
                w={128}
                display={{
                  base: "none",
                  lg: "block",
                }}
              />
            </HStack>
            <HStack w="full">
              <Link
                href={applicationUrl}
                w={{
                  base: "full",
                  lg: "40%",
                }}
              >
                <Button
                  w="full"
                  py={6}
                  size={{
                    base: "lg",
                    md: "xl",
                  }}
                >
                  Become a Pro
                </Button>
              </Link>
            </HStack>
          </VStack>
        </Section>
        <Footer />
      </VStack>
    </>
  );
};
