import React from "react";
import { Button, Image, Stack, Text, Icon, Box } from "@chakra-ui/react";
import { BsArrowRight } from "react-icons/bs";
import { useQuery } from "hooks";

interface CourseCardProps {
  title: string;
  image: string;
}

export const CourseCard = (props: CourseCardProps) => {

  /**
   * Get referrer from pro share link
   */
    const query = useQuery();
    const referredBy = query.get("referredBy") || undefined;

    const applicationUrl = `${process.env.REACT_APP_PRO_APPLICATION_URL}?preferredTrack=STUDYWORK${referredBy ? `&referredBy=${referredBy}` : ""}`


  return (
    <Box
      px={{
        base: 2,
        lg: 4,
      }}
    >
      <Stack
        minW={{
          base: "full",
          lg: "360px",
        }}
        rounded="lg"
        border="1px"
        borderColor="prussianBlue.50"
      >
        <Image
          src={props.image}
          alt={props.title}
          objectFit="cover"
          w="full"
          h="200px"
          roundedTop="lg"
        />
        <Stack p={4}>
          <Stack>
            <Text color="flatteredFlamingo.500" fontWeight="semibold">
              SKILLED TRADE
            </Text>
            <Text
              fontFamily="AeonikPro"
              fontSize={{
                base: "20px",
                md: "24px",
              }}
              color="prussianBlue.500"
              fontWeight="semibold"
            >
              {props.title}
            </Text>
          </Stack>
          <Stack direction="row" justify="end">
            <Button
              size="lg"
              as="a"
              href={applicationUrl}
              variant="unstyled"
              rightIcon={<Icon as={BsArrowRight} w={4} h={4} />}
              borderBottom="1px"
              py={2}
            >
              Apply Now
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};
