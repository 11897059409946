import React, { FC } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { HireForm } from "./HireForm";
import Success from "./success";

const $: any = window;

export interface HireProps {}

export const Hire: FC<HireProps> = ({}) => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/success`}>
        <Success />
      </Route>
      <Route exact path={path}>
        <HireForm />
      </Route>
    </Switch>
  );
};
