import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { useAuth0 } from "@auth0/auth0-react";
import { useMemo } from "react";
import { useApplicationConfig } from "../context/ApplicationConfig";

export const useAuth0ApolloClient = () => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  const { toggles, loading } = useApplicationConfig();

  const client = useMemo(() => {
    const httpLink = new HttpLink({
      uri: `${process.env.REACT_APP_BACKEND_URL}/graphql`,
    });

    const authLink = setContext((_, { headers }) => {
      return new Promise(async (resolve) => {
        const token = isAuthenticated ? await getAccessTokenSilently() : "";

        resolve({
          headers: {
            ...headers,
            authorization: token,
            authprovider: "auth0",
          },
        });
      });
    });

    const client = new ApolloClient({
      cache: new InMemoryCache(),
      link: authLink.concat(httpLink),
    });

    return client;
  }, [getAccessTokenSilently, isAuthenticated]);

  if (!loading && toggles?.use_auth0_explicitly) {
    return {
      client,
      loading,
    };
  }

  return {
    loading,
  };
};
