import React, { FC, useEffect } from "react";

import styles from "./styles.module.scss";
import { useSession } from "../../../hooks";
import { CompletionForm } from "./CompletionForm";
import { useHistory, useLocation } from "react-router-dom";
import Loading from "_components/Loading";
import { handleSignIn } from "../helper";
import { useApplicationConfig } from "../../../context/ApplicationConfig";
import { isClient, startsWithZero } from "../../../helpers/utils/funtions";
import { useMutation } from "@apollo/client";
import { REGISTER_CLIENT } from "../../../graphql/mutation.constants";
import { AccountCreationInput } from "./types";

export interface CompleteProfileProps {}

export const CompleteProfile: FC<CompleteProfileProps> = ({}) => {
  const { user, idTokenResult, initializing } = useSession();
  const history = useHistory();
  const { toggles, loading } = useApplicationConfig();

  const { search } = useLocation();

  const query = new URLSearchParams(search);

  const [registerClient, { loading: loadingRegisterClient }] =
    useMutation(REGISTER_CLIENT);

  /**
   * If the user is not new, we check if the user is already completed the profile
   * if the user has completed the profile, handle sign in
   */

  useEffect(() => {
    if (isClient(user, idTokenResult)) {
      handleSignIn(history, toggles);
    }
  }, [user, idTokenResult]);

  const phoneNumber =
    query.get("phoneNumber") || user?.phoneNumber || undefined;

  const marketingTag = sessionStorage.getItem("marketing_tag");

  const leadSourceFromLocalStorage =
    sessionStorage.getItem("lead_source") || undefined;

  const leadSource: string | undefined = marketingTag
    ? "GoogleAd"
    : leadSourceFromLocalStorage;

  const missingFieldExists = !user?.displayName || !phoneNumber || !leadSource;

  const handleAccountCreation = async ({
    firstName,
    lastName,
    phoneNumber,
    leadSource,
  }: AccountCreationInput) => {
    const data = await registerClient({
      variables: {
        data: {
          marketingTag,
          leadSource,
          ...(firstName &&
            lastName && {
              firstName,
              lastName,
            }),
          ...(phoneNumber && {
            phoneNumber:
              "+234" +
              (startsWithZero(phoneNumber)
                ? phoneNumber.substring(1)
                : phoneNumber),
          }),
        },
      },
    });

    if (data) {
      // reload user after server adds user claims
      await user?.reload();
    }

    handleSignIn(history, toggles);
  };

  useEffect(() => {
    /**
     * If missing fields do not exist, complete the account registration on our servers and handle sign in
     */
    if (!missingFieldExists && leadSource) {
      handleAccountCreation({
        phoneNumber: phoneNumber,
        leadSource,
      });
    }
  }, [missingFieldExists, leadSource]);

  return (
    <div className={styles.complete}>
      {(loading || initializing || loadingRegisterClient) && <Loading />}
      <h1>Complete Your Profile</h1>
      <CompletionForm
        handleAccountCreation={handleAccountCreation}
        injectedFields={{
          phoneNumber: query.get("phoneNumber") || undefined,
          leadSource,
        }}
        requestedFields={
          missingFieldExists
            ? {
                name: !user?.displayName,
                phoneNumber: !user?.phoneNumber,
                leadSource: !leadSource,
              }
            : undefined
        }
      />
    </div>
  );
};
