import { formatToNaira } from "helpers/utils/funtions";
import { Category } from "pages/dashboard/types";

export const formatMinimumPlanPrice = (category: Category) => {
  const packages = category.packages;

  const plans = packages.flatMap(({ plans }) => plans);

  const prices = plans.map(({ costBreakdown }) => costBreakdown.total);

  const minimumPlanPrice = Math.min(...prices);

  return formatToNaira(Math.floor(minimumPlanPrice / 1000) * 1000, 0);
};
