import Carpentry from "../../assets/study-work-abroad/carpentry.webp";
import Construction from "../../assets/study-work-abroad/construction.webp";
import Electromechanical from "../../assets/study-work-abroad/electromechanical.webp";
import Hvac from "../../assets/study-work-abroad/hvac.webp";
import Mechanical from "../../assets/study-work-abroad/mechanical.webp";
import ElectricalTechniques from "../../assets/study-work-abroad/electrical-techniques.webp";
import WeldingTechniques from "../../assets/study-work-abroad/welding-techniques.webp";

export const STUDY_WORK_COURSES = [
  {
    title: "Carpentry & Renovation",
    image: Carpentry,
  },
  {
    title: "CNC and Precision Machining",
    image: Mechanical,
  },
  {
    title: "Construction Engineering",
    image: Construction,
  },
  {
    title: "Electromechanical Engineering",
    image: Electromechanical,
  },
  {
    title: "Heating, Refrigeration and AC",
    image: Hvac,
  },
  {
    title: "Electrical Techniques",
    image: ElectricalTechniques,
  },
  {
    title: "Welding Techniques",
    image: WeldingTechniques,
  },
];
