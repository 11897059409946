import { gql } from "@apollo/client";

export const REGISTER_CLIENT = gql`
  mutation registerNewClient($data: RegisterNewClientInput!) {
    registerNewClient(data: $data) {
      status
      message
    }
  }
`;
