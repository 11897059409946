import React, { FC, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import Button from "@laborhack/custom-button";

import styles from "./styles.module.scss";
import messageSent from "assets/message-sent.svg";
import firebase from "helpers/firebase";
import RequestFailed from "_components/RequestFailed";

export interface VerifyEmailProps {}

export const VerifyEmail: FC<VerifyEmailProps> = ({}) => {
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(false);

  if (!firebase.auth().currentUser) {
    return <Redirect to="/auth/register"></Redirect>;
  }

  const handleSignOut = () => {
    firebase.auth().signOut();
  };

  const handleResendVerification = () => {
    const actionCodeSettings = {
      url: window.location.origin,
      handleCodeInApp: true,
    };
    firebase
      .auth()
      .currentUser?.sendEmailVerification(actionCodeSettings)
      .then(() => {
        setSent(true);
      })
      .catch(() => {
        setError(true);
      });
  };

  return (
    <div className={styles.verify}>
      {error ? (
        <RequestFailed
          onRetry={() => {
            setSent(false);
            setError(false);
          }}
        />
      ) : (
        <>
          <h1>Verify Your Email</h1>
          <img src={messageSent} alt="an illustration of a sent message" />
          <section>
            <p>
              Hi. To secure your account, a verification email has been sent to{" "}
              <strong>{firebase.auth().currentUser?.email}</strong>.
            </p>
            <p>
              If you can’t find the email, check your <strong>spam</strong>{" "}
              folder. Still can’t find it?
              {sent ? (
                <span>Sent</span>
              ) : (
                <Button
                  className={styles.inlineButton}
                  type="link"
                  onClick={handleResendVerification}
                >
                  Resend
                </Button>
              )}
            </p>
            <p>
              Wrong email address?{" "}
              <Button
                className={styles.inlineButton}
                type="link"
                onClick={handleSignOut}
              >
                Logout
              </Button>{" "}
              and create a new account.
            </p>
          </section>
          <p>
            Already have an account? <Link to="/auth/login">Sign In</Link>
          </p>
        </>
      )}
    </div>
  );
};
