import {
  FormControl,
  FormErrorMessage,
  Stack,
  Textarea,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { useRouteMatch } from "react-router-dom";
import { HomeownerHireContext } from "../../../context/homeowners/homeowner-hire.context";
import { logEvent } from "../../../helpers/analytics";
import { nonEmpty } from "../../../helpers/utils/funtions";
import { usePageView } from "../../../hooks/usePageView";
import {
  HireLayout,
  HireLayoutContent,
  HireLayoutFooter,
  HireLayoutHeader,
} from "./layout";
import { ThemedFormLabel } from "./themed-form-label";
import { useApplicationConfig } from "context/ApplicationConfig";

export const DescribeTask = () => {
  usePageView();

  const match = useRouteMatch();

  const {toggles} = useApplicationConfig();

  const { formData, setFormData, isLoading, client, categoryHasNonCustomSubscriptionPackages } =
    useContext(HomeownerHireContext);

  const [taskDescription, setTaskDescription] = React.useState(
    formData.description || ""
  );

  const [showError, setShowError] = React.useState(false);

  const handleTaskDescriptionChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setTaskDescription(e.target.value);
    setShowError(false);
  };

  const handleProceed = () => {
    if (!nonEmpty(taskDescription)) {
      setShowError(true);
      return;
    }

    setShowError(false);

    logEvent("homeowner/hire/task_description_set");

    setFormData(
      {
        description: taskDescription,
      },
      client ? "address-selector" : "contact-information"
    );
  };

  const backToPath = (
    toggles &&
    toggles.homeowner_subscription_requests
    && categoryHasNonCustomSubscriptionPackages && formData.isSubscriptionRequest) ? match.url.replace(/\/[^/]+$/, "/subscription-details") :
    (toggles &&
    toggles.homeowner_subscription_requests
    && categoryHasNonCustomSubscriptionPackages && !formData.isSubscriptionRequest) ? match.url.replace(/\/[^/]+$/, "/is-subscription-request") : match.url.replace(/\/[^/]+$/, "");

  return (
    <>
      <Helmet>
        <title>
          Describe Task | LaborHack Homeowners - Hire certified artisans for
          your home needs
        </title>
      </Helmet>
      <HireLayout>
        <HireLayoutHeader backTo={backToPath} isLoading={isLoading}>
          Describe the task you need assistance with
        </HireLayoutHeader>
        <HireLayoutContent>
          <Stack>
            <FormControl>
              <ThemedFormLabel>
                Please describe the specific task or project you need assistance
                with
              </ThemedFormLabel>
              <Textarea
                isInvalid={!nonEmpty(taskDescription) && showError}
                borderRadius={0}
                variant="filled"
                value={taskDescription}
                onChange={handleTaskDescriptionChange}
                size="lg"
                noOfLines={20}
                placeholder="e.g include any details you think the tradesperson should know
              (approx. extension dimensions, timeframe, etc.)"
              />

              <FormErrorMessage>
                Please enter the type of artisan
              </FormErrorMessage>
            </FormControl>
          </Stack>
        </HireLayoutContent>
        <HireLayoutFooter onClick={handleProceed}>Proceed</HireLayoutFooter>
      </HireLayout>
    </>
  );
};
