import React from "react";
import {
  Link,
  ListItem,
  OrderedList,
  Text,
  UnorderedList,
  VStack,
} from "@chakra-ui/layout";
import { FAQProps } from "../../_components/FAQs/FAQs";
import { Link as RouterLink } from "react-router-dom";

export const generalFAQs: FAQProps["items"] = [
  {
    title: "What is LaborHack?",
    body: "LaborHack is a marketplace platform that connects construction companies, facility management companies, SMEs, and homeowners with skilled artisans to manage their projects, facilities, or homes.",
  },
  {
    title: "How does LaborHack work?",
    body: "LaborHack works by connecting you to an artisan(s) that is an ideal fit for your needs ranging from construction services to installation, repair, and maintenance projects. We have over 1,500 skilled artisans that can be deployed to your project.",
  },
  {
    title: "Is LaborHack available in my location?",
    body: "LaborHack is currently available in Lagos State, but we are actively working on expanding our services to other states.",
  },
  {
    title: "How do I create an account on LaborHack?",
    body: (
      <Text>
        To create an account, click this{" "}
        <Link as={RouterLink} textDecoration="underline" to="/auth/register">
          link
        </Link>{" "}
        and fill in the required information.
      </Text>
    ),
  },
  {
    title: "How do I find skilled artisans on LaborHack?",
    body: (
      <VStack alignItems="start" spacing={4}>
        <Text>
          To find skilled artisans on LaborHack, simply follow these steps.
        </Text>

        <OrderedList>
          <ListItem>
            Create an account{" "}
            <Link
              as={RouterLink}
              textDecoration="underline"
              to="/auth/register"
            >
              here
            </Link>
          </ListItem>
          <ListItem>Submit a request</ListItem>
          <ListItem>Get matched</ListItem>
        </OrderedList>
      </VStack>
    ),
  },
  {
    title: "How are artisans verified and certified on LaborHack?",
    body: "LaborHack goes through a rigorous process to ensure that our artisans are an ideal match for your project. Our process includes Identity Verification, Knowledge Tests, and Practical Assessments.",
  },
  {
    title: "How do I pay for artisan services on LaborHack?",
    body: "LaborHack will issue you an invoice that can be paid online through our payment gateway partner as part of the matching process. We support multiple payment methods, including card, bank transfer, and USSD.",
  },
  {
    title: "What if I have an issue with an artisan on LaborHack?",
    body: "In the rare event that you have an issue with an artisan, LaborHack will act as a mediator. We will ensure that you are 100% satisfied with all services provided by our artisans. ",
  },
  {
    title: "How do I contact LaborHack customer support?",
    body: (
      <Text>
        You can fill out the contact form{" "}
        <Link as={RouterLink} textDecoration="underline" to="/contact">
          here
        </Link>{" "}
        or reach out to us at +234 913 159 8380
      </Text>
    ),
  },
];

export const businessFAQs: FAQProps["items"] = [
  {
    title: "What types of businesses use LaborHack?",
    body: (
      <VStack alignItems="start" spacing={4}>
        <Text>LaborHacks supports a wide range of businesses, including:</Text>
        <UnorderedList>
          <ListItem>Construction Businesses</ListItem>
          <ListItem>Facility Management Companies</ListItem>
          <ListItem>Offices</ListItem>
          <ListItem>SMEs</ListItem>
          <ListItem>Others</ListItem>
        </UnorderedList>
      </VStack>
    ),
  },
  {
    title:
      "How do I find and hire skilled artisans for my business on LaborHack?",
    body: (
      <VStack alignItems="start" spacing={4}>
        <Text>
          To find skilled artisans on LaborHack, simply follow these steps.
        </Text>

        <OrderedList>
          <ListItem>
            Create an account{" "}
            <Link
              as={RouterLink}
              textDecoration="underline"
              to="/auth/register"
            >
              here
            </Link>
          </ListItem>
          <ListItem>Submit a request</ListItem>
          <ListItem>Get matched</ListItem>
        </OrderedList>
      </VStack>
    ),
  },
  {
    title: "What certifications do artisans on LaborHack have?",
    body: "",
  },
  {
    title:
      "How can LaborHack help me save time and money on artisan services for my business?",
    body: "LaborHack can help you save time and money by providing you with a steady pipeline of workers with strong theoretical knowledge and practical skills. We help you solve the challenge of finding, vetting, and training artisans. We reduce your hiring time from weeks to minutes.",
  },
  {
    title:
      "How does LaborHack handle payroll and other administrative tasks for hired artisans?",
    body: "",
  },
  {
    title:
      "How does LaborHack ensure the safety and security of my business and its information?",
    body: "LaborHack artisans go through rigorous training, including a Health and Safety Course. In addition, LaborHack provides these artisans with Personal Protective Equipment (PPE) and on-the-job medical insurance.",
  },
];

export const homeownerFAQs: FAQProps["items"] = [
  {
    title:
      "What types of home tasks can I find skilled artisans for on LaborHack?",
    body: "LaborHack has artisans to support all your home tasks, from maintenance to new projects. Our services cover Electrical repairs, Painting and Renovation, AC repair and installation, DSTV repair and installation, Plumbing, Carpentry, Cleaning, and much more.",
  },
  {
    title:
      "How do I find and hire skilled artisans for my home tasks on LaborHack?",
    body: (
      <Text>
        Simply click{" "}
        <Link
          as={RouterLink}
          textDecoration="underline"
          to="/homeowners/hire/new"
        >
          here
        </Link>{" "}
        to create a request. We&apos;ll get you matched with the ideal artisan
        for your request.
      </Text>
    ),
  },
  {
    title:
      "How are artisans on LaborHack vetted and certified for home services?",
    body: "LaborHack goes through a rigorous process to ensure that our artisans are an ideal match for your home projects. Our process includes Identity Verification, Knowledge Tests, and Practical Assessments.",
  },
  {
    title: "How can LaborHack help me save time and money on home services?",
    body: "LaborHack can help you save time and money by providing you with a reliable pipeline of artisans with strong theoretical knowledge and practical skills. We help you solve the challenge of finding and vetting artisans for your Home projects - giving you peace of mind that your home is safe and your project is completed to your specifications.",
  },
  {
    title: "What if I have an issue with an artisan on LaborHack?",
    body: "In the rare event that you have an issue with an artisan, LaborHack will act as a mediator. We will ensure that you are 100% satisfied with all services provided by our artisans.",
  },
  {
    title:
      "How does LaborHack ensure the safety and security of my home and its information?",
    body: "",
  },
];

export const artisanFAQs: FAQProps["items"] = [
  {
    title: "How do I become a certified artisan on LaborHack?",
    // body: "Simply sign up to the LaborHack platform here, complete your registration, and select your Trade to begin the certification process.",
    body: (
      <Text>
        Simply sign up to the LaborHack platform{" "}
        <Link
          textDecoration="underline"
          href={process.env.REACT_APP_PRO_APPLICATION_URL}
        >
          here
        </Link>
        , complete your registration, and select your Trade to begin the
        certification process.
      </Text>
    ),
  },
  {
    title: "What types of services can I offer as an artisan on LaborHack?",
    body: "We allow you to offer all the services you are trained and certified to provide.",
  },
  {
    title: "How do I get hired by businesses or homeowners on LaborHack?",
    body: "Once a business or homeowner creates a job request that fits your profile, we will provide you with all the necessary details and connect you to the job requester.",
  },
  {
    title:
      "How does LaborHack ensure that I receive fair compensation for my services?",
    body: "",
  },
  {
    title: "What types of benefits does LaborHack offer to its artisans?",
    body: (
      <VStack alignItems="start" spacing={4}>
        <Text>LaborHack offers the following benefits:</Text>
        <UnorderedList>
          <ListItem>Increased access to job opportunities</ListItem>
          <ListItem>Increased revenue</ListItem>
          <ListItem>Access to affordable certification</ListItem>
          <ListItem>On-the-job insurance</ListItem>
        </UnorderedList>
      </VStack>
    ),
  },
  {
    title: "How can I contact LaborHack customer support if I have an issue?",
    // body: "You can contact LaborHack by completing this form or calling us at +234 913 159 8380",
    body: (
      <Text>
        You can contact LaborHack by completing{" "}
        <Link as={RouterLink} textDecoration="underline" to="/contact">
          this
        </Link>{" "}
        this form or reach out to us at +234 913 159 8380
      </Text>
    ),
  },
];
