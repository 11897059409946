import React, { FC } from "react";
import styles from "./styles.module.scss";
import clsx from "clsx";

export interface CustomBadgeProps {
  badgeText: string;
  size?: "small" | "normal";
  inverted?: boolean;
  badgeType:
    | "progress"
    | "success"
    | "warning"
    | "complete"
    | "default"
    | "basic"
    | "cancelled";
  dataTestid?: string;
  className?: string;
}

export const CustomBadge: FC<CustomBadgeProps> = ({
  badgeText,
  size,
  inverted,
  badgeType,
  dataTestid,
  className,
}) => {
  const classNames = clsx(
    className,
    styles.layoutComponentBadge,
    size === "small" && styles.small,
    badgeType === "progress" && styles.progress,
    badgeType === "success" && styles.success,
    badgeType === "complete" && styles.complete,
    badgeType === "warning" && styles.warning,
    badgeType === "default" && styles.default,
    badgeType === "basic" && styles.basic,
    inverted && styles.inverted
  );
  return (
    <div className={classNames} data-testid={dataTestid}>
      {badgeText}
    </div>
  );
};
