import { createContext } from "react";
import firebase from "firebase";

const data: {
  user: null | firebase.User;
  idTokenResult?: firebase.auth.IdTokenResult;
  initializing?: boolean;
} = {
  user: null,
};

export const userContext = createContext(data);
