import React from "react";
import { Grid, GridItem } from "@chakra-ui/react";
import { PropsWithChildren } from "react";
import ReviewCard, { ReviewCardProps } from "./ReviewCard";

interface ReviewCardWrapperProps {
  items: ReviewCardProps[];
}

export const ReviewCardWrapper = ({
  items,
}: PropsWithChildren<ReviewCardWrapperProps>) => {
  return (
    <Grid
      w="full"
      templateColumns={{
        base: "repeat(1, 1fr)",
        md: "repeat(2, 1fr)",
        ...(items.length > 2 && { lg: "repeat(3, 1fr)" }),
      }}
      gap={4}
      alignItems="stretch"
    >
      {items.map((item) => {
        return (
          <GridItem key={item.name} alignSelf="stretch">
            <ReviewCard {...item} />
          </GridItem>
        );
      })}
    </Grid>
  );
};
