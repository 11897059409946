import React, { FC } from "react";
import Footer from "../../_components/Footer";
import { useParams } from "react-router-dom";
import {
  Section,
  SectionActions,
  SectionContent,
  SectionHeader,
  SectionImage,
  SectionText,
  SectionTitle,
} from "../landing/components/Section";
import { logEvent } from "helpers/analytics";
import { Helmet } from "react-helmet";
import {
  Heading,
  HStack,
  VStack,
  Button,
  Image,
  Stack,
  Highlight,
  Text,
  Icon,
  Box,
  Link,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import VerifiedSectionicon from "../../assets/eclipsed-verified-icon.svg";
import ArtisanSpeakingToCustomer from "../../assets/artisan-speaking-to-customer.png";
import RepeatingLHBackground from "../../assets/repeating-lh-background.png";
import Supporticon from "../../assets/eclipsed-support-icon.svg";
import { BsArrowRight } from "react-icons/bs";
import CategoriesMarquee from "_components/CategoriesMarquee";
import ProBadge from "../../assets/pro-badge.svg";
import HowItWorksTabs from "_components/HowItWorksTabs";
import SubmitRequestImage from "../../assets/02-submit-request.png";
import ScheduleAnInspectionImage from "../../assets/03-schedule-an-inspection.png";
import ReceiveAQuoteImage from "../../assets/04-receive-a-quote.png";
import ProjectDeliveredImage from "../../assets/05-project-delivered.png";
import { allowedArtisans, locations } from "./constants";
import { useCategories } from "../../hooks/useCategories";

type Params = {
  artisan?: string;
  location?: string;
};

const determineArticle = (artisan: string) => {
  const vowels = ["a", "e", "i", "o", "u"];
  return vowels.includes(artisan[0].toLowerCase()) ? "an" : "a";
};

const formatLocation = (string: string) => {
  if (string !== "near-me") {
    let formattedString = string.replace(/-/g, " ");
    // Capitalize each word
    formattedString = formattedString.replace(/\b\w/g, (char) =>
      char.toUpperCase()
    );
    return `in ${formattedString}`;
  }
  return "near you";
};

export const HireProInLocation: FC = () => {
  const { artisan = "artisan", location = "near-me" } = useParams<Params>();

  const { categories } = useCategories();

  const selectedArtisan =
    allowedArtisans[artisan.toLowerCase()] || allowedArtisans["artisan"];

  const selectedLocation =
    locations.find(
      (allowedLocation) => allowedLocation === location.toLowerCase()
    ) || "near-me";

  const locationText = formatLocation(selectedLocation);

  const description =
    selectedArtisan.prompt || selectedArtisan.categoryName.toLowerCase();

  const selectedCategory = categories?.find(
    (category) => category.name === selectedArtisan.categoryName
  );

  const callToActionUrl = `/homeowners/hire/new${
    selectedCategory ? `?category=${selectedCategory.id}` : ""
  }`;

  const howToHireSteps = [
    {
      label: "01. Submit request",
      sublabel: `Share your requirements, preferences, and any specific details about the task and we'll match you with the best ${selectedArtisan.formattedName} around you. It's simple and free.`,
      imageSrc: SubmitRequestImage,
    },
    {
      label: "02. Arrange an Inspection",
      sublabel:
        "After submitting your request, we'll promptly schedule an inspection at your convenience. Our team will visit your location to assess the task firsthand and ensure we fully understand your needs.",
      imageSrc: ScheduleAnInspectionImage,
    },
    {
      label: "03. Receive a quote",
      sublabel:
        "After the inspection is completed, you will receive a detailed quote within hours. This quote will encompass all relevant aspects of the job.",
      imageSrc: ReceiveAQuoteImage,
    },
    {
      label: "04. Project delivered",
      sublabel:
        "Upon acceptance of the quote and payment, we will deliver your task within the agreed timeframe, ensuring the highest quality standards are met.",
      imageSrc: ProjectDeliveredImage,
    },
  ];

  const handleHeroCTAClick = () => {
    logEvent("hero_cta_clicked");
  };

  return (
    <>
      <Helmet>
        <title>
          {`LaborHack - Hire skilled ${
            selectedArtisan?.formattedName
          }s ${formatLocation(location)} for your home or business`}
        </title>
        <meta
          name="description"
          content={`Hire well-vetted ${
            selectedArtisan?.formattedName
          }s ${formatLocation(
            location
          )} to handle all your ${description} needs.`}
        />
      </Helmet>
      <Section
        backgroundImage={RepeatingLHBackground}
        backgroundSize={"contain"}
      >
        <SectionContent
          order={{
            base: 1,
            lg: 3,
          }}
        >
          <SectionHeader>
            <SectionTitle data-testid="title">
              Hire an expert
              <br />{" "}
              <Highlight
                query={[`${selectedArtisan.formattedName}`]}
                styles={{
                  padding: 0,
                  color: "flatteredFlamingo.500",
                }}
              >
                {selectedArtisan.formattedName}
              </Highlight>{" "}
              {locationText}
            </SectionTitle>
            <SectionText>
              {`Find well-vetted and skilled workers for all your ${description} needs. Just post your job to get a free quote in minutes.`}
            </SectionText>
          </SectionHeader>
          <SectionActions>
            <Link
              data-testid="hire-cta-link"
              flex={1}
              as={RouterLink}
              to={callToActionUrl}
            >
              <Button
                data-testid="hire-cta"
                colorScheme="flatteredFlamingo"
                variant="solid-dark"
                w={{
                  base: "full",
                  lg: "50%",
                }}
                textColor="white"
                paddingY={{
                  base: 6,
                  lg: 8,
                }}
                fontSize={{
                  base: 15,
                  lg: 22,
                }}
                onClick={handleHeroCTAClick}
                rightIcon={<Icon as={BsArrowRight} w={6} h={6} />}
              >
                Hire {determineArticle(artisan)} {selectedArtisan.formattedName}
              </Button>
            </Link>
          </SectionActions>
        </SectionContent>
        <SectionImage
          src={ArtisanSpeakingToCustomer}
          alt="an artisan talking to a customer"
        />
      </Section>
      {/* How to hire */}
      <Section bgColor="white">
        <VStack w="full">
          <HStack w="full" justifyContent="center">
            <Heading
              as="h2"
              fontSize={{
                base: 20,
                md: 40,
              }}
              w="80%"
              fontWeight={{
                base: "normal",
                md: "medium",
              }}
              lineHeight={{
                base: "base",
                md: "shorter",
              }}
              textAlign="center"
            >
              How It Works
            </Heading>
          </HStack>
          <HowItWorksTabs
            className="mt-[-120px] w-full"
            steps={howToHireSteps}
          />
        </VStack>
      </Section>
      <Section bgColor="#F8F8F8" justifyContent="center" alignItems="center">
        <VStack
          w="full"
          spacing={{
            base: 12,
            lg: 20,
          }}
          justifyContent="center"
        >
          <HStack w="full" justifyContent="center">
            <Heading
              as="h2"
              fontSize={{
                base: 20,
                md: 40,
              }}
              w="80%"
              fontWeight={{
                base: "normal",
                md: "medium",
              }}
              lineHeight={{
                base: "base",
                md: "shorter",
              }}
              textAlign="center"
            >
              Every project is{" "}
              <Highlight
                query={["triple"]}
                styles={{
                  padding: 0,
                  color: "flatteredFlamingo.500",
                }}
              >
                triple
              </Highlight>{" "}
              guaranteed
            </Heading>
          </HStack>
          <Stack
            w="full"
            direction={{
              base: "column",
              lg: "row",
            }}
            spacing={{
              base: 12,
              lg: 0,
            }}
          >
            <Box flex={1}>
              <VStack
                w={{
                  base: "full",
                  lg: "92%",
                }}
                minH={{
                  base: "auto",
                  lg: "full",
                }}
                alignItems="start"
                spacing={{
                  base: 2,
                  lg: 4,
                }}
                bgColor="white"
                padding={5}
              >
                <Stack
                  direction={{
                    base: "row",
                    lg: "column",
                  }}
                  spacing={{
                    base: 4,
                    lg: 8,
                  }}
                  alignItems={{
                    base: "center",
                    lg: "start",
                  }}
                >
                  <Image
                    src={VerifiedSectionicon}
                    w={{
                      base: 10,
                      lg: 20,
                    }}
                  />
                  <Heading
                    as="h4"
                    fontSize={{
                      base: 18,
                      lg: 32,
                    }}
                    fontWeight="medium"
                  >
                    LaborHack Guarantee
                  </Heading>
                </Stack>

                <Text
                  fontSize={{
                    base: 15,
                    lg: 24,
                  }}
                  lineHeight="base"
                  fontWeight={{
                    base: "normal",
                    lg: "medium",
                  }}
                  color="gray.500"
                  w={{
                    base: "100%",
                    lg: "95%",
                  }}
                >
                  If you&apos;re not satisfied, we&apos;ll work to make it
                  right.
                </Text>
              </VStack>
            </Box>
            <Box flex={1}>
              <VStack
                w={{
                  base: "full",
                  lg: "92%",
                }}
                minH={{
                  base: "auto",
                  lg: "full",
                }}
                alignItems="start"
                spacing={{
                  base: 2,
                  lg: 4,
                }}
                bgColor="white"
                padding={5}
              >
                <Stack
                  direction={{
                    base: "row",
                    lg: "column",
                  }}
                  spacing={{
                    base: 4,
                    lg: 8,
                  }}
                  alignItems={{
                    base: "center",
                    lg: "start",
                  }}
                >
                  <Image
                    src={VerifiedSectionicon}
                    w={{
                      base: 10,
                      lg: 20,
                    }}
                  />
                  <Heading
                    as="h4"
                    fontSize={{
                      base: 18,
                      lg: 32,
                    }}
                    fontWeight="medium"
                  >
                    Transparent Pricing
                  </Heading>
                </Stack>

                <Text
                  fontSize={{
                    base: 15,
                    lg: 24,
                  }}
                  lineHeight="base"
                  fontWeight={{
                    base: "normal",
                    lg: "medium",
                  }}
                  color="gray.500"
                  w={{
                    base: "100%",
                    lg: "95%",
                  }}
                >
                  With our transparent pricing, you can rest assured knowing
                  there are no hidden fees or surprises.
                </Text>
              </VStack>
            </Box>
            <Box flex={1}>
              <VStack
                w={{
                  base: "full",
                  lg: "92%",
                }}
                minH={{
                  base: "auto",
                  lg: "full",
                }}
                alignItems="start"
                spacing={{
                  base: 2,
                  lg: 4,
                }}
                bgColor="white"
                padding={5}
              >
                <Stack
                  direction={{
                    base: "row",
                    lg: "column",
                  }}
                  spacing={{
                    base: 4,
                    lg: 8,
                  }}
                  alignItems={{
                    base: "center",
                    lg: "start",
                  }}
                >
                  <Image
                    src={Supporticon}
                    w={{
                      base: 10,
                      lg: 20,
                    }}
                  />
                  <Heading
                    as="h4"
                    fontSize={{
                      base: 18,
                      lg: 32,
                    }}
                    fontWeight="medium"
                  >
                    Dedicated Support
                  </Heading>
                </Stack>

                <Text
                  fontSize={{
                    base: 15,
                    lg: 24,
                  }}
                  lineHeight="base"
                  fontWeight={{
                    base: "normal",
                    lg: "medium",
                  }}
                  color="gray.500"
                  w={{
                    base: "100%",
                    lg: "95%",
                  }}
                >
                  Friendly service when you need us - every day of the week.
                </Text>
              </VStack>
            </Box>
          </Stack>
        </VStack>
      </Section>
      <CategoriesMarquee />
      <HStack w="full" bgColor="prussianBlue.500" justifyContent="center">
        <VStack
          px={5}
          py={{
            base: 8,
            lg: 20,
          }}
          spacing={{
            base: 6,
            lg: 12,
          }}
          w="full"
          maxW="container.xl"
          alignItems="start"
        >
          <HStack
            w="full"
            justifyContent={{
              base: "start",
              lg: "space-between",
            }}
          >
            <Heading
              as="h5"
              fontSize={{
                base: "2xl",
                md: "5xl",
              }}
              fontWeight="normal"
              color="white"
              lineHeight={{
                base: "base",
                md: "shorter",
              }}
            >
              <strong>
                Hire for your next project
                <br className="block md:hidden" /> and get
                <br className="hidden md:block" /> the
                <br className="block md:hidden" /> job done right the first
                time.
              </strong>
            </Heading>
            <Image
              src={ProBadge}
              alt="LaborHack professional badge"
              w={128}
              display={{
                base: "none",
                lg: "block",
              }}
            />
          </HStack>
          <HStack w="full">
            <Link
              flex={1}
              as={RouterLink}
              to={callToActionUrl}
              onClick={handleHeroCTAClick}
            >
              <Button
                w={{
                  base: "full",
                  lg: "40%",
                }}
                py={6}
                size={{
                  base: "lg",
                  md: "xl",
                }}
              >
                Get Started
              </Button>
            </Link>
          </HStack>
        </VStack>
      </HStack>
      <Footer />
    </>
  );
};
