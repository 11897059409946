import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import React, { FC, useEffect } from "react";
import Button from "@laborhack/custom-button";
import { formatToNaira } from "../../../helpers/utils/funtions";
import { PaymentProvidersArgs } from "../graphql/args";
import { GET_PAYMENT_PROVIDERS } from "../graphql/query";
import { PaymentProvidersResponse } from "../graphql/responses";
import { IInvoice, InvoicePayment, PaymentProvider } from "../types";

import styles from "./styles.module.scss";
import PaymentProviderCard from "./_components/PaymentProviderCard";
import RequestFailed from "../../../_components/RequestFailed";
import { GET_INVOICE_PAYMENT_LINK } from "../graphql/mutation";
import Loading from "../../../_components/Loading";

export interface SelectPaymentProviderProps {
  invoice: IInvoice;
  invoicePaymentSummary: InvoicePayment;
}

export const SelectPaymentProvider: FC<SelectPaymentProviderProps> = ({
  invoice: { id, allowedPaymentProviders },
  invoicePaymentSummary,
}) => {
  const [getInvoicePayment, getInvoicePaymentResult] = useMutation(
    GET_INVOICE_PAYMENT_LINK,
    {
      onCompleted: (data) => {
        const { paymentUrl } = data?.getInvoicePaymentLink.data;
        window.open(paymentUrl, "_self");
      },
    }
  );

  const [selectedPaymentProvider, setSelectedPaymentProvider] =
    React.useState<string>();

  // get payment providers with transacrion fee query
  const [getPaymentProviders, { loading, data, error }] = useLazyQuery<
    PaymentProvidersResponse,
    PaymentProvidersArgs
  >(GET_PAYMENT_PROVIDERS);

  const paymentProvidersMap: {
    [key: string]: PaymentProvider;
  } = {};

  useEffect(() => {
    getPaymentProviders({
      variables: {
        amount:
          invoicePaymentSummary.total *
          invoicePaymentSummary.nextPaymentPercentage,
      },
    });
  }, [invoicePaymentSummary]);

  data?.paymentProviders.forEach((paymentProvider) => {
    paymentProvidersMap[paymentProvider.id] = paymentProvider;
  });

  if (loading) return <Loading />;

  if (error) return <RequestFailed />;

  const total = Math.ceil(
    invoicePaymentSummary.total * invoicePaymentSummary.nextPaymentPercentage
  );

  const paymentProviders = data?.paymentProviders.filter((provider) =>
    allowedPaymentProviders.includes(provider.id)
  );

  return (
    <div className={styles.wrapper}>
      <h2>Select a payment provider</h2>
      <div className={styles.paymentProviderList}>
        {paymentProviders?.map(
          ({ id, name, logo, transactionFee, isActive }) => {
            return (
              <PaymentProviderCard
                selected={selectedPaymentProvider === id}
                key={id}
                name={name}
                logo={logo}
                transactionFee={transactionFee}
                onClick={() => setSelectedPaymentProvider(id)}
                disabled={!isActive}
              />
            );
          }
        )}
      </div>

      {selectedPaymentProvider && (
        <>
          <div className={styles.totals}>
            <div className={styles.field}>
              <p>Subtotal</p>
              <p>
                <strong>{formatToNaira(total)}</strong>
              </p>
            </div>
            <div className={styles.field}>
              <p>Transaction Fee</p>
              <p>
                <strong>
                  {formatToNaira(
                    paymentProvidersMap[selectedPaymentProvider].transactionFee
                  )}
                </strong>
              </p>
            </div>
            <div className={styles.field}>
              <p>Total</p>
              <p>
                <strong>
                  {formatToNaira(
                    paymentProvidersMap[selectedPaymentProvider]
                      .transactionFee + total
                  )}
                </strong>
              </p>
            </div>
          </div>
          <Button
            className={styles.button}
            size="big"
            loading={getInvoicePaymentResult.loading}
            onClick={() =>
              getInvoicePayment({
                variables: {
                  invoiceId: id,
                  providerId: selectedPaymentProvider,
                },
              })
            }
          >
            Pay{" "}
            {formatToNaira(
              paymentProvidersMap[selectedPaymentProvider].transactionFee +
                total
            )}
          </Button>
        </>
      )}
    </div>
  );
};
