import React, { FC } from "react";
import { Link } from "react-router-dom";
import Button from "@laborhack/custom-button";

import styles from "./styles.module.scss";
import orderConfirmed from "assets/order-confirmed.svg";
import Container from "../../../_components/Container";

export interface SuccessProps {}

export const Success: FC<SuccessProps> = ({}) => {
  return (
    <Container>
      <div className={styles.wrapper}>
        <img
          src={orderConfirmed}
          alt="an illustration of a woman wearing a suit standing next to a giant phone with a checked sign on screen"
        />
        <article>
          <h1>Your request has been placed successfully.</h1>
          <p>Thank you for making a request. We’ll be in touch shortly.</p>
        </article>
        <div className={styles.actions}>
          <Link to="/dashboard/home">
            <Button>View Dashbaord</Button>
          </Link>
        </div>
      </div>
    </Container>
  );
};
