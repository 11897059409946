import {
  Button,
  Flex,
  Icon,
  Link,
  LinkBox,
  LinkOverlay,
  Skeleton,
  Stack,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { Link as RouterLink, useRouteMatch } from "react-router-dom";
import { RiSearch2Line } from "react-icons/ri";
import { useCategories } from "../../hooks/useCategories";
import { logEvent } from "../../helpers/analytics";

export const HeroSectionCTA = () => {
  const match = useRouteMatch();

  const { categories, loading } = useCategories();

  const otherCategory = categories?.find(
    (category) => category.name === "Other"
  );

  const prioritySortedNonOtherCategories = categories
    ?.slice()
    .sort((a, b) => b.priority - a.priority)
    .filter((category) => category.name !== "Other")
    .slice(0, 5);

  return (
    <Stack
      width="full"
      align="center"
      gap={{
        base: 6,
        lg: 8,
      }}
    >
      <Stack
        direction="row"
        width="full"
        maxW={{
          base: "full",
          lg: "80%",
        }}
      >
        <LinkBox
          width="full"
          onClick={() => {
            logEvent("hero_section_cta_search_clicked");
            logEvent("homeowner_hire_workflow_started");
          }}
        >
          <LinkOverlay as={RouterLink} to={`${match.path}/hire/new`}>
            <Stack
              direction="row"
              justify="space-between"
              alignItems="center"
              width="full"
              p={{
                base: 3,
                lg: 5,
              }}
              border="1px"
              borderColor="gray.600"
            >
              <Text
                fontSize={{
                  base: 13,
                  lg: 22,
                }}
                fontWeight="normal"
              >
                What type of artisan do you want to hire?
              </Text>
              <Icon
                as={RiSearch2Line}
                w={{
                  base: 6,
                  lg: 8,
                }}
                h={{
                  base: 6,
                  lg: 8,
                }}
              />
            </Stack>
          </LinkOverlay>
        </LinkBox>
      </Stack>
      <Flex
        wrap="wrap"
        gap={{
          base: 4,
          lg: 8,
        }}
        justify="center"
      >
        {loading ? (
          new Array(6)
            .fill(0)
            .map((_, index) => (
              <Skeleton key={index} width="120px" height="48px" />
            ))
        ) : (
          <>
            {prioritySortedNonOtherCategories?.map((category) => (
              <Link
                key={category.id}
                as={RouterLink}
                to={`${match.path}/hire/new?category=${category.id}`}
                py={{
                  base: 1,
                  lg: 2,
                }}
                px={{
                  base: 3,
                  lg: 4,
                }}
                border="1px"
                borderColor="gray.600"
                fontSize={{
                  base: 13,
                  lg: 20,
                }}
                onClick={() => {
                  logEvent("hero_section_cta_category_clicked", {
                    category: category.name,
                  });
                  logEvent("homeowner_hire_workflow_started", {
                    category: category.name,
                  });
                }}
              >
                {category.proTitle}
              </Link>
            ))}
            {otherCategory && (
              <Link
                as={RouterLink}
                to={`${match.path}/hire/new`}
                py={{
                  base: 1,
                  lg: 2,
                }}
                px={{
                  base: 3,
                  lg: 4,
                }}
                border="1px"
                borderColor="gray.600"
                fontSize={{
                  base: 13,
                  lg: 20,
                }}
                onClick={() => {
                  logEvent("hero_section_cta_category_clicked", {
                    category: "Others",
                  });
                  logEvent("homeowner_hire_workflow_started", {
                    category: "Others",
                  });
                }}
              >
                Others
              </Link>
            )}
          </>
        )}
      </Flex>
    </Stack>
  );
};
