import React, { PropsWithChildren } from "react";
import { Button, Icon } from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { BiLogOut } from "react-icons/bi";
import { FaExternalLinkAlt } from "react-icons/fa";
import clsx from "clsx";

interface LoggedInMenuContentProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  email: string;
  isClient: boolean;
  isPro: boolean;
  logout: () => void;
}

export const LoggedInMenuContent = ({
  email,
  isClient,
  isPro,
  logout,
  className,
}: PropsWithChildren<LoggedInMenuContentProps>) => {
  const hasNoProfiles = !isClient && !isPro;

  const hasBothProfiles = isClient && isPro;

  return (
    <div className={clsx("px-2 py-2", className)}>
      {isClient && (
        <div className="hover:bg-transparent">
          <Button
            className="w-full"
            colorScheme="flatteredFlamingo"
            rightIcon={<Icon as={FaExternalLinkAlt} />}
            onClick={() => {
              window.open(
                process.env.REACT_APP_HOMEOWNER_DASHBOARD_URL,
                "_self"
              );
            }}
          >
            Go To Dashboard
          </Button>
        </div>
      )}
      {isPro && !hasBothProfiles && (
        <div className="hover:bg-transparent">
          <Button
            className="w-full"
            colorScheme="flatteredFlamingo"
            rightIcon={<Icon as={FaExternalLinkAlt} />}
            onClick={() => {
              window.open(process.env.REACT_APP_PRO_APPLICATION_URL, "_self");
            }}
          >
            Go To Dashboard
          </Button>
        </div>
      )}
      <hr className="my-2" />
      <div className="flex flex-col gap-1">
        <h6 className="text-sm font-semibold">Account</h6>
        <div className="hover:bg-transparent">
          <Button
            className="w-full"
            colorScheme="flatteredFlamingo"
            rightIcon={<Icon as={BiLogOut} />}
            variant="ghost"
            onClick={() => {
              logout();
            }}
          >
            Sign Out
          </Button>
        </div>
      </div>
      <hr className="my-2" />
      {hasNoProfiles && (
        <div className="py-2 flex flex-col gap-1">
          <h6 className="text-sm font-semibold">Get Started</h6>
          <div className="flex flex-col gap-2">
            <div className="hover:bg-transparent">
              <Button
                className="w-full"
                colorScheme="flatteredFlamingo"
                rightIcon={<ArrowForwardIcon />}
                onClick={() => {
                  window.open(
                    process.env.REACT_APP_HOMEOWNER_DASHBOARD_URL,
                    "_self"
                  );
                }}
              >
                Hire a Pro
              </Button>
            </div>
            <div className="hover:bg-transparent">
              <Button
                className="w-full"
                colorScheme="flatteredFlamingo"
                rightIcon={<ArrowForwardIcon />}
                variant="ghost"
                onClick={() => {
                  window.open(
                    process.env.REACT_APP_PRO_APPLICATION_URL,
                    "_self"
                  );
                }}
              >
                Become a Pro
              </Button>
            </div>
          </div>
        </div>
      )}
      <hr className="my-2" />
      <div className="px-2 py-2">
        <h6 className="text-sm font-semibold">Logged in as</h6>
        <div className="flex flex-col gap-2">
          <p>{email}</p>
        </div>
      </div>
    </div>
  );
};
