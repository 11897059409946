import React, { FC } from "react";
import { Helmet } from "react-helmet";
import { Link as RouterLink } from "react-router-dom";
import {
  Button,
  Center,
  Checkbox,
  FormControl,
  FormErrorMessage,
  Heading,
  HStack,
  Icon,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Modal,
  ModalContent,
  ModalOverlay,
  Select,
  Stack,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import Footer from "../../../_components/Footer";
import { Section } from "../../landing/components/Section";
import ProBadge from "../../../assets/pro-badge.svg";
import {
  FaCheck,
  FaChevronDown,
  FaEnvelope,
  FaPhoneAlt,
  FaUserAlt,
} from "react-icons/fa";
import { gql, useMutation } from "@apollo/client";
import RequestFailed from "../../../_components/RequestFailed";
import CategoriesMarquee from "../../../_components/CategoriesMarquee";
import {
  isValidEmail,
  isValidPhoneNumber,
  isValidText,
  cleanPhoneNumber,
} from "../../../lib/utils";

const CREATE_ENQUIRY = gql`
  mutation CreateEnquiry(
    $name: String!
    $email: String!
    $phoneNumber: String!
    $accountType: AccountType!
    $message: String!
    $joinedMailingList: Boolean!
  ) {
    createEnquiry(
      name: $name
      email: $email
      phoneNumber: $phoneNumber
      accountType: $accountType
      message: $message
      joinedMailingList: $joinedMailingList
    )
  }
`;

enum AccountType {
  Homeowner = "Homeowner",
  Artisan = "Artisan",
  Business = "Business",
}

const ContactUs: FC = () => {
  const [showSuccess, setShowSuccess] = React.useState(false);

  const [
    createEnquiry,
    { loading: createEnquiryLoading, error: createEnquiryError, reset },
  ] = useMutation(CREATE_ENQUIRY, {
    onCompleted: () => {
      setShowSuccess(true);
      clearForm();
    },
  });

  const [showErrors, setShowErrors] = React.useState(false);

  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [accountType, setAccountType] = React.useState<AccountType>();
  const [message, setMessage] = React.useState("");
  const [joinedMailingList, setJoinedMailingList] = React.useState(false);

  const clearForm = () => {
    setName("");
    setEmail("");
    setPhoneNumber("");
    setAccountType(undefined);
    setMessage("");
    setJoinedMailingList(false);
  };

  const handleSubmit = () => {
    if (
      !isValidText(name) ||
      !isValidEmail(email) ||
      !isValidPhoneNumber(phoneNumber) ||
      !accountType ||
      !isValidText(message)
    ) {
      setShowErrors(true);
      return;
    } else {
      setShowErrors(false);
    }

    createEnquiry({
      variables: {
        name: name.trim(),
        email: email.trim(),
        phoneNumber: "+234" + cleanPhoneNumber(phoneNumber),
        accountType,
        message: message.trim(),
        joinedMailingList,
      },
    });
  };

  if (createEnquiryError) {
    return <RequestFailed onRetry={reset} text={createEnquiryError.message} />;
  }

  return (
    <>
      <Helmet>
        <title>Contact Us | LaborHack</title>
      </Helmet>
      <Modal
        isOpen={showSuccess}
        onClose={() => {
          setShowSuccess(false);
        }}
        closeOnOverlayClick={false}
        size={{
          base: "md",
          lg: "2xl",
        }}
        isCentered
      >
        <ModalOverlay />
        <ModalContent rounded="none">
          <VStack
            p={{
              base: 8,
              lg: 16,
            }}
            spacing={{
              base: 6,
              lg: 12,
            }}
          >
            <VStack
              spacing={{
                base: 4,
                lg: 8,
              }}
            >
              <Center
                rounded="full"
                bgColor="flatteredFlamingo.500"
                p={{
                  base: 4,
                  lg: 6,
                }}
              >
                <Icon
                  as={FaCheck}
                  w={{
                    base: 8,
                    lg: 12,
                  }}
                  h={{
                    base: 8,
                    lg: 12,
                  }}
                  color="white"
                />
              </Center>
              <Heading
                fontSize={{
                  base: 18,
                  lg: 32,
                }}
              >
                Enquiry Sent!
              </Heading>
              <Text
                textAlign="center"
                fontSize={{
                  base: 15,
                  lg: 24,
                }}
                color="gray.500"
              >
                Thank you for sending your enquiry. Our team will be in touch
                with you shortly.
              </Text>
            </VStack>
            <Button
              w={{
                base: "full",
                lg: "60%",
              }}
              fontFamily="AeonikPro"
              size="lg"
              colorScheme="flatteredFlamingo"
              variant="subtle"
              onClick={() => {
                setShowSuccess(false);
              }}
              paddingX={{
                base: 16,
                lg: 32,
              }}
              paddingY={{
                base: 5,
                lg: 10,
              }}
              fontSize={{
                base: 15,
                lg: 24,
              }}
            >
              Close
            </Button>
          </VStack>
        </ModalContent>
      </Modal>
      <VStack spacing={0} minHeight="full">
        <Section>
          <VStack spacing={10} alignContent="start" w="full">
            <VStack spacing={2} alignContent="start" w="full">
              <Heading
                as="h1"
                fontSize={{
                  base: 26,
                  md: 52,
                }}
                fontWeight="bold"
                color="prussianBlue.900"
                lineHeight={{
                  base: "base",
                  md: "shorter",
                }}
                w="full"
              >
                Contact Us
              </Heading>
              <Text
                fontSize={{
                  base: 15,
                  lg: 24,
                }}
                fontWeight={{
                  base: 400,
                  lg: 500,
                }}
                color="prussianBlue.300"
                textAlign="left"
                w="full"
              >
                Do you have general enquiries or need support? Please send us a
                message.
              </Text>
            </VStack>
            <VStack
              w="full"
              spacing={{
                base: 4,
                lg: 6,
              }}
              alignItems="start"
            >
              <Stack
                direction={{
                  base: "column",
                  lg: "row",
                }}
                spacing={{
                  base: 4,
                  lg: 6,
                }}
                w="full"
              >
                <FormControl
                  isInvalid={!isValidText(name) && showErrors}
                  isDisabled={createEnquiryLoading}
                >
                  <InputGroup>
                    <InputLeftElement
                      py={{
                        base: 4,
                        lg: 8,
                      }}
                      px={{
                        base: 6,
                        lg: 12,
                      }}
                      h="full"
                    >
                      <Icon
                        as={FaUserAlt}
                        w={{
                          base: 4,
                          lg: 6,
                        }}
                        h={{
                          base: 4,
                          lg: 6,
                        }}
                        color="gray.400"
                      />
                    </InputLeftElement>
                    <Input
                      type="text"
                      py={{
                        base: 6,
                        lg: 12,
                      }}
                      pl={{
                        base: 12,
                        lg: 20,
                      }}
                      fontSize={{
                        base: 15,
                        lg: 28,
                      }}
                      fontWeight="semibold"
                      placeholder="Your name"
                      fontFamily="AeonikPro"
                      rounded="none"
                      _placeholder={{
                        fontWeight: "medium",
                      }}
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </InputGroup>
                  <FormErrorMessage>Please enter your name</FormErrorMessage>
                </FormControl>

                <FormControl
                  isInvalid={!isValidEmail(email) && showErrors}
                  isDisabled={createEnquiryLoading}
                >
                  <InputGroup>
                    <InputLeftElement
                      py={{
                        base: 4,
                        lg: 8,
                      }}
                      px={{
                        base: 6,
                        lg: 12,
                      }}
                      h="full"
                    >
                      <Icon
                        as={FaEnvelope}
                        w={{
                          base: 4,
                          lg: 6,
                        }}
                        h={{
                          base: 4,
                          lg: 6,
                        }}
                        color="gray.400"
                      />
                    </InputLeftElement>
                    <Input
                      type="email"
                      py={{
                        base: 6,
                        lg: 12,
                      }}
                      pl={{
                        base: 12,
                        lg: 20,
                      }}
                      fontSize={{
                        base: 15,
                        lg: 28,
                      }}
                      fontWeight="semibold"
                      placeholder="Your email address"
                      fontFamily="AeonikPro"
                      rounded="none"
                      _placeholder={{
                        fontWeight: "medium",
                      }}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </InputGroup>
                  <FormErrorMessage>
                    Please enter a valid email address
                  </FormErrorMessage>
                </FormControl>

                <FormControl
                  isInvalid={!isValidPhoneNumber(phoneNumber) && showErrors}
                  isDisabled={createEnquiryLoading}
                >
                  <InputGroup>
                    <InputLeftElement
                      py={{
                        base: 4,
                        lg: 8,
                      }}
                      px={{
                        base: 6,
                        lg: 12,
                      }}
                      h="full"
                    >
                      <Icon
                        as={FaPhoneAlt}
                        w={{
                          base: 4,
                          lg: 6,
                        }}
                        h={{
                          base: 4,
                          lg: 6,
                        }}
                        color="gray.400"
                      />
                    </InputLeftElement>
                    <Input
                      type="tel"
                      py={{
                        base: 6,
                        lg: 12,
                      }}
                      pl={{
                        base: 12,
                        lg: 20,
                      }}
                      fontSize={{
                        base: 15,
                        lg: 28,
                      }}
                      fontWeight="semibold"
                      placeholder="Your phone number"
                      fontFamily="AeonikPro"
                      rounded="none"
                      _placeholder={{
                        fontWeight: "medium",
                      }}
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                  </InputGroup>
                  <FormErrorMessage>
                    Please enter a valid Nigerian phone number
                  </FormErrorMessage>
                </FormControl>
              </Stack>
              <FormControl
                isInvalid={!accountType && showErrors}
                isDisabled={createEnquiryLoading}
              >
                <Select
                  icon={<FaChevronDown />}
                  iconSize="16"
                  w="full"
                  size={{
                    base: "md",
                    lg: "xl",
                  }}
                  variant="filled"
                  fontSize={{
                    base: 15,
                    lg: 28,
                  }}
                  fontWeight="semibold"
                  fontFamily="AeonikPro"
                  placeholder="I am a ..."
                  _placeholder={{
                    fontWeight: "medium",
                  }}
                  value={accountType}
                  color={accountType ? "prussianBlue.500" : "gray.400"}
                  onChange={(e) => {
                    setAccountType(e.target.value as AccountType);

                    console.log(e.target.value as AccountType);
                  }}
                >
                  <option value={AccountType.Homeowner}>
                    I am a Homeowner
                  </option>
                  <option value={AccountType.Artisan}>I am an Artisan</option>
                  <option value={AccountType.Business}>
                    We are a Business
                  </option>
                </Select>
                <FormErrorMessage>
                  Please select a valid account type
                </FormErrorMessage>
              </FormControl>
              <FormControl
                isInvalid={!isValidText(message) && showErrors}
                isDisabled={createEnquiryLoading}
              >
                <Textarea
                  size="lg"
                  py={{
                    base: 4,
                    lg: 6,
                  }}
                  pl={{
                    base: 4,
                    lg: 8,
                  }}
                  fontSize={{
                    base: 15,
                    lg: 28,
                  }}
                  variant="filled"
                  fontWeight="semibold"
                  placeholder="Type a message..."
                  fontFamily="AeonikPro"
                  rounded="none"
                  _placeholder={{
                    fontWeight: "medium",
                  }}
                  minH={{
                    base: "100px",
                    lg: "200px",
                  }}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
                <FormErrorMessage>Please enter a message</FormErrorMessage>
              </FormControl>
              <Checkbox
                colorScheme="flatteredFlamingo"
                size={{
                  base: "md",
                  lg: "lg",
                }}
                isChecked={joinedMailingList}
                onChange={(e) => setJoinedMailingList(e.target.checked)}
                isDisabled={createEnquiryLoading}
              >
                <Text
                  fontFamily="AeonikPro"
                  fontSize={{
                    base: 15,
                    lg: 28,
                  }}
                  px={{
                    base: 2,
                    lg: 4,
                  }}
                  color="prussianBlue.400"
                >
                  I want to join LaborHack&apos;s mailing list
                </Text>
              </Checkbox>
              <Button
                size={{
                  base: "lg",
                  lg: "xl",
                }}
                fontFamily="AeonikPro"
                px={{
                  base: 12,
                  lg: 20,
                }}
                colorScheme="flatteredFlamingo"
                isLoading={createEnquiryLoading}
                onClick={handleSubmit}
              >
                Submit message
              </Button>
            </VStack>
          </VStack>
        </Section>
        <CategoriesMarquee />
        <Section bg="prussianBlue.500">
          <VStack
            w="full"
            spacing={{
              base: 6,
              lg: 12,
            }}
            alignItems="start"
          >
            <HStack
              w="full"
              justifyContent={{
                base: "start",
                lg: "space-between",
              }}
            >
              <Heading
                as="h5"
                fontSize={{
                  base: "2xl",
                  md: "5xl",
                }}
                fontWeight="normal"
                color="white"
                lineHeight={{
                  base: "base",
                  md: "shorter",
                }}
                w={{
                  base: "full",
                  lg: "55%",
                }}
              >
                <strong>Hire for your next project and get the</strong> job done
                right the first time.
              </Heading>
              <Image
                src={ProBadge}
                alt="LaborHack professional badge"
                w={128}
                display={{
                  base: "none",
                  lg: "block",
                }}
              />
            </HStack>
            <HStack w="full">
              <Link
                as={RouterLink}
                to="/auth/register"
                w={{
                  base: "full",
                  lg: "40%",
                }}
              >
                <Button
                  w="full"
                  py={6}
                  size={{
                    base: "lg",
                    md: "xl",
                  }}
                >
                  Get Started
                </Button>
              </Link>
            </HStack>
          </VStack>
        </Section>
        <Footer />
      </VStack>
    </>
  );
};

export default ContactUs;
