import React, { useState, FC } from "react";
import firebase from "helpers/firebase";
import { IFormField } from "./../types";
import { Button, Form, Message, Grid } from "semantic-ui-react";
import { logEvent } from "helpers/analytics";

const ResetPassword: FC = () => {
  const [state, setState] = useState<IFormField>({
    email: "",
    email_sent: false,
  });
  const [errorState, setError] = useState({
    error: null,
  });
  const sendResetEmail = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    firebase
      .auth()
      .sendPasswordResetEmail(state.email)
      .then(() => {
        setState({
          ...state,
          email_sent: true,
        });
      })
      .catch((err) => {
        setError({
          ...errorState,
          error: err.message,
        });
      });
    logEvent("reset_password");
  };

  return (
    <Grid textAlign="center" style={{ height: "90vh" }} verticalAlign="middle">
      <Grid.Column className="reset-card">
        {/* <Header as="h2">Laborhack</Header> */}
        <div className="reset-form__wrapper">
          <div className="form-title">
            <h3>Reset Password</h3>
          </div>
          <div className="form-text">
            {state.email_sent ? (
              <div>
                <p>
                  An email with instructions on how to reset your password has
                  been sent to you. Kindly check your email for further details.
                </p>
              </div>
            ) : (
              <div>
                {errorState.error !== null && (
                  <Message
                    error
                    header="Error"
                    content="No record for this user!"
                  />
                )}
                <p>Enter your email to reset your password.</p>
                <p>
                  An email with instructions on how to reset your password will
                  be sent to you.
                </p>
              </div>
            )}
          </div>
          <div className="form-field">
            {state.email_sent ? (
              <Message
                success
                icon="check"
                content="An email has been sent to you!"
              />
            ) : (
              <div>
                {" "}
                <Form.Input
                  fluid
                  icon="user"
                  iconPosition="left"
                  placeholder="E-mail address"
                  onChange={(e) => {
                    setState({
                      ...state,
                      email: e.target.value,
                    });
                  }}
                  onFocus={() => {
                    setError({
                      ...errorState,
                      error: null,
                    });
                  }}
                />
                <Button fluid size="large" onClick={sendResetEmail}>
                  Reset Password
                </Button>
              </div>
            )}
          </div>
        </div>

        {/* ========================= */}
      </Grid.Column>
    </Grid>
  );
};
export default ResetPassword;
