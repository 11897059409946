export const HIRE_ROUTES = [
  "describe-task",
  "is-subscription-request",
  "subscription-details",
  "contact-information",
  "address-selector",
  "start-date",
  "profile",
  "confirmation",
];
