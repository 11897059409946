import { gql } from "@apollo/client";

export const GET_CATEGORIES = gql`
  query Categories($includeCustomPackages: Boolean) {
    categories {
      id
      name
      proTitle
      imageUrl
      requestCount
      priority
      packages(includeCustomPackages: $includeCustomPackages) {
        name
        maximumCalls
        baseInterval
        id
        isCustom
        plans {
          id
          interval
          discount
          costBreakdown {
            discount
            subTotal
            tax
            total
          }
          price {
            id
          }
        }
      }
    }
  }
`;

export const GET_USER_BY_EMAIL = gql`
  query GetUserByEmail($email: String!) {
    getUserRecordByEmail(email: $email) {
      id
      email
    }
  }
`;

export const GET_USER_BY_PHONENUMBER = gql`
  query GetUserByPhoneNumber($phoneNumber: String!) {
    getUserRecordByPhoneNumber(phoneNumber: $phoneNumber) {
      id
      phoneNumber
    }
  }
`;

export const GET_BUSINESS_BY_EMAIL = gql`
  query GetBusinessByEmail($email: String!) {
    businessByEmail(email: $email) {
      id
      email
      phoneNumber
    }
  }
`;

export const GET_MY_LOCATION = gql`
  query GetMyLocations {
    getMyLocations {
      status
      message
      data {
        defaultLocationId
        locations {
          buildingNumber
          streetName
          city
          landmark
          area
          location
          id
        }
      }
    }
  }
`;

export const MY_CLIENT = gql`
  query MyClient {
    myClient {
      id
      firstName
      lastName
      email
      phoneNumber
    }
  }
`;
